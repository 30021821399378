import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {ReactComponent as GreyCircleIcon} from "../../../assets/Icons/GreyCircle.svg";
import {MORTGAGE} from "../../../constants";

export default class FourthStepDisabled extends React.Component {
  render() {
    const {
      goToPrevStep,
      value,
      userInfo,
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">4</span>
          </div>
          <span className="step-title-disabled-header">Финальные Условия</span>
          {goToPrevStep &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>
        <Row>
          <div className={"hyd-osago-insurance-name-block hyd-prev-step"}>
            <GreyCircleIcon/>
            <div className={"hyd-osago-insurance-name"}>
              <span>{value.name} </span>
              <span>{value.type === MORTGAGE ? "Ипотека" : ""}</span>
            </div>
          </div>
          <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
            <div className={"hyd-osago-ins-result-value"}>{Number(value.price).toLocaleString("ru-RU")} ₽</div>
          </div>
          {userInfo.show_commission && value.agent_commission && <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Комиссия агента</div>
            <div className={"hyd-osago-ins-result-value"}>
              {(value.agent_commission.identifier || !isNaN(value.agent_commission.value)) &&
                (Number(value.agent_commission.value) * Number(value.price) / 100.0).toLocaleString(
                  "ru-RU", {style: "currency", currency: "RUB"})}
              {" "}{value.agent_commission.value} {(value.agent_commission.identifier ||
              !isNaN(value.agent_commission.value)) && "%"}
            </div>
          </div>}
        </Row>
      </>
    )
  }
}