import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  AutoComplete,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";


import {
  goToNextStep,
  goToPrevStep,
  setDataForm,
  clearIsRequestState,
  getAddressData,
} from "../../actions/kkmActions";
import {
  kkmDataFormSelector,
  isRequestSelector,
  errorsKKMSelector,
  regAddressArrSelector,
} from "../../selectors/kkmSelectors";
import {
  transform,
  nameRules,
  checkValueBirthDate,
  checkValueExperienceDate,
  validateGender,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {kkmIdentityDocs} from "./kkmUtils";

const {Option} = Select;


class KkmPerson extends Component {

  componentDidUpdate(prevProps, prevState, snapshot?) {}

  render() {
    const {
      form,
      regAddressArr,
      getAddressData,
      saveAddressData,
      lastName,
      lastNameError,
      middleName,
      middleNameError,
      name,
      nameError,
      birthDate,
      birthDateError,
      phone,
      phoneError,
      email,
      emailError,
      identityDoc,
      identityDocError,
      identityDocSeries,
      identityDocSeriesError,
      identityDocNumber,
      identityDocNumberError,
      identityDocDate,
      identityDocDateError,
      identityDocDivisionCode,
      identityDocDivisionCodeError,
      identityDocIssuer,
      identityDocIssuerError,
      registrationAddress,
      registrationAddressError,
    } = this.props;
    const {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
    } = form;

    return (
      <>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              validateStatus={lastNameError ? "error" : ""}
              help={lastNameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283 hyd-osago-autocomplete"
                htmlFor={"kkm-person_" + lastName}
                labelText={"Фамилия"}
                divId={"float-label-small"}
                child={getFieldDecorator(lastName, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: nameRules,
                })(<Input className={"ant-input hyd-input-small hyd-kkm-283"}/>)}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              validateStatus={nameError ? "error" : ""}
              help={nameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283 hyd-input-autocomplete-small"
                htmlFor={"kkm-person_" + name}
                labelText={"Имя"}
                divId={"float-label-small"}
                child={getFieldDecorator(name, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: nameRules,
                })(<Input className={"ant-input hyd-input-small hyd-kkm-283"}/>)}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              validateStatus={middleNameError ? "error" : ""}
              help={middleNameError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283 hyd-osago-autocomplete-small"
                htmlFor={"kkm-person_" + middleName}
                labelText={"Отчество"}
                divId={"float-label-small"}
                child={getFieldDecorator(middleName, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: false,
                      message: " "
                    },
                    {
                      // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                      pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                      message: " ",
                    },
                    {
                      validator: validateGender(setFieldsValue),
                    },
                  ],
                })(<Input className={"ant-input hyd-input-small hyd-kkm-283"}/>)}
              />
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              validateStatus={birthDateError ? "error" : ""}
              help={birthDateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283"
                htmlFor={"kkm-person_" + birthDate}
                labelText={"Дата рождения"}
                divId={"float-label-small"}
                child={getFieldDecorator(birthDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: !getFieldValue(birthDate),
                      message: " "
                    },
                    {
                      validator: checkValueBirthDate,
                    },
                  ]
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-kkm-283"
                    placeholder=""
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"]
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"]
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              validateStatus={phoneError ? "error" : ""}
              help={phoneError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283"
                htmlFor={"kkm-person_" + phone}
                labelText={"Телефон"}
                divId={"float-label-small"}
                child={getFieldDecorator(phone, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                      message: " "
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-kkm-283"
                    key={"phone-1"}
                    options={{
                      phone: true,
                      phoneRegionCode: 'RU',
                      prefix: "+7",
                      noImmediatePrefix: true,
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-283"
                htmlFor={"kkm-person_" + email}
                labelText={"E-mail"}
                divId={"float-label-small"}
                child={getFieldDecorator(email, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                      message: " ",
                    },
                  ],
                })(<Input className="hyd-input-small hyd-kkm-283"/>)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            {/* Документы */}
            <Form.Item
              validateStatus={identityDocError ? "error" : ""}
              help={identityDocError || ""}
            >
              <div className="hyd-select-small hyd-kkm-283 hyd-osago-select">
                {getFieldDecorator(identityDoc, {
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    placeholder={"Документы"}
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                  >
                    {kkmIdentityDocs.map(({value, text}) =>
                      <Option value={value} key={value}>
                        <div className={"hyd-select-tooltip"}>Документы</div>
                        <div>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
          </Col>

          <Col span={3} style={{marginRight: 30}}>
            {/* Серия */}
            <Form.Item
              validateStatus={identityDocSeriesError ? "error" : ""}
              help={identityDocSeriesError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-137"
                htmlFor={"kkm-person_" + identityDocSeries}
                labelText={"Серия"}
                divId={"float-label-small"}
                child={getFieldDecorator(identityDocSeries, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^\d{2}\d{2}$/g,
                      message: " "
                    }
                  ]
                })(
                  <Cleave
                    key={identityDocSeries}
                    className="ant-input hyd-input-small hyd-kkm-137"
                    options={{
                      numericOnly: true,
                      numeralPositiveOnly: true,
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={4} style={{marginRight: 8}}>
            {/* Номер */}
            <Form.Item
              validateStatus={identityDocNumberError ? "error" : ""}
              help={identityDocNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-136"
                htmlFor={"kkm-person_" + identityDocNumber}
                labelText={"Номер"}
                divId={"float-label-small"}
                child={getFieldDecorator(identityDocNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^\d{6}$/g,
                      message: " "
                    }
                  ]
                })(<Input className="hyd-input-small hyd-kkm-136" maxLength={6}/>)}
              />
            </Form.Item>
          </Col>

          <Col span={3} style={{marginRight: 30}}>
            {/* Дата выдачи */}
            <Form.Item
              validateStatus={identityDocDateError ? "error" : ""}
              help={identityDocDateError || ""}
            >
              <FloatingLabelInput
                divClassName={"hyd-kkm-137"}
                htmlFor={"kkm-person_" + identityDocDate}
                labelText={"Дата выдачи"}
                divId={"float-label-small"}
                child={getFieldDecorator(identityDocDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      validator: checkValueExperienceDate(getFieldValue(birthDate)),
                    },
                  ],
                })(
                  <Cleave
                    className={
                      "ant-input hyd-input-small hyd-kkm-137"
                    }
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            {/* Код подразделения */}
            <Form.Item
              validateStatus={identityDocDivisionCodeError ? "error" : ""}
              help={identityDocDivisionCodeError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-136 kkm-no-margin"
                htmlFor={"kkm-person_" + identityDocDivisionCode}
                labelText={"Код подразд."}
                divId={"float-label-small"}
                child={getFieldDecorator(identityDocDivisionCode, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^\d{3}-\d{3}$/g,
                      message: " "
                    }
                  ]
                })(
                  <Cleave
                    key={identityDocSeries}
                    className="ant-input hyd-input-small hyd-kkm-136"
                    options={{
                      blocks: [3, 3],
                      delimiter: "-",
                      numericOnly: true,
                      numeralPositiveOnly: true,
                    }}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {/* Кем выдан */}
          <Form.Item
            validateStatus={identityDocIssuerError ? "error" : ""}
            help={identityDocIssuerError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-kkm-597"
              htmlFor={"kkm-person_" + identityDocIssuer}
              labelText={"Кем выдан"}
              divId={"float-label-small"}
              child={getFieldDecorator(identityDocIssuer, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[А-Яа-яЁё\d\s-]{5,}$/g,
                    message: " "
                  },
                  {
                    pattern: /^.{5,}$/g,
                    message: " "
                  },
                ]
              })(
                <Input
                  className="ant-input hyd-input-small hyd-kkm-597"
                />
              )}
            />
          </Form.Item>
        </Row>

        <Row>
          {/* Адрес регистрации */}
          <Form.Item
            validateStatus={registrationAddressError ? "error" : ""}
            help={registrationAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-kkm-597 hyd-osago-autocomplete"
              htmlFor={"kkm-person_" + registrationAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(registrationAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={regAddressArr}
                  onSelect={(inputValue) => {
                    regAddressArr.map((value) => {
                      // достаем адрес, регион, страну из массива полученного с бэка
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(registrationAddress)

                        if (valueOfInput.indexOf("_") !== -1) {
                          valueOfInput = valueOfInput.split("_")
                          setFieldsValue({
                            registrationAddress: valueOfInput[1],
                          })
                        }
                        saveAddressData(
                          value.value_kladr,
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        );
                        // }

                      }
                    })
                  }}

                  onChange={(value) => {
                    if (value && value.length > 1) {
                      getAddressData(value, "reg")
                    }
                  }}
                />
              )}
            />
          </Form.Item>
        </Row>
      </>
    )
  }
}

const WrappedKkmPersonForm = Form.create({
    name: "kkm-person",
    mapPropsToFields(props) {
      const {kkmDataForm} = props
      const transformed = transform({kkmDataForm})

      return transformed.kkmDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields)
    },
  }
)(KkmPerson)

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  errors: errorsKKMSelector(state),
  kkmDataForm: kkmDataFormSelector(state),
  regAddressArr: regAddressArrSelector(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      getAddressData,
      clearIsRequestState,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedKkmPersonForm)
