import React, {Component} from "react";
import {Form, Button, notification, Popover} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import {
  isRequestSelector,
  errorsProfileSelector,
  generatedReferralLinkSelector,
  isGenerateRefLinkCompleteSelector,
  QRCodeImageSelector,
} from "../../selectors/profileSelectors";
import {
  clearIsRequestState,
  getProductReferralLink,
  getReferralLink,
  getProductReferralQRCode,
} from "../../actions/profileActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import {tokenSelector} from "../../selectors/loginSelectors";


class ReferralLink extends Component {

  componentDidMount() {
    document.title = "Реферальная ссылка"

    const {token, clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    if (token) {
      getNotifications()
      checkEarnedMoneyAmount()
    }
    clearIsRequestState()
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {isRequest, isGenerateRefLinkComplete, generatedReferralLink} = this.props

    if (isGenerateRefLinkComplete !== prevProps.isGenerateRefLinkComplete && !isRequest && isGenerateRefLinkComplete) {
      setTimeout(async () => await navigator.clipboard.writeText(generatedReferralLink).then(() => {
          notification["success"]({message: "Ссылка скопирована!", duration: 30, placement: "bottomLeft"})
        }, () => {
          notification["error"]({message: "Ошибка при копировании ссылки!", duration: 30, placement: "bottomLeft"})
        })
      )
    }

    const {QRCodeImage} = this.props;

    if (QRCodeImage !== prevProps.QRCodeImage && !isRequest && QRCodeImage) { //открытие QR кода
      let file = new Blob([QRCodeImage], {type: 'image/png'});
      let fileURL = URL.createObjectURL(file);
      fileURL && window.open(fileURL);
    }


  }


  render() {
    const {getReferralLink, getProductReferralLink, getProductReferralQRCode} = this.props;

    return (
      <>
        <div className="rl-container">
          <span className="hyd-very-big-bold-text-small">Реферальная ссылка</span>

          <Form>
            <span className={"hyd-norm-text"}>
              Вам начисляется полное агентское вознаграждение за продажи по реферальной ссылке
            </span>

            <div>
              <div className={"hyd-bold-text-2 hyd-rl-span"}>
                Полисы
              </div>

              <div>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>Общая ссылка</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>ОСАГО</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>КАСКО</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>ИФЛ</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>Ипотека</Button>
              </div>

              <div>
                <Button className="hyd-btn-small-3 hyd-rl-btn">НС</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>КРОСС</Button>
              </div>

            </div>

            <div>
              <div className={"hyd-bold-text-2 hyd-rl-span"}>
                Выбрать СК
              </div>

              <div>
                <Button className="hyd-btn-small-3 hyd-rl-btn">ВСК</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>Абсолют</Button>
                <Button className="hyd-btn-small-3 hyd-rl-btn" disabled>Все</Button>
              </div>
              <div>
                <Popover
                  trigger="click"
                  content={
                    <div>
                      <Button
                        className="hyd-btn-small-3 hyd-rl-btn"
                        onClick={getProductReferralLink}
                      >
                        Ссылка
                      </Button>
                      <Button
                        className="hyd-btn-small-3 hyd-rl-btn"
                        onClick={getProductReferralQRCode}
                      >
                        QR код
                      </Button>
                    </div>
                  }
                >
                  <Button className="hyd-btn-small-3 hyd-rl-btn">Ссылка клиента</Button>
                </Popover>

              </div>
            </div>


            <div>
              <div className={"hyd-bold-text-2 hyd-rl-span"}>
                Приглашение агента
              </div>

              <div>
                <Button
                  className="hyd-btn-small-3 hyd-rl-btn"
                  onClick={getReferralLink}
                >
                  Ссылка на приглашение
                </Button>
              </div>
            </div>

          </Form>

        </div>
        <ModalError type={5}/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  errors: errorsProfileSelector(state),
  generatedReferralLink: generatedReferralLinkSelector(state),
  isGenerateRefLinkComplete: isGenerateRefLinkCompleteSelector(state),
  QRCodeImage: QRCodeImageSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearIsRequestState,
      getReferralLink,
      getProductReferralLink,
      getProductReferralQRCode,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  );


const WrappedReferralLinkForm = Form.create({name: 'referral-link'})(ReferralLink);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedReferralLinkForm);
