import React, {Component} from "react";
import {Button, Form, Popover, Row,} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  clearIsRequestState,
  getPaymentStatus,
} from "../../actions/kkmActions";
import {
  isRequestSelector,
  errorsKKMSelector,
  paymentDocsSelector,
  paymentStatusSelector,
  documentsSelector,
  priceSelector,
  agentCommissionSelector,
  paymentUrlSelector,
  draftIdSelector,
} from "../../selectors/kkmSelectors";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import {downloadDocsButton, get_doc, transform,} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import FirstStepDisabled from "../../components/KKMDisabledSteps/FirstStepDisabled";
import Preloader from "../../components/Preloader";
import {kkmDataFormSelector} from "../../selectors/kkmSelectors";
import {ReactComponent as ErrorMarkIcon} from "../../assets/Icons/ErrorMark.svg";


class SecondStepKKM extends Component {

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    const {clearIsRequestState} = this.props
    clearIsRequestState()
    this.headerRef.current.scrollIntoView()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isRequest,
      paymentDocs,
      paymentDocType,
    } = this.props

    if (paymentDocs !== prevProps.paymentDocs && !isRequest && paymentDocs) { //открытие Документов заявление, копия полиса
      let docType
      if (paymentDocType) {
        docType = paymentDocType
      } else {
        docType = 'application/pdf'
      }
      let file = new Blob([paymentDocs], {type: docType})
      let fileURL = URL.createObjectURL(file)
      fileURL && window.open(fileURL)
    }
  }

  render() {
    const {
      isRequest,
      getPaymentStatus,
      paymentStatus,
      documents,
      userInfo,
      price,
      paymentUrl,
      draftId,
      agentCommission,
    } = this.props

    return (
      <>
        <FirstStepDisabled
          price={price}
          agent_commission={agentCommission}
          userInfo={userInfo}
        />

        <Preloader loading={isRequest}/>

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>
          <span className="step-title-active-header">Оплата</span>
        </div>

        {documents &&
          <Row>
            {documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}

            {["paid"].includes(paymentStatus) ?
              <Button className={"hyd-btn-small hyd-policy-paid"}>Полис оплачен</Button> :
              <>
                <div className={"hyd-accident-payment"}>
                  <Button
                    className={"hyd-btn-small"}
                    onClick={() => {
                      window.open(paymentUrl)
                    }}
                    type="primary"
                  >
                    Оплата онлайн
                  </Button>
                </div>
                <div className={"hyd-accident-payment"}>
                  <Button
                    className={"hyd-btn-small"}
                    type="primary"
                    onClick={() => {
                      getPaymentStatus(draftId)
                    }}
                  >
                    Проверить статус оплаты
                  </Button>
                </div>
              </>
            }
            {paymentStatus && <div>
              {!["paid"].includes(paymentStatus) ?
                <div className={"hyd-not-paid hyd-not-paid-mortgage"}>
                  <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                  <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                </div> : ""
              }
            </div>}

        {paymentUrl &&
          paymentUrl.errors &&
          paymentUrl.errors.error && (
            <div className={"hyd-accident-payment"}>
              <Popover
                content={paymentUrl.errors.error}
                title="Ошибка"
                trigger="hover"
              >
                <Button
                  className={"hyd-btn-small"}
                  type="primary"
                  disabled={true}
                >
                  Оплата картой
                </Button>
              </Popover>
            </div>
          )}


        <ModalError type={11}/>
      </>
    )
  }

}

const WrappedSecondStepKKMForm = Form.create({
    name: "kkm-step-2",
    mapPropsToFields(props) {
      const {kkmDataForm} = props;
      const transformed = transform({kkmDataForm});

      return transformed.kkmDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(SecondStepKKM);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  token: tokenSelector(state),
  paymentDocs: paymentDocsSelector(state),
  kkmDataForm: kkmDataFormSelector(state),
  paymentStatus: paymentStatusSelector(state),
  documents: documentsSelector(state),
  errors: errorsKKMSelector(state),
  userInfo: userInfoSelector(state),
  price: priceSelector(state),
  paymentUrl: paymentUrlSelector(state),
  draftId: draftIdSelector(state),
  agentCommission: agentCommissionSelector(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPaymentStatus,
      clearIsRequestState,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSecondStepKKMForm);
