const getTodayDate = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = dd + "." + mm + "." + yyyy;

  return today;
};

const getFutureDate = (sub_day = 1) => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth();
  let yyyy = today.getFullYear();

  let finishDate = new Date(yyyy + 1, mm, dd - sub_day);

  let finish_dd = finishDate.getDate();
  let finish_mm = finishDate.getMonth() + 1;
  let finish_yyyy = finishDate.getFullYear();
  if (finish_dd < 10) {
    finish_dd = "0" + finish_dd;
  }
  if (finish_mm < 10) {
    finish_mm = "0" + finish_mm;
  }
  finishDate = finish_dd + "." + finish_mm + "." + finish_yyyy;

  return finishDate;
};

const generateDynamicValues = (name, start, end, defaultValue = 1) => {
  const dynamicValues = {}

  for (let i = start; i <= end; i++) {
    dynamicValues[`${name}_${i}`] = {
      value: defaultValue,
      name: `${name}_${i}`,
      errors: false,
      validating: false,
      dirty: false,
    }
  }

  return dynamicValues
}

export default {
  profileReducer: {
    isRequest: false,
    QRCodeImage: null,
    isEditModeOn: false,
    errors: null,
    visibleErrorModal: false,
    errorsCustomVisible: false,
    errorsCustom: null,
    updatingComplete: false,
    resetPasswordComplete: false,
    isGenerateRefLinkComplete: false,
    generatedReferralLink: undefined,
    regions: undefined,
    userAddressArr: undefined,
    selectedUserAddress: false,  // для проверки выбран ли регион из списка
    dataForm: {
      username: undefined,
      account_type: {
        value: "FIZ",
        name: "account_type",
        errors: false,
        validating: false,
        dirty: false
      },
      last_name: undefined,
      first_name: undefined,
      middle_name: undefined,
      phone: undefined,
      email: undefined,
      region: undefined,
      address: undefined,
      commission: undefined,
      referral_link: undefined,
      user_type: undefined,
      supervisor: undefined,
      supervisor_data: {
        sup_full_name: undefined,
        sup_phone: undefined,
        sup_email: undefined,
      },
      credit_limit: undefined,
      passport: undefined,
      passport_issued_by: undefined,
      passport_date: undefined,
      passport_unite_code: undefined,
      gender: undefined,
      birth_date: undefined,
      inn: undefined,
      snils: undefined,
      juristic_name: undefined,
      juristic_boss_full_name: undefined,
      juristic_ogrn: undefined,
      bank_account: undefined,
      bank_bik: undefined,
      bank_division_address: undefined,
      bank_division_code: undefined,
      bank_ks: undefined,
      bank_name: undefined,
      bank_recipient: undefined,
      card_bank_num: undefined,
      card_holder_name: undefined,
      card_valid_date: undefined,
    },
    dataFormResult: undefined,
    cardPaymentSystem: undefined,
  },
  registrationReducer: {
    isRequest: false,
    errors: null,
    visibleErrorModal: false,
    signUpComplete: false,
    regionId: undefined,
    refId: undefined,
  },
  loginReducer: {
    token: undefined,
    isRequest: false,
    errors: null,
    visibleErrorModal: false,
    oneTimeToken: null,
    userInfo: {
      can_access_payment_acts: false,
      can_create_bso_policy: false,
      can_create_ebso_policy: false,
      can_create_eosago_policy: false,
      user_full_name: "",
      user_header_name: undefined,
      user_type: "",
      show_commission: false,
    },
  },
  agentsReducer: {
    errors: null,
    pageSize: 12,
    currentPage: 1,
    agentsTypes: [],
    kvCategories: [],
    agents: undefined,
    isRequest: false,
    filterIsActive: undefined,
    filterIsOnline: undefined,
    filterShowKV: undefined,
    selectedAgentsList: [],
    selectedSupervisorsList: [],
    visibleErrorModal: false,
    isAllAgentsReceived: false,
    agentsTypesSelectedFilters: [],
    kvCategoriesSelectedFilters: [],
  },
  osagoReducer: {
    isRenewal: false,
    renewalInsuranceId: undefined,
    renewalPolicySerial: undefined,
    renewalPolicyNum: undefined,
    renewalFinishPolicyDate: undefined,
    isRequest: false,
    isRequestCrossProducts: false,
    creatingPolicy: false,
    policyCreated: false,
    errors: null,
    currentStep: 0,
    calculationInProgress: false,
    calculationComplete: false,
    typeTransportInsurer: 1,
    sberPaymentUrl: undefined,
    sberQRImage: undefined,
    renessans_docs: undefined,
    crossProducts: [],
    selectedCrossProducts: [],
    dataForm: {
      draft_id: undefined,
      alien_plate: undefined,
      transit_plate: undefined,
      car_plate: undefined,
      vehicle_type: {
        value: 2,
        name: "vehicle_type",
        errors: false,
        validating: false,
        dirty: false
      },
      vin: undefined,
      car_dk_date: undefined,
      car_to_number: undefined,
      body_number: undefined,
      chassis_number: undefined,
      cladr: undefined,
      brand_car: undefined,
      model_car: undefined,
      modification_car: undefined,
      car_year_issue: undefined,
      engine_power: undefined,
      documents: {
        value: 1,
        name: "documents",
        errors: false,
        validating: false,
        dirty: false
      },
      series_document: undefined,
      number_document: undefined,
      date_of_issue_document: undefined,
      inshur: {
        value: 1,
        name: "inshur",
        errors: false,
        validating: false,
        dirty: false
      }, //null был
      policy_owner_inshur: {
        value: 1,
        name: "policy_owner_inshur",
        errors: false,
        validating: false,
        dirty: false
      },
      number_drivers: {
        value: 1,
        name: "number_drivers",
        errors: false,
        validating: false,
        dirty: false
      },
      car_owner_juristic_document_type: {
        value: 1,
        name: "car_owner_juristic_document_type",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_juristic_document_type: {
        value: 1,
        name: "policy_owner_juristic_document_type",
        errors: false,
        validating: false,
        dirty: false
      },
      is_foreign_vehicle: {
        value: false,
        name: "is_foreign_vehicle",
        errors: false,
        validating: false,
        dirty: false
      },
      full_name: undefined,
      birtday: undefined,
      series_number: undefined,
      date_of_issue_doc: undefined,
      passport_whos: undefined,
      address: undefined,
      is_owner: {
        value: true,
        name: "is_owner",
        errors: false,
        validating: false,
        dirty: false
      },
      phone: undefined,
      email: undefined,
      start_policy_date: {
        value: getTodayDate(),
        name: "start_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      finish_policy_date: {
        value: getFutureDate(),
        name: "finish_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      start_vehicle_date: {
        value: getTodayDate(),
        name: "start_vehicle_date",
        errors: false,
        validating: false,
        dirty: false
      },
      finish_vehicle_date: {
        value: getFutureDate(),
        name: "finish_vehicle_date",
        errors: false,
        validating: false,
        dirty: false
      },
      vehicle_duration: {
        value: 8,
        name: "vehicle_duration",
        errors: false,
        validating: false,
        dirty: false
      },
      trailer: {
        value: 1,
        name: "trailer",
        errors: false,
        validating: false,
        dirty: false
      },
      rent: {
        value: 2,
        name: "rent",
        errors: false,
        validating: false,
        dirty: false
      },
      purpose_use: {
        value: 4,
        name: "purpose_use",
        errors: false,
        validating: false,
        dirty: false
      },
      gross_violation: {
        value: 1,
        name: "gross_violation",
        errors: false,
        validating: false,
        dirty: false
      },
      car_owner_document_number: undefined,
      car_owner_driving_experience: undefined,
      kbm_coeff: undefined,
      duration: {
        value: 11,
        name: "duration",
        errors: false,
        validating: false,
        dirty: false
      },
      //insured_accident_count: undefined,
      //policy_owner_insured_accident_count: { value: 0, name: "policy_owner_insured_accident_count", errors: false, validating: false, dirty: false },//undefined,//{value: ''}
      //car_owner_insured_accident_count: { value: 0, name: "car_owner_insured_accident_count", errors: false, validating: false, dirty: false },//undefined,//{value: ''}
      // policy_owner_birth_date_driver: {
      //   value: "",
      //   name: "policy_owner_birth_date_driver",
      //   errors: false,
      //   validating: false,
      //   dirty: false
      // },
      policy_owner_gender: {
        value: "M",
        name: "policy_owner_gender",
        errors: false,
        validating: false,
        dirty: false
      },
      car_owner_gender: {
        value: "M",
        name: "car_owner_gender",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_identity_document: {
        value: 1,
        name: "policy_owner_identity_document",
        errors: false,
        validating: false,
        dirty: false
      },
      identity_document: {
        value: 1,
        name: "identity_document",
        errors: false,
        validating: false,
        dirty: false
      },
      allowed_person_type: {
        value: 1,
        name: "allowed_person_type",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_document_type: {
        value: 1,
        name: "policy_owner_document_type",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_family_state: {
        value: 1,
        name: "policy_owner_family_state",
        errors: false,
        validating: false,
        dirty: false
      },
      car_brand_data: undefined,
      car_model_data: undefined,
      car_modification_data: undefined,
      prolongation_previous_policy_insurance_company: {
        value: "seva",
        name: "prolongation_previous_policy_insurance_company",
        errors: false,
        validating: false,
        dirty: false
      },
      prolongation_policy_owner_inshur: {
        value: 1,
        name: "prolongation_policy_owner_inshur",
        errors: false,
        validating: false,
        dirty: false
      },
      // addendum:
      agent_email: {
        value: undefined,
        name: "agent_email",
        errors: false,
        validating: false,
        dirty: false
      },
      insurance_company: {
        value: undefined,
        name: "insurance_company",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_series: {
        value: undefined,
        name: "policy_series",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_number: {
        value: undefined,
        name: "policy_number",
        errors: false,
        validating: false,
        dirty: false
      },
      note: {
        value: undefined,
        name: "note",
        errors: false,
        validating: false,
        dirty: false
      },
      add_driver: {
        value: false,
        name: "add_driver",
        errors: false,
        validating: false,
        dirty: false
      },
      delete_driver: {
        value: false,
        name: "delete_driver",
        errors: false,
        validating: false,
        dirty: false
      },
      change_number_sts: {
        value: false,
        name: "change_number_sts",
        errors: false,
        validating: false,
        dirty: false
      },
      change_driver_license: {
        value: false,
        name: "change_driver_license",
        errors: false,
        validating: false,
        dirty: false
      },
      add_or_change_reg_plate: {
        value: false,
        name: "add_or_change_reg_plate",
        errors: false,
        validating: false,
        dirty: false
      },
      change_last_name: {
        value: false,
        name: "change_last_name",
        errors: false,
        validating: false,
        dirty: false
      },
      prolongation_insurance_period: {
        value: false,
        name: "prolongation_insurance_period",
        errors: false,
        validating: false,
        dirty: false
      },
      change_address: {
        value: false,
        name: "change_address",
        errors: false,
        validating: false,
        dirty: false
      },
    },
    dataFormResult: undefined, //отдельный объект для отправки данных на сервер из-за того,что переносим в отдельный массив список доп. водителей и при нажатии "Назад" неоткуда обновлять данные в форме
    calcResult: [],
    typeFormPersonStepTwo: 1,
    typeFormPolicyOwnerStepTwo: 1,
    numberDriversStepThree: 1, // 1 -ограничено, 2 - без ограничений (шаг 3)
    quantityAdditionalDrivers: 0, //кол-во дополнительных водителей

    driver_kbm: "",
    driver_key: "",
    car_owner_cladr: "",
    policy_owner_cladr: "",
    infoTO: undefined,
    car_brands: undefined,
    car_models: undefined,
    countries: undefined,
    infoPolicyOwnerJuristic: undefined,
    buttonTO: false,
    isProlongDisabledButtons: false,
    isProlongDisabledFields: false,
    isProlongCalcSuccess: false,
    withoutCarPlate: false,
    withoutVIN: false,
    ingosWithoutVIN: false,
    typeCarIdentity: 1,
    typeCarIdentityIngos: 1,
    visibleErrorModal: false,
    visibleTOModal: false,
    visibleChoiceTOModal: false,
    visibleExistTOModal: false,
    visibleKbmModal: false,
    visibleResultKbmModal: false,
    visibleOsagoModal: false,
    visibleKPModal: false,
    visibleBSOModal: false,
    urlDownloadPdf: undefined,
    documentsPolicy: undefined,
    adressArr: undefined,
    policyAdressArr: undefined,
    selectedAdress: false, //для проверки выбран ли адрес страхователя из списка адресов (шаг 2)
    selectedCarOwnerAdress: false, //для проверки выбран ли адрес владельца из списка адресов (шаг 2)
    driverKbm: undefined,
    isSelectedBrand: false, //для проверки выбрана ли марка со списка марок автомобиля
    isSelectedModel: false, //для проверки выбрана ли модель со списка модель автомобиля
    calc_id: undefined, //id страховой, при повторном запросе на оформление полиса
    correlation_id: undefined, //id для Росгосстрах
    kbm_data_additional_drivers: undefined, //данные кбм только для ВСК
    makc_kbm_data: undefined, //данные кбм только для МАКС
    lastNameArr: undefined, //для автозаполнения фамилий
    currentOwner: undefined,
    firstNameArr: undefined, //для автозаполнения имен
    fio: undefined,
    genGender: undefined,
    patronymicArr: undefined, //для автозаполнения отчеств
    policyAdressJuristicArr: undefined, //список адресов юр лица -страхователя
    selectedPolicyOwnerJuristicAdress: false, //для проверки выбран ли адрес страхователя-юр лица из списка адресов (шаг 2)
    carAdressJuristicArr: undefined,  //список адресов юр лица - собственника
    selectedCarOwnerJuristicAdress: false, //для проверки выбран ли адрес собственника-юр лица из списка адресов (шаг 2)
    configAdmin: {
      insurer_email: true,
      insurer_phone: true,
      owner_email: true,
      owner_phone: true,
      online_payment: true,
      offline_payment: true,
      prepare_check: true,
      offline_check: true
    }, //настройки с админки
    paymentMethods: undefined,
    visibleProlongationModal: false,
    docs_renesans: undefined,
    buttonModalTO: false,
    visibleReasonTO: true,
    innInfoKbmModal: undefined,
    keyNameInsurance: undefined,
    documentsBSO: undefined,
    documentsNSS: undefined,
    paymentDocs: undefined,
    policyDocument: undefined,
    payment_status: undefined,
    rosgosstrah_policy_id: undefined,
    vsk_policy_id: undefined,
    ingostrah_policy_id: undefined,
    renesans_policy_id: undefined,
    alfastrah_policy_id: undefined,
    gelios_policy_id: undefined,
    bill_isn: undefined,
    alfa_docs: undefined,
    ingostrah_docs: undefined,
    insuranceDocuments: undefined,
    policyObjId: undefined,
    modelModifications: undefined,
    insurances: [],
    addendumFileList: [],
    isFormSuccessfullySent: false,
    visibleModalAddendumApplication: false,
  },
  cascoReducer: {
    isRequest: false,
    errors: null,
    cascoKind: 1,
    ptsFileList: [],
    stsFileList: [],
    currentPolicyFileList: [],
    passportFileList: [],
    juridicalCardFileList: [],
    drivingLicenseFileList: [],
    dataFormResult: undefined,
    isFormSuccessfullySent: false,
    visibleModalCASCOApplication: false,
    cascoDataForm: {
      pledge_lease: {
        value: undefined,
        name: "pledge_lease",
        errors: false,
        validating: false,
        dirty: false
      },
      insured_sum: {
        value: undefined,
        name: "insured_sum",
        errors: false,
        validating: false,
        dirty: false
      },
      franchise: {
        value: undefined,
        name: "franchise",
        errors: false,
        validating: false,
        dirty: false
      },
      mileage: {
        value: undefined,
        name: "mileage",
        errors: false,
        validating: false,
        dirty: false
      },
      note: {
        value: undefined,
        name: "note",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_full_name: {
        value: undefined,
        name: "policyholder_full_name",
        errors: false,
        validating: false,
        dirty: false
      },
    },
  },
  mortgageReducer: {
    isRequest: false,
    errors: null,
    currentStep: 0,
    visibleErrorModalMortgage: false,
    visibleModalMortgageApplication: false,
    passportFileList: [],
    loanAgreementFileList: [],
    evaluationReportFileList: [],
    EGRNFileList: [],
    mortgageDataForm: {
      draft_id: undefined,
      start_policy_date: {
        value: getTodayDate(),
        name: "start_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      finish_policy_date: {
        value: undefined,
        name: "finish_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      loan_term_in_month: {
        value: undefined,
        name: "loan_term_in_month",
        errors: false,
        validating: false,
        dirty: false
      },
      number_of_floors: {
        value: '',
        name: "number_of_floors",
        errors: false,
        validating: false,
        dirty: false
      },
      floors_num: {
        value: undefined,
        name: "floors_num",
        errors: false,
        validating: false,
        dirty: false
      },
      application_date: {
        value: getTodayDate(),
        name: "application_date",
        errors: false,
        validating: false,
        dirty: false
      },
      mortgage_bank: {
        value: 492,  // id from Bitrix
        name: "mortgage_bank",
        errors: false,
        validating: false,
        dirty: false
      },
      is_life_risk: {
        value: true,
        name: "is_life_risk",
        errors: false,
        validating: false,
        dirty: false
      },
      is_property_risk: {
        value: false,
        name: "is_property_risk",
        errors: false,
        validating: false,
        dirty: false
      },
      application_is_life_and_health_insurance: {
        value: false,
        name: "application_is_life_and_health_insurance",
        errors: false,
        validating: false,
        dirty: false
      },
      application_is_property_insurance: {
        value: false,
        name: "application_is_property_insurance",
        errors: false,
        validating: false,
        dirty: false
      },
      application_is_title_plus_property: {
        value: false,
        name: "application_is_title_plus_property",
        errors: false,
        validating: false,
        dirty: false
      },
      application_is_wooden_floors: {
        value: false,
        name: "application_is_wooden_floors",
        errors: false,
        validating: false,
        dirty: false
      },
      application_is_gas: {
        value: false,
        name: "application_is_gas",
        errors: false,
        validating: false,
        dirty: false
      },
      percentage_of_osz: {
        value: 100,
        name: "percentage_of_osz",
        errors: false,
        validating: false,
        dirty: false
      },
      identity_doc: {
        value: 1,
        name: "identity_doc",
        errors: false,
        validating: false,
        dirty: false
      },
      ...generateDynamicValues("identity_doc", 0, 21),
      property_share: {
        value: 100,
        name: "property_share",
        errors: false,
        validating: false,
        dirty: false
      },
      document_series: {
        value: undefined,
        name: "document_series",
        errors: false,
        validating: false,
        dirty: false
      },
      document_number: {
        value: undefined,
        name: "document_number",
        errors: false,
        validating: false,
        dirty: false
      },
      document_issue_date: {
        value: undefined,
        name: "document_issue_date",
        errors: false,
        validating: false,
        dirty: false
      },
      division_code: {
        value: undefined,
        name: "division_code",
        errors: false,
        validating: false,
        dirty: false
      },
      document_issuer: {
        value: undefined,
        name: "document_issuer",
        errors: false,
        validating: false,
        dirty: false
      },
      employment_status: {
        value: 110,  // Работник по найму из справочника Реника
        name: "employment_status",
        errors: false,
        validating: false,
        dirty: false
      },
      health_abnormalities: {
        value: 1,
        name: "health_abnormalities",
        errors: false,
        validating: false,
        dirty: false
      },
      ...generateDynamicValues("health_abnormalities", 0, 21),
      is_fact_address: {
        value: 1,
        name: "is_fact_address",
        errors: false,
        validating: false,
        dirty: false
      },
      ...generateDynamicValues("is_fact_address", 0, 21),
      fact_address: {
        value: undefined,
        name: "fact_address",
        errors: false,
        validating: false,
        dirty: false
      },
      marital_status: {
        value: 1,
        name: "marital_status",
        errors: false,
        validating: false,
        dirty: false
      },
      not_public_official: {
        value: true,
        name: "not_public_official",
        errors: false,
        validating: false,
        dirty: false
      },
      insurance_accidents_illnesses: {
        value: true,
        name: "insurance_accidents_illnesses",
        errors: false,
        validating: false,
        dirty: false
      },
      is_terms_confirmed: {
        value: true,
        name: "is_terms_confirmed",
        errors: false,
        validating: false,
        dirty: false
      },
      not_insure_foreign: {
        value: true,
        name: "not_insure_foreign",
        errors: false,
        validating: false,
        dirty: false
      },
      property_type: {
        value: 1,
        name: "property_type",
        errors: false,
        validating: false,
        dirty: false
      },
      insurance_city: {
        value: undefined,
        name: "insurance_city",
        errors: false,
        validating: false,
        dirty: false
      },
      insurance_object: {
        value: 1,
        name: "insurance_object",
        errors: false,
        validating: false,
        dirty: false
      },
      building_material: {
        value: 1,
        name: "building_material",
        errors: false,
        validating: false,
        dirty: false
      },
      floor_material: {
        value: 1,
        name: "floor_material",
        errors: false,
        validating: false,
        dirty: false
      },
      country: {
        value: "Россия",
        name: "country",
        errors: false,
        validating: false,
        dirty: false
      },
      loan_amount: {
        value: undefined,
        name: "loan_amount",
        errors: false,
        validating: false,
        dirty: false
      },
      rate: {
        value: undefined,
        name: "rate",
        errors: false,
        validating: false,
        dirty: false
      },
      city: {
        value: undefined,
        name: "city",
        errors: false,
        validating: false,
        dirty: false
      },
      region: {
        value: undefined,
        name: "region",
        errors: false,
        validating: false,
        dirty: false
      },
      street: {
        value: undefined,
        name: "street",
        errors: false,
        validating: false,
        dirty: false
      },
      house: {
        value: undefined,
        name: "house",
        errors: false,
        validating: false,
        dirty: false
      },
      block: {
        value: undefined,
        name: "block",
        errors: false,
        validating: false,
        dirty: false
      },
      block_type: {
        value: undefined,
        name: "block_type",
        errors: false,
        validating: false,
        dirty: false
      },
      flat: {
        value: undefined,
        name: "flat",
        errors: false,
        validating: false,
        dirty: false
      },
      zip_code: {
        value: undefined,
        name: "zip_code",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_kladr: {
        value: undefined,
        name: "policyholder_kladr",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_address: {
        value: undefined,
        name: "policyholder_address",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_address_fact: {
        value: undefined,
        name: "policyholder_address_fact",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_fias: {
        value: undefined,
        name: "policyholder_fias",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_fias_fact: {
        value: undefined,
        name: "policyholder_fias_fact",
        errors: false,
        validating: false,
        dirty: false
      },
      contract_number: {
        value: undefined,
        name: "contract_number",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_fias: {
        value: undefined,
        name: "property_address_fias",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_kladr: {
        value: undefined,
        name: "property_address_kladr",
        errors: false,
        validating: false,
        dirty: false
      },
      address_str: {
        value: undefined,
        name: "address_str",
        errors: false,
        validating: false,
        dirty: false
      },
      cadastr_nr: {
        value: undefined,
        name: "cadastr_nr",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_region: {
        value: undefined,
        name: "property_address_region",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_region_without_type: {
        value: undefined,
        name: "property_address_region_without_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_area_with_type: {
        value: undefined,
        name: "property_area_with_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_city: {
        value: undefined,
        name: "property_address_city",
        errors: false,
        validating: false,
        dirty: false
      },
      property_settlement_with_type: {
        value: undefined,
        name: "property_settlement_with_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_street: {
        value: undefined,
        name: "property_address_street",
        errors: false,
        validating: false,
        dirty: false
      },
      property_house_type: {
        value: undefined,
        name: "property_house_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_house: {
        value: undefined,
        name: "property_address_house",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_corpus: {
        value: undefined,
        name: "property_address_corpus",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_building: {
        value: undefined,
        name: "property_address_building",
        errors: false,
        validating: false,
        dirty: false
      },
      property_block_type: {
        value: undefined,
        name: "property_block_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_block: {
        value: undefined,
        name: "property_block",
        errors: false,
        validating: false,
        dirty: false
      },
      property_flat_type: {
        value: undefined,
        name: "property_flat_type",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_flat: {
        value: undefined,
        name: "property_address_flat",
        errors: false,
        validating: false,
        dirty: false
      },
      property_address_room: {
        value: undefined,
        name: "property_address_room",
        errors: false,
        validating: false,
        dirty: false
      },
      object_wear: {
        value: undefined,
        name: "object_wear",
        errors: false,
        validating: false,
        dirty: false
      },
      overhaul_year: {
        value: undefined,
        name: "overhaul_year",
        errors: false,
        validating: false,
        dirty: false
      },
      policyholder_full_name: {
        value: undefined,
        name: "policyholder_full_name",
        errors: false,
        validating: false,
        dirty: false
      },
    },
    isFormSuccessfullySent: false,
    dataFormResult: undefined, //отдельный объект для отправки данных на сервер
    applicationDataFormResult: undefined, //отдельный объект для отправки данных на сервер
    precalculateDataFormResult: undefined, //отдельный объект для отправки данных на сервер
    lastNameArr: undefined, //для автозаполнения фамилий
    middleNameArr: undefined, //для автозаполнения отчеств
    firstNameArr: undefined, //для автозаполнения имен
    fiasId: undefined,
    fio: undefined,
    genGender: undefined,
    regAddressArr: undefined,
    factAddressArr: undefined,
    requestedAddressType: undefined,
    selectedRegAddress: false, //для проверки выбран ли адрес из списка адресов (шаг 2)
    objectAddressArr: undefined,
    selectedObjectAddress: false, //для проверки выбран ли адрес из списка адресов (шаг 3)
    calculationComplete: false,
    calculationInProgress: false,
    creatingPolicy: false,
    policyCreated: false,
    documentsPolicy: undefined,
    docs: undefined,
    policy_id: undefined,
    paymentStatus: undefined,
    insuranceDocuments: undefined,
    documents: undefined,
    paymentDocs: undefined,
    policyObjId: undefined,
    precalculateResult: [],
    calcResult: [],
    professions: undefined,
    banks: [],
    employment_statuses_list: [],
    health_abnormalities_list: [],
    coBorrowersKeys: [],
    coBorrowersQuantity: 0,  // количество созаёмщиков
    coBorrowersQuantityMax: 3,  // макс кол-во созаёмщиков
    propertyShareSum: 100,  // сумма долей
  },
  accidentsSportReducer: {
    isRequest: false,
    errors: null,
    currentStep: 0,
    accidentsSportDataForm: {
      duration_insurance: {
        value: 1,
        name: "duration_insurance",
        errors: false,
        validating: false,
        dirty: false
      },
      product_name: {
        value: 2,
        name: "product_name",
        errors: false,
        validating: false,
        dirty: false
      },
      leisure: {
        value: false,
        name: "leisure",
        errors: false,
        validating: false,
        dirty: false
      },
      sport: {
        value: true,
        name: "sport",
        errors: false,
        validating: false,
        dirty: false
      },
      sport_kind: [],
      sport_kind_data: undefined,
      territory: {
        value: 2,
        name: "territory",
        errors: false,
        validating: false,
        dirty: false
      },
      start_policy_date: {
        value: undefined,
        name: "start_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      finish_policy_date: {
        value: undefined,
        name: "finish_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      is_professional: {
        value: false,
        name: "is_professional",
        errors: false,
        validating: false,
        dirty: false
      },
      physical_injuries: {
        value: false,
        name: "physical_injuries",
        errors: false,
        validating: false,
        dirty: false
      },
      disability: {
        value: false,
        name: "disability",
        errors: false,
        validating: false,
        dirty: false
      },
      death: {
        value: true,
        name: "death",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_is_insurer: {
        value: false,
        name: "policy_owner_is_insurer",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_last_name: {
        value: undefined,
        name: "insurer_last_name",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_first_name: {
        value: undefined,
        name: "insurer_first_name",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_middle_name: {
        value: undefined,
        name: "insurer_middle_name",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_birth_date: {
        value: undefined,
        name: "insurer_birth_date",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_address_text: {
        value: undefined,
        name: "insurer_address_text",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_address: undefined,
      insurer_series_document: undefined,
      insurer_number_document: undefined,
      series_number_document: {
        value: undefined,
        name: "series_number_document",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_document_issue_date: {
        value: undefined,
        name: "insurer_document_issue_date",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_document_issue_by: undefined,
      main_insurer_last_name: {
        value: undefined,
        name: "main_insurer_last_name",
        errors: false,
        validating: false,
        dirty: false
      },
      main_insurer_name: {
        value: undefined,
        name: "main_insurer_name",
        errors: false,
        validating: false,
        dirty: false
      },
      main_insurer_middle_name: {
        value: undefined,
        name: "main_insurer_middle_name",
        errors: false,
        validating: false,
        dirty: false
      },
      main_insurer_birth_date: {
        value: undefined,
        name: "main_insurer_birth_date",
        errors: false,
        validating: false,
        dirty: false
      },
      insurer_email: undefined,
      insurer_phone: undefined,
      insured_person: [],
      sum_range: {
        value: 200000,
        name: "sum_range",
        errors: false,
        validating: false,
        dirty: false
      },
      keys: {
        value: [],
        name: "keys",
        errors: false,
        validating: false,
        dirty: false
      },
    },
    insurerAddressArr: undefined,
    kindsOfSports: undefined,
    calcResult: null,
    calculationInProgress: false,
    calculationComplete: false,
    visibleErrorModalAccidentsSport: false,
    selectedInsurerAddress: false, //для проверки выбран ли адрес из списка адресов (шаг 1)
    isSelectedSport: false, //для проверки выбран ли вид спорта
    quantityAdditionalInsurers: 0, //кол-во дополнительных застрахованных
    dataFormResult: undefined, //отдельный объект для отправки данных на сервер, т.к. переносим в отдельный массив список доп. страхователей и при нажатии "Назад" неоткуда обновлять данные в форме
    urlDownloadPdf: undefined,
    creatingPolicy: false,
    policyCreated: false,
    documentsPolicy: undefined,
    paymentStatus: undefined,
    documents: undefined,
    paymentDocs: undefined,
    policyObjId: undefined,
  },
  contractsReducer: {
    visibleViewDocsModal: false,
    contractsList: [],
    contractsCount: null,
    contractsFilters: {},
    visibleErrorModal: false,
    errors: null,
  },
  paymentActsReducer: {
    paymentActsList: [],
    paymentActsCount: null,
    paymentActsFilters: {},
    visibleErrorModal: false,
    errors: null,
    paymentActDetails: null,
    selectedAct: null,
    visiblePaymentActDetailsModal: false
  },
  mainReducer: {
    availableProducts: {},
    isRequest: false,
    errors: null,
    visibleToolsModal: false,
    visibleNotificationsModal: false,
    visibleErrorModal: false,
    toolsList: [],
    tempToolsList: [],
    favoritesTools: [],
    notifications: [],
    earnedBonuses: "0.0",
    earnedMoney: "0.0",
  },
  crossReducer: {
    isRequest: false,
    errors: null,
    currentStep: 0,
    visibleErrorModalCross: false,
    calculationInProgress: false,
    calculationComplete: false,
    calcResult: [],
    dataFormResult: undefined, //отдельный объект для отправки данных на сервер
    isnTariff: undefined,
    limitSums: undefined,
    attrIsn: undefined,
    productName: undefined,
    marks: undefined,
    policyCreated: false,
    creatingPolicy: false,
    urlDownloadPdf: undefined,
    documentsPolicy: undefined,
    calc_id: undefined,
    docs: undefined,
    documents: undefined,
    policy_id: undefined,
    paymentStatus: undefined,
    insuranceDocuments: undefined,
    paymentDocs: undefined,
    policyObjId: undefined,
    crossKind: 1,
    crossDataForm: {
      draft_id: undefined,
      insured_sum: {
        value: 300000,
        name: "insured_sum",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_series: {
        value: undefined,
        name: "policy_series",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_number: {
        value: undefined,
        name: "policy_number",
        errors: false,
        validating: false,
        dirty: false
      },
    }
  },
  kkmReducer: {
    isRequest: false,
    errors: null,
    currentStep: 0,
    visibleErrorModalKkm: false,
    paymentStatus: undefined,
    documents: undefined,
    programs: [],
    dtpCoverages: [],
    commissarCoverages: [],
    accidentCoverages: [],
    emergencyHelpCoverages: [],
    evacuationCoverages: [],
    juristicConsultCoverages: [],
    evacuationPrice: undefined,
    juristicConsultPrice: undefined,
    commissarPrice: undefined,
    emergencyHelpPrice: undefined,
    dataFormResult: undefined, //отдельный объект для отправки данных на сервер
    carBrands: undefined,
    carModels: undefined,
    price: undefined,
    agentCommission: {},
    payment_url: undefined,
    calculationComplete: false,
    draft_id: undefined,
    car_owner_cladr: undefined,
    car_owner_cladr_address: undefined,
    car_owner_cladr_okato: undefined,
    car_owner_cladr_country: undefined,
    car_owner_cladr_region: undefined,
    car_owner_cladr_zip: undefined,
    car_owner_cladr_city_name: undefined,
    car_owner_cladr_city_kladr: undefined,
    car_owner_cladr_street_kladr: undefined,
    car_owner_cladr_street_name: undefined,
    car_owner_cladr_house: undefined,
    car_owner_cladr_block: undefined,
    car_owner_cladr_flat: undefined,
    car_owner_fias_id: undefined,
    car_owner_house_fias_id: undefined,
    regAddressArr: [],
    policy_owner_cladr: undefined,
    policy_owner_cladr_address: undefined,
    policy_owner_cladr_okato: undefined,
    policy_owner_cladr_country: undefined,
    policy_owner_cladr_region: undefined,
    policy_owner_cladr_zip: undefined,
    policy_owner_cladr_city_name: undefined,
    policy_owner_cladr_city_kladr: undefined,
    policy_owner_cladr_street_kladr: undefined,
    policy_owner_cladr_street_name: undefined,
    policy_owner_cladr_house: undefined,
    policy_owner_cladr_block: undefined,
    policy_owner_cladr_flat: undefined,
    policy_owner_fias_id: undefined,
    policy_owner_house_fias_id: undefined,
    kkmDataForm: {
      policy_series: {
        value: undefined,
        name: "policy_series",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_num: {
        value: undefined,
        name: "policy_num",
        errors: false,
        validating: false,
        dirty: false
      },
      start_policy_date: {
        value: getTodayDate(),
        name: "start_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      finish_policy_date: {
        value: getFutureDate(0),
        name: "finish_policy_date",
        errors: false,
        validating: false,
        dirty: false
      },
      is_owner: {
        value: true,
        name: "is_owner",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_serial: {
        value: undefined,
        name: "policy_owner_serial",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_number: {
        value: undefined,
        name: "policy_owner_number",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_experience_date: {
        value: undefined,
        name: "policy_owner_experience_date",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_issuer_code: {
        value: undefined,
        name: "policy_owner_issuer_code",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_issuer: {
        value: undefined,
        name: "policy_owner_issuer",
        errors: false,
        validating: false,
        dirty: false
      },
      policy_owner_address: {
        value: undefined,
        name: "policy_owner_address",
        errors: false,
        validating: false,
        dirty: false
      },
      dtp: {
        value: true,
        name: "dtp",
        errors: false,
        validating: false,
        dirty: false
      },
      accident: {
        value: false,
        name: "accident",
        errors: false,
        validating: false,
        dirty: false
      },
      evacuation: {
        value: false,
        name: "evacuation",
        errors: false,
        validating: false,
        dirty: false
      },
      emergency_help: {
        value: false,
        name: "emergency_help",
        errors: false,
        validating: false,
        dirty: false
      },
      commissar: {
        value: false,
        name: "commissar",
        errors: false,
        validating: false,
        dirty: false
      },
      juristic_consult: {
        value: false,
        name: "juristic_consult",
        errors: false,
        validating: false,
        dirty: false
      },
      without_car_plate: {
        value: false,
        name: "without_car_plate",
        errors: false,
        validating: false,
        dirty: false
      },
      without_vin: {
        value: false,
        name: "without_vin",
        errors: false,
        validating: false,
        dirty: false
      },
      coverage_program: {
        value: undefined,
        name: "coverage_program",
        errors: false,
        validating: false,
        dirty: false
      },
      dtp_cover: {
        value: undefined,
        name: "dtp_cover",
        errors: false,
        validating: false,
        dirty: false
      },
      dtp_price: {
        value: undefined,
        name: "dtp_price",
        errors: false,
        validating: false,
        dirty: false
      },
      accident_cover: {
        value: undefined,
        name: "accident_cover",
        errors: false,
        validating: false,
        dirty: false
      },
      accident_price: {
        value: undefined,
        name: "accident_price",
        errors: false,
        validating: false,
        dirty: false
      },
      documents: {
        value: 1,
        name: "documents",
        errors: false,
        validating: false,
        dirty: false
      },
      vehicle_type: {
        value: 2,
        name: "vehicle_type",
        errors: false,
        validating: false,
        dirty: false
      },
      car_plate: undefined,
      vin: undefined,
      body_number: undefined,
      chassis_number: undefined,
      car_brand_data: undefined,
      car_model_data: undefined,
      car_brand: undefined,
      car_model: undefined,
    }
  },
  renewalReducer: {
    isRequest: false,
    errors: null,
    visibleErrorModal: false,
    renewalPolicies: [],
    renewalPoliciesCount: null,
    selectedObjId: {},
    renewalPoliciesFilters: {},
  },
};
