import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';
import {calculateFilters} from "../utils";


export default injectReducer(initialState.renewalReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.renewalReducer
  }),

  [types.GET_RENEWAL_POLICIES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_RENEWAL_POLICIES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      renewalPolicies: payload,
      renewalPoliciesFilters: calculateFilters(payload, [
        "renewal_status",
        "insurance_name",
      ]),
      errors: null
    }
  },

  [types.GET_RENEWAL_POLICIES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.GET_RENEWAL_POLICIES_COUNT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_RENEWAL_POLICIES_COUNT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      renewalPoliciesCount: payload.renewal_policies_count,
      errors: null
    }
  },

  [types.GET_RENEWAL_POLICIES_COUNT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.SELECT_RENEWAL_POLICY]: (state, {payload}) => ({
    ...state,
    selectedObjId: payload
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

})
