import * as types from "../store/actionTypes";

export const goToPrevStep = step => ({
  type: types.PREV_STEP,
  payload: step
});

export const goToNextStep = step => ({
  type: types.NEXT_STEP,
  payload: step
});

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_FORM,
  payload: {data, object}
});

export const getInfoByVin = ({car_plate, vin}) => ({
  type: types.GET_INFO_BY_VIN,
  payload: {car_plate, vin}
});

export const sendDataForm = () => ({
  type: types.SEND_DATA_FORM
});

export const toggleTypeFormPersonStepTwo = value => ({
  type: types.TOGGLE_TYPE_FORM_PERSON_STEP_TWO,
  payload: value
});

export const toggleTypeFormPolicyOwnerStepTwo = value => ({
  type: types.TOGGLE_TYPE_FORM_POLICY_OWNER_STEP_TWO,
  payload: value
});

export const toggleIsPolicyOwner = value => ({
  type: types.IS_POLICY_OWNER,
  payload: value
});

export const toggleNumberDriversStepThree = value => ({
  type: types.NUMBER_DRIVERS_STEP_THREE,
  payload: value
});

export const removeDriverFromListDrivers = key => ({
  type: types.REMOVE_DRIVER_FROM_LIST_DRIVERS,
  payload: key
});

export const clearListDrivers = () => ({
  type: types.CLEAR_LIST_DRIVERS
});

export const changeQuantityAdditionalDrivers = quantity => ({
  type: types.CHANGE_QUANTITY_ADDITIONAL_DRIVERS,
  payload: quantity
});


export const getPaymentStatus = ({insKey, policyObjId}) => ({
  type: types.GET_PAYMENT_STATUS,
  payload: {insKey:insKey, policyObjId:policyObjId},
});

export const patchAlfaPayment = value => ({
  type: types.PATCH_ALFA_PAYMENT,
  payload: value,
});

export const patchIngosPayment = value => ({
  type: types.PATCH_INGOS_PAYMENT,
  payload: value,
});

export const getDriverKbm = (value, k) => ({
  type: types.GET_DRIVER_KBM,
  payload: value,
  driver_key: k
});

export const getOwnerDriverKbm = value => ({
  type: types.GET_OWNER_DRIVER_KBM,
  payload: value
});

export const getCarOwnerDriverKbm = value => ({
  type: types.GET_CAR_OWNER_DRIVER_KBM,
  payload: value
});

export const getKbm = value => ({
  type: types.GET_KBM,
  payload: value
});

export const clearModalDriverKbm = () => ({
  type: types.CLEAR_MODAL_DRIVER_KBM
});
export const getKbmPolicyCarOwnerJuristic = value => ({
  type: types.GET_KBM_POLICY_CAR_OWNER_JURISTIC,
  payload: value
});

export const getKbmJuristic = (value) => ({
  type: types.GET_KBM_JURISTIC,
  payload: value
});

export const getKladrPolicyCarOwner = value => ({
  type: types.GET_KLADR_POLICY_CAR_OWNER,
  payload: value
});

export const getKladrCarOwner = value => ({
  type: types.GET_KLADR_CAR_OWNER,
  payload: value
});

export const getTOInfo = value => ({
  type: types.GET_TO_INFO,
  payload: value
});

export const getAlfaProlongation = value => ({
  type: types.GET_ALFA_PROLONGATION,
  payload: value
});

export const getAlfaAgreementData = value => ({
  type: types.GET_ALFA_AGREEMENT_DATA,
  payload: value
});

export const getVskProlongation = value => ({
  type: types.GET_VSK_PROLONGATION,
  payload: value
});

export const getVskAgreementData = value => ({
  type: types.GET_VSK_AGREEMENT_DATA,
  payload: value
});

export const getProlongationRGS = value => ({
  type: types.GET_RGS_PROLONGATION,
  payload: value
});

export const getAgreementDataRGS = value => ({
  type: types.GET_RGS_AGREEMENT_DATA,
  payload: value
});

export const getRenessansProlongation = value => ({
  type: types.GET_RENESSANS_PROLONGATION,
  payload: value
});


export const getSevaProlongation = value => ({
  type: types.GET_SEVA_PROLONGATION,
  payload: value
});

export const getRenessansAgreementData = value => ({
  type: types.GET_RENESSANS_AGREEMENT_DATA,
  payload: value
});

export const getIngosAgreementData = value => ({
  type: types.GET_INGOS_AGREEMENT_DATA,
  payload: value
});

export const getIngosProlongation = value => ({
  type: types.GET_INGOS_PROLONGATION,
  payload: value
});

export const getCarBrands = () => ({
  type: types.GET_CAR_BRANDS
});

export const getInsurances = () => ({
  type: types.GET_INSURANCES
});

export const getCountries = () => ({
  type: types.GET_COUNTRIES
});

export const getCarModels = (brand) => ({
  type: types.GET_CAR_MODELS,
  payload: brand
});

export const getModelModifications = (model) => ({
  type: types.GET_MODEL_MODIFICATIONS,
  payload: model
});

export const getPolicyOwnerInfoByINN = value => ({
  type: types.GET_INFO_POLICY_OWNER_BY_INN,
  payload: value
});

export const getInfoKbmModalByINN = value => ({
  type: types.GET_INFO_KBM_MODAL_BY_INN,
  payload: value
});

export const getCarOwnerInfoByINN = value => ({
  type: types.GET_INFO_CAR_OWNER_BY_INN,
  payload: value
});

export const clearDataPolicyOwner = () => ({
  type: types.CLEAR_DATA_POLICY_OWNER
});

export const clearDataPolicyOwnerJuristic = () => ({
  type: types.CLEAR_DATA_POLICY_OWNER_JURISTIC
});

export const clearDataCarOwner = () => ({
  type: types.CLEAR_DATA_CAR_OWNER
});

export const clearDataCarOwnerJuristic = () => ({
  type: types.CLEAR_DATA_CAR_OWNER_JURISTIC
});

export const clearDataCarPlate = () => ({
  type: types.CLEAR_DATA_CAR_PLATE
});

export const clearDataProlongation = value => ({
  type: types.CLEAR_FORM,
  payload: value
});

export const clearDataInfoTO = () => ({
  type: types.CLEAR_DATA_TO
});

export const toggleWithoutCarPlate = value => ({
  type: types.WITHOUT_CAR_PLATE,
  payload: value
});

export const clearDataVIN = () => ({
  type: types.CLEAR_DATA_VIN
});

export const clearMarkModel = () => ({
  type: types.CLEAR_MARK_MODEL
});

export const clearDataDK = () => ({
  type: types.CLEAR_DATA_DK
});

export const clearPaymentStatus = () => ({
  type: types.CLEAR_PAYMENT_STATUS
});

export const toggleWithoutVIN = value => ({
  type: types.WITHOUT_VIN,
  payload: value
});

export const toggleIngosVIN = value => ({
  type: types.INGOS_VIN,
  payload: value
});

export const toggleTypeCarIdentity = value => ({
  type: types.TOGGLE_TYPE_CAR_IDENTITY,
  payload: value
});

export const toggleIngosTypeCarIdentity = value => ({
  type: types.TOGGLE_INGOS_TYPE_CAR_IDENTITY,
  payload: value
});

export const toggleTypeTransportInsurer = value => ({
  type: types.TOGGLE_TYPE_TRANSPORT_INSURER,
  payload: value
});

export const clearDataBodyNumber = () => ({
  type: types.CLEAR_DATA_BODY_NUMBER
});

export const clearDataChassisNumber = () => ({
  type: types.CLEAR_DATA_CHASSIS_NUMBER
});

export const showTOButton = value => ({
  type: types.SHOW_TO_BUTTON,
  payload: value
});

export const toggleErrorModal = value => ({
  type: types.TOGGLE_ERROR_MODAL,
  payload: value
});

export const createPolicy = (value, series, number) => ({
  type: types.CREATE_POLICY,
  payload: {value, series, number}
});

export const clearKBM = () => ({
  type: types.CLEAR_KBM
});

export const toggleTOModal = value => ({
  type: types.TOGGLE_TO_MODAL,
  payload: value
});

export const toggleKbmResultModal = value => ({
  type: types.TOGGLE_KBM_RESULT_MODAL,
  payload: value
});

export const toggleChoiceTOModal = value => ({
  type: types.TOGGLE_CHOICE_TO_MODAL,
  payload: value
});

export const toggleExistTOModal = value => ({
  type: types.TOGGLE_EXIST_TO_MODAL,
  payload: value
});

export const toggleKbmModal = value => ({
  type: types.TOGGLE_KBM_MODAL,
  payload: value
});

export const saveCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const savePolicyOwnerCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_POLICY_OWNER_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const saveCarBrandData = data => ({
  type: types.SAVE_CAR_BRAND_DATA,
  payload: data
});

export const saveCarModelData = data => ({
  type: types.SAVE_CAR_MODEL_DATA,
  payload: data
});

export const saveModelModificationsData = data => ({
  type: types.SAVE_MODEL_MODIFICATIONS_DATA,
  payload: data
});

export const savePolicyOwnerCountryData = data => ({
  type: types.SAVE_POLICY_OWNER_COUNTRY_DATA,
  payload: data
})

export const toggleSelectedAddress = value => ({
  type: types.SELECTED_ADDRESS,
  payload: value
});

export const toggleCarOwnerSelectedAddress = value => ({
  type: types.SELECTED_CAR_OWNER_ADDRESS,
  payload: value
});

export const toggleIsSelectedBrand = value => ({
  type: types.TOOGLE_IS_SELECTED_BRAND,
  payload: value
});

export const toggleIsSelectedModel = value => ({
  type: types.TOOGLE_IS_SELECTED_MODEL,
  payload: value
});

export const getKbmDriverNoLimit = (value) => ({
  type: types.GET_KBM_DRIVER_NO_LIMIT,
  payload: value
});

export const defineGender = (type, owner_type, value) => ({
  type: types.DEFINE_GENDER,
  payload: {type, owner_type, value}
});

export const getConfig = () => ({
  type: types.GET_GONFIG
});

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const getKladrPolicyCarOwnerJuristic = value => ({
  type: types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC,
  payload: value
});

export const savePolicyOwnerJuristicCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_POLICY_OWNER_JURISTIC_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});


export const toggleSelectedPolicyOwnerJuristicAddress = value => ({
  type: types.SELECTED_ADDRESS_POLICY_OWNER_JURISTIC,
  payload: value
});

export const getKladrCarOwnerJuristic = value => ({
  type: types.GET_KLADR_CAR_OWNER_JURISTIC,
  payload: value
});

export const saveCarOwnerJuristicCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.SAVE_CAR_OWNER_JURISTIC_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const toggleSelectedCarOwnerJuristicAddress = value => ({
  type: types.SELECTED_ADDRESS_CAR_OWNER_JURISTIC,
  payload: value
});

export const toggleVehicleCategory = value => ({
  type: types.TOOGLE_CATEGORY,
  payload: value
});

export const toggleOsagoModal = (value, calc_item) => ({
  type: types.TOGGLE_OSAGO_MODAL,
  payload: {value, calc_item}
});

export const toggleButtonModalTo = value => ({
  type: types.TOGGLE_BUTTON_MODAL_TO,
  payload: value
});

export const changeDateInfoTO = (value, serial, number) => ({
  type: types.CHANGE_DATE_INFO_TO,
  payload: {value, serial, number},
});

export const toggleBsoModal = (value) => ({
  type: types.TOGGLE_BSO_MODAL,
  payload: value,
});

export const toggleKPModal = (value) => ({
  type: types.TOGGLE_KP_MODAL,
  payload: value,
});

export const doPaymentBso = (value) => ({
  type: types.DO_PAYMENT_BSO,
  payload: value,
});

export const doPaymentNSS = ({insKey, policyObjId}) => ({
  type: types.DO_PAYMENT_NSS,
  payload: {insKey: insKey, policyObjId: policyObjId},
});


export const getPaymentMethods = (value) => ({
  type: types.GET_PAYMENT_METHODS,
  payload: value,
});

export const getSberQRCode = (value) => ({
  type: types.GET_SBER_QR_CODE,
  payload: value,
});

export const getSberQRPaymentStatus = (value) => ({
  type: types.GET_SBER_QR_PAYMENT_STATUS,
  payload: value,
});

export const toggleSberQRModal = (value) => ({
  type: types.TOGGLE_SBER_QR_MODAL,
  payload: value,
});


export const populateBrandModelData = () => ({
  type: types.POPULATE_BRAND_MODEL_DATA
});

export const printKP = (payload) => ({
  type: types.PRINT_KP,
  payload: payload,
});

export const uploadAddendumFiles = (payload) => ({
    type: types.UPLOAD_ADDENDUM_FILES,
    payload: payload,
})

export const removeAddendumFiles = (payload) => ({
    type: types.REMOVE_ADDENDUM_FILES,
    payload: payload,
})

export const sendAddendumApplication = (payload) => ({
    type: types.SEND_ADDENDUM_APPLICATION,
    payload: payload,
})

export const toggleModalAddendum = (value) => ({
  type: types.TOGGLE_ADDENDUM_MODAL,
  payload: value,
})

export const getCrossProducts = (value) => ({
  type: types.GET_CROSS_PRODUCTS,
  payload: value,
})

export const addCrossProductToList = (value) => ({
    type: types.ADD_CROSS_PRODUCT_TO_LIST,
    payload: value
})

export const clearSelectedCrossProducts = () => ({
    type: types.CLEAR_SELECTED_CROSS_PRODUCTS,
})
