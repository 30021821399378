import React, {Component} from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Checkbox,
  Select,
  AutoComplete,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  goToNextStep,
  clearIsRequestState,
  setDataForm,
  sendDataForm,
  getInfoByVin,
  getCarBrands,
  getCarModels,
  getPolicy,
  clearForm,
  savePolicyOwnerCladr,
  saveCarOwnerCladr,
  createPolicy,
  clearCalcData,
  getPrograms,
  setCoverages,
} from "../../actions/kkmActions";
import {
  isRequestSelector,
  currentStepSelector,
  kkmDataFormSelector,
  errorsKKMSelector,
  carBrandsSelector,
  carModelsSelector,
  priceSelector,
  agentCommissionSelector,
  draftIdSelector,
  programsSelector,
  dtpCoveragesSelector,
  commissarCoveragesSelector,
  accidentCoveragesSelector,
  emergencyHelpCoveragesSelector,
  evacuationCoveragesSelector,
  juristicConsultCoveragesSelector,
  commissarPriceSelector,
  emergencyHelpPriceSelector,
  evacuationPriceSelector,
  juristicConsultPriceSelector,
} from "../../selectors/kkmSelectors";
import ModalError from "../../components/ModalError";
import {
  transform,
  hasErrors,
  formItemLayout,
  transliterateSeries,
  checkPolicyValueDate,
  isPDProcessingConsentCheckedKey,
  checkCarYearIssue,
  carDocumentsOptions,
  baseRules,
  checkCarDocumentsDate,
  onChangeCarDocumentSeriesNumber,
  addYear,
} from "../../utils";
import {ACCIDENT} from "../../constants";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import Preloader from "../../components/Preloader";
import {ReactComponent as QuestionIcon} from "../../assets/Icons/Question.svg";
import Cleave from "cleave.js/react";
import KkmPerson from "./KkmPerson"
import {currencyOptions} from "./kkmUtils";
import PersonalDataCheckbox from "../../components/Form/PersonalDataCheckbox";
import KKMCalcResultForm from "./KKMCalcResultForm";

const {Option} = Select;

class FirstStepKKM extends Component {

  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  state = {
    cleaveKey: 0,
  }

  componentDidMount() {
    const {clearIsRequestState, getCarBrands, getPrograms} = this.props;
    clearIsRequestState()
    getCarBrands()
    getPrograms()
  }

  handleSubmit = (e) => {
    const {sendDataForm, form} = this.props

    e.preventDefault()

    form.validateFields({force: true}, (err,) => {
      if (!err) {
        sendDataForm()
      }
    })
  }

  render() {
    const {
      getPolicy,
      getCarModels,
      getInfoByVin,
      carBrands,
      carModels,
      isRequest,
      clearForm,
      form,
      savePolicyOwnerCladr,
      saveCarOwnerCladr,
      createPolicy,
      price,
      agentCommission,
      draftId,
      programs,
      setCoverages,
      dtpCoverages,
      accidentCoverages,
      commissarPrice,
      emergencyHelpPrice,
      evacuationPrice,
      juristicConsultPrice,
    } = this.props

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = form

    const policySeries = "osago_policy_series";
    const policySeriesError = getFieldError(policySeries);

    const policyNumber = "osago_policy_number";
    const policyNumberError = getFieldError(policyNumber);

    const startPolicyDate = "start_policy_date";
    const startPolicyDateError = getFieldError(startPolicyDate);

    const finishPolicyDate = "finish_policy_date";
    const finishPolicyDateError = getFieldError(finishPolicyDate);

    const isOwner = "is_owner";

    // insurer person
    const insurerLastName = `policy_owner_last_name`;
    const insurerLastNameError = getFieldError(insurerLastName);
    const insurerMiddleName = `policy_owner_middle_name`;
    const insurerMiddleNameError = getFieldError(insurerMiddleName);
    const insurerName = `policy_owner_name`;
    const insurerNameError = getFieldError(insurerName);
    const insurerBirthDate = `policy_owner_birth_date`;
    const insurerBirthDateError = getFieldError(insurerBirthDate);
    const insurerPhone = `policy_owner_phone`;
    const insurerPhoneError = getFieldError(insurerPhone);
    const insurerEmail = `policy_owner_email`;
    const insurerEmailError = getFieldError(insurerEmail);
    const insurerIdentityDoc = `policy_owner_identity_document`;
    const insurerIdentityDocError = getFieldError(insurerIdentityDoc);
    const insurerIdentityDocSeries = `policy_owner_serial`;
    const insurerIdentityDocSeriesError = getFieldError(insurerIdentityDocSeries);
    const insurerIdentityDocNumber = `policy_owner_number`;
    const insurerIdentityDocNumberError = getFieldError(insurerIdentityDocNumber);
    const insurerIdentityDocDate = `policy_owner_experience_date`;
    const insurerIdentityDocDateError = getFieldError(insurerIdentityDocDate);
    const insurerIdentityDocDivisionCode = `policy_owner_issuer_code`;
    const insurerIdentityDocDivisionCodeError = getFieldError(insurerIdentityDocDivisionCode);
    const insurerIdentityDocIssuer = `policy_owner_issuer`;
    const insurerIdentityDocIssuerError = getFieldError(insurerIdentityDocIssuer);
    const insurerRegistrationAddress = `policy_owner_address`;
    const insurerRegistrationAddressError = getFieldError(insurerRegistrationAddress);

    // owner person
    const ownerLastName = `car_owner_last_name`;
    const ownerLastNameError = getFieldError(ownerLastName);
    const ownerMiddleName = `car_owner_middle_name`;
    const ownerMiddleNameError = getFieldError(ownerMiddleName);
    const ownerName = `car_owner_name`;
    const ownerNameError = getFieldError(ownerName);
    const ownerBirthDate = `car_owner_birth_date`;
    const ownerBirthDateError = getFieldError(ownerBirthDate);
    const ownerPhone = `car_owner_phone`;
    const ownerPhoneError = getFieldError(ownerPhone);
    const ownerEmail = `car_owner_email`;
    const ownerEmailError = getFieldError(ownerEmail);
    const ownerIdentityDoc = `car_owner_identity_document`;
    const ownerIdentityDocError = getFieldError(ownerIdentityDoc);
    const ownerIdentityDocSeries = `car_owner_serial`;
    const ownerIdentityDocSeriesError = getFieldError(ownerIdentityDocSeries);
    const ownerIdentityDocNumber = `car_owner_number`;
    const ownerIdentityDocNumberError = getFieldError(ownerIdentityDocNumber);
    const ownerIdentityDocDate = `car_owner_experience_date`;
    const ownerIdentityDocDateError = getFieldError(ownerIdentityDocDate);
    const ownerIdentityDocDivisionCode = `car_owner_issuer_code`;
    const ownerIdentityDocDivisionCodeError = getFieldError(ownerIdentityDocDivisionCode);
    const ownerIdentityDocIssuer = `car_owner_issuer`;
    const ownerIdentityDocIssuerError = getFieldError(ownerIdentityDocIssuer);
    const ownerRegistrationAddress = `car_owner_address`;
    const ownerRegistrationAddressError = getFieldError(ownerRegistrationAddress);

    const kkmCoverageProgram = "coverage_program";
    const kkmCoverageProgramError = getFieldError(finishPolicyDate);

    const dtp = "dtp";

    const DTPCoverage = "dtp_cover";
    const DTPCoverageError = getFieldError(DTPCoverage);

    const DTPPrice = "dtp_price";
    const DTPPriceError = getFieldError(DTPPrice);


    const accidentCoverage = "accident_cover";
    const accidentCoverageError = getFieldError(accidentCoverage);

    const accidentPrice = "accident_price";
    const accidentPriceError = getFieldError(accidentPrice);

    const damagedVehicleEvacuation = "evacuation";
    const breakdownsEmergencyAssistance = "emergency_help";
    const emergencyCommissioner = "commissar";
    const legalConsultation = "juristic_consult";

    const withoutCarPlate = "without_car_plate";
    const withoutVIN = "without_vin";

    const carPlate = "car_plate";
    const carPlateError = getFieldError(carPlate);
    const vin = "vin";
    const vinError = getFieldError(vin);


    const bodyNumber = "body_number";
    const bodyNumberError = getFieldError(bodyNumber);
    const chassisNumber = "chassis_number";
    const chassisNumberError = getFieldError(chassisNumber);

    const brandCar = "car_brand";
    const brandCarError = getFieldError(brandCar);
    const modelCar = "car_model";
    const modelCarError = getFieldError(modelCar);

    // const vehicleType = "vehicle_type";
    // const vehicleTypeError = getFieldError(vehicleType);

    const carYearIssue = "car_year_issue";
    const carYearIssueError = getFieldError(carYearIssue);
    const enginePower = "engine_power";
    const enginePowerError = getFieldError(enginePower);

    const documents = "documents";
    const documentsError = getFieldError(documents);
    const carDocumentDate = "car_document_date";
    const carDocumentDateError = getFieldError(carDocumentDate);
    const carDocumentSerial = "car_document_serial";
    const carDocumentSerialError = getFieldError(carDocumentSerial);
    const carDocumentNumber = "car_document_number";
    const carDocumentNumberError = getFieldError(carDocumentNumber);
    const carDocumentSeriesNumber = "car_document_series_number"
    const carDocumentSeriesNumberError = getFieldError(carDocumentSeriesNumber);

    const showCarPlateField = () => {
      return (
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              validateStatus={carPlateError ? "error" : ""}
              help={carPlateError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-216 hyd-osago-low-margin"
                htmlFor={"kkm_" + carPlate}
                labelText={"Гос. номер авто"}
                divId={"float-label-small"}
                child={getFieldDecorator(carPlate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^[A-Za-zА-Яа-яЁё0-9\s]{7,9}$/g,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-kkm-216"
                    maxLength={9}
                  />
                )
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-kkm-216"
                type="primary"
                disabled={carPlateError || !getFieldValue(carPlate)}
                onClick={() => {
                  getInfoByVin({car_plate: getFieldValue(carPlate)})
                }}
              >
                Проверить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )
    }

    const showVINField = () => {
      return (
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              validateStatus={vinError ? "error" : ""}
              help={vinError || ""}
            >
              <FloatingLabelInput
                divClassName={"hyd-kkm-216 hyd-osago-low-margin"}
                htmlFor={"kkm_" + vin}
                labelText={"VIN"}
                divId={"float-label-small"}
                child={getFieldDecorator(vin, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^[A-Za-z0-9]{13}[0-9]{4}$/g,
                      message: " "
                    },
                  ],
                })(
                  <Input
                    className={"hyd-input-small hyd-kkm-216"}
                    maxLength={17}/>
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-kkm-216"
                type="primary"
                disabled={vinError || !getFieldValue(vin)}
                onClick={() => {
                  getInfoByVin({vin: getFieldValue(vin)})
                  this.setState({getAutoInfo: true})
                }}
              >
                Проверить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )
    }

    const showIdentityNumbers = () => {
      return (
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item
              validateStatus={bodyNumberError ? "error" : ""}
              help={bodyNumberError || ""}
            >
              <FloatingLabelInput
                divClassName={"hyd-kkm-216"}
                htmlFor={"kkm_" + bodyNumber}
                labelText={"Кузов"}
                divId={"float-label-small"}
                child={getFieldDecorator(bodyNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{
                    required: !getFieldValue(chassisNumber),
                    message: " "
                  }],
                })(
                  <Input
                    className={
                      "hyd-input-small hyd-kkm-216"
                    }
                    maxLength={17}
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              validateStatus={chassisNumberError ? "error" : ""}
              help={chassisNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-kkm-216"
                htmlFor={"kkm_" + chassisNumber}
                labelText={"Шасси"}
                divId={"float-label-small"}
                child={getFieldDecorator(chassisNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{
                    required: !getFieldValue(bodyNumber),
                    message: " "
                  }],
                })(
                  <Input
                    className="hyd-input-small hyd-kkm-216"
                    maxLength={17}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )
    }

    const toggleCheckBoxCarPlate = (e) => {
      if (getFieldValue("documents") === 2 && e.target.checked) {
        setFieldsValue({
          documents: 1,
          car_document_serial: '',
          car_document_number: '',
          car_document_series_number: '',
          car_document_date: '',
        })
      }

      if (e.target.checked) {
        // если авто без номера и установлен соответствующий флаг, удаляем данные о введённом номере,
        // + убираем из формы текстовое поле для ввода номер и кнопка "Проверить" по номеру,
        // + указать можно лишь документ «Паспорт транспортного средства» или эПТС(?),
        // + чистим в форме гос.номер, тип документа, его серию, номер и дату
      }
    }

    const toggleCheckBoxVIN = (e) => {
      if (e.target.checked) {
        // если авто без VIN и установлен соответствующий флаг, удаляем данные о введённом VIN,
        // + убираем из формы текстовое поле для ввода номер и кнопка "Проверить" по номеру,
        // + появляются два поля (радио) -- "Кузов" и "Шасси"
        // + чистим в форме VIN
      } else { // чистим в форме "Кузов" и "Шасси"
      }
    }


    return (
      <>
        <div className={"kkm-container"}>
          <Preloader loading={isRequest}/>

          <div className="step-title-active" ref={this.headerRef}>
            <div className="step-icon-block">
              <NumberIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">1</span>
            </div>
            <span className="step-title-active-header">Оформление</span>
          </div>

          <Form {...formItemLayout}
                onSubmit={this.handleSubmit}
                id="kkm-step-1"
          >

            <div className="step-title-active kkm-title">
              <span className="step-title-active-header kkm-header">Полис ОСАГО</span>
            </div>

            <Row gutter={10}>
              {/*Серия полиса*/}
              <Col span={4}>
                <Form.Item
                  validateStatus={policySeriesError ? "error" : ""}
                  help={policySeriesError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-kkm"
                    htmlFor={"kkm_" + policySeries}
                    labelText={"Серия полиса"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(policySeries, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: " "
                        },
                        {
                          pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                          message: " "
                        }
                      ]
                    })(<Input
                      className="hyd-input-kkm hyd-input-small"
                      maxLength={3}
                      onChange={(e) => {
                        e.target.value = transliterateSeries(e.target.value.toUpperCase())
                      }}
                    />)}
                  />
                </Form.Item>
              </Col>

              {/* Номер полиса */}
              <Col span={4}>
                <Form.Item
                  validateStatus={policyNumberError ? "error" : ""}
                  help={policyNumberError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-kkm"
                    htmlFor={"kkm_" + policyNumber}
                    labelText={"Номер полиса"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(policyNumber, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: " "
                        },
                        {
                          pattern: /^[0-9]{10}$/g,
                          message: " "
                        },
                      ],
                    })(<Input className="hyd-input-small hyd-input-kkm" maxLength={10}/>)}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-kkm-btn-fill"
                    form="kkm-step-1"
                    key="fill"
                    onClick={() => {
                      let policySeries = getFieldValue("osago_policy_series")
                      let policyNumber = getFieldValue("osago_policy_number")
                      if (!policySeries || !policyNumber) {
                        notification["error"]({
                          message: 'Введите серию и номер полиса!'
                        })
                      } else {
                        clearForm(9)
                        getPolicy({
                          policy_series: policySeries,
                          policy_number: policyNumber,
                        })
                      }
                    }}
                  >
                    Заполнить
                  </Button>
                </Form.Item>
              </Col>


              <Col span={1}>
                <Tooltip
                  trigger="hover"
                  title="Укажите номер полиса, оформленный в SEVA."
                >
                  <span className="hyd-q-ico-4-container">
                    <QuestionIcon className="hyd-q-ico-4"/>
                  </span>
                </Tooltip>
              </Col>

              <Col span={5} style={{marginRight: 16}}>
                {/* Дата начала действия полиzса */}
                <Form.Item
                  validateStatus={startPolicyDateError ? "error" : ""}
                  help={startPolicyDateError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-kkm-2"
                    htmlFor={"kkm_" + startPolicyDate}
                    labelText={"Дата начала действия"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(startPolicyDate, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        ...baseRules,
                        {
                          validator: checkPolicyValueDate,
                        },
                      ],
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-input-kkm-2"
                        key={JSON.stringify({
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        })}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        }}
                        onChange={(e) => {
                          addYear(e.target.value, form, finishPolicyDate)
                        }}
                      />
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                {/* Дата окончания действия полиса */}
                <Form.Item
                  validateStatus={finishPolicyDateError ? "error" : ""}
                  help={finishPolicyDateError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-kkm-2"
                    htmlFor={"kkm_" + finishPolicyDate}
                    labelText={"Дата окончания действия"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(finishPolicyDate, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        ...baseRules,
                        {
                          pattern: /^([0-9]{2}\.[0-9]{2}\.((19|20)[0-9])\d{1})$/g,
                          message: " ",
                        },
                      ],
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-input-kkm-2"
                        key={JSON.stringify({
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        })}
                        options={{
                          date: true,
                          delimiter: ".",
                          datePattern: ["d", "m", "Y"],
                        }}
                        disabled={true}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={1}>
              <Col span={6} className="step-title-active kkm-title">
                <span className="step-title-active-header kkm-header">Страхователь</span>
              </Col>

              <Col span={6} className="kkm-is-owner-checkbox">
                {getFieldDecorator(isOwner, {initialValue: true})(
                  <Checkbox
                    checked={getFieldValue(isOwner)}
                    className={"hyd-norm-text"}
                  >
                    Является собственником
                  </Checkbox>
                )}
              </Col>
            </Row>

            <KkmPerson
              isOwner={true}
              form={form}
              lastName={insurerLastName}
              lastNameError={insurerLastNameError}
              middleName={insurerMiddleName}
              middleNameError={insurerMiddleNameError}
              name={insurerName}
              nameError={insurerNameError}
              birthDate={insurerBirthDate}
              birthDateError={insurerBirthDateError}
              phone={insurerPhone}
              phoneError={insurerPhoneError}
              email={insurerEmail}
              emailError={insurerEmailError}
              identityDoc={insurerIdentityDoc}
              identityDocError={insurerIdentityDocError}
              identityDocSeries={insurerIdentityDocSeries}
              identityDocSeriesError={insurerIdentityDocSeriesError}
              identityDocNumber={insurerIdentityDocNumber}
              identityDocNumberError={insurerIdentityDocNumberError}
              identityDocDate={insurerIdentityDocDate}
              identityDocDateError={insurerIdentityDocDateError}
              identityDocDivisionCode={insurerIdentityDocDivisionCode}
              identityDocDivisionCodeError={insurerIdentityDocDivisionCodeError}
              identityDocIssuer={insurerIdentityDocIssuer}
              identityDocIssuerError={insurerIdentityDocIssuerError}
              registrationAddress={insurerRegistrationAddress}
              registrationAddressError={insurerRegistrationAddressError}
              saveAddressData={savePolicyOwnerCladr}
            />

            {!getFieldValue(isOwner) &&
              <>
                <span className={"step-title-active kkm-subtitle"}>Собственник</span>
                <KkmPerson
                  form={form}
                  lastName={ownerLastName}
                  lastNameError={ownerLastNameError}
                  middleName={ownerMiddleName}
                  middleNameError={ownerMiddleNameError}
                  name={ownerName}
                  nameError={ownerNameError}
                  birthDate={ownerBirthDate}
                  birthDateError={ownerBirthDateError}
                  phone={ownerPhone}
                  phoneError={ownerPhoneError}
                  email={ownerEmail}
                  emailError={ownerEmailError}
                  identityDoc={ownerIdentityDoc}
                  identityDocError={ownerIdentityDocError}
                  identityDocSeries={ownerIdentityDocSeries}
                  identityDocSeriesError={ownerIdentityDocSeriesError}
                  identityDocNumber={ownerIdentityDocNumber}
                  identityDocNumberError={ownerIdentityDocNumberError}
                  identityDocDate={ownerIdentityDocDate}
                  identityDocDateError={ownerIdentityDocDateError}
                  identityDocDivisionCode={ownerIdentityDocDivisionCode}
                  identityDocDivisionCodeError={ownerIdentityDocDivisionCodeError}
                  identityDocIssuer={ownerIdentityDocIssuer}
                  identityDocIssuerError={ownerIdentityDocIssuerError}
                  registrationAddress={ownerRegistrationAddress}
                  registrationAddressError={ownerRegistrationAddressError}
                  saveAddressData={saveCarOwnerCladr}
                />
              </>
            }

            {/* Покрытие */}
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <div className={"kkm-header-cont"}>
                  <span className={"kkm-header-txt"}>Покрытие</span>
                </div>

                <Form.Item
                  validateStatus={kkmCoverageProgramError ? "error" : ""}
                  help={kkmCoverageProgramError || ""}
                >
                  <div className="hyd-select-small hyd-kkm-119 hyd-osago-select">
                    {getFieldDecorator(kkmCoverageProgram, {
                      rules: [{required: true}],
                    })(
                      <Select
                        className="hyd-input-select-small"
                        style={{width: "100%"}}
                        onChange={(e) => {
                          const program = programs.find((value) => value.id === e)
                          setCoverages(program)
                        }}
                      >
                        {programs && programs.map(({id, name}) =>
                          <Option value={id} key={id}>
                            <div className={"hyd-select-tooltip"}>Программа</div>
                            <div>{name}</div>
                          </Option>
                        )}
                      </Select>
                    )}
                  </div>
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(dtp, {initialValue: true})(
                    <Checkbox
                      checked={getFieldValue(dtp)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      ДТП по вине установленных третьих лиц
                    </Checkbox>
                  )}
                </Form.Item>

                <Row gutter={2}>
                  <Col span={9}>
                    <Form.Item
                      validateStatus={DTPCoverageError ? "error" : ""}
                      help={DTPCoverageError || ""}
                    >
                      <div className="hyd-select-small hyd-kkm-158 hyd-osago-select">
                        {getFieldDecorator(DTPCoverage, {
                          rules: [{required: true}],
                        })(
                          <Select
                            onChange={(e) => {
                              setFieldsValue({[DTPPrice]: dtpCoverages.find(value => value.id === e).cost})
                            }}
                            className="hyd-input-select-small"
                            style={{width: "100%"}}
                          >
                            {dtpCoverages && dtpCoverages.map(({id, coverage}) =>
                              <Option value={id} key={id}>
                                <div className={"hyd-select-tooltip"}>Покрытие, руб.</div>
                                <div>{coverage}</div>
                              </Option>
                            )}
                          </Select>
                        )}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      validateStatus={DTPPriceError ? "error" : ""}
                      help={DTPPriceError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-kkm-116 hyd-osago-low-margin"
                        htmlFor={"kkm_" + DTPPrice}
                        labelText={"Цена, руб."}
                        divId={"float-label-small"}
                        child={getFieldDecorator(DTPPrice, {})(
                          <Input
                            disabled
                            className="hyd-input-small hyd-kkm-116"
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(ACCIDENT, {initialValue: false})(
                    <Checkbox
                      checked={getFieldValue(ACCIDENT)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      Несчастный случай
                    </Checkbox>
                  )}
                </Form.Item>

                <Row gutter={2}>
                  <Col span={9}>
                    <Form.Item
                      validateStatus={accidentCoverageError ? "error" : ""}
                      help={accidentCoverageError || ""}
                    >
                      <div className="hyd-select-small hyd-kkm-158 hyd-osago-select">
                        {getFieldDecorator(accidentCoverage, {
                          rules: [{required: true}],
                        })(
                          <Select
                            onChange={(e) => {
                              setFieldsValue({[accidentPrice]: accidentCoverages.find(value => value.id === e).cost})
                            }}
                            className="hyd-input-select-small"
                            style={{width: "100%"}}
                          >
                            {accidentCoverages && accidentCoverages.map(({id, coverage}) =>
                              <Option value={id} key={id}>
                                <div className={"hyd-select-tooltip"}>Покрытие, руб.</div>
                                <div>{coverage}</div>
                              </Option>
                            )}
                          </Select>
                        )}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      validateStatus={accidentPriceError ? "error" : ""}
                      help={accidentPriceError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-kkm-116 hyd-osago-low-margin"
                        htmlFor={"kkm_" + accidentPrice}
                        labelText={"Цена, руб."}
                        divId={"float-label-small"}
                        child={getFieldDecorator(accidentPrice, {})(
                          <Input
                            disabled
                            className="hyd-input-small hyd-kkm-116"
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(damagedVehicleEvacuation, {initialValue: false})(
                    <Checkbox
                      checked={getFieldValue(damagedVehicleEvacuation)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      Эвакуация ТС при повреждении <span className={"kkm-checkbox-txt-grey"}>
                      Цена +{evacuationPrice && Number(evacuationPrice).toLocaleString("ru-RU", currencyOptions)}
                    </span>
                    </Checkbox>
                  )}
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(breakdownsEmergencyAssistance, {initialValue: false})(
                    <Checkbox
                      checked={getFieldValue(breakdownsEmergencyAssistance)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      Экстренная помощь при поломках на дорогах <span
                      className={"kkm-checkbox-txt-grey"}>
                      Цена +{emergencyHelpPrice && Number(emergencyHelpPrice).toLocaleString("ru-RU", currencyOptions)}
                    </span>
                    </Checkbox>
                  )}
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(emergencyCommissioner, {initialValue: false})(
                    <Checkbox
                      checked={getFieldValue(emergencyCommissioner)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      Аварийный комиссар <span className={"kkm-checkbox-txt-grey"}>
                      Цена +{commissarPrice && Number(commissarPrice).toLocaleString("ru-RU", currencyOptions)}
                    </span>
                    </Checkbox>
                  )}
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator(legalConsultation, {initialValue: false})(
                    <Checkbox
                      checked={getFieldValue(legalConsultation)}
                      className={"kkm-checkbox-txt kkm-checkbox-txt-cont"}
                    >
                      Юридическая консультация <span className={"kkm-checkbox-txt-grey"}>
                      Цена +{juristicConsultPrice && Number(juristicConsultPrice).toLocaleString("ru-RU", currencyOptions)}
                    </span>
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>

              {/* Транспортное средство */}
              <Col span={12}>

                <div className={"kkm-header-cont"}>
                  <span className={"kkm-header-txt"}>Транспортное средство</span>
                </div>

                <Form.Item style={{marginBottom: 20}}>
                  {getFieldDecorator(withoutCarPlate, {initialValue: false})(
                    <Checkbox
                      onChange={toggleCheckBoxCarPlate}
                      checked={getFieldValue(withoutCarPlate)}
                      className={"hyd-norm-text"}
                    >
                      Без гос. номера
                    </Checkbox>
                  )}

                  {getFieldDecorator(withoutVIN, {initialValue: false})(
                    <Checkbox
                      onChange={toggleCheckBoxVIN}
                      checked={getFieldValue(withoutVIN)}
                      className={"hyd-norm-text"}
                    >
                      VIN отсутствует
                    </Checkbox>
                  )}
                </Form.Item>

                {!getFieldValue(withoutCarPlate) && showCarPlateField()}
                {!getFieldValue(withoutVIN) && showVINField()}

                {getFieldValue(withoutVIN) && showIdentityNumbers()}

                <Row gutter={6}>
                  <Col span={12}>
                    <Form.Item
                      validateStatus={brandCarError ? "error" : ""}
                      help={brandCarError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-kkm-216 hyd-osago-autocomplete"
                        htmlFor={"kkm_" + brandCar}
                        labelText={"Марка"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(brandCar, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: baseRules,
                        })(
                          <AutoComplete
                            className="hyd-input-autocomplete-small"
                            dataSource={carBrands}
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) === 0
                            }
                            onSelect={(inputValue, _) => {
                              getCarModels({brand: inputValue})
                              setFieldsValue({car_model: ""})
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {/* Модель */}
                    <Form.Item
                      validateStatus={modelCarError ? "error" : ""}
                      help={modelCarError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-kkm-216 hyd-osago-autocomplete"
                        htmlFor={"kkm_" + modelCar}
                        labelText={"Модель"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(modelCar, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [{
                            required: getFieldValue(brandCar),
                            message: " "
                          }],
                        })(
                          <AutoComplete
                            className="hyd-input-autocomplete-small"
                            dataSource={carModels}
                            filterOption={(inputValue, option) =>
                              option.props.children
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) === 0
                            }
                            disabled={brandCarError || !getFieldValue(brandCar)}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={6}>
                  {/*<Col span={11} style={{marginRight: 12}}>*/}
                  {/*  <Form.Item*/}
                  {/*    validateStatus={vehicleTypeError ? "error" : ""}*/}
                  {/*    help={vehicleTypeError || ""}*/}
                  {/*  >*/}
                  {/*    <div className="hyd-select-small hyd-kkm-210 hyd-osago-select">*/}
                  {/*      {getFieldDecorator(vehicleType, {*/}
                  {/*        validateTrigger: ["onBlur", "onChange"],*/}
                  {/*        rules: [...baseRules],*/}
                  {/*      })(*/}
                  {/*        <Select*/}
                  {/*          className="hyd-input-select-small"*/}
                  {/*          style={{width: "100%"}}*/}
                  {/*        >*/}
                  {/*          {vehicleCategories.map(({value, text}) =>*/}
                  {/*            <Option value={value} key={value}>*/}
                  {/*              <div className={"hyd-select-tooltip"}>Тип транспортного средства</div>*/}
                  {/*              <div>{text}</div>*/}
                  {/*            </Option>*/}
                  {/*          )}*/}
                  {/*        </Select>*/}
                  {/*      )}*/}
                  {/*    </div>*/}
                  {/*  </Form.Item>*/}
                  {/*</Col>*/}

                  {/*<Col span={5} style={{marginRight: 12}}>*/}
                  <Col span={12}>
                    <Form.Item
                      validateStatus={carYearIssueError ? "error" : ""}
                      help={carYearIssueError || ""}
                    >
                      <FloatingLabelInput
                        // divClassName="hyd-kkm-99"
                        divClassName="hyd-kkm-216"
                        htmlFor={"kkm_" + carYearIssue}
                        labelText={"Год выпуска"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(carYearIssue, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: !getFieldValue(carYearIssue),
                              message: " "
                            },
                            {
                              validator: checkCarYearIssue,
                            },
                          ],
                        })(
                          <Input
                            // className="hyd-input-small hyd-kkm-99"
                            className="hyd-input-small hyd-kkm-216"
                            maxLength={4}/>
                        )}
                      />
                    </Form.Item>
                  </Col>

                  {/*<Col span={5}>*/}
                  <Col span={12}>
                    <Form.Item
                      validateStatus={enginePowerError ? "error" : ""}
                      help={enginePowerError || ""}
                    >
                      <FloatingLabelInput
                        // divClassName="hyd-kkm-116 hyd-osago-no-margin"
                        divClassName="hyd-kkm-216 hyd-osago-no-margin"
                        htmlFor={"kkm_" + enginePower}
                        labelText={"Мощность в лс"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(enginePower, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            ...baseRules,
                            {
                              pattern: /^(\d)+$/g,
                              message: " ",
                            },
                          ],
                        })(
                          <Input
                            maxLength={4}
                            // className="hyd-input-small hyd-kkm-116"
                            className="hyd-input-small hyd-kkm-216"
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={50}>
                  <Col span={7} style={{marginRight: 17}}>
                    {/*Документы на ТС*/}
                    <Form.Item
                      validateStatus={documentsError || getFieldValue(documents) === 5 ? "error" : ""}
                      help={documentsError || ""}
                    >
                      <div className="hyd-select-small hyd-kkm-152 hyd-osago-select">
                        {getFieldDecorator(documents, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: baseRules,
                        })(
                          <Select
                            className="hyd-input-select-small"
                            style={{width: "100%"}}
                            onChange={(e) => {
                              if (
                                !([7, 8].includes(getFieldValue(documents)) === [7, 8].includes(e)) ||
                                !([3, 6].includes(getFieldValue(documents)) === [3, 6].includes(e)) ||
                                !((getFieldValue(documents) === 1) === (e === 1)) ||
                                !((getFieldValue(documents) === 5) === (e === 5))
                              ) {
                                this.setState({cleaveKey: this.state.cleaveKey + 1});
                                setFieldsValue({
                                  car_document_serial: '',
                                  car_document_number: '',
                                  car_document_series_number: ''
                                })
                              }
                            }}
                          >
                            {carDocumentsOptions.map(({value, text}) =>
                              <Option
                                value={value}
                                key={value}
                                disabled={
                                  ((value === 2) && withoutCarPlate)
                                }
                              >
                                <div className={"hyd-select-tooltip"}>Документы на ТС</div>
                                <div className={"hyd-select-value"}>{text}</div>
                              </Option>
                            )}
                          </Select>
                        )}
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={6} style={{marginRight: 5}}>
                    {/* Дата выдачи */}
                    <Form.Item
                      validateStatus={carDocumentDateError ? "error" : ""}
                      help={carDocumentDateError || ""}
                    >
                      <FloatingLabelInput
                        divClassName={"hyd-kkm-118 hyd-osago-no-margin"}
                        htmlFor={"kkm_" + carDocumentDate}
                        labelText={"Дата выдачи"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(carDocumentDate, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: !getFieldValue(carDocumentDate),
                              message: " "
                            },
                            {
                              validator: checkCarDocumentsDate(getFieldValue(carYearIssue)),
                            },
                          ],
                        })(
                          <Cleave
                            className={
                              "ant-input hyd-input-small hyd-kkm-118 "
                            }
                            key={JSON.stringify({
                              date: true,
                              delimiter: ".",
                              datePattern: ["d", "m", "Y"],
                            })}
                            options={{
                              date: true,
                              delimiter: ".",
                              datePattern: ["d", "m", "Y"],
                            }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={7}>
                    {/*Серия и номер документа*/}
                    <Form.Item
                      validateStatus={carDocumentSeriesNumberError ? "error" : ""}
                      help={carDocumentSeriesNumberError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-kkm-151"
                        htmlFor={"kkm_" + carDocumentSeriesNumber}
                        labelText={"Номер документа"}
                        divId={"float-label-small"}
                        child={getFieldDecorator(carDocumentSeriesNumber, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            ...baseRules,
                            {
                              pattern: (
                                [7, 8].includes(getFieldValue(documents)) ? /^\d{15}$/g :
                                  (getFieldValue(documents) === 1 ? /(^[0-9]{2}[АВЕКМНОРСТУХавекмнорстух]{2}) [0-9]{6}$/g :
                                    [3, 6].includes(getFieldValue(documents)) ? /(^[АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя]{2}) [0-9]{6}$/g :
                                      /(^[0-9]{4}) [0-9]{6}|(^[0-9]{2}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}) [0-9]{6}$/g)
                              ),
                              message: " ",
                            },
                          ],
                        })(
                          <Cleave
                            key={this.state.cleaveKey}
                            className="ant-input hyd-input-small hyd-kkm-151"
                            options={{
                              blocks: ([7, 8].includes(getFieldValue(documents)) ? [15] : [3, 6].includes(getFieldValue(documents)) ? [2, 6] : [4, 6]),
                              delimiter: ' ',
                              numericOnly: false
                            }}
                            onChange={onChangeCarDocumentSeriesNumber(setFieldsValue)}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Серия */}
                <Form.Item
                  style={{display: 'none'}}
                  validateStatus={carDocumentSerialError ? "error" : ""}
                  help={carDocumentSerialError || ""}
                >
                  <FloatingLabelInput
                    htmlFor={"kkm_" + carDocumentSerial}
                    labelText={"Серия"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(carDocumentSerial, {})(<Input/>)}
                  />
                </Form.Item>

                {/* Номер */}
                <Form.Item
                  style={{display: 'none'}}
                  validateStatus={carDocumentNumberError ? "error" : ""}
                  help={carDocumentNumberError || ""}
                >
                  <FloatingLabelInput
                    htmlFor={"kkm_" + carDocumentNumber}
                    labelText={"Номер"}
                    divId={"float-label-small"}
                    child={getFieldDecorator(carDocumentNumber, {})(<Input/>)}
                  />
                </Form.Item>

              </Col>
            </Row>

            <Row gutter={8} style={{marginTop: 40}}>
              <Col>
                <PersonalDataCheckbox
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  checkboxText={"Согласие клиента на обработку персональных данных"}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={5}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-kkm-btn-calc"
                    type="primary"
                    htmlType="submit"
                    form="kkm-step-1"
                    key="submit"
                    style={{marginTop: 20}}
                    disabled={hasErrors(getFieldsError()) || !getFieldValue(isPDProcessingConsentCheckedKey)}
                  >
                    Рассчитать
                  </Button>
                </Form.Item>
              </Col>

              {price && <Col className={"hyd-kkm-ins-result"} span={8}>
                <KKMCalcResultForm value={{price: price, agent_commission: agentCommission}}/>
              </Col>}

              <Col span={5} style={{float: "right"}}>
                <Form.Item>
                  <Button
                    className="hyd-btn-small hyd-kkm-btn-proceed"
                    type="primary"
                    style={{marginTop: 20}}
                    disabled={hasErrors(getFieldsError()) || !getFieldValue(isPDProcessingConsentCheckedKey) || !draftId}
                    onClick={() => {
                      createPolicy(draftId)
                    }}
                  >
                    Оформить
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>

          {[
            {number: 2, text: "Оплата"},
          ].map(({number, text}) =>
            <div className="step-title-disabled-small" key={number}>
              <div className="step-icon-block">
                <NumberInactiveIcon className="hyd-large-num-ico"/>
                <span className="hyd-large-num-ico-num">{number}</span>
              </div>
              <span className="step-title-disabled-header">{text}</span>
            </div>
          )}

          <ModalError type={11}/>
        </div>
      </>
    )
  }
}

const WrappedFirstStepKKMForm = Form.create({
    name: "kkm-step-1",
    mapPropsToFields(props) {
      const {kkmDataForm} = props
      const transformed = transform({kkmDataForm})

      return transformed.kkmDataForm
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields)
      props.clearCalcData()
    },
  }
)(FirstStepKKM)

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  errors: errorsKKMSelector(state),
  kkmDataForm: kkmDataFormSelector(state),
  carBrands: carBrandsSelector(state),
  carModels: carModelsSelector(state),
  price: priceSelector(state),
  agentCommission: agentCommissionSelector(state),
  draftId: draftIdSelector(state),
  programs: programsSelector(state),
  dtpCoverages: dtpCoveragesSelector(state),
  commissarCoverages: commissarCoveragesSelector(state),
  accidentCoverages: accidentCoveragesSelector(state),
  emergencyHelpCoverages: emergencyHelpCoveragesSelector(state),
  evacuationCoverages: evacuationCoveragesSelector(state),
  juristicConsultCoverages: juristicConsultCoveragesSelector(state),
  commissarPrice: commissarPriceSelector(state),
  emergencyHelpPrice: emergencyHelpPriceSelector(state),
  evacuationPrice: evacuationPriceSelector(state),
  juristicConsultPrice: juristicConsultPriceSelector(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearForm,
      getPolicy,
      goToNextStep,
      clearIsRequestState,
      setDataForm,
      sendDataForm,
      getInfoByVin,
      getCarBrands,
      getCarModels,
      savePolicyOwnerCladr,
      saveCarOwnerCladr,
      createPolicy,
      clearCalcData,
      getPrograms,
      setCoverages,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFirstStepKKMForm)
