import React from "react";
import {Divider, Col, Row} from "antd";
import {numToRubbles} from "../../../utils";

export default class KKMCalcResultForm extends React.Component {
  render() {

    const {
      value,
    } = this.props

    let commissionPercent = value && value.agent_commission && value.agent_commission.value ? value.agent_commission.value : 0
    let price = value && value.price ? value.price : 0

    return (
      <div style={{marginTop: 22}}>
        <Col span={7}>
          <Row className={"hyd-osago-ins-result-head"}>Стоимость</Row>
          <Row className={"hyd-osago-ins-result-value"}>
            {numToRubbles(price)}
          </Row>
        </Col>

        <Col span={2}>
          <Divider
            type={"vertical"}
            style={{height: 40, background: "var(--color-two)"}}
          />
        </Col>

        <Col span={14}>
          <Row className={"hyd-osago-ins-result-head"}>Комиссия</Row>
          <Row className={"hyd-osago-ins-result-value hyd-osago-ins-result-commission"}>
            {numToRubbles(Number(commissionPercent) * Number(price) / 100.0)}
            <span className={"hyd-osago-ins-result-value hyd-osago-ins-result-commission-percent"}>
              {" / "}{commissionPercent}%
            </span>
          </Row>
        </Col>
      </div>
    )
  }
}
