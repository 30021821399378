import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Checkbox, Row} from "antd";
import {connect} from "react-redux";
import {
  mortgageBuildingMaterials,
  mortgageFloorMaterials,
  mortgageInsuranceObjects,
  mortgageObjectTypes,
  mortgageCheckBoxText,
} from "../../../utils";
import {mortgageDataFormSelector, isRequestSelector} from "../../../selectors/mortgageSelectors";


class ThirdStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      isDisabled
    } = this.props

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">3</span>
          </div>
          <span className="step-title-disabled-header">Страхование</span>
          {goToPrevStep && !isDisabled &&
            <Button
              className="hyd-change-button-small"
              onClick={goToPrevStep}
            >
              Изменить
            </Button>}
        </div>

        <Row>
          {dataForm.start_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата начала</div>
              <div className={"hyd-prev-step-value"}>{dataForm.start_policy_date.value}</div>
            </div>}

          {dataForm.finish_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата Окончания</div>
              <div className={"hyd-prev-step-value"}>{dataForm.finish_policy_date.value}</div>
            </div>}

          {dataForm.application_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата Заявления</div>
              <div className={"hyd-prev-step-value"}>{dataForm.application_date.value}</div>
            </div>}
        </Row>

        {dataForm.is_life_risk && dataForm.is_life_risk.value === true &&
          <Row>
            {dataForm.insurance_accidents_illnesses &&
              <Checkbox
                style={{marginLeft: 16}}
                checked={dataForm.insurance_accidents_illnesses.value}
                className={"hyd-norm-text"}
                disabled
              >
                Страховать от несчастных случаев и болезней
              </Checkbox>}
          </Row>
        }

        {dataForm.is_property_risk && dataForm.is_property_risk.value === true &&
          <>

            <Row>
              {dataForm.object_cost && dataForm.object_cost.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Стоимость объекта</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.object_cost.value} ₽</div>
                </div>}

              {dataForm.object_wear && dataForm.object_wear.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Износ</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.object_wear.value} %</div>
                </div>}

              {dataForm.floors_num && dataForm.floors_num.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Кол-во этажей</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.floors_num.value}</div>
                </div>}

              {dataForm.object_area && dataForm.object_area.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Площадь</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.object_area.value}</div>
                </div>}

              {dataForm.cadastr_nr && dataForm.cadastr_nr.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Кадастровый номер</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.cadastr_nr.value}</div>
                </div>}
            </Row>

            <Row>
              {dataForm.is_terms_confirmed &&
                <Checkbox
                  style={{marginLeft: 16}}
                  checked={dataForm.is_terms_confirmed.value}
                  className={"hyd-norm-text"}
                  disabled
                >
                  Подтверждаю условия страхования
                </Checkbox>}
            </Row>

            <Row>
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Тип объекта</div>
                <div className={"hyd-prev-step-value"}>
                  {mortgageObjectTypes.find(e => dataForm.property_type.value === e.value).text}
                </div>
              </div>

              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Объект страхования</div>
                <div className={"hyd-prev-step-value"}>
                  {mortgageInsuranceObjects.find(e => dataForm.insurance_object.value === e.value).text}
                </div>
              </div>

              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Объект страхования</div>
                <div className={"hyd-prev-step-value"}>
                  {mortgageInsuranceObjects.find(e => dataForm.insurance_object.value === e.value).text}
                </div>
              </div>

              {dataForm.construction_year &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Год постройки</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.construction_year.value}</div>
                </div>}

              {dataForm.overhaul_year && dataForm.overhaul_year.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Год кап. рем.</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.overhaul_year.value}</div>
                </div>}
            </Row>

            <Row>
              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Материал строения</div>
                <div className={"hyd-prev-step-value"}>
                  {mortgageBuildingMaterials.find(e => dataForm.building_material.value === e.value).text}
                </div>
              </div>

              <div className={"hyd-prev-step"}>
                <div className={"hyd-prev-step-header"}>Материал перекрытий</div>
                <div className={"hyd-prev-step-value"}>
                  {mortgageFloorMaterials.find(e => dataForm.floor_material.value === e.value).text}
                </div>
              </div>
            </Row>

            <Row>
              {dataForm.property_address && dataForm.property_address.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Адрес объекта</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address.value}</div>
                </div>}
            </Row>

            <Row>
              {dataForm.country && dataForm.country.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Государство</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.country.value}</div>
                </div>}

              {dataForm.property_address_zip_code && dataForm.property_address_zip_code.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Индекс</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_zip_code.value}</div>
                </div>}

              {dataForm.property_address_region && dataForm.property_address_region.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Республика, край, область</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_region.value}</div>
                </div>}

              {dataForm.property_address_city && dataForm.property_address_city.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Населённый пункт</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_city.value}</div>
                </div>}
            </Row>

            <Row>
              {dataForm.property_address_street && dataForm.property_address_street.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Улица</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_street.value}</div>
                </div>}

              {dataForm.property_address_house && dataForm.property_address_house.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Дом</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_house.value}</div>
                </div>}

              {dataForm.property_address_corpus && dataForm.property_address_corpus.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Корпус</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_corpus.value}</div>
                </div>}

              {dataForm.property_address_building && dataForm.property_address_building.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Строение</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_building.value}</div>
                </div>}

              {dataForm.property_address_flat && dataForm.property_address_flat.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Квартира</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_flat.value}</div>
                </div>}

              {dataForm.property_address_room && dataForm.property_address_room.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Комната</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.property_address_room.value}</div>
                </div>}
            </Row>

            <Row>
              {dataForm.address_str && dataForm.address_str.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Адрес строкой</div>
                  <div className={"hyd-prev-step-value"}>{dataForm.address_str.value}</div>
                </div>}
            </Row>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    dataForm: mortgageDataFormSelector(state),
    isRequest: isRequestSelector(state),
  });

export default connect(mapStateToProps)(ThirdStepDisabled);