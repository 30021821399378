import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';


export default injectReducer(initialState.agentsReducer, {

    [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.agentsReducer
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.GET_AGENTS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    agents: [],
  }),

  [types.GET_AGENTS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      agents: payload,
      errors: null,
    };
  },

  [types.GET_AGENTS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload,
  }),

  [types.GET_KV_CATEGORIES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    kvCategories: [],
  }),

  [types.GET_KV_CATEGORIES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      kvCategories: payload,
      errors: null,
    };
  },

  [types.GET_KV_CATEGORIES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload,
  }),

  [types.GET_AGENTS_TYPES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    agentsTypes: [],
  }),

  [types.GET_AGENTS_TYPES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      agentsTypes: payload,
      errors: null,
    };
  },

  [types.GET_AGENTS_TYPES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload,
  }),

  [types.GET_ALL_AGENTS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    agents: [],
  }),

  [types.GET_ALL_AGENTS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      agents: payload,
      errors: null,
    };
  },

  [types.GET_ALL_AGENTS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload,
  }),

  [types.ADD_AGENT_TO_LIST]: (state, {payload}) => {
    if (state.selectedAgentsList.includes(payload)) {
      return {
        ...state,
        selectedAgentsList: [...state.selectedAgentsList.filter(id => id !== payload)]
      }
    } else {
      return {
        ...state,
        selectedAgentsList: [...state.selectedAgentsList, payload],
      }
    }
  },

  [types.ADD_SUPERVISOR_TO_LIST]: (state, {payload}) => {
    if (state.selectedSupervisorsList.includes(payload)) {
      return {
        ...state,
        selectedSupervisorsList: [...state.selectedSupervisorsList.filter(id => id !== payload)]
      }
    } else {
      return {
        ...state,
        selectedSupervisorsList: [...state.selectedSupervisorsList, payload],
      }
    }
  },

  [types.CLEAR_SELECTED_AGENTS_LIST]: (state) => ({
    ...state,
    selectedAgentsList: [],
  }),

  [types.UPDATE_AGENT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.UPDATE_AGENT_SUCCESS]: state => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      selectedAgentsList: [],
    }
  },

  [types.UPDATE_AGENT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.TOGGLE_IS_ALL_AGENTS_RECEIVED]: (state, {payload}) => ({
    ...state,
    isAllAgentsReceived: payload
  }),

  [types.SET_PAGE_SIZE]: (state, {payload}) => ({
    ...state,
    pageSize: payload
  }),

  [types.SET_CURRENT_PAGE]: (state, {payload}) => ({
    ...state,
    currentPage: payload
  }),

  [types.SET_IS_ACTIVE_FILTER]: (state, {payload}) => ({
    ...state,
    filterIsActive: payload
  }),

  [types.SET_IS_ONLINE_FILTER]: (state, {payload}) => ({
    ...state,
    filterIsOnline: payload
  }),

  [types.SET_IS_SHOW_KV_FILTER]: (state, {payload}) => ({
    ...state,
    filterShowKV: payload
  }),

  [types.UPDATE_AGENTS_TYPES_FILTER]: (state, {payload}) => {
    if (state.agentsTypesSelectedFilters.includes(payload)) {
      return {
        ...state,
        agentsTypesSelectedFilters: [...state.agentsTypesSelectedFilters.filter(type => type !== payload)]
      }
    } else {
      return {
        ...state,
        agentsTypesSelectedFilters: [...state.agentsTypesSelectedFilters, payload],
      }
    }
  },

  [types.UPDATE_KV_CATEGORIES_FILTER]: (state, {payload}) => {
    if (state.kvCategoriesSelectedFilters.includes(payload)) {
      return {
        ...state,
        kvCategoriesSelectedFilters: [...state.kvCategoriesSelectedFilters.filter(type => type !== payload)]
      }
    } else {
      return {
        ...state,
        kvCategoriesSelectedFilters: [...state.kvCategoriesSelectedFilters, payload],
      }
    }
  },

  [types.CLEAR_AGENTS_FILTERS]: state => {
    return {
      ...state,
      filterShowKV: undefined,
      filterIsActive: undefined,
      filterIsOnline: undefined,
      agentsTypesSelectedFilters: [],
      kvCategoriesSelectedFilters: [],
    }
  }

});
