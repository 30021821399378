import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  getNotifications,
  toggleNotificationsModal,
  updateNotificationStatus,
} from "../../../actions/mainActions";
import {Divider, Form, Modal, Skeleton, Spin} from "antd";
import {
  isRequestSelector,
  notificationsSelector,
  visibleNotificationsModalSelector,
} from "../../../selectors/mainSelectors";
import {ReactComponent as DeleteIcon} from '../../../assets/Icons/Delete.svg'
import moment from "moment-timezone";
import DOMPurify from 'dompurify';

const sentStatus = "sent"
const openStatus = "open"

class ModalNotifications extends Component {


  componentDidUpdate(prevProps, prevState, snapshot) {
    const {visibleNotificationsModal, updateNotificationStatus, notifications} = this.props

    if (!prevProps.visibleNotificationsModal && visibleNotificationsModal) {
      let notificationIds = notifications
        .filter(notification => notification.status === sentStatus)
        .map(notification => notification.id)
      let patchInfo = {
        new_status: openStatus,
        notification_ids: notificationIds,
      }
      updateNotificationStatus(patchInfo)
    }
  }


  handleOk = () => {
    const {toggleNotificationsModal, getNotifications} = this.props
    toggleNotificationsModal(false)
    getNotifications()
  }


  render() {
    const {
      isRequest,
      notifications,
      visibleNotificationsModal,
    } = this.props


    return (
      <Modal
        title={<><span>Уведомления</span> <span onClick={this.handleOk}><DeleteIcon className={"notification-del-ico"}/></span></>}
        visible={visibleNotificationsModal}
        onOk={this.handleOk}
        onCancel={this.handleOk}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        width='519px'
        bodyStyle={{overflowY: 'auto', maxHeight: 'calc(80vh - 200px)'}}
        closable={false}
        footer={false}
      >
        {
          isRequest ?
            <div><Spin/><Skeleton active={true}/></div> :
            <>
              {
                notifications && notifications.length ?
                  <>
                    {notifications.map((notification) => {
                      return (
                        <div>
                          <div className={"hyd-notifications-grey-txt"}>
                            {moment.utc(`${notification["send_date"]} ${notification["send_time"]}`).tz(moment.tz.guess()).format("HH:mm:SS DD.MM.YY")}
                          </div>
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontWeight: notification.status === sentStatus ? "bold" : "normal"
                            }}
                          >
                        <span
                          style={{width: 450, display: 'inline-block'}}
                          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notification.message)}}
                        >
                        </span>
                      </span>
                          <Divider/>
                        </div>)
                    })}
                  </> : "Уведомлений нет"
              }
            </>
        }
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  notifications: notificationsSelector(state),
  visibleNotificationsModal: visibleNotificationsModalSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      toggleNotificationsModal,
      updateNotificationStatus,
      getNotifications,
    },
    dispatch
  )

const WrappedModalViewDocsForm = Form.create(
  {name: 'notifications'})(ModalNotifications
)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedModalViewDocsForm)
