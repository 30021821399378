import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";

export default injectReducer(initialState.mainReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.mainReducer
  }),

  [types.GET_TOOLS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_TOOLS_SUCCESS]: (state, {payload}) => {
    let payloadSorted = payload.sort((a, b) => b["mandatory"] - a["mandatory"])
    return {
      ...state,
      isRequest: false,
      errors: null,
      toolsList: payloadSorted,
    };
  },

  [types.GET_TOOLS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.TOGGLE_TOOLS_MODAL]: (state, {payload}) => ({
    ...state,
    visibleToolsModal: payload
  }),

  [types.GET_FAVORITES_TOOLS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_FAVORITES_TOOLS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      favoritesTools: payload["favorites_tools"],
    };
  },

  [types.GET_FAVORITES_TOOLS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.GET_AVAILABLE_PRODUCTS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_AVAILABLE_PRODUCTS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      availableProducts: payload,
    };
  },

  [types.GET_AVAILABLE_PRODUCTS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.ADD_TOOL_TO_TEMP_LIST]: (state, {payload}) => {
    if (state.tempToolsList.includes(payload)) {
      return {
        ...state,
        tempToolsList: [...state.tempToolsList.filter(tool => tool !== payload)]
      }
    } else {
      return {
        ...state,
        tempToolsList: [...state.tempToolsList, payload],
      }
    }
  },

  [types.CLEAR_TOOLS_TEMP_LIST]: (state) => ({
    ...state,
    tempToolsList: [],
  }),

  [types.UPDATE_FAVORITES_TOOLS]: (state) => {
    return ({
      ...state,
      favoritesTools: [...state.favoritesTools, ...state.tempToolsList],
    });
  },

  [types.UPDATE_FAVORITES_TOOLS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.UPDATE_FAVORITES_TOOLS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      favoritesTools: payload["favorites_tools"],
    };
  },

  [types.UPDATE_FAVORITES_TOOLS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.DEL_TOOL_FROM_FAVORITES]: (state, {payload}) => ({
    ...state,
    favoritesTools: [...state.favoritesTools.filter(tool => tool !== payload)]
  }),

  [types.DEL_TOOL_FROM_FAVORITES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.DEL_TOOL_FROM_FAVORITES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      favoritesTools: payload["favorites_tools"],
    };
  },

  [types.DEL_TOOL_FROM_FAVORITES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.GET_ACTIVE_BANNERS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_ACTIVE_BANNERS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      activeBanners: payload,
    };
  },

  [types.GET_ACTIVE_BANNERS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    activeBanners: [],
  }),

  [types.GET_NOTIFICATIONS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_NOTIFICATIONS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      notifications: payload,
    }
  },

  [types.GET_NOTIFICATIONS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    notifications: [],
  }),

  [types.UPDATE_NOTIFICATION_STATUS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.UPDATE_NOTIFICATION_STATUS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
    }
  },

  [types.UPDATE_NOTIFICATION_STATUS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    visibleErrorModal: true,
    errors: payload
  }),

  [types.TOGGLE_NOTIFICATIONS_MODAL]: (state, {payload}) => ({
    ...state,
    visibleNotificationsModal: payload
  }),


  [types.CHECK_EARNED_MONEY_AMOUNT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.CHECK_EARNED_MONEY_AMOUNT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      earnedMoney: payload,
    }
  },

  [types.CHECK_EARNED_MONEY_AMOUNT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
    earnedMoney: 0,
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

});
