import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Button, Row} from "antd";
import {ReactComponent as GreyCircleIcon} from "../../../assets/Icons/GreyCircle.svg";
import {isObject} from "../../../utils";
import {eOSAGO, BSO} from "../../../constants";

export default class FifthStepDisabled extends React.Component {
  render() {
    const {
      goToPrevStep,
      value,
      userInfo,
    } = this.props

    let agentCommission = value && value.agent_commission && isObject(value.agent_commission.value) ? value.agent_commission.value : value.agent_commission

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">5</span>
          </div>
          <span className="step-title-disabled-header">Оформление полиса ОСАГО</span>
          {goToPrevStep &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}
        </div>
        <Row>
          <div className={"hyd-osago-insurance-name-block hyd-prev-step"}>
            <GreyCircleIcon/>
            <div className={"hyd-osago-insurance-name"}>
              <span>{value.name} </span>
              <span>{value.type === BSO ? "ОСАГО" : value.type === eOSAGO ? "Е-ОСАГО" : ""}</span>
            </div>
          </div>
          <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
            <div className={"hyd-osago-ins-result-value"}>{Number(value.price).toLocaleString("ru-RU")} ₽</div>
          </div>
          {userInfo.show_commission && agentCommission &&
            <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
              <div className={"hyd-osago-ins-result-head"}>Комиссия агента</div>
              <div className={"hyd-osago-ins-result-value"}>
                {(agentCommission.identifier || !isNaN(agentCommission.value)) &&
                  (Number(agentCommission.value) * Number(value.price) / 100.0).toLocaleString(
                    "ru-RU", {style: "currency", currency: "RUB"})}
                {" "}{agentCommission.value} {(agentCommission.identifier ||
                !isNaN(agentCommission.value)) && "%"}
              </div>
            </div>
          }
        </Row>
      </>
    )
  }
}