import {takeLatest, call} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';
import history from "../modules/history"
import * as keys from "../routers/keys";


export function* getPaymentActsListSaga() {
  yield api.get(`payment_act/list/`, null, types.GET_PAYMENT_ACTS_LIST);
}

export function* createPaymentActSaga() {
  yield api.post(`payment_act/create/`, null, null, types.CREATE_PAYMENT_ACT);
}

export function* signPaymentActSaga({payload}) {
  yield api.patch(`payment_act/sign/`, null, payload, types.SIGN_PAYMENT_ACT);
}

export function* getPaymentActDetailsSaga({payload}) {
  yield api.post(`payment_act/details/`, null, payload, types.GET_PAYMENT_ACT_DETAILS);
}

export function* checkProfileRedirectSaga({payload}) {
  if (payload.profile_error) {
    yield call(history.replace, keys.PROFILE)
  }
}

export default function* () {
  yield takeLatest(types.GET_PAYMENT_ACTS_LIST, getPaymentActsListSaga);
  yield takeLatest(types.CREATE_PAYMENT_ACT_SUCCESS, getPaymentActsListSaga);
  yield takeLatest(types.SIGN_PAYMENT_ACT_SUCCESS, getPaymentActsListSaga);
  yield takeLatest(types.CREATE_PAYMENT_ACT, createPaymentActSaga);
  yield takeLatest(types.CREATE_PAYMENT_ACT_FAILURE, checkProfileRedirectSaga);
  yield takeLatest(types.SIGN_PAYMENT_ACT, signPaymentActSaga);
  yield takeLatest(types.GET_PAYMENT_ACT_DETAILS, getPaymentActDetailsSaga);
}