import {takeLatest, select} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';
import {isObject} from '../utils';
import {ACCIDENT} from '../constants';
import {oneTimeTokenSelector} from "../selectors/loginSelectors";
import {store} from "../store/configureStore";
import {getSocket} from "../socket";


export function* getKindsOfSportsSaga() {
  yield api.get(
    "get_sport_kind/",
    null,
    types.GET_KINDS_OF_SPORTS
  )
}

export function* getInsurerKladrSaga({payload}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    payload,
    types.GET_INSURER_KLADR
  )
}

export function* sendDataAccidentSportFormSaga() {
  const stateForm = yield select((state) => state.accidentsSportReducer.dataFormResult)
  let formFields = {}

  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) ? stateForm[item].value : stateForm[item]
  }
  formFields['offset'] = new Date().getTimezoneOffset()

  yield* api.get("one_time_token/", null, types.GET_ONE_TIME_TOKEN)
  const oneTimeToken = yield select(state => oneTimeTokenSelector(state))
  let insSocket = getSocket(oneTimeToken)

  insSocket.onmessage = function (e) {
    const data = JSON.parse(e.data)
    const type = data.type
    switch (type) {
      case 'calc_result':
        store.dispatch({
          type: types.ADD_CALC_RESULT_ACCIDENT_SPORT,
          payload: data
        })
        break
      case 'calc_error':
        store.dispatch({
          type: types.CALC_ERROR_ACCIDENT_SPORT,
          payload: data
        })
        insSocket.close()
        break
      case 'draft_id_assigned':
        store.dispatch({
          type: types.ASSIGN_DRAFT_ID_ACCIDENT_SPORT,
          payload: data
        })
        break
      case 'start_calculation':
        store.dispatch({
          type: types.START_CALCULATION_ACCIDENT_SPORT,
          payload: {data: data}
        })
        break
      case 'calculation_complete':
        store.dispatch({
          type: types.CALC_COMPLETE_ACCIDENT_SPORT,
          payload: data
        })
        break
      default:
        store.dispatch({
          type: types.CALC_ERROR_ACCIDENT_SPORT,
          payload: data
        })
    }
    console.log(data)
  }

  insSocket.onclose = function () {
    const state = store.getState()
    if (!state.accidentsSportReducer.calculationComplete && !state.accidentsSportReducer.visibleErrorModalAccidentsSport) {
      store.dispatch({
        type: types.CALC_ERROR_ACCIDENT_SPORT,
        payload: {errors: {error: ["Соединение с сервером разорвано!"]}}
      })
    }
    console.error('Chat socket closed unexpectedly')
  }

  insSocket.onopen = () => {
    console.log(formFields)
    insSocket.send(JSON.stringify({"type": ACCIDENT, formFields}))
  }
}

export function* createPolicyAccidentSportSaga({payload}) {
  payload = payload.value
  const stateForm = yield select(state => state.accidentsSportReducer.dataFormResult)

  let formFields = {}

  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) ? stateForm[item].value : stateForm[item]
  }

  formFields["calc_data"] = {
    "price": payload.price,
    "calc_info": payload.calc_info,
    "calc_id": payload.calc_id
  }

  formFields["agent_commission"] = payload.agent_commission;
  if (formFields["agent_commission"]) {
    formFields["agent_commission"].value = parseInt(formFields["agent_commission"].value);
  }

  formFields["offset"] = new Date().getTimezoneOffset()
  formFields["props_id"] = payload.props_id;

  yield* api.post(
    `accident/create_policy/${payload.key}/`,
    null,
    formFields,
    types.CREATE_POLICY_ACCIDENT_SPORT
  )

  console.log(formFields)
}

export function* getAccidentSportPaymentStatusSaga({payload}) {
  yield api.post(
    `accident/payment/${payload.key}/status/`,
    null,
    payload,
    types.GET_PAYMENT_STATUS_ACCIDENT_SPORT
  )
}

export default function* () {
  yield takeLatest(types.GET_KINDS_OF_SPORTS, getKindsOfSportsSaga)
  yield takeLatest(types.GET_INSURER_KLADR, getInsurerKladrSaga)
  yield takeLatest(types.SEND_DATA_ACCIDENT_SPORT_FORM, sendDataAccidentSportFormSaga)
  yield takeLatest(types.CREATE_POLICY_ACCIDENT_SPORT, createPolicyAccidentSportSaga)
  yield takeLatest(types.GET_PAYMENT_STATUS_ACCIDENT_SPORT, getAccidentSportPaymentStatusSaga)
}
