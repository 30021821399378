import React from "react";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {Checkbox, Row} from "antd";
import {connect} from "react-redux";
import {
  kkmDataFormSelector,
  withoutVinSelector,
  withoutCarPlateSelector,
  isRequestSelector,
  programsSelector,
  dtpCoveragesSelector,
  accidentCoveragesSelector,
} from "../../../selectors/kkmSelectors";
import {carDocumentsOptions, vehicleCategories,} from "../../../utils";
import {ReactComponent as GreyCircleIcon} from "../../../assets/Icons/GreyCircle.svg";
import {kkmIdentityDocs} from "../../../pages/KKM/kkmUtils";
import {bindActionCreators} from "redux";
import {getPrograms} from "../../../actions/kkmActions";

class FirstStepDisabled extends React.Component {
  componentDidMount() {
    this.props.getPrograms()
  }

  render() {
    const {
      dataForm,
      withoutVIN,
      withoutCarPlate,
      userInfo,
      price,
      agent_commission,
      programs,
      dtpCoverages,
      accidentCoverages,
    } = this.props

    const getCarPlate = () =>
      <>
        {dataForm.car_plate &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Гос. номер авто</div>
            <div className={"hyd-prev-step-value"}>{dataForm.car_plate.value}</div>
          </div>}
      </>

    const getVIN = () =>
      <>
        {dataForm.vin &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>VIN</div>
            <div className={"hyd-prev-step-value"}>{dataForm.vin.value}</div>
          </div>}
      </>

    const getIdentity = () =>
      <>
        {dataForm.body_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Кузов</div>
            <div className={"hyd-prev-step-value"}>{dataForm.body_number.value}</div>
          </div>}
        {dataForm.chassis_number &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Шасси</div>
            <div className={"hyd-prev-step-value"}>{dataForm.chassis_number.value}</div>
          </div>}
      </>

    return (
      <>
        <div className="step-title-disabled">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-disabled-header">Оформление</span>
        </div>

        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            Полис ОСАГО
          </div>
        </div>

        <Row>
          {dataForm.policy_series &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия полиса</div>
              <div className={"hyd-prev-step-value"}>{dataForm.osago_policy_series.value}</div>
            </div>}
          {dataForm.policy_num &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер полиса</div>
              <div className={"hyd-prev-step-value"}>{dataForm.osago_policy_number.value}</div>
            </div>}
          {dataForm.start_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата начала</div>
              <div className={"hyd-prev-step-value"}>{dataForm.start_policy_date.value}</div>
            </div>}
          {dataForm.finish_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата окончания</div>
              <div className={"hyd-prev-step-value"}>{dataForm.finish_policy_date.value}</div>
            </div>}
        </Row>

        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            Страхователь
          </div>
        </div>
        <Row>
          {dataForm.policy_owner_last_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Фамилия</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_last_name.value}</div>
            </div>}
          {dataForm.policy_owner_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Имя</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_name.value}</div>
            </div>}
          {dataForm.policy_owner_middle_name &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Отчество</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_middle_name.value}</div>
            </div>}
          {dataForm.policy_owner_birth_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата рождения</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_birth_date.value}</div>
            </div>}
          {dataForm.phone &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Телефон</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_phone.value}</div>
            </div>}
          {dataForm.email &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>email</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_email.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.policy_owner_identity_document &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Документ</div>
              <div className={"hyd-prev-step-value"}>
                {kkmIdentityDocs.find(e => dataForm.policy_owner_identity_document.value === e.value).text}
              </div>
            </div>}
          {dataForm.policy_owner_serial &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия паспорта</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_serial.value}</div>
            </div>}
          {dataForm.policy_owner_number &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Номер паспорта</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_number.value}</div>
            </div>}
          {dataForm.policy_owner_experience_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата выдачи</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_experience_date.value}</div>
            </div>}
          {dataForm.policy_owner_issuer_code &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Код подразделения</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_issuer_code.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.policy_owner_issuer &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Кем выдан</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_issuer.value}</div>
            </div>}
        </Row>

        <Row>
          {dataForm.policy_owner_address &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
              <div className={"hyd-prev-step-value"}>{dataForm.policy_owner_address.value}</div>
            </div>}
        </Row>

        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            Покрытие
          </div>
        </div>
        <Row>
          {programs.length > 0 && dataForm.coverage_program &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Программа</div>
              <div className={"hyd-prev-step-value"}>
                {programs.find(e => dataForm.coverage_program.value === e.id)?.name}
              </div>
            </div>}
          {dataForm.dtp &&
            <Row>
              <Checkbox
                style={{marginLeft: 16}}
                checked={dataForm.dtp.value}
                className={"hyd-norm-text"}
                disabled
              >
                ДТП по вине установленных третьих лиц
              </Checkbox>
              {dtpCoverages.length > 0 && dataForm.dtp_cover &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Покрытие</div>
                  <div className={"hyd-prev-step-value"}>
                    {
                      Number(
                        dtpCoverages.find(
                          e => dataForm.dtp_cover.value === e.id
                        )?.coverage
                      ).toLocaleString("ru-RU")
                    } ₽
                  </div>
                </div>}
              {dataForm.dtp_price.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Цена</div>
                  <div className={"hyd-prev-step-value"}>
                    {
                      Number(
                        dataForm.dtp_price.value
                      ).toLocaleString("ru-RU")
                    } ₽
                  </div>
                </div>}
            </Row>}
          {dataForm.accident &&
            <Row>
              <Checkbox
                style={{marginLeft: 16}}
                checked={dataForm.accident.value}
                className={"hyd-norm-text"}
                disabled
              >
                Несчастный случай
              </Checkbox>
              {accidentCoverages.length > 0 && dataForm.accident_cover &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Покрытие</div>
                  <div className={"hyd-prev-step-value"}>
                    {
                      Number(
                        accidentCoverages.find(
                          e => dataForm.accident_cover.value === e.id
                        )?.coverage
                      ).toLocaleString("ru-RU")
                    } ₽
                  </div>
                </div>}
              {dataForm.accident_price.value &&
                <div className={"hyd-prev-step"}>
                  <div className={"hyd-prev-step-header"}>Цена</div>
                  <div className={"hyd-prev-step-value"}>
                    {
                      Number(
                        dataForm.accident_price.value
                      ).toLocaleString("ru-RU")
                    } ₽
                  </div>
                </div>}
            </Row>}
        </Row>

        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            Транспортное средство
          </div>
        </div>
        <Row>
          {!withoutCarPlate && getCarPlate()}

          {!withoutVIN && getVIN()}
          {withoutVIN && getIdentity()}
        </Row>

        <Row>
          {dataForm.car_brand_data &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Марка</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_brand_data.text}</div>
            </div>}

          {dataForm.vehicle_type &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Тип транспортного средства</div>
              <div className={"hyd-prev-step-value"}>
                {vehicleCategories.find(e => dataForm.vehicle_type.value === e.value).text}
              </div>
            </div>}

          {dataForm.car_year_issue &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Год выпуска</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_year_issue.value}</div>
            </div>}

          {dataForm.car_model_data &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Модель</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_model_data.text}</div>
            </div>}

          {dataForm.engine_power &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Мощность ТС в ЛС</div>
              <div className={"hyd-prev-step-value"}>{dataForm.engine_power.value}</div>
            </div>}
        </Row>

        <Row>
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Документы на ТС</div>
            <div className={"hyd-prev-step-value"}>
              {carDocumentsOptions.find(e => dataForm.documents.value === e.value).text}
            </div>
          </div>

          {dataForm.car_document_series_number &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Серия и номер документа</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_document_series_number.value}</div>
            </div>}

          {dataForm.car_document_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата выдачи</div>
              <div className={"hyd-prev-step-value"}>{dataForm.car_document_date.value}</div>
            </div>}
        </Row>

        <div className={"hyd-prev-step"}>
          <div className={"hyd-driver-number-osago-disabled"}>
            Итого
          </div>
        </div>
        <Row>
          <div className={"hyd-osago-insurance-name-block hyd-prev-step"}>
            <GreyCircleIcon/>
            <div className={"hyd-osago-insurance-name"}>
              <span>САО „ВСК“</span>
              <span>ККМ</span>
            </div>
          </div>
          <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
            <div className={"hyd-osago-ins-result-value"}>{Number(price).toLocaleString("ru-RU")} ₽</div>
          </div>
          {userInfo.show_commission && agent_commission &&
            <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
              <div className={"hyd-osago-ins-result-head"}>Комиссия агента</div>
              <div className={"hyd-osago-ins-result-value"}>
                {(agent_commission.identifier || !isNaN(agent_commission.value)) &&
                  (Number(agent_commission.value) * Number(price) / 100.0).toLocaleString(
                    "ru-RU", {style: "currency", currency: "RUB"})}
                {" "}{agent_commission.value} {(agent_commission.identifier ||
                !isNaN(agent_commission.value)) && "%"}
              </div>
            </div>}
        </Row>

      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    dataForm: kkmDataFormSelector(state),
    isRequest: isRequestSelector(state),
    withoutVIN: withoutVinSelector(state),
    withoutCarPlate: withoutCarPlateSelector(state),
    programs: programsSelector(state),
    dtpCoverages: dtpCoveragesSelector(state),
    accidentCoverages: accidentCoveragesSelector(state),
  })

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPrograms,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FirstStepDisabled);