import {call, put as putEffect, select, takeLatest} from "redux-saga/effects";
import * as api from "./api";
import * as types from "../store/actionTypes";
import * as keys from "../routers/keys";
import history from "../modules/history"
import {constructField, getCategoryId, isObject} from "../utils";
import {oneTimeTokenSelector} from "../selectors/loginSelectors";
import {store} from "../store/configureStore";
import {getSocket} from "../socket";
import {ABSOLUT, ALFA, ALFA_REGIONS, HELIOS, INGOS, MAX, RGS, UGSK, VSK, eOSAGO} from "../constants";

export function* getInfoByVin({payload}) {
  yield api.post("osago/vehicle/make_report/", null, payload, types.GET_INFO_BY_VIN);

  const category_avtocod = yield select(state => state.osagoReducer.dataForm.vehicle_category_avtocod)
  if (category_avtocod && category_avtocod.value) {
    yield putEffect({type: types.TOOGLE_CATEGORY, payload: getCategoryId(category_avtocod.value)})
  }

  const brand_avtocod_id = yield select(state => state.osagoReducer.dataForm.brand_avtocod_id)
  if (brand_avtocod_id && brand_avtocod_id.value) {
    const carBrands = yield select(state => state.osagoReducer.car_brands)
    let brandData = carBrands.find((item) => item["avtocod_id"] === brand_avtocod_id.value)
    if (brandData) {
      yield putEffect({type: types.SAVE_CAR_BRAND_DATA, payload: brandData})
      let brandCar = constructField("brand_car", brandData.value.toString())
      yield putEffect({type: types.SAVE_CAR_BRAND, payload: brandCar})
      yield putEffect({type: types.TOOGLE_IS_SELECTED_BRAND, payload: true})
      const model_avtocod_id = yield select(state => state.osagoReducer.dataForm.model_avtocod_id)
      if (model_avtocod_id) {
        yield* api.get("osago/get_car_models/", {brand: brandData.value}, types.GET_CAR_MODELS);
        const carModels = yield select(state => state.osagoReducer.car_models);
        let modelData = carModels.find((item) => item["avtocod_id"] === model_avtocod_id.value)
        if (modelData) {
          yield putEffect({type: types.SAVE_CAR_MODEL_DATA, payload: modelData})
          let modelCar = constructField("model_car", modelData.value.toString())
          yield putEffect({type: types.SAVE_CAR_MODEL, payload: modelCar})
          yield putEffect({type: types.TOOGLE_IS_SELECTED_MODEL, payload: true})
          yield* api.get("osago/get_car_modifications/", {model: modelData.value}, types.GET_MODEL_MODIFICATIONS);
        }
      }
    }
  }
}

export function* getPaymentStatusSaga({payload}){
    yield api.post(
    `osago/payment/${payload.insKey}/status/`,
    null,
      {policyObjId: payload.policyObjId},
    types.GET_PAYMENT_STATUS
  );
}

export function* patchAlfaPaymentSaga({payload}) {
  yield api.post(
    "osago/payment/alfastrah/bso/",
    null,
    payload,
    types.PATCH_ALFA_PAYMENT
  );
}

export function* patchIngosPaymentSaga({payload}) {
  yield api.post(
    "osago/payment/ingostrah/bso/",
    null,
    payload,
    types.PATCH_INGOS_PAYMENT
  );
}

export function* getDriverKbmSaga({payload}) {
  yield api.post("osago/get_driver_kbm/", null, payload, types.GET_DRIVER_KBM);
}

export function* getKbmSaga({payload}) {
  yield api.post("osago/get_driver_kbm/", null, payload, types.GET_KBM);
}

export function* getOwnerDriverKbmSaga({payload}) {
  yield api.post(
    "osago/get_driver_kbm/",
    null,
    payload,
    types.GET_OWNER_DRIVER_KBM
  );
}

export function* getCarOwnerDriverKbmSaga({payload}) {
  yield api.post(
    "osago/get_driver_kbm/",
    null,
    payload,
    types.GET_CAR_OWNER_DRIVER_KBM
  );
}

export function* getKbmPolicyCarOwnerJuristicSaga({payload}) {
  yield api.post(
    "osago/get_jp_kbm/",
    null,
    payload,
    types.GET_KBM_POLICY_CAR_OWNER_JURISTIC
  );
}

export function* getKbmJuristicSaga({payload}) {
  yield api.post(
    "osago/get_jp_kbm/",
    null,
    payload,
    types.GET_KBM_JURISTIC
  );
}

export function* sendDataFormSaga() {
  const stateForm = yield select(state => state.osagoReducer.dataFormResult);
  let formFields = {};

  for (const item in stateForm) {
    formFields[item] = (isObject(stateForm[item])
      && item !== 'car_brand_data'
      && item !== 'car_model_data'
      && item !== 'car_modification_data'
    ) || item === 'car_plate'
      ? stateForm[item].value
      : stateForm[item];
    if (formFields[item] === "") {
      formFields[item] = undefined
    }
  }

  formFields["additional_drivers"] = formFields["additional_drivers"].map((item) => {
    if (["", null].includes(item['country'])) {
      item['country'] = undefined
    }
    return item
  })

  formFields['offset'] = new Date().getTimezoneOffset();
  formFields['car_brand'] = stateForm['car_brand_data'] && stateForm['car_brand_data'].value
  formFields['car_model'] = stateForm['car_model_data'] && stateForm['car_model_data'].value
  formFields['car_modification'] = stateForm['car_modification_data'] && stateForm['car_modification_data'].value

  yield* api.get("one_time_token/", null, types.GET_ONE_TIME_TOKEN);
  const oneTimeToken = yield select(state => oneTimeTokenSelector(state));
  let insSocket = getSocket(oneTimeToken)

  insSocket.onmessage = function (e) {
    const data = JSON.parse(e.data);
    const type = data.type
    switch (type) {
      case 'calc_result':
        store.dispatch({
          type: types.ADD_CALC_RESULT,
          payload: data
        });
        break;
      case 'calc_error':
        store.dispatch({
          type: types.CALC_ERROR,
          payload: data
        });
        insSocket.close()
        break;
      case 'draft_id_assigned':
        store.dispatch({
          type: types.ASSIGN_DRAFT_ID,
          payload: data
        });
        break;
      case 'start_calculation':
        store.dispatch({
          type: types.START_CALCULATION,
          payload: {data: data}
        });
        break;
      case 'calculation_complete':
        store.dispatch({
          type: types.CALC_COMPLETE,
          payload: data
        });
        break;
      default:
        store.dispatch({
          type: types.CALC_ERROR,
          payload: data
        });
    }

    console.log(data)
  };

  insSocket.onclose = function () {
    const state = store.getState()
    if (!state.osagoReducer.calculationComplete && !state.osagoReducer.visibleErrorModal) {
      store.dispatch({
        type: types.CALC_ERROR,
        payload: {errors: {error: ["Соединение с сервером разорвано!"]}}
      });
    }
    console.error('Chat socket closed unexpectedly');
  };

  insSocket.onopen = () => {
    console.log(formFields)
    insSocket.send(JSON.stringify({"type": "osago", formFields}));
  }


  // yield api.post("osago/calculate/", null, formFields, types.SEND_DATA_FORM);
}

export function* getKladrPolicyCarOwnerSaga({payload}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    payload,
    types.GET_KLADR_POLICY_CAR_OWNER
  );
}

export function* getKladrCarOwnerSaga({payload}) {
  yield api.post("osago/get_kladr/", null, payload, types.GET_KLADR_CAR_OWNER);
}

export function* getTOInfoSaga({payload}) {
  yield api.post("osago/get_ti_info/", null, payload, types.GET_TO_INFO); //get_ti_info/
}

export function* getAlfaProlongationSaga({payload}) {
  yield api.post("osago/prolongation/alfa/", null, payload, types.GET_ALFA_PROLONGATION);
}

export function* getAlfaAgreementDataSaga({payload}) {
  yield* api.post("osago/prolongation/get_alfa_agr/",
    null,
    payload,
    types.GET_ALFA_AGREEMENT_DATA
  )
}

export function* getVskProlongationSaga({payload}) {
  yield api.post("osago/prolongation/vsk/", null, payload, types.GET_VSK_PROLONGATION);
}


export function* getVskAgreementDataSaga({payload}) {
  yield* api.post("osago/prolongation/get_vsk_agr/",
      null,
      payload,
      types.GET_VSK_AGREEMENT_DATA
  )
}

export function* getProlongationRGSSaga({payload}) {
  yield api.post("osago/prolongation/rgs/", null, payload, types.GET_RGS_PROLONGATION);
}


export function* getAgreementDataRGSSaga({payload}) {
  yield* api.post("osago/prolongation/get_rgs_agr/",
      null,
      payload,
      types.GET_RGS_AGREEMENT_DATA
  )
}


export function* getReninsAgreementDataSaga({payload}) {
  yield* api.post("osago/prolongation/get_renes_agr/",
      null,
      payload,
      types.GET_RENESSANS_AGREEMENT_DATA
  )
}

export function* getReninsProlongationSaga({payload}) {
  yield api.post("osago/prolongation/renesans/", null, payload, types.GET_RENESSANS_PROLONGATION);
}

export function* getSevaProlongationSaga({payload}) {
  yield api.post("osago/prolongation/seva/", null, payload, types.GET_SEVA_PROLONGATION);
}

export function* redirectToOsagoSaga() {
  yield call(history.replace, keys.OSAGO)
}

export function* getIngosAgreementDataSaga({payload}) {
  yield* api.post("osago/prolongation/get_ingos_agr/",
    null,
    payload,
    types.GET_INGOS_AGREEMENT_DATA
  );
}

export function* getIngosProlongationSaga({payload}) {
  yield* api.post("osago/prolongation/ingos/",
    null,
    payload,
    types.GET_INGOS_PROLONGATION
  );
}

export function* getCarBrandsSaga() {
  yield api.get("osago/get_car_brands/", null, types.GET_CAR_BRANDS);
}

export function* getInsurancesSaga() {
  yield api.get("osago/get_insurances/", null, types.GET_INSURANCES);
}

export function* getCountriesSaga() {
  yield api.get("osago/get_countries/", null, types.GET_COUNTRIES);
}

export function* getCarModelsSaga({payload}) {
  yield api.get("osago/get_car_models/", payload, types.GET_CAR_MODELS);
}

export function* getModelModificationsSaga({payload}) {
  yield api.get("osago/get_car_modifications/", payload, types.GET_MODEL_MODIFICATIONS);
}

export function* getPolicyOwnerInfoByINN({payload}) {
  yield api.post(
    "osago/get_jp_by_inn/",
    null,
    payload,
    types.GET_INFO_POLICY_OWNER_BY_INN
  );
}


export function* getInfoKbmModalByINNSaga({payload}) {
  yield api.post(
    "osago/get_jp_by_inn/",
    null,
    payload,
    types.GET_INFO_KBM_MODAL_BY_INN
  );
}

export function* getCarOwnerInfoByINN({payload}) {
  yield api.post(
    "osago/get_jp_by_inn/",
    null,
    payload,
    types.GET_INFO_CAR_OWNER_BY_INN
  );
}

export function* createPolicySaga({payload}) {
  let number_policy;
  let series_policy;
  const stateForm = yield select(state => state.osagoReducer.dataFormResult);
  let makc_kbm_data = undefined;
  let correlation_id = undefined;
  let rosgosstrah_price = undefined;

  let rosgosstrah_policy_id = yield select(state => state.osagoReducer.rosgosstrah_policy_id);
  let vsk_policy_id = yield select(state => state.osagoReducer.vsk_policy_id);
  let ingostrah_policy_id = yield select(state => state.osagoReducer.ingostrah_policy_id);
  let renesans_policy_id = yield select(state => state.osagoReducer.renesans_policy_id);
  let alfastrah_policy_id = yield select(state => state.osagoReducer.alfastrah_policy_id);
  let gelios_policy_id = yield select(state => state.osagoReducer.gelios_policy_id);
  let bill_isn = yield select(state => state.osagoReducer.bill_isn);
  let selectedCrossProducts = yield select(state => state.osagoReducer.selectedCrossProducts)

  if ([RGS, INGOS, ABSOLUT, ALFA, ALFA_REGIONS, HELIOS, UGSK, VSK].includes(payload.value.key)) {
    number_policy = yield select(
      state => state.osagoReducer.dataForm.number_policy ? state.osagoReducer.dataForm.number_policy.value : undefined
    );
    series_policy = yield select(
      state => state.osagoReducer.dataForm.series_policy ? state.osagoReducer.dataForm.series_policy.value : undefined
    );
  } else {
    series_policy = payload.series;
    number_policy = payload.number;
  }
  let type_osago = yield select(
      state => state.osagoReducer.dataForm.type_osago.value
    );
  let formFields = {};
  let kbm_data = undefined;

  if (payload.value.key === VSK) {
    kbm_data = yield select(
      state => state.osagoReducer.kbm_data_additional_drivers
    );
    type_osago = yield select(
      state => state.osagoReducer.dataForm.type_osago.value
    );
  }
  if (payload.value.key === MAX) {
    kbm_data = yield select(
      state => state.osagoReducer.makc_kbm_data
    );
  }

  for (const item in stateForm) {
    formFields[item] = isObject(stateForm[item]) || item === 'car_plate'
      ? stateForm[item].value
      : stateForm[item];
  }

  if (payload.value.key === MAX) {
    makc_kbm_data = payload.value.makc_kbm_data
  }
  if (RGS === payload.value.key) {
    correlation_id = payload.value.correlation_id;
    rosgosstrah_price = payload.value.price;
  }


  formFields["calc_id"] = payload.value.calc_id;
  formFields["props_id"] = payload.value.props_id;
  formFields["calc_info"] = payload.value.calc_info;
  formFields["price"] = payload.value.price;
  formFields["reinsurance_pool"] = payload.value.reinsurance_pool;
  formFields["policy_type"] = payload.value.policy_type;
  formFields["discount_program"] = payload.value.discount_program;
  formFields["add_product"] = payload.value.add_product;
  formFields["makc_kbm_data"] = makc_kbm_data;
  formFields["correlation_id"] = correlation_id;
  formFields["type_osago"] = type_osago;
  formFields["number_policy"] = number_policy;
  formFields["series_policy"] = series_policy;
  formFields["kbm_data"] = kbm_data;
  //formFields["rosgosstrah_uuid"] = rosgosstrah_uuid;
  formFields["rosgosstrah_price"] = rosgosstrah_price;

  formFields["rosgosstrah_policy_id"] = rosgosstrah_policy_id;
  formFields["vsk_policy_id"] = vsk_policy_id;
  formFields["ingostrah_policy_id"] = ingostrah_policy_id;
  formFields["renesans_policy_id"] = renesans_policy_id;
  formFields["alfastrah_policy_id"] = alfastrah_policy_id;
  formFields["gelios_policy_id"] = gelios_policy_id;
  formFields["bill_isn"] = bill_isn;

  formFields["agent_commission"] = payload.value.agent_commission;
  if (formFields["agent_commission"]) {
    formFields["agent_commission"].value = parseInt(formFields["agent_commission"].value);
  }

  formFields["car_brand_data"] = yield select(
    state => state.osagoReducer.dataForm.car_brand_data
  );
  formFields["car_model_data"] = yield select(
    state => state.osagoReducer.dataForm.car_model_data
  );
  formFields["car_modification_data"] = yield select(
    state => state.osagoReducer.dataForm.car_modification_data
  );
  formFields['car_brand'] = stateForm['car_brand_data'] && stateForm['car_brand_data'].value
  formFields['car_model'] = stateForm['car_model_data'] && stateForm['car_model_data'].value
  formFields['car_modification'] = stateForm['car_modification_data'] && stateForm['car_modification_data'].value

  if (selectedCrossProducts) {
    let crossProducts = payload.value.cross_products
    if (crossProducts && crossProducts.length) {
      formFields['cross_products'] = crossProducts.filter(item =>
        selectedCrossProducts.includes(item.marketingProductName)
      )
    }
  }

  if (payload.value.type === eOSAGO) {
    formFields['insurance_contract'] = payload.value.insurance_contract
  }

  formFields["offset"] = new Date().getTimezoneOffset();
  yield api.post(
    `osago/create_policy/${payload.value.key}/`,
    null,
    formFields,
    types.CREATE_POLICY
  );
}

export function* refreshTokenSaga({payload}) {
  yield api.post("refresh/", null, payload, types.REFRESH_TOKEN, true);
}

export function* getKbmDriverNoLimitSaga({payload}) {
  // const vin = yield select(state => state.osagoReducer.dataForm.vin.value);
  // const series = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_serial.value
  // );
  // const number = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_number.value
  // );
  // const last_name = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_last_name_driver.value
  // );
  // const first_name = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_name_driver.value
  // );
  // const middle_name = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_middle_name_driver.value
  // );
  // const birth_date = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_birth_date.value
  // );
  // const document_type = yield select(
  //   state => state.osagoReducer.dataForm.policy_owner_identity_document.value
  // );

  // const obj = {
  //   vin: vin,
  //   series: series,
  //   number: number,
  //   last_name: last_name,
  //   first_name: first_name,
  //   middle_name: middle_name,
  //   birth_date: birth_date,
  //   document_type: document_type
  // };

  yield api.post(
    "osago/get_insurer_kbm/",
    null,
    //obj,
    payload,
    types.GET_KBM_DRIVER_NO_LIMIT
  );
}

export function* getLastNameArraySaga({payload}) {
  yield api.post("osago/get_fio/", null, payload, types.GET_LASTNAME_ARRAY);
}

export function* getConfigSaga({payload}) {
  yield api.get(`get_configs/`, payload, types.GET_GONFIG);
}

export function* getKladrPolicyCarOwnerJuristicSaga({payload}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    payload,
    types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC
  );
}

export function* getKladrCarOwnerJuristicSaga({payload}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    payload,
    types.GET_KLADR_CAR_OWNER_JURISTIC
  );
}


export function* doPaymentBso({payload}) {
  yield api.post(
    `osago/payment/${payload.key}/link_receipt/`,
    null,
    payload,
    types.DO_PAYMENT_BSO
  );
}

export function* doPaymentNssSaga({payload}) {
  let series_policy = yield select(state => state.osagoReducer.dataForm.series_policy)
  let number_policy = yield select(state => state.osagoReducer.dataForm.number_policy)
  let formFields = {
    policyObjId: payload.policyObjId,
    series_policy: series_policy?.value,
    number_policy: number_policy?.value,
    offset: new Date().getTimezoneOffset(),
  };

  yield api.post(
    `osago/payment/${payload.insKey}/`,
    null,
    formFields,
    types.DO_PAYMENT_NSS
  );
}

export function* printKPSaga({payload}) {
  // console.log(payload)
  let datka = {
    "car_plate": yield select(state => state.osagoReducer.dataForm.car_plate.value),
    "vin": yield select(state => state.osagoReducer.dataForm.vin.value),
    "body": yield select(state => state.osagoReducer.dataForm.body_number.value),
    "chassis": yield select(state => state.osagoReducer.dataForm.chassis_number.value),
    "seller": yield select(state => state.osagoReducer.dataForm.seller.value),
    "calc_results": payload
  }
  yield api.post("/api/v1/osago/print_kp/", null, datka, types.PRINT_KP, true);
}

export function* getSberQRCodeSaga({payload}) {
  yield api.post("sber_qr/", null, {obj_id: payload}, types.GET_SBER_QR_CODE);
}

export function* getPaymentMethodsSaga({payload}) {
  yield api.get("payment_methods/", {product_name:payload}, types.GET_PAYMENT_METHODS);
}

export function* getSberQRPaymentStatusSaga({payload}) {
  yield api.post("sber_qr_status/", null, {obj_id: payload}, types.GET_SBER_QR_PAYMENT_STATUS);
}

export function* populateBrandModelDataSaga() {
  yield* api.get("osago/get_car_brands/", null, types.GET_CAR_BRANDS);
  const car_brand = yield select(state => state.osagoReducer.dataForm.car_brand);
  if (car_brand) {
    const car_brands = yield select(state => state.osagoReducer.car_brands);
    const brandData = car_brands.find(
      (item) => +item.value === +car_brand.value
    );
    yield putEffect({
      type: types.SAVE_CAR_BRAND_DATA,
      payload: brandData
    })
    yield putEffect({
      type: types.TOOGLE_IS_SELECTED_BRAND,
      payload: true
    })
    yield* api.get("osago/get_car_models/", {brand: car_brand.value}, types.GET_CAR_MODELS);
    const car_model = yield select(state => state.osagoReducer.dataForm.car_model);
    const car_models = yield select(state => state.osagoReducer.car_models);
    if (car_model) {
      const modelData = car_models.find(
        (item) => +item.value === +car_model.value
      );
      yield putEffect({
        type: types.SAVE_CAR_MODEL_DATA,
        payload: modelData
      })
      yield putEffect({
        type: types.TOOGLE_IS_SELECTED_MODEL,
        payload: true
      })
      yield* api.get("osago/get_car_modifications/", {model: car_model.value}, types.GET_MODEL_MODIFICATIONS);
      const model_modification = yield select(state => state.osagoReducer.dataForm.car_modification);
      const model_modifications = yield select(state => state.osagoReducer.modelModifications);
      if (model_modification) {
        const modificationData = model_modifications.find(
          (item) => +item.value === +model_modification.value
        );
        yield putEffect({
          type: types.SAVE_MODEL_MODIFICATIONS_DATA,
          payload: modificationData
        })
      }
    }
  }
}

export function* populateJuristicDataSaga() {
  const dataForm = yield select(state => state.osagoReducer.dataForm);
  if (dataForm["policy_owner_inshur"] && dataForm["policy_owner_inshur"].value === 2 && dataForm["policy_owner_identify_number_juristic"]) {
    yield putEffect({
      type: types.GET_INFO_POLICY_OWNER_BY_INN,
      payload: {inn: dataForm["policy_owner_identify_number_juristic"].value}
    })
  }
  if (dataForm["inshur"] && dataForm["inshur"].value === 2 && dataForm["car_owner_identify_number_juristic"]) {
    yield putEffect({
      type: types.GET_INFO_CAR_OWNER_BY_INN,
      payload: {inn: dataForm["car_owner_identify_number_juristic"].value}
    })
  }
}

export function* sendAddendumApplicationSaga() {
  const stateForm = yield select(state => state.osagoReducer.addendumApplicationDataFormResult)
  yield api.post('addendum_calc_request/', null, stateForm, types.SEND_ADDENDUM_APPLICATION)
}

export function* getCrossProductsSaga({payload}) {
  yield api.get("cross_products", {draft_id: payload}, types.GET_CROSS_PRODUCTS)
}

export default function* () {
  yield takeLatest(types.GET_INFO_BY_VIN, getInfoByVin);
  yield takeLatest(types.SEND_DATA_FORM, sendDataFormSaga);
  yield takeLatest(types.GET_DRIVER_KBM, getDriverKbmSaga);
  yield takeLatest(types.GET_KBM, getKbmSaga);
  yield takeLatest(types.GET_OWNER_DRIVER_KBM, getOwnerDriverKbmSaga);
  yield takeLatest(types.GET_CAR_OWNER_DRIVER_KBM, getCarOwnerDriverKbmSaga);
  yield takeLatest(types.GET_KLADR_POLICY_CAR_OWNER, getKladrPolicyCarOwnerSaga);
  //yield takeLatest(types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC, getKladrPolicyCarOwnerJuristicSaga);
  yield takeLatest(types.GET_KLADR_CAR_OWNER, getKladrCarOwnerSaga);
  //yield takeLatest(types.GET_KLADR_CAR_OWNER_JURISTIC, getKladrCarOwnerJuristicSaga);
  yield takeLatest(types.GET_TO_INFO, getTOInfoSaga);
  yield takeLatest(types.GET_ALFA_PROLONGATION, getAlfaProlongationSaga);
  yield takeLatest(types.GET_ALFA_AGREEMENT_DATA, getAlfaAgreementDataSaga);
  yield takeLatest(types.GET_VSK_PROLONGATION, getVskProlongationSaga);
  yield takeLatest(types.GET_VSK_AGREEMENT_DATA, getVskAgreementDataSaga);
  yield takeLatest(types.GET_RGS_PROLONGATION, getProlongationRGSSaga);
  yield takeLatest(types.GET_RGS_AGREEMENT_DATA, getAgreementDataRGSSaga);
  yield takeLatest(types.GET_RENESSANS_AGREEMENT_DATA, getReninsAgreementDataSaga);
  yield takeLatest(types.GET_RENESSANS_PROLONGATION, getReninsProlongationSaga);
  yield takeLatest(types.GET_SEVA_PROLONGATION, getSevaProlongationSaga);
  yield takeLatest(types.GET_INGOS_AGREEMENT_DATA, getIngosAgreementDataSaga);
  yield takeLatest(types.GET_INGOS_PROLONGATION, getIngosProlongationSaga);
  yield takeLatest(types.GET_CAR_BRANDS, getCarBrandsSaga);
  yield takeLatest(types.GET_CAR_MODELS, getCarModelsSaga);
  yield takeLatest(types.GET_MODEL_MODIFICATIONS, getModelModificationsSaga);
  yield takeLatest(types.GET_COUNTRIES, getCountriesSaga)
  yield takeLatest(types.GET_INFO_POLICY_OWNER_BY_INN, getPolicyOwnerInfoByINN);
  yield takeLatest(types.GET_INFO_CAR_OWNER_BY_INN, getCarOwnerInfoByINN);
  yield takeLatest(types.GET_KBM_POLICY_CAR_OWNER_JURISTIC, getKbmPolicyCarOwnerJuristicSaga);
  yield takeLatest(types.CREATE_POLICY, createPolicySaga);
  yield takeLatest(types.REFRESH_TOKEN, refreshTokenSaga);
  yield takeLatest(types.GET_KBM_DRIVER_NO_LIMIT, getKbmDriverNoLimitSaga);
  yield takeLatest(types.GET_LASTNAME_ARRAY, getLastNameArraySaga);
  yield takeLatest(types.GET_GONFIG, getConfigSaga);
  yield takeLatest(types.GET_KLADR_POLICY_CAR_OWNER_JURISTIC, getKladrPolicyCarOwnerJuristicSaga);
  yield takeLatest(types.GET_KLADR_CAR_OWNER_JURISTIC, getKladrCarOwnerJuristicSaga);
  yield takeLatest(types.GET_KBM_JURISTIC, getKbmJuristicSaga);
  yield takeLatest(types.GET_INFO_KBM_MODAL_BY_INN, getInfoKbmModalByINNSaga);
  yield takeLatest(types.DO_PAYMENT_BSO, doPaymentBso);
  yield takeLatest(types.DO_PAYMENT_NSS, doPaymentNssSaga);
  yield takeLatest(types.GET_PAYMENT_STATUS, getPaymentStatusSaga);
  yield takeLatest(types.PATCH_ALFA_PAYMENT, patchAlfaPaymentSaga);
  yield takeLatest(types.PATCH_INGOS_PAYMENT, patchIngosPaymentSaga);
  yield takeLatest(types.GET_INSURANCES, getInsurancesSaga);
  yield takeLatest(types.POPULATE_BRAND_MODEL_DATA, populateBrandModelDataSaga);
  yield takeLatest(types.GET_INGOS_AGREEMENT_DATA_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_INGOS_AGREEMENT_DATA_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.COPY_POLICY_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.COPY_POLICY_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.RESUME_DRAFT_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.RESUME_DRAFT_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.RESUME_POLICY_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.RESUME_POLICY_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.GET_ALFA_AGREEMENT_DATA_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_ALFA_AGREEMENT_DATA_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.GET_ALFA_AGREEMENT_DATA_SUCCESS, redirectToOsagoSaga);
  yield takeLatest(types.GET_SEVA_PROLONGATION_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_SEVA_PROLONGATION_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.GET_SEVA_PROLONGATION_SUCCESS, redirectToOsagoSaga);
  yield takeLatest(types.GET_VSK_AGREEMENT_DATA_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_VSK_AGREEMENT_DATA_SUCCESS, populateJuristicDataSaga);
  yield takeLatest(types.GET_VSK_AGREEMENT_DATA_SUCCESS, redirectToOsagoSaga);
  yield takeLatest(types.GET_INGOS_AGREEMENT_DATA_SUCCESS, redirectToOsagoSaga);
  yield takeLatest(types.GET_RGS_AGREEMENT_DATA_SUCCESS, redirectToOsagoSaga);
  yield takeLatest(types.PRINT_KP, printKPSaga);
  yield takeLatest(types.GET_SBER_QR_CODE, getSberQRCodeSaga);
  yield takeLatest(types.GET_SBER_QR_PAYMENT_STATUS, getSberQRPaymentStatusSaga);
  yield takeLatest(types.SEND_ADDENDUM_APPLICATION, sendAddendumApplicationSaga);
  yield takeLatest(types.SEND_ADDENDUM_APPLICATION_SUCCESS, getInsurancesSaga);
  yield takeLatest(types.GET_PAYMENT_METHODS, getPaymentMethodsSaga);
  yield takeLatest(types.GET_CROSS_PRODUCTS, getCrossProductsSaga);
}
