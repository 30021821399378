import {select, call, put as putEffect} from "redux-saga/effects";
import axios from "axios";
import {tokenSelector} from "../../selectors/loginSelectors";
import {signOut} from "../../actions/loginActions";
import config from "../../config";
import qs from 'qs';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export function* get(url, query = null, actionName, blob = false) {
  return yield apiCall(url, "GET", query, null, actionName, blob);
}

export function* post(url, query = null, body = null, actionName, blob = false) {
  return yield apiCall(url, "POST", query, body, actionName, blob);
}

export function* put(url, query = null, body = null, actionName) {
  return yield apiCall(url, "PUT", query, body, actionName);
}

export function* patch(url, query = null, body = null, actionName) {
  return yield apiCall(url, "PATCH", query, body, actionName);
}

export function* del(url, query = null, actionName) {
  return yield apiCall(url, "DELETE", query, null, actionName);
}

axios.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function (error) {
    // const errorResponse = error.response;
    // if (isTokenExpiredError(errorResponse)) {
    //   return resetTokenAndReattemptRequest(error);
    // }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value

  return errorResponse && errorResponse.data && errorResponse.data.code; //  && errorResponse.data.code === 'token_not_valid';
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete

const axiosRequests = ({
                         url,
                         method,
                         query,
                         body,
                         token = null,
                         blob = false
                       }) => {
  let tmpUrl = blob ? (/^(https?:\/\/)/.test(url) ? url : `${config.url}${url}`) : (/^(https?:\/\/)/.test(url) ? url : `${config.apiUrl}${url}`);

  let request = {
    url: tmpUrl,
    timeout: 240000,
    method: method || "GET",
    data: body,
    params: query,
    paramsSerializer: params => {
      return qs.stringify(params, {arrayFormat: 'repeat'})
    },
    headers: {}
  };

  if (blob) {
    request.responseType = "blob";
  }

  if (token) request.headers.Authorization = `Bearer ${token}`;

  return axios(request)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};

function* apiCall(url, method, query, body, actionName, blob = false) {
  const token = yield select(state => tokenSelector(state));

  yield putEffect({type: `${actionName}_REQUEST`});

  try {
    const response = yield call(axiosRequests, {
      url,
      method,
      query,
      body,
      token,
      blob
    });

    if (response && (response.status === 200 || response.status === 201 || response.status === 204 || response.status === 202)) {
      if (response.data && response.data.errors && !response.data.not_modal) {
        yield putEffect({
          type: `${actionName}_FAILURE`,
          payload: response.data.errors,
          documentsPayload: response.data.value ? response.data : undefined,
        });
      } else {
        yield putEffect({
          type: `${actionName}_SUCCESS`,
          payload: response.data ? response.data : response
        });
      }
    } else {
      const payload = yield response;
      if (payload.response && (payload.response.status >= 500)) {
        yield putEffect({
          type: `${actionName}_FAILURE`,
          payload: typeof payload.response.data === 'object' ? payload.response.data : {errors: {error: ["Ошибка Сервера!", payload.response.data]}}
        })
      } else if (payload.response && (payload.response.status === 401)) {
        const isAuthenticated = yield select(state => !!tokenSelector(state))
        if (!isAuthenticated) {
          return
        }
        alert("Ошибка авторизации. Пожалуйста, авторизуйтесь заново.")
        yield putEffect(signOut())
      } else if (payload.response && payload.response.data) {
        yield putEffect({
          type: `${actionName}_FAILURE`,
          payload: typeof payload.response.data === 'object' ? payload.response.data : {errors: {error: [payload.response.data]}}
        })
      } else {
        yield putEffect({
          type: `${actionName}_FAILURE`,
          payload: {errors: {error: ["Ошибка связи с сервером"]}}
        })
      }
    }
  } catch (error) {
    yield putEffect({
      type: `${actionName}_FAILURE`,
      payload: error ? {errors: error.toString()} : {errors: "Сервер недоступен!"}
    })
  }
}
