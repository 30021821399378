import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Button,
  Radio,
  AutoComplete,
  message,
  Tabs
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import "cleave.js/dist/addons/cleave-phone.ru";
import Cleave from "cleave.js/react";

import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  toggleTypeFormPersonStepTwo,
  toggleIsPolicyOwner,
  toggleTypeFormPolicyOwnerStepTwo,
  getKladrPolicyCarOwner,
  getKladrCarOwner,
  getOwnerDriverKbm,
  clearDataPolicyOwner,
  clearDataPolicyOwnerJuristic,
  clearDataCarOwner,
  clearDataCarOwnerJuristic,
  getKbmPolicyCarOwnerJuristic,
  saveCladr,
  savePolicyOwnerCladr,
  toggleSelectedAddress,
  toggleCarOwnerSelectedAddress,
  getConfig,
  getKladrPolicyCarOwnerJuristic,
  savePolicyOwnerJuristicCladr,
  toggleSelectedPolicyOwnerJuristicAddress,
  getKladrCarOwnerJuristic,
  saveCarOwnerJuristicCladr,
  toggleSelectedCarOwnerJuristicAddress,
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  dataFormSelector,
  typeFormPersonStepTwoSelector,
  typeFormPolicyOwnerStepTwoSelector,
  adressArrSelector,
  policyAdressArrSelector,
  selectedAdressSelector,
  selectedCarOwnerAdressSelector,
  configAdminSelector,
  policyAdressJuristicArrSelector,
  selectedPolicyOwnerJuristicAdressSelector,
  carAdressJuristicArrSelector,
  selectedCarOwnerJuristicAdressSelector,
} from "../../selectors/osagoSelectors";

import {
  hasErrors,
  transform,
} from "../../utils";

import InfoOwnerCar from "../../components/Form/Step2Components/InfoOwnerCar.js";
import InfoJuristicOwnerCar from "../../components/Form/Step2Components/InfoJuristicOwnerCar.js";
import InfoJuristicPolicyCar from "../../components/Form/Step2Components/InfoJuristicPolicyCar.js";
import ModalError from "../../components/ModalError";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {tokenSelector} from "../../selectors/loginSelectors";

const {TabPane} = Tabs;

class TwoStep extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    const {token, getConfig} = this.props
    if (token) {
      getConfig()
    }
    this.headerRef.current.scrollIntoView()
  }

  handleSubmit = (e) => {
    const {currentStep, goToNextStep} = this.props;

    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        /* если страхователь - физ.лицо - и страхователь является собственником*/
        if (
          this.props.form.getFieldValue("policy_owner_inshur") === 1 &&
          this.props.selectedAdress &&
          this.props.form.getFieldValue("is_owner")
        ) {
          goToNextStep(currentStep + 1);
        } else if (
          /* если страхователь - физ.лицо и собственник-физ лицо */
          this.props.form.getFieldValue("policy_owner_inshur") === 1 &&
          !this.props.form.getFieldValue("is_owner") &&
          this.props.form.getFieldValue("inshur") === 1 &&
          this.props.selectedAdress &&
          this.props.selectedCarOwnerAdress
        ) {
          goToNextStep(currentStep + 1);
        } else if (
          /* если страхователь - юридическое лицо и страхователь является собственником   */
          this.props.form.getFieldValue("policy_owner_inshur") === 2 &&
          this.props.selectedPolicyOwnerJuristicAdress &&
          this.props.form.getFieldValue("is_owner")
        ) {
          goToNextStep(currentStep + 1);
        } else if (
          /* если страхователь - юридическое лицо и собственник-физ лицо   */
          this.props.form.getFieldValue("policy_owner_inshur") === 2 &&
          this.props.selectedPolicyOwnerJuristicAdress &&
          !this.props.form.getFieldValue("is_owner") &&
          this.props.form.getFieldValue("inshur") === 1 &&
          this.props.selectedCarOwnerAdress
        ) {
          goToNextStep(currentStep + 1);
        } else if (

          /* если страхователь - физ лицо и собственник юр лицо */
          this.props.form.getFieldValue("policy_owner_inshur") === 1 &&
          this.props.selectedAdress &&
          !this.props.form.getFieldValue("is_owner") &&
          this.props.form.getFieldValue("inshur") === 2 &&
          this.props.selectedCarOwnerJuristicAdress
        ) {
          goToNextStep(currentStep + 1);
        } else if (

          /* если страхователь - юр лицо и собственник юр лицо */
          this.props.form.getFieldValue("policy_owner_inshur") === 2 &&
          this.props.selectedPolicyOwnerJuristicAdress &&
          !this.props.form.getFieldValue("is_owner") &&
          this.props.form.getFieldValue("inshur") === 2 &&
          this.props.selectedCarOwnerJuristicAdress
        ) {
          goToNextStep(currentStep + 1);
        } else
          message.error("Пожалуйста, выберите адрес регистрации из списка");
      }
    });
  };

  render() {
    const {currentStep, goToPrevStep} = this.props;
    const {
      toggleTypeFormPersonStepTwo,
      toggleIsPolicyOwner,
      toggleTypeFormPolicyOwnerStepTwo,
      getKladrPolicyCarOwner,
      getKladrCarOwner,
      clearDataPolicyOwner,
      clearDataPolicyOwnerJuristic,
      clearDataCarOwner,
      clearDataCarOwnerJuristic,
      adressArr,
      saveCladr,
      savePolicyOwnerCladr,
      toggleSelectedAddress,
      toggleCarOwnerSelectedAddress,
      policyAdressJuristicArr,
      getKladrPolicyCarOwnerJuristic,
      savePolicyOwnerJuristicCladr,
      toggleSelectedPolicyOwnerJuristicAddress,
      carAdressJuristicArr,
      getKladrCarOwnerJuristic,
      saveCarOwnerJuristicCladr,
      toggleSelectedCarOwnerJuristicAddress,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = this.props.form;

    const isOwner = "is_owner";
    /* car owner */
    const inshur = "inshur";
    const inshurError = getFieldError(inshur);

    /*Количество страховых случаев по предыдущему договору обязательного страхования собственника данного ТС. */
    // const carOwnerInsuredAccidentCount = "car_owner_insured_accident_count";
    // const carOwnerInsuredAccidentCountError = getFieldError(carOwnerInsuredAccidentCount);

    const carOwnerName = "car_owner_name";
    const carOwnerNameError = getFieldError(carOwnerName);

    const carOwnerLastName = "car_owner_last_name";
    const carOwnerLastNameError = getFieldError(carOwnerLastName);

    const carOwnerMiddleName = "car_owner_middle_name";
    const carOwnerMiddleNameError = getFieldError(carOwnerMiddleName);

    const carOwnerGender = "car_owner_gender";
    const carOwnerGenderError = getFieldError(carOwnerGender);

    // const fullName = "full_name"
    // const fullNameError = getFieldError(fullName);

    const carOwnerBirthDate = "car_owner_birth_date";
    const carOwnerBirthDateError = getFieldError(carOwnerBirthDate);

    const identityDocument = "identity_document";
    const identityDocumentError = getFieldError(identityDocument);

    const carOwnerSerial = "car_owner_serial";
    const carOwnerSerialError = getFieldError(carOwnerSerial);

    const carOwnerNumber = "car_owner_number";
    const carOwnerNumberError = getFieldError(carOwnerNumber);

    const carOwnerExperienceDate = "car_owner_experience_date";
    const carOwnerExperienceDateError = getFieldError(carOwnerExperienceDate);

    const carOwnerIssuer = "car_owner_issuer";
    const carOwnerIssuerError = getFieldError(carOwnerIssuer);

    const carOwnerAddress = "car_owner_address";
    const carOwnerAddressError = getFieldError(carOwnerAddress);

    /* car juristic owner */
    const carOwnerJuristicDocumentType = "car_owner_juristic_document_type";

    const seriesJuristicDocument = "car_owner_series_juristic_document";

    const nameJuristic = "car_owner_name_juristic";
    const nameJuristicError = getFieldError(nameJuristic);

    const numberJuristicDocument = "car_owner_number_juristic_document";
    const numberJuristicDocumentError = getFieldError(numberJuristicDocument);

    const dateOfJuristicDoc = "car_owner_date_of_juristic_issue_doc";
    const dateOfJuristicDocError = getFieldError(dateOfJuristicDoc);

    const identifyNumberJuristic = "car_owner_identify_number_juristic";
    const identifyNumberJuristicError = getFieldError(identifyNumberJuristic);

    const carOwnerJuristicKpp = "car_owner_kpp_juristic";
    const carOwnerJuristicKppError = getFieldError(carOwnerJuristicKpp);

    const carOwnerCladrJuristic = "car_owner_cladr_juristic";
    const carOwnerCladrJuristicError = getFieldError(carOwnerCladrJuristic);

    const carOwnerPhone = "car_owner_phone"
    const carOwnerPhoneError = getFieldError(carOwnerPhone)

    const carOwnerEmail = "car_owner_email"
    const carOwnerEmailError = getFieldError(carOwnerEmail)

    const carOwnerJuristicAddress = "car_owner_juristic_cladr_address";
    const carOwnerJuristicAddressError = getFieldError(carOwnerJuristicAddress);

    /* policy owner */

    /*Количество страховых случаев по предыдущему договору обязательного страхования страхователя данного ТС. */
    // const policyOwnerInsuredAccidentCount = "policy_owner_insured_accident_count";
    // const policyOwnerInsuredAccidentCountError = getFieldError(policyOwnerInsuredAccidentCount);

    const policyOwnerAddress = "policy_owner_address";
    const policyOwnerAddressError = getFieldError(policyOwnerAddress);

    const policyOwnerInshur = "policy_owner_inshur";
    const policyOwnerInshurError = getFieldError(policyOwnerInshur);

    const policyOwnerName = "policy_owner_name";
    const policyOwnerNameError = getFieldError(policyOwnerName);

    const policyOwnerLastName = "policy_owner_last_name";
    const policyOwnerLastNameError = getFieldError(policyOwnerLastName);

    const policyOwnerMiddleName = "policy_owner_middle_name";
    const policyOwnerMiddleNameError = getFieldError(policyOwnerMiddleName);

    const policyOwnerGender = "policy_owner_gender";
    const policyOwnerGenderError = getFieldError(policyOwnerGender);

    const policyOwnerBirthDate = "policy_owner_birth_date";
    const policyOwnerBirthDateError = getFieldError(policyOwnerBirthDate);

    const policyOwnerIdentityDocument = "policy_owner_identity_document";
    const policyOwnerIdentityDocumentError = getFieldError(policyOwnerIdentityDocument);

    const policyOwnerSerial = "policy_owner_serial";
    const policyOwnerSerialError = getFieldError(policyOwnerSerial);

    const policyOwnerNumber = "policy_owner_number";
    const policyOwnerNumberError = getFieldError(policyOwnerNumber);

    const policyOwnerExperienceDate = "policy_owner_experience_date";
    const policyOwnerExperienceDateError = getFieldError(policyOwnerExperienceDate);

    const policyOwnerIssuer = "policy_owner_issuer";
    const policyOwnerIssuerError = getFieldError(policyOwnerIssuer);

    const phone = "phone"
    const phoneError = getFieldError(phone)

    const email = "email"
    const emailError = getFieldError(email)

    /*Дата начала стажа вождения */
    const policyOwnerDrivingExperienceDate = "policy_owner_driving_experience_date";
    const policyOwnerDrivingExperienceDateError = getFieldError(policyOwnerDrivingExperienceDate);

    /* policy juristic owner */

    /*Название страхователя как ЮЛ */
    const policyOwnerNameJuristic = "policy_owner_name_juristic";
    const policyOwnerNameJuristicError = getFieldError(policyOwnerNameJuristic);

    const policyOwnerJuristicDocumentType = "policy_owner_juristic_document_type";

    const policyOwnerSeriesJuristicDocument = "policy_owner_series_juristic_document";

    const policyOwnerNumberJuristicDocument = "policy_owner_number_juristic_document";
    const policyOwnerNumberJuristicDocumentError = getFieldError(policyOwnerNumberJuristicDocument);

    const policyOwnerDateOfJuristicDoc = "policy_owner_date_of_juristic_issue_doc";
    const policyOwnerDateOfJuristicDocError = getFieldError(policyOwnerDateOfJuristicDoc);

    const policyOwnerIdentifyNumberJuristic = "policy_owner_identify_number_juristic";
    const policyOwnerIdentifyNumberJuristicError = getFieldError(policyOwnerIdentifyNumberJuristic);

    const policyOwnerJuristicKpp = "policy_owner_juristic_kpp";
    const policyOwnerJuristicKppError = getFieldError(policyOwnerJuristicKpp);

    const policyOwnerCladrJuristic = "policy_owner_cladr_juristic";
    const policyOwnerCladrJuristicError = getFieldError(policyOwnerCladrJuristic);

    const policyOwnerJuristicAddress = "policy_owner_juristic_cladr_address";
    const policyOwnerJuristicAddressError = getFieldError(policyOwnerJuristicAddress);

    const checkBoxChange = (e) => {
      toggleIsPolicyOwner(e.target.value);
      setFieldsValue({
        [isOwner]: e.target.value,
        [inshur]: 1
      })

      clearDataCarOwner();
      clearDataCarOwnerJuristic();
      toggleCarOwnerSelectedAddress(true);

    };

    const getFormPhysicalPerson = () => {
      const {form} = this.props;

      return (
        <>
          <InfoOwnerCar
            form={form}
            isOwner={true}
            nameError={carOwnerNameError}
            name={carOwnerName}
            lastNameError={carOwnerLastNameError}
            lastName={carOwnerLastName}
            middleNameError={carOwnerMiddleNameError}
            middleName={carOwnerMiddleName}
            genderError={carOwnerGenderError}
            gender={carOwnerGender}
            birthDateError={carOwnerBirthDateError}
            birthDate={carOwnerBirthDate}
            identityDocumentError={identityDocumentError}
            identityDocument={identityDocument}
            serialError={carOwnerSerialError}
            serial={carOwnerSerial}
            numberError={carOwnerNumberError}
            number={carOwnerNumber}
            experienceDateError={carOwnerExperienceDateError}
            experienceDate={carOwnerExperienceDate}
            issuerError={carOwnerIssuerError}
            issuer={carOwnerIssuer}
            //drivingExperienceDateError={carOwnerDrivingExperienceDateError}
            //drivingExperienceDate = {carOwnerDrivingExperienceDate}
          />
          {/* Получить КЛАДР   */}
          <Form.Item
            validateStatus={carOwnerAddressError ? "error" : ""}
            help={carOwnerAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-16-input hyd-osago-autocomplete-small"
              htmlFor={"step-2_" + carOwnerAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(carOwnerAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={adressArr}
                  onSelect={(inputValue) => {

                    toggleCarOwnerSelectedAddress(true);

                    adressArr.map((value) => {
                      //достаем адрес, регион,страну с массива с сервеара
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(carOwnerAddress);

                        if (valueOfInput.indexOf("_") !== -1) {
                          //valueOfInput = valueOfInput.split("_")[1];
                          valueOfInput = valueOfInput.split("_");
                          setFieldsValue({
                            carOwnerAddress: valueOfInput[1],
                          });
                        }

                        //const valueSub = valueOfInput.substring(0, valueOfInput.indexOf('_'));
                        // if(valueOfInput.indexOf("_")!==-1) {
                        //   valueOfInput = valueOfInput.split("_")[1];
                        //   setFieldsValue({carOwnerAddress: valueOfInput});
                        // }

                        saveCladr(
                          value.value_cladr,
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        );
                      }
                    });
                  }}

                  onChange={(value) => {
                    // let str = getFieldValue(carOwnerAddress);
                    if (value && value.length > 1) {
                      getKladrCarOwner({address: value});
                    }
                    // this.setState({selectedAdress: false});
                    toggleCarOwnerSelectedAddress(false);
                  }}
                />
              )}
            />
          </Form.Item>

        </>
      );
    };

    const getFormJuristicPerson = () => {
      const {form} = this.props;
      return (
        <>
          <InfoJuristicOwnerCar
            form={form}
            identifyNumberJuristicError={identifyNumberJuristicError}
            identifyNumberJuristic={identifyNumberJuristic}
            nameJuristic={nameJuristic}
            nameJuristicError={nameJuristicError}
            seriesJuristicDocument={seriesJuristicDocument}
            numberJuristicDocumentError={numberJuristicDocumentError}
            numberJuristicDocument={numberJuristicDocument}
            dateOfJuristicDocError={dateOfJuristicDocError}
            dateOfJuristicDoc={dateOfJuristicDoc}
            carOwnerJuristicKpp={carOwnerJuristicKpp}
            carOwnerJuristicKppError={carOwnerJuristicKppError}
            carOwnerCladrJuristic={carOwnerCladrJuristic}
            carOwnerCladrJuristicError={carOwnerCladrJuristicError}
            carOwnerJuristicDocumentType={carOwnerJuristicDocumentType}
          />

          {/*Адрес регистрации юр лица собственника*/}
          <Form.Item
            validateStatus={carOwnerJuristicAddressError ? "error" : ""}
            help={carOwnerJuristicAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-16-input hyd-osago-autocomplete-small"
              htmlFor={"step-2_" + carOwnerJuristicAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(carOwnerJuristicAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={carAdressJuristicArr}
                  onSearch={() => {
                  }}
                  onSelect={(inputValue, option) => {
                    carAdressJuristicArr.map((value) => {
                      //достаем адрес, регион,страну с массива с сервеара
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(
                          carOwnerJuristicAddress
                        );
                        if (valueOfInput.indexOf("_") !== -1) {
                          valueOfInput = valueOfInput.split("_");
                          setFieldsValue({
                            carOwnerJuristicAddress: valueOfInput[1],
                          });
                        }

                        saveCarOwnerJuristicCladr(
                          value.value_cladr,
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        );
                      }
                    });

                    toggleSelectedCarOwnerJuristicAddress(true);
                  }}
                  onChange={(value) => {
                    // let str = getFieldValue(carOwnerJuristicAddress);

                    if (value && value.length > 1)
                      getKladrCarOwnerJuristic({address: value});

                    toggleSelectedCarOwnerJuristicAddress(false);
                  }}
                />
              )}
            />
          </Form.Item>
        </>
      );
    };

    const getInfoPolicyOwner = () => {
      const {form, policyAdressArr} = this.props;

      return (
        <>
          <InfoOwnerCar
            form={form}
            nameError={policyOwnerNameError}
            name={policyOwnerName}
            lastNameError={policyOwnerLastNameError}
            lastName={policyOwnerLastName}
            middleNameError={policyOwnerMiddleNameError}
            middleName={policyOwnerMiddleName}
            genderError={policyOwnerGenderError}
            gender={policyOwnerGender}
            birthDateError={policyOwnerBirthDateError}
            birthDate={policyOwnerBirthDate}
            identityDocumentError={policyOwnerIdentityDocumentError}
            identityDocument={policyOwnerIdentityDocument}
            serialError={policyOwnerSerialError}
            serial={policyOwnerSerial}
            numberError={policyOwnerNumberError}
            number={policyOwnerNumber}
            experienceDateError={policyOwnerExperienceDateError}
            experienceDate={policyOwnerExperienceDate}
            issuerError={policyOwnerIssuerError}
            issuer={policyOwnerIssuer}
            drivingExperienceDateError={policyOwnerDrivingExperienceDateError}
            drivingExperienceDate={policyOwnerDrivingExperienceDate}
          />
          {/* Получить КЛАДР страхователя*/}
          <Form.Item
            validateStatus={policyOwnerAddressError ? "error" : ""}
            help={policyOwnerAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-16-input hyd-osago-autocomplete-small"
              htmlFor={"step-2_" + policyOwnerAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={policyAdressArr}
                  onSearch={() => {
                  }}
                  onSelect={(inputValue, option) => {
                    policyAdressArr.map((value) => {
                      //достаем адрес, регион,страну с массива с сервеара
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(policyOwnerAddress);
                        if (valueOfInput.indexOf("_") !== -1) {
                          //valueOfInput = valueOfInput.split("_")[1];
                          valueOfInput = valueOfInput.split("_");
                          setFieldsValue({
                            policyOwnerAddress: valueOfInput[1],
                          });
                        }

                        // savePolicyOwnerCladr(valueOfInput.substring(0, valueOfInput.indexOf('_')), value.text, value.country, value.region, value.zip, value.city_kladr, value.street_kladr, value.street_name, value.house);
                        savePolicyOwnerCladr(
                          value.value_cladr,
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        );
                      }
                    });

                    toggleSelectedAddress(true);
                  }}
                  onChange={(value) => {
                    if (value && value.length > 1) {
                      getKladrPolicyCarOwner({address: value});
                    }
                    toggleSelectedAddress(false);
                  }}
                />
              )}
            />
          </Form.Item>

        </>
      );
    };

    const getFormJuristicPolicyOwner = () => {
      const {form} = this.props;
      return (
        <>
          <InfoJuristicPolicyCar
            form={form}
            identifyNumberJuristicError={policyOwnerIdentifyNumberJuristicError}
            identifyNumberJuristic={policyOwnerIdentifyNumberJuristic}
            nameJuristic={policyOwnerNameJuristic}
            nameJuristicError={policyOwnerNameJuristicError}
            seriesJuristicDocument={policyOwnerSeriesJuristicDocument}
            numberJuristicDocumentError={policyOwnerNumberJuristicDocumentError}
            numberJuristicDocument={policyOwnerNumberJuristicDocument}
            dateOfJuristicDocError={policyOwnerDateOfJuristicDocError}
            dateOfJuristicDoc={policyOwnerDateOfJuristicDoc}
            policyOwnerJuristicKpp={policyOwnerJuristicKpp}
            policyOwnerJuristicKppError={policyOwnerJuristicKppError}
            policyOwnerCladrJuristic={policyOwnerCladrJuristic}
            policyOwnerCladrJuristicError={policyOwnerCladrJuristicError}
            policyOwnerJuristicDocumentType={policyOwnerJuristicDocumentType}
          />
          {/*Адрес регистрации юр лица*/}
          <Form.Item
            validateStatus={policyOwnerJuristicAddressError ? "error" : ""}
            help={policyOwnerJuristicAddressError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-16-input hyd-osago-autocomplete-small"
              htmlFor={"step-2_" + policyOwnerJuristicAddress}
              labelText={"Адрес регистрации"}
              divId={"float-label-small"}
              child={getFieldDecorator(policyOwnerJuristicAddress, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " ",
                  },
                ],
              })(
                <AutoComplete
                  className="hyd-input-autocomplete-small"
                  dataSource={policyAdressJuristicArr}
                  onSearch={() => {
                  }}
                  onSelect={(inputValue, option) => {
                    policyAdressJuristicArr.map((value) => {
                      //достаем адрес, регион,страну с массива с сервеара
                      if (inputValue === value.value) {
                        let valueOfInput = getFieldValue(
                          policyOwnerJuristicAddress
                        );
                        if (valueOfInput.indexOf("_") !== -1) {
                          //valueOfInput = valueOfInput.split("_")[1];
                          valueOfInput = valueOfInput.split("_");
                          setFieldsValue({
                            policyOwnerJuristicAddress: valueOfInput[1],
                          });
                        }

                        savePolicyOwnerJuristicCladr(
                          value.value_cladr,
                          value.text,
                          value.okato,
                          value.country,
                          value.region,
                          value.zip,
                          value.city_name,
                          value.city_kladr,
                          value.street_kladr,
                          value.street_name,
                          value.house,
                          value.block,
                          value.flat,
                          value.fias_id,
                          value.house_fias_id,
                        );
                      }
                    });

                    toggleSelectedPolicyOwnerJuristicAddress(true);
                  }}
                  onChange={(value) => {
                    // let str = getFieldValue(policyOwnerJuristicAddress);

                    if (value && value.length > 1)
                      getKladrPolicyCarOwnerJuristic({address: value});

                    toggleSelectedPolicyOwnerJuristicAddress(false);
                  }}
                />
              )}
            />
          </Form.Item>
        </>
      );
    };

    const onChangeRadio = (e) => {
      toggleTypeFormPersonStepTwo(e);

      //resetFields();
    };

    const onChangeRadioPolicyOwner = (e) => {
      toggleTypeFormPolicyOwnerStepTwo(e);
    };

    const getCarOwner = () => {
      return (
        <>
          <div className={"hyd-bold-text"} style={{marginTop: "48px"}}>Собственник ТС</div>
          <Tabs
            className="hyd-tab-osago"
            defaultActiveKey={getFieldValue(inshur).toString()}
            onChange={(e) => {
              e = parseInt(e);
              setFieldsValue({
                [inshur]: e,
              });
              onChangeRadioPolicyOwner(e);
              // if (getFieldValue(inshur) === 2) {
              if (e === 2) {
                clearDataCarOwner();
                toggleCarOwnerSelectedAddress(true);
              } else {
                clearDataCarOwnerJuristic();
                toggleCarOwnerSelectedAddress(false);
              }
            }}
          >
            <TabPane tab={<span className="hyd-tab-pane">Физическое лицо</span>} key="1">
              {getFieldValue(inshur) === 1 && getFormPhysicalPerson()}
            </TabPane>
            <TabPane tab={<span className="hyd-tab-pane">Юридическое лицо</span>} key="2">
              {getFieldValue(inshur) === 2 && getFormJuristicPerson()}
            </TabPane>
          </Tabs>
          <Form.Item
            style={{display: "none"}}
            validateStatus={inshurError ? "error" : ""}
            help={inshurError || ""}
          >
            {getFieldDecorator(inshur, {
              initialValue: inshur,
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}],
            })(
              <Radio.Group
                className={"hyd-radio-group"}
                name={inshur}
              >
                <Radio value={1}>Физическое лицо</Radio>
                <Radio value={2}>Юридическое лицо</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <div className={"hyd-bold-text hyd-subtitle hyd-subtitle-margin"}>
            Контакты
          </div>
          <Row>
            <Form.Item
              validateStatus={carOwnerEmailError ? "error" : ""}
              help={carOwnerEmailError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-2_" + carOwnerEmail}
                labelText={"Email"}
                divId={"float-label-small"}
                child={getFieldDecorator(carOwnerEmail, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                      message: " ",
                    },
                  ],
                })(<Input
                  className="hyd-input-small hyd-osago-8-input"
                />)}
              />
            </Form.Item>

            <Form.Item
              validateStatus={carOwnerPhoneError ? "error" : ""}
              help={carOwnerPhoneError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-2_" + carOwnerPhone}
                labelText={"Телефон"}
                divId={"float-label-small"}
                child={getFieldDecorator(carOwnerPhone, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                      message: " "
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={"phone-1"}
                    options={{
                      phone: true,
                      phoneRegionCode: 'RU',
                      prefix: "+7",
                      noImmediatePrefix: true,
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

        </>
      );
    };


    return (
      <>
        <FirstStepDisabled
          goToPrevStep={() => goToPrevStep(currentStep - 1)}
        />

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">2</span>
          </div>

          <span className="step-title-active-header">Страхователь</span>
        </div>
        <div className={"hyd-bold-text"}>Страхователь ТС</div>

        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
        >
          {/* Страхователь ТС */}
          <Tabs
            className="hyd-tab-osago"
            defaultActiveKey={getFieldValue(policyOwnerInshur).toString()}
            onChange={(e) => {
              e = parseInt(e);
              setFieldsValue({
                [policyOwnerInshur]: e,
              });
              onChangeRadio(e);
              // if (getFieldValue(policyOwnerInshur) === 2) {
              if (e === 2) {
                clearDataPolicyOwner();
                toggleSelectedAddress(true);
              } else {
                clearDataPolicyOwnerJuristic();
                toggleSelectedAddress(false);
              }
              //    this.props.form.setFieldsValue({[carOwnerInsuredAccidentCount] :''});
            }}>
            <TabPane tab={<span className="hyd-tab-pane">Физическое лицо</span>} key="1">
              {getFieldValue(policyOwnerInshur) === 1 && getInfoPolicyOwner()}
            </TabPane>
            <TabPane tab={<span className="hyd-tab-pane">Юридическое лицо</span>} key="2">
              {getFieldValue(policyOwnerInshur) === 2 && getFormJuristicPolicyOwner()}
            </TabPane>
          </Tabs>
          <Form.Item
            style={{display: 'none'}}
            label="Страхователь ТС:"
            validateStatus={policyOwnerInshurError ? "error" : ""}
            help={policyOwnerInshurError || ""}
          >
            {getFieldDecorator(policyOwnerInshur, {
              initialValue: policyOwnerInshur,
              validateTrigger: ["onBlur", "onChange"],
              rules: [{required: true, message: " "}],
            })(
              <Radio.Group
                className={"hyd-radio-group"}
                name={policyOwnerInshur}
              >
                <Radio value={1}>Физическое лицо</Radio>
                <Radio value={2}>Юридическое лицо</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Row>
            <Form.Item>
              <div className={"hyd-bold-text hyd-subtitle"}>
                Страхователь является собственником?
              </div>
              {getFieldDecorator(isOwner, {
                valuePropName: "value",
              })(
                <Input style={{display: "none"}}/>
              )}
              <Radio.Group
                value={!!getFieldValue(isOwner)}
                defaultValue={!!getFieldValue(isOwner)}
                className={"hyd-radio-group"}
                onChange={checkBoxChange}
              >
                <Radio value={true}>Да</Radio>
                <Radio value={false}>Нет</Radio>
              </Radio.Group>
            </Form.Item>

          </Row>
          <div className={"hyd-bold-text hyd-subtitle hyd-subtitle-margin"}>
            Контакты
          </div>
          <Row>
            <Form.Item
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-2_" + email}
                labelText={"Email"}
                divId={"float-label-small"}
                child={getFieldDecorator(email, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-osago-8-input"
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              validateStatus={phoneError ? "error" : ""}
              help={phoneError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-2_" + phone}
                labelText={"Телефон"}
                divId={"float-label-small"}
                child={getFieldDecorator(phone, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " ",
                    },
                    {
                      pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                      message: " "
                    },
                  ],
                })(
                  <Cleave
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    key={"phone-2"}
                    options={{
                      phone: true,
                      phoneRegionCode: 'RU',
                      prefix: "+7",
                      noImmediatePrefix: true,
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

          {!getFieldValue(isOwner) && getCarOwner()}

          {/* Назад/Далее  */}
          <Row>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-osago-btn-proceed"
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Продолжить
              </Button>
            </Form.Item>
          </Row>
        </Form>
        {[
          {number: 3, text: "Список водителей"},
          {number: 4, text: "Условия использования"},
          {number: 5, text: "Оформление полиса ОСАГО"},
          {number: 6, text: "Предварительный просмотр документов"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}
        <ModalError type={1}/>
      </>
    );
  }
}

const WrappedTwoStepForm = Form.create({
  name: "step-2",
  mapPropsToFields(props) {
    const {dataForm} = props;
    const transformed = transform({dataForm});

    return transformed.dataForm;
  },
  onFieldsChange(props, changedFields) {
    props.setDataForm(changedFields, "carOwner");
  },
})(TwoStep);

const mapStateToProps = (state) => ({
  token: tokenSelector(state),
  currentStep: currentStepSelector(state),
  dataForm: dataFormSelector(state),
  typeFormPersonStepTwo: typeFormPersonStepTwoSelector(state),
  typeFormPolicyOwnerStepTwo: typeFormPolicyOwnerStepTwoSelector(state),
  adressArr: adressArrSelector(state),
  policyAdressArr: policyAdressArrSelector(state),
  selectedAdress: selectedAdressSelector(state),
  selectedCarOwnerAdress: selectedCarOwnerAdressSelector(state),
  configAdmin: configAdminSelector(state),
  policyAdressJuristicArr: policyAdressJuristicArrSelector(state),
  selectedPolicyOwnerJuristicAdress: selectedPolicyOwnerJuristicAdressSelector(state),
  carAdressJuristicArr: carAdressJuristicArrSelector(state),
  selectedCarOwnerJuristicAdress: selectedCarOwnerJuristicAdressSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      toggleTypeFormPersonStepTwo,
      toggleIsPolicyOwner,
      toggleTypeFormPolicyOwnerStepTwo,
      getKladrPolicyCarOwner,
      getKladrCarOwner,
      getOwnerDriverKbm,
      clearDataPolicyOwner,
      clearDataPolicyOwnerJuristic,
      clearDataCarOwner,
      clearDataCarOwnerJuristic,
      getKbmPolicyCarOwnerJuristic,
      saveCladr,
      savePolicyOwnerCladr,
      toggleSelectedAddress,
      toggleCarOwnerSelectedAddress,
      getConfig,
      getKladrPolicyCarOwnerJuristic,
      savePolicyOwnerJuristicCladr,
      toggleSelectedPolicyOwnerJuristicAddress,
      getKladrCarOwnerJuristic,
      saveCarOwnerJuristicCladr,
      toggleSelectedCarOwnerJuristicAddress,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedTwoStepForm);
