import * as types from "../store/actionTypes";


export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
})

export const getRenewalPolicies = () => ({
  type: types.GET_RENEWAL_POLICIES
})

export const getRenewalPoliciesCount = () => ({
  type: types.GET_RENEWAL_POLICIES_COUNT
})

export const selectRenewalPolicy = (id) => ({
  type: types.SELECT_RENEWAL_POLICY,
  payload: id,
})

export const copyRenewalPolicyParams = (payload) => ({
  type: types.COPY_RENEWAL_POLICY_PARAMS,
  payload: payload,
})
