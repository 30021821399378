import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {calculateFilters, replaceNullWithEmptyString} from "../utils";

export default injectReducer(initialState.paymentActsReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.paymentActsReducer
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload
  }),

  [types.TOGGLE_PAYMENT_ACT_DETAILS_MODAL]: (state, {payload}) => ({
    ...state,
    visiblePaymentActDetailsModal: payload
  }),

  [types.SELECT_PAYMENT_ACT]: (state, {payload}) => ({
    ...state,
    selectedAct: payload
  }),

  [types.GET_PAYMENT_ACTS_LIST_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_PAYMENT_ACTS_LIST_SUCCESS]: (state, {payload}) => {
    const paymentActsList = replaceNullWithEmptyString(payload)
    return {
      ...state,
      isRequest: false,
      paymentActsList: paymentActsList,
      paymentActsCount: payload.length,
      selectedAct: state.selectedAct ?
        paymentActsList.find((paymentAct) => paymentAct.id === state.selectedAct.id) : null,
      errors: null,
      paymentActsFilters: calculateFilters(payload, [
        "status"
      ]),
    }
  },

  [types.GET_PAYMENT_ACTS_LIST_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.CREATE_PAYMENT_ACT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.CREATE_PAYMENT_ACT_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null
  }),

  [types.CREATE_PAYMENT_ACT_FAILURE]: (state, {payload}) => {
    if (payload.profile_error) {
      return {
        ...state,
        isRequest: false,
      }
    }

    return {
      ...state,
      isRequest: false,
      errors: payload,
      visibleErrorModal: true
    }
  },

  [types.SIGN_PAYMENT_ACT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.SIGN_PAYMENT_ACT_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null
  }),

  [types.SIGN_PAYMENT_ACT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

  [types.GET_PAYMENT_ACT_DETAILS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_PAYMENT_ACT_DETAILS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentActDetails: payload,
    visiblePaymentActDetailsModal: true,
    errors: null
  }),

  [types.GET_PAYMENT_ACT_DETAILS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),

})
