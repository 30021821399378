import React, {Component} from "react";

import {
  Button,
  Layout,
  Table,
  DatePicker,
  notification,
  Spin,
  Empty,
  Skeleton,
} from "antd";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ModalViewDocs from "../../components/Form/ModalViewDocs";
import {
  getExportDocument,
  clearIsRequestState,
  getContractsList,
  resumeDraft,
  resumeDraftMortgage,
  resumeDraftCross,
  resumePolicy,
  resumePolicyProlongation,
  resumeDraftProlongation,
  resumePolicyMortgage,
  resumePolicyCross,
  copyPolicy,
  toggleViewDocsModal,
  getPolicyDocs,
  checkPaymentStatusCommon,
  resumeAccidentsSport,
  copyPolicyAccidentsSport,
  copyPolicyMortgage,
  copyPolicyCross,
  payPolicyAccidentsSport,
  resumeDraftKKM,
  copyPolicyKKM,
  resumePolicyKKM,
} from "../../actions/contractsActions";
import {
  isRequestSelector,
  contractsListSelector,
  contractsFiltersSelector,
  contractsCountSelector,
} from "../../selectors/contractsSelectors";

import {ReactComponent as DocumentIcon} from '../../assets/Icons/Document.svg'
import "./styles/index.scss";
import * as moment from "moment";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";
import {frontDateFormat} from "../../utils";
import {CROSS, MORTGAGE} from "../../constants";
import {
  DRAFT,
  ORDERED,
  BID,
  DENIAL,
  rowSelection,
  generateFilteredColumn,
  generateSearchedColumn,
  generateDateSortedColumn,
  generateEllipsisColumn,
  generateSortedColumn,
  generateIndexColumn,
} from "../../utilsTable";
import ModalError from "../../components/ModalError";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import {renewalPoliciesCountSelector} from "../../selectors/renewalSelectors";
import * as keys from "../../routers/keys";
import {getRenewalPoliciesCount} from "../../actions/renewalActions";

const {Content} = Layout;
const {RangePicker} = DatePicker;


class Contracts extends Component {
  state = {
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    filteredInfo: {},
    sortedInfo: {
      columnKey: "updated",
      field: "updated",
      order: "descend"
    },
    searchText: '',
    searchedColumn: '',
    pageNum: 1,
    pageSize: 10,
    index: 0,
    selectedObjId: {},
  }

  componentDidMount() {
    document.title = "Журнал"
    const {
      token,
      getContractsList,
      clearIsRequestState,
      toggleViewDocsModal,
      getNotifications,
      checkEarnedMoneyAmount,
      getRenewalPoliciesCount,
    } = this.props;
    if (token) {
      getNotifications()
      checkEarnedMoneyAmount()
      getRenewalPoliciesCount()
      getContractsList(
        moment(this.state.startDate).format(frontDateFormat),
        moment(this.state.endDate).format(frontDateFormat)
      )
    }
    clearIsRequestState()
    toggleViewDocsModal(false)
  }

  handleChange = (pagination, filters, sorter: { currentDataSource: [] }) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    })
  }

  clearFilters = () => {
    this.setState({
      filteredInfo: null,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
    });
    this.props.getContractsList(
      moment().startOf("day").format(frontDateFormat),
      moment().endOf("day").format(frontDateFormat)
    )
  };

  clearAll = () => {
    this.setState({
      filteredInfo: {},
      sortedInfo: {},
      searchText: "",
      searchedColumn: "",
      index: this.state.index + 1,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
    })
    this.props.getContractsList(
      moment().startOf("day").format(frontDateFormat),
      moment().endOf("day").format(frontDateFormat)
    )
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({searchText: ""})
  }

  render() {
    const {
      history,
      isRequest,
      getExportDocument,
      contractsList,
      getContractsList,
      resumeDraft,
      resumeDraftMortgage,
      resumeDraftCross,
      resumeAccidentsSport,
      copyPolicyAccidentsSport,
      copyPolicyMortgage,
      copyPolicyCross,
      payPolicyAccidentsSport,
      resumePolicy,
      resumePolicyProlongation,
      resumeDraftProlongation,
      resumePolicyMortgage,
      resumePolicyCross,
      toggleViewDocsModal,
      copyPolicy,
      getPolicyDocs,
      checkPaymentStatusCommon,
      userInfo,
      contractsCount,
      renewalPoliciesCount,
      resumeDraftKKM,
      copyPolicyKKM,
      resumePolicyKKM,
      contractsFilters,
    } = this.props;

    let {sortedInfo, filteredInfo, pageNum, pageSize} = this.state

    const contractsColumns = [
      generateIndexColumn("index", "№", pageNum, pageSize),
      generateFilteredColumn(contractsFilters, filteredInfo, "policy_type", "Признак"),
      generateFilteredColumn(contractsFilters, filteredInfo, "insurance_name", "Компания"),
      generateFilteredColumn(contractsFilters, filteredInfo, "product_name", "Вид страхования"),
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "policy_number", "№ договора"),
      generateEllipsisColumn("policy_id", "№ расчёта"),
      generateDateSortedColumn(sortedInfo, "updated", "Дата создания"),
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "insurer_name", "Страхователь", true),
      generateSortedColumn(sortedInfo, "premium_sum", "Премия"),
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "receipt_num", "Квитанция"),
      generateFilteredColumn(contractsFilters, filteredInfo, "car_category", "Кат. ТС"),
      ...["BROKER_CLASSIC", "BROKER_ONLINE", "BRANCH_DIRECTOR",].includes(userInfo.user_type) ? [
        generateFilteredColumn(contractsFilters, filteredInfo, "car_brand_name", "Марка ТС"),
        generateFilteredColumn(contractsFilters, filteredInfo, "car_model_name", "Модель ТС"),
        generateFilteredColumn(contractsFilters, filteredInfo, "car_year_issue", "Год выпуска"),
        generateFilteredColumn(contractsFilters, filteredInfo, "common_kbm", "КБМ"),
        generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "region", "Территория"),
      ] : [],
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "seller_full_name", "Агент", true),
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "manager", "Менеджер", true),
      generateFilteredColumn(contractsFilters, filteredInfo, "policy_status", "Статус полиса"),
      generateFilteredColumn(contractsFilters, filteredInfo, "payment_type", "Вид оплаты"),
      generateFilteredColumn(contractsFilters, filteredInfo, "payment_status", "Статус оплаты"),
    ]

    const applicationKeys = ["calc_request", "casco_calc_request", "mortgage_calc_request", "addendum_calc_request"]
    const selectedObjId = this.state.selectedObjId
    return (
      <>
        <Content className="content-wrapper">
          <div className="hyd-contracts-container">

            <div className="hyd-contracts-title">
              <span className="hyd-very-big-bold-text-small">
                Журнал договоров
                <span className="hyd-contracts-count">
                  {contractsCount}
                </span>
              </span>
            </div>

            <div className="hyd-date-picker-block">
              <RangePicker
                className="hyd-range-picker"
                picker="date"
                format="DD.MM.YYYY"
                allowClear={false}
                suffixIcon={<></>}
                separator={<></>}
                placeholder={["С", "По"]}
                value={[this.state.startDate, this.state.endDate]}
                onChange={
                  async dates => {
                    if (dates) {
                      let start = dates[0].startOf("date")
                      let end = dates[1].endOf("date")
                      this.setState({
                        startDate: start,
                        endDate: end
                      }, () => getContractsList(
                        moment(this.state.startDate).format(frontDateFormat),
                        moment(this.state.endDate).format(frontDateFormat)
                      ))
                    }
                  }
                }
              />

              {userInfo["can_export_excel"] && <Button
                className="hyd-doc-btn hyd-download-btn"
                target="_blank"
                style={{marginRight: "15px"}}
                onClick={() => {
                  getExportDocument(
                    moment(this.state.startDate).format(frontDateFormat),
                    moment(this.state.endDate).format(frontDateFormat)
                  )
                }}
              >
                Выгрузить полисы в .xlsx
                <span className="hyd-doc-ico">
                  <DocumentIcon/>
                </span>
              </Button>}

              <Button
                className="hyd-medium-btn"
                target="_blank"
                style={{width: 157}}
                disabled={!renewalPoliciesCount}
                onClick={() => {
                  history.replace(keys.RENEWAL)
                }}
              >
                <span>Продление</span>
                <span className="renewal-count">
                  {renewalPoliciesCount}
                </span>
              </Button>

            </div>

            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              disabled={!selectedObjId["obj_type"] || applicationKeys.includes(selectedObjId["obj_type"])}
              style={{width: 128}}
              onClick={() => {
                if ([BID, DENIAL].includes(selectedObjId["policy_status"])) {
                  if (selectedObjId["draft_type"] === "services") {
                    resumeDraftProlongation(selectedObjId.draft_id)
                  } else if (selectedObjId["obj_type"] === "draft") {
                    resumeDraft(selectedObjId.draft_id)
                  } else if (selectedObjId["obj_type"] === "accidents") {
                    resumeAccidentsSport(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === MORTGAGE) {
                    resumeDraftMortgage(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === CROSS) {
                    resumeDraftCross(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kkm") {
                    resumeDraftKKM(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kasko_go") {
                    notification['info']({
                      message: `Оформление полиса КАСКОGO невозможно`
                    })
                  } else if (selectedObjId["obj_type"] === "alfa_cross") {
                    notification['info']({
                      message: `Оформление полиса КАСКО Альфа КРОСС невозможно`
                    })
                  }
                } else {
                  notification["info"]({
                    message: `Необходимо выбрать ${BID} или ${DENIAL}`
                  })
                }
              }}
            >
              <span>Оформить</span>
            </Button>

            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              style={{width: 128}}
              onClick={() => {
                getPolicyDocs({
                  policy_id: selectedObjId["policy_id"],
                  insurance_name: selectedObjId["insurance_name"],
                  policy_number: selectedObjId["policy_number"],
                  obj_type: selectedObjId["obj_type"],
                  policy_obj_id: selectedObjId["policy_obj_id"],
                })

                toggleViewDocsModal(true)
              }}
            >
              <span>Просмотр</span>
            </Button>

            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              disabled={!selectedObjId["obj_type"] || applicationKeys.includes(selectedObjId["obj_type"])}
              onClick={() => {
                if (userInfo["is_superuser"] || [ORDERED, DRAFT].includes(selectedObjId["policy_status"])) {
                  if (selectedObjId["draft_type"] === "services") {
                    notification['info']({
                      message: `Копирование пролонгации невозможно`
                    })
                  } else if (selectedObjId["obj_type"] === "accidents") {
                    copyPolicyAccidentsSport(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === MORTGAGE) {
                    copyPolicyMortgage(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === CROSS) {
                    copyPolicyCross(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kkm") {
                    copyPolicyKKM(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kasko_go") {
                    notification['info']({
                      message: `Копирование полиса КАСКОGO невозможно`
                    })
                  } else if (selectedObjId["obj_type"] === "alfa_cross") {
                    notification['info']({
                      message: `Копирование полиса КАСКО Альфа КРОСС невозможно`
                    })
                  } else {
                    copyPolicy(selectedObjId.draft_id)
                  }
                } else {
                  notification['info']({
                    message: `Копировать полис можно только в статусе ${ORDERED} или ${DRAFT}`
                  })
                }
              }}
            >
              Скопировать
            </Button>

            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              disabled={!selectedObjId["obj_type"] || applicationKeys.includes(selectedObjId["obj_type"])}
              onClick={() => {
                if ([ORDERED, DRAFT].includes(selectedObjId["policy_status"])) {
                  checkPaymentStatusCommon({
                    obj_id: selectedObjId["policy_obj_id"],
                    obj_type: selectedObjId["obj_type"],
                  })
                  toggleViewDocsModal(true)
                } else {
                  notification['info']({
                    message: `Получить ПФ можно только в статусе ${ORDERED} или ${DRAFT}`
                  })
                }
              }}
            >
              <span>Получить ПФ</span>
            </Button>

            <Button
              className="hyd-medium-btn"
              target="_blank"
              disabled={!selectedObjId["obj_type"] || applicationKeys.includes(selectedObjId["obj_type"])}
              onClick={() => {
                if (selectedObjId["policy_status"] === DRAFT) {
                  if (selectedObjId["draft_type"] === "services") {
                    resumePolicyProlongation(selectedObjId["policy_obj_id"])
                  } else if (selectedObjId["obj_type"] === "accidents") {
                    payPolicyAccidentsSport(selectedObjId["policy_obj_id"])
                  } else if (selectedObjId["obj_type"] === MORTGAGE) {
                    resumePolicyMortgage(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === CROSS) {
                    resumePolicyCross(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kkm") {
                    resumePolicyKKM(selectedObjId.policy_obj_id)
                  } else if (selectedObjId["obj_type"] === "kasko_go") {
                    notification['info']({
                      message: `Оплата полиса КАСКОGO невозможна`
                    })
                  } else if (selectedObjId["obj_type"] === "alfa_cross") {
                    notification['info']({
                      message: `Оплата полиса КАСКО Альфа КРОСС невозможна`
                    })
                  } else {
                    resumePolicy(selectedObjId["policy_obj_id"])
                  }
                } else {
                  notification['info']({
                    message: `Оплатить полис можно только в статусе ${DRAFT}`
                  })
                }
              }}
            >
              Оплатить
            </Button>

            <Button
              className="hyd-link-btn hyd-reset-filters"
              onClick={() => this.clearAll()}
            >
              Сбросить фильтры
            </Button>

            <div className="hyd-contracts-table">
              <Table
                key={this.state.index}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      getPolicyDocs({
                        policy_id: record["policy_id"],
                        insurance_name: record["insurance_name"],
                        policy_number: record["policy_number"],
                        obj_type: record["obj_type"],
                        policy_obj_id: record["policy_obj_id"],
                      })

                      toggleViewDocsModal(true)
                    }
                  }
                }}
                size={"small"}
                columns={contractsColumns}
                dataSource={isRequest ? [] : contractsList}
                locale={{
                  emptyText: isRequest ?
                    <div><Spin/><Skeleton active={true}/></div> :
                    <Empty/>
                }}
                onChange={this.handleChange}
                rowSelection={rowSelection(obj => this.setState({selectedObjId:obj}))}
                rowClassName={"hyd-table-content"}
                pagination={{
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                  size: "large",
                }}
                scroll={{x: 'max-content'}}
              />
            </div>

          </div>

        </Content>
        <ModalViewDocs/>
        <ModalError type={9}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  contractsList: contractsListSelector(state),
  userInfo: userInfoSelector(state),
  contractsCount: contractsCountSelector(state),
  renewalPoliciesCount: renewalPoliciesCountSelector(state),
  contractsFilters: contractsFiltersSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resumeDraft,
      resumeDraftMortgage,
      resumeDraftCross,
      resumePolicy,
      resumePolicyProlongation,
      resumeDraftProlongation,
      resumePolicyMortgage,
      resumePolicyCross,
      copyPolicy,
      getExportDocument,
      clearIsRequestState,
      getContractsList,
      toggleViewDocsModal,
      getPolicyDocs,
      checkPaymentStatusCommon,
      resumeAccidentsSport,
      copyPolicyAccidentsSport,
      copyPolicyMortgage,
      copyPolicyCross,
      payPolicyAccidentsSport,
      getNotifications,
      checkEarnedMoneyAmount,
      getRenewalPoliciesCount,
      resumeDraftKKM,
      copyPolicyKKM,
      resumePolicyKKM,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);

