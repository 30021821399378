import React from "react";
import {Row} from "antd";
import {ReactComponent as GreyCircleIcon} from "../../../../assets/Icons/GreyCircle.svg";
import {eOSAGO, BSO} from "../../../../constants";

export default class ProlongationIssueDisabled extends React.Component {
  render() {
    const {value, userInfo} = this.props

    return (
      <>
        <div className="step-title-disabled">
          <span>Оформление полиса ОСАГО</span>
        </div>
        <Row>
          <div className={"hyd-osago-insurance-name-block hyd-prev-step"}>
            <GreyCircleIcon/>
            <div className={"hyd-osago-insurance-name"}>
              <span>{value.name} </span>
              <span>{value.type === BSO ? "ОСАГО" : value.type === eOSAGO ? "Е-ОСАГО" : ""}</span>
            </div>
          </div>
          <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
            <div className={"hyd-osago-ins-result-value"}>{Number(value.price).toLocaleString("ru-RU")} ₽</div>
          </div>
          {userInfo.show_commission && value.agent_commission && <div className={"hyd-osago-ins-result-item hyd-prev-step"}>
            <div className={"hyd-osago-ins-result-head"}>Комиссия агента</div>
            <div className={"hyd-osago-ins-result-value"}>
              {(value.agent_commission.identifier || !isNaN(value.agent_commission.value)) &&
                (Number(value.agent_commission.value) * Number(value.price) / 100.0).toLocaleString(
                  "ru-RU", {style: "currency", currency: "RUB"})}
              {" "}{value.agent_commission.value} {(value.agent_commission.identifier ||
              !isNaN(value.agent_commission.value)) && "%"}
            </div>
          </div>}
        </Row>
      </>
    )
  }
}