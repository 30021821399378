import React from "react";
import {Button, Collapse, Tooltip, Divider, Checkbox, Row} from "antd";
import {ReactComponent as ErrorMarkIcon} from "../../../assets/Icons/ErrorMark.svg";
import {ReactComponent as ExclamationSmallGreenIcon} from "../../../assets/Icons/ExclamationSmallGreen.svg";
import {ReactComponent as DropdownIcon} from "../../../assets/Icons/Dropdown.svg";
import {ReactComponent as DropdownUpIcon} from "../../../assets/Icons/DropdownUp.svg";
import {ReactComponent as DropdownBlueIcon} from "../../../assets/Icons/CustomCollapse/DropdownBlue.svg";
import {ReactComponent as DropdownBlueUpIcon} from "../../../assets/Icons/CustomCollapse/DropdownBlueUp.svg";
import {ReactComponent as AbsolutLogo} from "../../../assets/Icons/logos/AbsolutLogo.svg";
import {ReactComponent as AlphaLogo} from "../../../assets/Icons/logos/AlphaLogo.svg";
import {ReactComponent as IngosLogo} from "../../../assets/Icons/logos/IngosLogo.svg";
import {ReactComponent as MaksLogo} from "../../../assets/Icons/logos/MaksLogo.svg";
import {ReactComponent as ReninsLogo} from "../../../assets/Icons/logos/ReninsLogo.svg";
import {ReactComponent as RgsIcon} from "../../../assets/Icons/logos/RgsLogo.svg";
import {ReactComponent as VskLogo} from "../../../assets/Icons/logos/VskLogoNew.svg";
import {ReactComponent as GeliosLogo} from "../../../assets/Icons/logos/GeliosLogo.svg";
import {ReactComponent as UgskLogo} from "../../../assets/Icons/logos/UgskLogo.svg";
import {ReactComponent as BorovitskoeLogo} from "../../../assets/Icons/logos/BorovitskoeLogo.svg";
import {declOfNum, numToRubbles} from "../../../utils"
import {BSO, eBSO, eOSAGO, ACCIDENT, MORTGAGE, CROSS, ALFA, ALFA_REGIONS} from "../../../constants"

const {Panel} = Collapse;

const insLogos = {
  absolut: <AbsolutLogo/>,
  alfastrah: <AlphaLogo/>,
  alfa_regions: <AlphaLogo/>,
  ingostrah: <IngosLogo/>,
  makc: <MaksLogo/>,
  renesans: <ReninsLogo/>,
  rosgosstrah: <RgsIcon/>,
  vsk: <VskLogo/>,
  gelios: <GeliosLogo/>,
  ugsk: <UgskLogo/>,
  bsoinsur: <BorovitskoeLogo/>,
}

const productName = [
  {type: BSO, name: "ОСАГО"},
  {type: eBSO, name: "Е-БСО"},
  {type: eOSAGO, name: "Е-ОСАГО"},
  {type: ACCIDENT, name: "НС"},
  {type: MORTGAGE, name: "Ипотека"},
  {type: CROSS, name: "КРОСС"},
]

const reinsurance_levels = {
  "ingos level 10": "-10",
  "ingos level 9": "-9",
  "ingos level 8": "-8",
  "ingos level 7": "-7",
  "ingos level 6": "-6",
  "pool": "",
}

export default class CustomCollapse extends React.Component {
  state = {
    isActive: false,
  }

  render() {

    const verticalDivider = () => {
      return (
        <Divider
          type={"vertical"}
          style={{margin: "0 8px 0 8px", height: 40, background: "var(--color-two)"}}
        />
      )
    }

    const horizontalDivider = (width, margin) => {
      return (
        <span
          style={{
            margin: 0,
            display: "flex",
            padding: "10px 0",
          }}
        >
          <Divider
            type={"horizontal"}
            style={{
              width: width,
              margin: margin,
              background: "var(--color-two)",
            }}
          />
        </span>
      )
    }

    const agentCommissionTitle = () => {
      return (
        <div className={"hyd-osago-ins-result-head"}>Комиссия</div>
      )
    }

    const additionalProductsSpan = () => {
      return (
        <span className={"cross-dropdown-txt"}>
          Доп. продукты
        </span>
      )
    }

    const crossProductsDropdown = () => {
      return (
        <>
          {
            this.state.isActive ?
              <>
                {additionalProductsSpan()}
                <DropdownBlueUpIcon className={"cross-dropdown-icon"}/>
              </> :
              <>
                {additionalProductsSpan()}
                <DropdownBlueIcon className={"cross-dropdown-icon"}/>
              </>
          }
        </>
      )
    }

    const showCost = (price) => {
      return (
        <div>
          <div className={"hyd-osago-ins-result-head"}>Стоимость</div>
          <div className={"hyd-osago-ins-result-value"}>
            {numToRubbles(price)}
          </div>
        </div>
      )
    }

    const showCommission = (price, agentCommission) => {
      return (
        <div>
          {agentCommissionTitle()}
          <div className={"hyd-osago-ins-result-value hyd-osago-ins-result-commission"}>
            {
              (agentCommission.identifier || !isNaN(agentCommission.value)) &&
              numToRubbles((Number(agentCommission.value) * Number(price) / 100.0))
            }
            <span className={"hyd-osago-ins-result-value hyd-osago-ins-result-commission-percent"}>
              {" / "}{agentCommission.value} {(agentCommission.identifier ||
              !isNaN(agentCommission.value)) && "% "}
            </span>
            {agentCommission.cross &&
              <div className={"hyd-osago-cross"}>
                <span>+ Кросс</span>
              </div>}
          </div>
        </div>
      )
    }

    const crossProductsAlfa = (crossProducts, addCrossProductToList, selectedCrossProducts, userInfo) => {
      return (
        <>
          {horizontalDivider(920, "0 -16px 0 -16px")}
          <Checkbox.Group
            onChange={(checkedValues) => {
              addCrossProductToList(checkedValues)
            }}
            value={selectedCrossProducts}
            style={{
              display: "initial !important",
            }}
          >
            {crossProducts.map((value, index) => (
              <>
                <Row
                  key={index}
                  align="middle"
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <div style={{
                    alignItems: "center",
                    display: "flex",
                    flex: .8,
                  }}>
                    <Checkbox
                      className="hyd-checkbox-osago"
                      style={{marginRight: 8}}
                      value={value.marketingProductName}
                    />
                    <span>{value.marketingProductName}</span>
                  </div>

                  <div style={{flex: .1}}>
                    {showCost(value.totalPremium)}
                  </div>

                  {value.agent_commission && userInfo.show_commission &&
                    <>
                      {verticalDivider()}
                      <div style={{flex: .21}}>
                        {showCommission(value.totalPremium, value.agent_commission)}
                      </div>
                    </>
                  }

                  <div style={{marginLeft: 200}}></div>
                </Row>
                {crossProducts.length - 1 !== index && horizontalDivider(816, "0 0 0 8px")}
              </>
            ))}
          </Checkbox.Group>
        </>
      )
    }

    const isAlfaEOSAGOCross = (value) => {
      return !value.errors && value.cross_products && [ALFA, ALFA_REGIONS].includes(value.key) && value.type === eOSAGO
    }

    const {
      value,
      index,
      setDataForm,
      toggleOsagoModal,
      userInfo,
      handleSubmit,
      smallUI,
      insuranceRules,
      getFieldDecorator,
      getFieldValue,
      addCrossProductToList,
      selectedCrossProducts,
    } = this.props

    return (
      <div
        className={`hyd-osago-insurance-panel ${isAlfaEOSAGOCross(value) && "hyd-panel-cross"}`}
        key={value.key + index}
      >
        <Collapse
          onChange={(e) => {
            if (e.length > 0) {
              this.setState({isActive: true})
            } else {
              this.setState({isActive: false})
            }
          }}
          key={index}
        >
          <Panel
            disabled={!!value.errors}
            key={index}
            showArrow={false}
            header={
              <>
                <div className={"hyd-osago-panel-header"}>
                  <div className={"hyd-osago-insurance-name-block"}>
                    <span className={"hyd-osago-insurance-logo"}>
                      {insLogos[value.key]}
                    </span>
                    <div className={"hyd-osago-insurance-name"}>
                      <span>{value.name} </span>
                      <span>{productName.find(e => value.type === e.type)?.name}</span>
                    </div>
                  </div>
                  {value.errors &&
                    <Tooltip
                      title={value.errors.error}
                      placement={"bottom"}
                      overlayClassName={"hyd-tooltip"}>
                      <div className={"hyd-osago-error"}>
                        <ErrorMarkIcon/>
                        <span>Ошибка</span>
                      </div>
                    </Tooltip>}
                  {!value.errors && value.reinsurance_pool &&
                    <div className={"hyd-osago-error"}>
                      <ErrorMarkIcon/>
                      <span>ПУЛ{value.reinsurance_level ? `${reinsurance_levels[value.reinsurance_level]}` : ""}</span>
                    </div>
                  }
                  {!value.errors && !value.reinsurance_pool &&
                    value.reinsurance_level && value.reinsurance_level !== "not pool" &&
                    value.reinsurance_level in reinsurance_levels &&
                    <Tooltip
                      title={reinsurance_levels[value.reinsurance_level]}
                      placement={"bottom"}
                      overlayClassName={"hyd-tooltip"}>
                      <div className={"hyd-osago-error hyd-osago-plus-days"}>
                        <ExclamationSmallGreenIcon/>
                        <span>{reinsurance_levels[value.reinsurance_level]}</span>
                      </div>
                    </Tooltip>}
                  {!value.errors && value.extra && value.extra.days != null &&
                    <Tooltip
                      title={`к дате начала действия полиса добавлено ${value.extra.days} ${declOfNum(value.extra.days, ['день', 'дня', 'дней'])}`}
                      placement={"bottom"}
                      overlayClassName={"hyd-tooltip"}>
                      <div className={"hyd-osago-error hyd-osago-plus-days"}>
                        <ExclamationSmallGreenIcon/>
                        <span>{`+ ${value.extra.days} ${declOfNum(value.extra.days, ['день', 'дня', 'дней'])}`}</span>
                      </div>
                    </Tooltip>}
                  {!value.errors && value.extra && value.extra.year === true && <Tooltip
                    title={`1 год`}
                    placement={"bottom"}
                    overlayClassName={"hyd-tooltip"}>
                    <div className={"hyd-osago-error hyd-osago-plus-days hyd-osago-year"}>
                      <ExclamationSmallGreenIcon/>
                      <span>{`1 год`}</span>
                    </div>
                  </Tooltip>
                  }
                  {!value.errors &&
                    <div className={"hyd-osago-ins-result"}>
                      {showCost(value.price)}
                      {value.agent_commission && value.agent_commission.forbidden ?
                        <>
                          {verticalDivider()}
                          <div>
                            {agentCommissionTitle()}
                            <div className={"hyd-osago-forbidden"}>
                              <span>Запрет</span>
                            </div>
                          </div>
                        </> :
                        value.agent_commission && userInfo.show_commission &&
                        <>
                          {verticalDivider()}
                          {showCommission(value.price, value.agent_commission)}
                        </>
                      }
                      <div className={"hyd-osago-ins-result-item hyd-osago-dropdown-block"}
                           style={{marginLeft: 32}}>
                        {this.state.isActive ? <DropdownUpIcon/> : <DropdownIcon/>}
                      </div>
                      <div className={"hyd-osago-ins-result-item"}>
                        <Button
                          className={smallUI ? "hyd-btn-small hyd-osago-create-bt" : "hyd-medium-btn hyd-osago-create-btn"}
                          onClick={(e) => {
                            e.stopPropagation()
                            let policy_type = undefined
                            if (value.type === BSO) {
                              policy_type = "_1"
                            } else if (value.type === eOSAGO) {
                              policy_type = "_2"
                            } else if (value.type === eBSO) {
                              policy_type = "_3"
                            }
                            setDataForm({type_osago: {value: String(value.key) + policy_type}});
                            if (value.type === BSO) toggleOsagoModal(true, value);
                            else handleSubmit(value);
                          }}
                          disabled={
                            (value.type === eOSAGO && !userInfo.can_create_eosago_policy) ||
                            (value.type === eBSO && !userInfo.can_create_ebso_policy) ||
                            (value.type === BSO && !userInfo.can_create_bso_policy) ||
                            (!productName.map(product => product.type).includes(value.type)) ||
                            (value.agent_commission && value.agent_commission.forbidden)
                          }
                          type="primary"
                        >
                          Оформить
                        </Button>
                      </div>
                    </div>
                  }
                  {!value.errors && insuranceRules &&
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 40,
                      }}
                    >
                      <Row>
                        {getFieldDecorator("insurance_rules", {initialValue: true})(
                          <Checkbox
                            checked={getFieldValue("insurance_rules")}
                            className={"hyd-norm-text mortgage-checkbox-disabled"}
                            disabled
                          >
                            <a
                              onClick={event => {
                                event.stopPropagation();
                              }}
                              href={insuranceRules}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              С правилами страхования ознакомлен, согласен
                            </a>
                          </Checkbox>
                        )}
                      </Row>
                    </div>
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    left: 44,
                    bottom: 1,
                  }}
                >
                  {
                    isAlfaEOSAGOCross(value) && crossProductsDropdown()
                  }
                </div>
              </>
            }>
            <div className={"hyd-osago-results-block"}>
              {value?.calc_info &&
                Array.isArray(value.calc_info) &&
                value.calc_info.map((value, index) => (
                  <div key={index}>
                    <div className="bold-text">{value.key}</div>
                    <div>{Number(value.value).toString()}</div>
                  </div>
                ))}
            </div>
            <div style={{margin: "10px 0 20px 8px"}}>
              <span className={"hyd-grey-txt"}>Идентификатор расчёта: </span>
              {value?.calc_id}
            </div>
            {
              isAlfaEOSAGOCross(value) && crossProductsAlfa(
                value.cross_products,
                addCrossProductToList,
                selectedCrossProducts,
                userInfo,
              )
            }
          </Panel>
        </Collapse>
      </div>
    )
  }
}
