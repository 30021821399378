import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Preloader from "../../components/Preloader";
import "./styles/index.scss";
import {
  goToPrevStep,
  goToNextStep,
  setDataForm,
  clearIsRequestState,
  clearForm
} from "../../actions/kkmActions";
import {
  isRequestSelector,
  currentStepSelector,
} from "../../selectors/kkmSelectors";
import FirstStep from "./step-1";
import SecondStep from "./step-2";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import {tokenSelector} from "../../selectors/loginSelectors";


class KKM extends Component {
  componentDidMount() {
    document.title = "Каско Компакт Минимум"

    const {token, clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    clearIsRequestState()
    if (token) {
      getNotifications()
      checkEarnedMoneyAmount()
    }
  }

  componentWillUnmount() {
    const {
      clearForm,
    } = this.props

    clearForm(9)
  }

  renderForms = () => {
    let {currentStep} = this.props;

    switch (currentStep) {
      case 0:
        return <FirstStep/>
      case 1:
        return <SecondStep/>
      default:
        return <FirstStep/>
    }
  }

  render() {
    const {
      isRequest,
    } = this.props

    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="osago-container">
          <span className="hyd-very-big-bold-text-small osago-header">Каско Компакт Минимум</span>
          {this.renderForms()}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      setDataForm,
      clearIsRequestState,
      clearForm,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(KKM)
