import {select, takeLatest} from 'redux-saga/effects';
import * as api from './api';
import * as types from '../store/actionTypes';

export function* getToolsSaga({payload}) {
  yield api.get("tools/", payload, types.GET_TOOLS);
}

export function* getFavoritesToolsSaga({payload}) {
  yield api.get("favorites_tools/", payload, types.GET_FAVORITES_TOOLS);
}

export function* getAvailableProductsSaga({payload}) {
  yield api.get("products_availability/", payload, types.GET_AVAILABLE_PRODUCTS);
}

export function* updateFavoritesToolsSaga() {
  const favoritesTools = yield select((state) => state.mainReducer.favoritesTools)
  if (favoritesTools) {
    yield api.patch('favorites_tools/', null, favoritesTools, types.UPDATE_FAVORITES_TOOLS);
  }
}

export function* reduceFavoritesToolsSaga() {
  const favoritesTools = yield select((state) => state.mainReducer.favoritesTools)
  if (favoritesTools) {
    yield api.patch('favorites_tools/', null, favoritesTools, types.DEL_TOOL_FROM_FAVORITES);
  }
}

export function* getActiveBannersSaga({payload}) {
  yield api.get("active_banners/", payload, types.GET_ACTIVE_BANNERS);
}

export function* getNotificationsSaga({payload}) {
  yield api.get("notifications/", payload, types.GET_NOTIFICATIONS);
}

export function* updateNotificationStatusSaga({payload}) {
  yield api.patch("notification_status/", null, payload, types.UPDATE_NOTIFICATION_STATUS);
}

export function* checkEarnedMoneyAmountSaga({payload}) {
  yield api.get("earned_money/", payload, types.CHECK_EARNED_MONEY_AMOUNT);
}

export default function* () {
  yield takeLatest(types.GET_TOOLS, getToolsSaga);
  yield takeLatest(types.GET_FAVORITES_TOOLS, getFavoritesToolsSaga);
  yield takeLatest(types.UPDATE_FAVORITES_TOOLS, updateFavoritesToolsSaga);
  yield takeLatest(types.DEL_TOOL_FROM_FAVORITES, reduceFavoritesToolsSaga);
  yield takeLatest(types.GET_ACTIVE_BANNERS, getActiveBannersSaga);
  yield takeLatest(types.GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLatest(types.UPDATE_NOTIFICATION_STATUS, updateNotificationStatusSaga);
  yield takeLatest(types.CHECK_EARNED_MONEY_AMOUNT, checkEarnedMoneyAmountSaga);
  yield takeLatest(types.CREATE_PAYMENT_ACT_SUCCESS, checkEarnedMoneyAmountSaga);
  yield takeLatest(types.GET_AVAILABLE_PRODUCTS, getAvailableProductsSaga);
}
