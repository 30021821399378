import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Redirect, withRouter} from "react-router-dom";
import {Layout} from "antd";

import Preloader from "../../components/Preloader";
import {clearIsRequestState} from "../../actions/osagoActions";
import {checkAuthentication} from "../../actions/loginActions";
import {isRequestSelector} from "../../selectors/osagoSelectors";
import {tokenSelector} from "../../selectors/loginSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";
import {ReactComponent as HeadLineIcon} from "../../assets/Icons/HeadLine.svg";
import {ReactComponent as LogoHeadIcon} from "../../assets/Icons/logos/LogoHead.svg";
import {scrollToTop} from "../../utils";

import * as keys from "../../routers/keys";
import "./styles/index.scss";
import {availableProductsSelector} from "../../selectors/mainSelectors";

const {Footer} = Layout;

class FooterContacts extends Component {
  state = {
    email: "support@sevains.ru",
  }

  componentDidMount() {
    this.props.checkAuthentication()
    this.props.clearIsRequestState()
  }

  render() {
    const {
      token,
      history,
      userInfo,
      isRequest,
      availableProducts,
    } = this.props

    if (!token) {
      return <Redirect to={keys.AUTH}/>
    }

    return (
      <Footer>
        <Preloader loading={isRequest}/>
        <div className="hyd-footer-container">
          <div className="hyd-header-divider" style={{marginBottom: 30}}>
            <HeadLineIcon/>
          </div>

          <div className="div-table">

            <div className="div-table-col hyd-footer-contacts">
              <div className="div-table-row hyd-line24">
                <span
                  className="hyd-logo"
                  onClick={() => {
                    if (this.props.location.pathname === keys.HOME) {
                      scrollToTop()
                    }
                    history.replace(keys.HOME)
                  }}
                ><LogoHeadIcon/></span>
              </div>
              <br/>
              <div className="div-table-row hyd-line16">
                <a className="hyd-text-btn div-table-bold-text"
                   href={`mailto:${this.state.email}`}>{this.state.email}</a>
              </div>
              <br/>

            </div>

            <div className="div-table-col">
              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text">Страхование</span>
              </div>
              <br/>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.OSAGO)
                  }}
                >ОСАГО</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.CASCO)
                  }}
                >КАСКО</span>
              </div>
              <div className="div-table-row div-table-text div-table-text-disabled">
                <span>ИФЛ</span>
              </div>
              <div className="div-table-row div-table-text div-table-text-disabled">
                <span>НС Авто</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.ACCIDENTS_SPORT)
                  }}
                >НС</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.MORTGAGE)
                  }}
                >Ипотека</span>
              </div>

              <br/>
              <div className="div-table-row hyd-line16">
                <span className={"div-table-medium-text"}>КРОСС</span>
              </div>
              <div
                className={`div-table-row div-table-text ${availableProducts["kkm"] === undefined || availableProducts["kkm"] === false ? "div-table-text-disabled" : ""}`}>
                <span
                  onClick={() => {
                    history.replace(keys.KKM)
                  }}
                >Каско Компакт Минимум</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.CROSS)
                  }}
                >Практичное Каско</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.CROSS)
                  }}
                >Демократичное КАСКО</span>
              </div>
              <br/>
            </div>

            <div className="div-table-col">
              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text">Сервисы</span>
              </div>
              <br/>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.KBM)
                  }}
                >Проверить КБМ</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.PROLONGATION)
                  }}
                >Пролонгация ОСАГО</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.ADDENDUM)
                  }}
                >Аддендум</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.RENEWAL)
                  }}
                >Продление</span>
              </div>
              <br/>

              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text">Отчёты</span>
              </div>
              <br/>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.CONTRACTS)
                  }}
                >Журнал договоров</span>
              </div>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.PAYMENT_ACTS)
                  }}
                >Выплаты</span>
              </div>
            </div>

            <div className="div-table-col">
              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text">Профиль</span>
              </div>
              <br/>
              <div className="div-table-row div-table-text">
                <span
                  onClick={() => {
                    history.replace(keys.REFERRAL_LINK)
                  }}
                >Реферальные ссылки</span>
              </div>
              <div className={`div-table-row div-table-text ${!userInfo["has_agents"] && "div-table-text-disabled"}`}>
                <span
                  onClick={() => {
                    userInfo["has_agents"] && history.replace(keys.AGENTS)
                  }}
                >Агенты</span>
              </div>
            </div>

            <div className="div-table-col">
              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text rep div-table-text-disabled">Статистика</span>
              </div>
              <br/>
              <div className="div-table-row hyd-line16">
                <span className="div-table-bold-text rep div-table-text-disabled">Телемедицина</span>
              </div>
            </div>

          </div>
        </div>
      </Footer>
    )
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  userInfo: userInfoSelector(state),
  availableProducts: availableProductsSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearIsRequestState,
      checkAuthentication,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FooterContacts)
)
