import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";

import Preloader from "../../components/Preloader";
import {clearForm} from "../../actions/accidentsSportActions";
import {isRequestSelector} from "../../selectors/osagoSelectors";

import * as keys from "../../routers/keys";
import {signOut} from "../../actions/loginActions";

import "./styles/index.scss";
import {ReactComponent as LogoHeadIcon} from '../../assets/Icons/logos/LogoHead.svg';
import {scrollToTop} from "../../utils";
import {earnedHeader, mainDropdownMenu, showNotifications} from "../HeaderBase";
import {toggleNotificationsModal} from "../../actions/mainActions";
import {earnedBonusesSelector, earnedMoneySelector, notificationsSelector} from "../../selectors/mainSelectors";
import {userInfoSelector} from "../../selectors/loginSelectors";

class HeaderSticky extends Component {

  render() {
    const {
      isRequest,
      history,
      notifications,
      toggleNotificationsModal,
      earnedMoney,
      earnedBonuses,
      location,
      userInfo,
    } = this.props

    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="hyd-header-container-sticky">

          <div className="hyd-header-left hyd-header-left-sticky">
            <span
              className="hyd-logo hyd-logo-header-sticky"
              onClick={() => {
                if (location.pathname === keys.HOME) {
                  scrollToTop()
                }
                history.replace(keys.HOME)
              }}
            >
              <LogoHeadIcon/>
            </span>
            {mainDropdownMenu(history)}
          </div>

          <div className="hyd-header-right-sticky">
            {earnedHeader(earnedMoney, earnedBonuses, userInfo)}

            {showNotifications(notifications, toggleNotificationsModal,
              "hyd-small-num-ico-num-sticky", "hyd-small-num-ico-num-2-sticky")}
          </div>

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  notifications: notificationsSelector(state),
  earnedBonuses: earnedBonusesSelector(state),
  earnedMoney: earnedMoneySelector(state),
  userInfo: userInfoSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut,
      clearForm,
      toggleNotificationsModal,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderSticky)
)
