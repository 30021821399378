import React, {Component} from "react";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {toggleVisiblePaymentActDetailsModal} from "../../../actions/paymentActsActions";
import {
  isRequestSelector,
  paymentActDetailsSelector,
  visiblePaymentActDetailsModalSelector
} from "../../../selectors/paymentActsSelectors";
import {Modal, Spin, Table} from "antd";

class ModalPaymentActDetails extends Component {

  render() {
    const {
      isRequest,
      paymentActDetails,
      visiblePaymentActDetailsModal,
      toggleVisiblePaymentActDetailsModal
    } = this.props
    return (
      <Modal
        title={
          "Акт выплаты"
        }
        visible={visiblePaymentActDetailsModal}
        onOk={() => {
          toggleVisiblePaymentActDetailsModal(false)
        }}
        onCancel={() => {
          toggleVisiblePaymentActDetailsModal(false)
        }}
        cancelButtonProps={{style: {display: 'none'}}}
        width='1000px'
        closable={false}
      >
        {isRequest && <Spin/>}
        {paymentActDetails &&
          <>
            <Table
              style={{marginBottom: "20px"}}
              title={() => "Согласованные выплаты"}
              size={"small"}
              dataSource={paymentActDetails.payment_items}
              columns={[
                {
                  title: "Дата",
                  dataIndex: "created_date"
                },
                {
                  title: "Страховая",
                  dataIndex: "insurance_name"
                },
                {
                  title: "Продукт",
                  dataIndex: "product_name"
                },
                {
                  title: "Страхователь",
                  dataIndex: "insurer_full_name"
                },
                {
                  title: "Номер полиса",
                  dataIndex: "policy_number"
                },
                {
                  title: "Стоимость",
                  dataIndex: "premium_sum",
                  render: (text) => `${text} ₽`
                },
                {
                  title: "КВ",
                  dataIndex: "commission_amount",
                  render: (text) => `${text} ₽`
                },
              ]}
              pagination={false}
            />
            <Table
              title={() => "Выплаты с расхождениями"}
              size={"small"}
              dataSource={paymentActDetails.discrepancy_items}
              columns={[
                {
                  title: "Дата",
                  dataIndex: "created_date"
                },
                {
                  title: "Страховая",
                  dataIndex: "insurance_name"
                },
                {
                  title: "Продукт",
                  dataIndex: "product_name"
                },
                {
                  title: "Страхователь",
                  dataIndex: "insurer_full_name"
                },
                {
                  title: "Номер полиса",
                  dataIndex: "policy_number"
                },
                {
                  title: "Стоимость",
                  dataIndex: "premium_sum",
                  render: (text) => `${text} ₽`
                },
                {
                  title: "исходное КВ",
                  dataIndex: "commission_amount",
                  render: (text) => `${text} ₽`
                },
                {
                  title: "Установленное КВ",
                  dataIndex: "broker_commission_amount",
                  render: (text) => `${text} ₽`
                },

              ]}
              pagination={false}
            />
          </>
        }
      </Modal>
    )
  }
}


const mapStateToProps = state => ({
  visiblePaymentActDetailsModal: visiblePaymentActDetailsModalSelector(state),
  paymentActDetails: paymentActDetailsSelector(state),
  isRequest: isRequestSelector(state),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      toggleVisiblePaymentActDetailsModal,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(ModalPaymentActDetails);