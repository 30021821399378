import React, {Component} from "react";
import {
  Row,
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Checkbox,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cleave from "cleave.js/react";

import {
  goToNextStep,
  setDataForm,
  getInfoByVin,
  getTOInfo,
  getCarBrands,
  getCarModels,
  getModelModifications,
  showTOButton,
  toggleTOModal,
  saveCarBrandData,
  saveCarModelData,
  saveModelModificationsData,
  toggleIsSelectedBrand,
  toggleIsSelectedModel,
  toggleWithoutCarPlate,
  clearDataCarPlate,
  toggleWithoutVIN,
  clearDataVIN,
  clearMarkModel,
  clearDataDK,
  toggleTypeCarIdentity,
  clearDataBodyNumber,
  clearDataChassisNumber,
  toggleButtonModalTo,
  toggleVehicleCategory,
  changeDateInfoTO,
  populateBrandModelData,
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  dataFormSelector,
  errorsSelector,
  infoTOSelector,
  carBrandsSelector,
  carModelsSelector,
  modelModificationsSelector,
  isRequestSelector,
  buttonTOSelector,
  inputCarPlateSelector,
  inputVinSelector,
  typeCarIdentitySelector,
  visibleTOModalSelector,
  isSelectedBrandSelector,
  isSelectedModelSelector,
  visibleChoiceTOModalSelector,
  buttonModalTOSelector,
} from "../../selectors/osagoSelectors";
import ModalDiagnosticCard from "../../components/Form/ModalDiagnosticCard";
import ModalError from "../../components/ModalError";
import ModalChoiceTO from "../../components/Form/ModalChoiceTO";
import {
  vehicleCategories,
  getCategoryId,
  hasErrors,
  transform,
  isEmpty,
  checkCarYearIssue,
  carDocumentsOptions,
  checkCarDocumentsDate,
  onChangeCarDocumentSeriesNumber,
} from "../../utils";

import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as NumberInactiveIcon} from "../../assets/Icons/NumberInactive.svg";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";

const {Option} = Select;

class FirstStep extends Component {
  state = {
    loading: false,
    currentYear: null,
    getAutoInfo: false,
    getTOInfo: false,
    cleaveKey: 0,
    showTOButton: false,
    // visible: false,
    // visibleErrorModal: true,
  };

  componentDidMount() {
    const {getCarBrands, dataForm, populateBrandModelData} = this.props;

    if (dataForm.car_brand) {
      populateBrandModelData()
    } else {
      getCarBrands();
    }
    this.setState(
      {
        currentYear: new Date().getFullYear(),
        currentDate: new Date(),
      },
    );
  }

  handleSubmit = (e) => {
    const {
      currentStep,
      goToNextStep,
      isSelectedBrand,
      isSelectedModel,
    } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (!err) {
        if (isSelectedBrand && isSelectedModel) {
          goToNextStep(currentStep + 1);
        } else {
          notification["error"]({
            message: `Необходимо выбрать производство/марку/модель машины из списка`
          })
        }
      }
    })
  }

  handleYearChange = (e) => {
    const year = parseInt(e.target.value);
    const {currentYear} = this.state;

    this.props.showTOButton(year - currentYear < -3);
  }
  checkMaxWeight =  (rule, value, callback) => {
    if (Number(value) <= 3500) {
      callback(" ")
    }
    callback()
  }

  render() {
    const {
      getInfoByVin,
      getTOInfo,
      carBrands,
      carModels,
      modelModifications,
      getCarModels,
      getModelModifications,
      dataForm,
      saveCarBrandData,
      saveCarModelData,
      saveModelModificationsData,
      toggleIsSelectedBrand,
      toggleIsSelectedModel,
      toggleWithoutCarPlate,
      clearDataCarPlate,
      toggleWithoutVIN,
      toggleVehicleCategory,
      clearDataVIN,
      clearMarkModel,
      clearDataDK,
      clearDataBodyNumber,
      clearDataChassisNumber,
      withoutCarPlate,
      withoutVIN,
      form,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = form

    const carPlate = "car_plate";
    const carPlateError = getFieldError(carPlate);

    const vin = "vin";
    const vinError = getFieldError(vin);

    const numberDiagnosticCard = "car_to_number";
    const numberDiagnosticCardError = getFieldError(numberDiagnosticCard);

    const dateDiagnosticCard = "car_dk_date";
    const dateDiagnosticCardError = getFieldError(dateDiagnosticCard);

    const carIdentity = "car_identity";
    const carIdentityError = getFieldError(carIdentity);

    const bodyNumber = "body_number";
    const bodyNumberError = getFieldError(bodyNumber);

    const chassisNumber = "chassis_number";
    const chassisNumberError = getFieldError(chassisNumber);

    const vehicleType = "vehicle_type";
    const vehicleTypeError = getFieldError(vehicleType);

    const brandCar = "brand_car";
    const brandCarError = getFieldError(brandCar);

    const isForeignVehicle = "is_foreign_vehicle"

    const modelCar = "model_car";
    const modelCarError = getFieldError(modelCar);

    const modelModification = "modification_car"
    const modelModificationError = getFieldError(modelModification);

    const carYearIssue = "car_year_issue";
    const carYearIssueError = getFieldError(carYearIssue);

    const enginePower = "engine_power";
    const enginePowerError = getFieldError(enginePower);

    const unladenMass = "unladen_mass";
    const unladenMassError = getFieldError(unladenMass);

    const resolutionMaxWeight = "resolution_max_weight";
    const resolutionMaxWeightError = getFieldError(resolutionMaxWeight);

    const numberOfSeats = "number_of_seats";
    const numberOfSeatsError = getFieldError(numberOfSeats);

    const documents = "documents";
    const documentsError = getFieldError(documents);

    const carDocumentSerial = "car_document_serial";
    const carDocumentSerialError = getFieldError(carDocumentSerial);

    const carDocumentNumber = "car_document_number";
    const carDocumentNumberError = getFieldError(carDocumentNumber);

    const carDocumentDate = "car_document_date";
    const carDocumentDateError = getFieldError(carDocumentDate);

    const carDocumentSeriesNumber = "car_document_series_number"
    const carDocumentSeriesNumberError = getFieldError(carDocumentSeriesNumber);

    const checkBoxCarPlate = (e) => {
      if (getFieldValue("documents") === 2 && e.target.checked) {
        setFieldsValue({
          documents: 1,
          car_document_serial: '',
          car_document_number: '',
          car_document_series_number: '',
          car_document_date: '',
        })
      }

      toggleWithoutCarPlate(e.target.checked);
      if (e.target.checked) {
        // если авто без номера и установлен соответствующий флаг, удаляем данные о введённом номере,
        // + убираем из формы текстовое поле для ввода номер и кнопка "Проверить" по номеру,
        // + указать можно лишь документ «Паспорт транспортного средства» или эПТС(?),
        // + чистим в форме гос.номер, тип документа, его серию, номер и дату
        clearDataCarPlate();
      }
    }

    const getCarPlate = () => {
      return (
        <>
          <Form.Item
            validateStatus={carPlateError ? "error" : ""}
            help={carPlateError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-4-input hyd-osago-low-margin"
              htmlFor={"step-1_" + carPlate}
              labelText={"Гос. номер авто"}
              divId={"float-label-small"}
              child={getFieldDecorator(carPlate, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[A-Za-zА-Яа-яЁё0-9\s]{7,9}$/g,
                    message: " "
                  },
                ],
              })(
                <Input
                  className="hyd-input-small hyd-osago-4-input"
                  maxLength={9}
                />
              )
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="hyd-btn-small hyd-osago-btn"
              type="primary"
              disabled={carPlateError || !getFieldValue(carPlate) || getFieldValue(isForeignVehicle)}
              onClick={() => {
                getInfoByVin({car_plate: getFieldValue(carPlate)})
                clearDataDK()
                clearMarkModel()
                this.setState({getAutoInfo: true})
                setFieldsValue({
                  car_dk_date: '',
                })
              }}
            >
              Проверить
            </Button>
          </Form.Item>
        </>
      )
    }

    const checkBoxVIN = (e) => {
      toggleWithoutVIN(e.target.checked);
      if (e.target.checked) {
        // если авто без VIN и установлен соответствующий флаг, удаляем данные о введённом VIN,
        // + убираем из формы текстовое поле для ввода номер и кнопка "Проверить" по номеру,
        // + появляются два поля (радио) -- "Кузов" и "Шасси"
        // + чистим в форме VIN
        clearDataVIN();
        clearDataDK();
        setFieldsValue({
          car_dk_date: '',
        })
      } else { // чистим в форме "Кузов" и "Шасси"
        clearDataBodyNumber();
        clearDataChassisNumber();
      }
    }

    const getVIN = () => {
      return (
        <>
          <Form.Item
            validateStatus={vinError ? "error" : ""}
            help={vinError || ""}
          >
            <FloatingLabelInput
              divClassName={"hyd-osago-12-input  hyd-osago-low-margin"}
              htmlFor={"step-1_" + vin}
              labelText={"VIN"}
              divId={"float-label-small"}
              child={getFieldDecorator(vin, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: true,
                    message: " "
                  },
                  {
                    pattern: /^[A-Za-z0-9]{13}[0-9]{4}$/g,
                    message: " "
                  },
                ],
              })(
                <Input
                  className={"hyd-input-small hyd-osago-12-input"}
                  maxLength={17}/>
              )}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="hyd-btn-small hyd-osago-btn-nm"
              type="primary"
              disabled={vinError || !getFieldValue(vin)}
              onClick={() => {
                getInfoByVin({vin: getFieldValue(vin)})
                this.setState({getAutoInfo: true})
                clearDataDK()
                clearMarkModel()
                setFieldsValue({
                  car_dk_date: '',
                })
              }}
            >
              Проверить
            </Button>
          </Form.Item>
        </>
      )
    }


    const getIdentity = () => {
      return (
        <>
          <Form.Item
            validateStatus={bodyNumberError ? "error" : ""}
            help={bodyNumberError || ""}
          >
            <FloatingLabelInput
              divClassName={"hyd-osago-8-input "}
              htmlFor={"step-1_" + bodyNumber}
              labelText={"Номер кузова"}
              divId={"float-label-small"}
              child={getFieldDecorator(bodyNumber, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [{
                  required: !getFieldValue(chassisNumber),
                  message: " "
                }],
              })(
                <Input
                  className={
                    "hyd-input-small hyd-osago-8-input "
                  }
                  maxLength={17}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            validateStatus={chassisNumberError ? "error" : ""}
            help={chassisNumberError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-1_" + chassisNumber}
              labelText={"Номер шасси"}
              divId={"float-label-small"}
              child={getFieldDecorator(chassisNumber, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [{
                  required: !getFieldValue(bodyNumber),
                  message: " "
                }],
              })(
                <Input
                  className="hyd-input-small hyd-osago-8-input"
                  maxLength={17}
                />
              )}
            />
          </Form.Item>
        </>
      )
    }

    const filterModelsByCategory = (models) => {
      let category = vehicleCategories.find((item) => item["value"] === getFieldValue(vehicleType))?.category
      return models["class_of_vehicle"] === category
    }

    const filterModifications = (modifs, year, power) => {
      if (year === undefined) {
        year = getFieldValue(carYearIssue)
      }
      if (power === undefined) {
        power = getFieldValue(enginePower)
      }
      year = Number(year)
      power = Number(power)
      if (year && power)
        return (
          modifs.year_from <= year &&
          modifs.year_to >= year &&
          modifs.engine_power <= power + 2 &&
          modifs.engine_power >= power - 2
        )
      if (year)
        return (
          modifs.year_from <= year &&
          modifs.year_to >= year
        )
      if (power)
        return (
          modifs.engine_power <= power + 2 &&
          modifs.engine_power >= power - 2
        )
      return modifs
    }

    const isModifInFilter = (modifs) => {
      return modifs.value === Number(dataForm.modification_car.value)
    }

    return (
      <>

        <div className="step-title-active">
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>

          <span className="step-title-active-header">Транспортное средство</span>
        </div>
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          id="osago-step-1"
        >
          {/* Гос. номер авто */}
          <Row>
            <Form.Item>
              <Checkbox className="hyd-checkbox-osago" onChange={checkBoxCarPlate} checked={withoutCarPlate}>
                <span className="hyd-checkbox-label-osago">Без гос. номера</span>
              </Checkbox>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item>
              <Checkbox className="hyd-checkbox-osago" onChange={checkBoxVIN} checked={withoutVIN}>
                <span className="hyd-checkbox-label-osago">VIN отсутствует</span>
              </Checkbox>
            </Form.Item>
          </Row>
          <Row
            style={{marginBottom: 40}}
          >
            <Form.Item>
              {getFieldDecorator(isForeignVehicle, {
                valuePropName: "checked",
                initialValue: getFieldValue(isForeignVehicle),
              })(
                <Checkbox className="hyd-checkbox-osago"
                          onChange={async () => {
                            await this.setState({cleaveKey: ++this.state.cleaveKey});
                            setFieldsValue({
                              car_document_serial: '',
                              car_document_number: '',
                              car_document_series_number: '',
                            })
                          }}
                >
                  <span className="hyd-checkbox-label-osago">ТС зарегистрировано в иностранном государстве</span>
                </Checkbox>
              )}
            </Form.Item>
          </Row>
          <Row gutter={0}>
            {!withoutCarPlate && getCarPlate()}

            {!withoutVIN && getVIN()}
            {withoutVIN && getIdentity()}
          </Row>

          <Row>
            <Form.Item
              validateStatus={brandCarError ? "error" : ""}
              help={brandCarError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-osago-autocomplete"
                htmlFor={"step-1_" + brandCar}
                labelText={"Марка"}
                divId={"float-label-small"}
                child={getFieldDecorator(brandCar, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{required: true, message: " "}],
                })(
                  <AutoComplete
                    className="hyd-input-autocomplete-small"
                    dataSource={carBrands}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) === 0
                    }
                    onSelect={(inputValue, option) => {
                      const brandData = carBrands.find(
                        (item) => +item.value === +inputValue
                      )
                      getCarModels({brand: inputValue});
                      saveCarBrandData(brandData);
                      setFieldsValue({model_car: "", modification_car: ""});
                      saveModelModificationsData(undefined)
                      toggleIsSelectedBrand(true);
                    }}
                    onChange={() => {
                      //this.setState({ isSelectedBrand: false });
                      toggleIsSelectedBrand(false);
                    }}
                  />
                )}
              />
            </Form.Item>

            {/* Модель */}
            <Form.Item
              validateStatus={modelCarError ? "error" : ""}
              help={modelCarError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input hyd-osago-autocomplete"
                htmlFor={"step-1_" + modelCar}
                labelText={"Модель"}
                divId={"float-label-small"}
                child={getFieldDecorator(modelCar, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{
                    required: getFieldValue(brandCar),
                    message: " "
                  }],
                })(
                  <AutoComplete
                    className="hyd-input-autocomplete-small"
                    dataSource={carModels && carModels.filter(model => filterModelsByCategory(model))}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) === 0
                    }
                    onSelect={(inputValue, option) => {
                      const modelData = carModels.find(
                        (item) => +item.value === +inputValue
                      )
                      toggleVehicleCategory(getCategoryId(modelData["class_of_vehicle"]))
                      getModelModifications({model: inputValue})
                      setFieldsValue({modification_car: ""})
                      saveModelModificationsData(undefined)
                      saveCarModelData(modelData)
                      toggleIsSelectedModel(true)
                    }}
                    onChange={() => {
                      this.setState({isSelectedModel: false});
                      toggleIsSelectedModel(false);
                    }}
                    disabled={brandCarError || !getFieldValue(brandCar)}
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              validateStatus={carYearIssueError ? "error" : ""}
              help={carYearIssueError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-3-input"
                htmlFor={"step-1_" + carYearIssue}
                labelText={"Год выпуска"}
                divId={"float-label-small"}
                child={getFieldDecorator(carYearIssue, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: !getFieldValue(carYearIssue),
                      message: " "
                    },
                    {
                      validator: checkCarYearIssue,
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-osago-3-input"
                    onChange={(e) => {
                      this.handleYearChange(e)
                      if (
                        modelModifications && e.target.value && !modelModifications.filter(modification =>
                          filterModifications(modification, e.target.value, undefined)
                        ).find(isModifInFilter)
                      ) {
                        setFieldsValue({modification_car: ""})
                        saveModelModificationsData(undefined)
                      }
                    }}
                    maxLength={4}/>
                )}
              />
            </Form.Item>

            <Form.Item
              validateStatus={enginePowerError ? "error" : ""}
              help={enginePowerError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-3-input hyd-osago-no-margin"
                htmlFor={"step-1_" + enginePower}
                labelText={"Мощность в лс"}
                divId={"float-label-small"}
                child={getFieldDecorator(enginePower, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: true,
                      message: " "
                    },
                    {
                      pattern: /^(\d)+$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Input
                    onChange={(e) => {
                      if (
                        modelModifications && !modelModifications.filter(modification =>
                          filterModifications(modification, undefined, e.target.value)
                        ).find(isModifInFilter)
                      ) {
                        setFieldsValue({modification_car: ""})
                        saveModelModificationsData(undefined)
                      }
                    }}
                    maxLength={4}
                    className="hyd-input-small hyd-osago-3-input"
                  />
                )}
              />
            </Form.Item>
          </Row>

          <Row>
            {/* Модификации */}
            <Form.Item
              validateStatus={modelModificationError ? "error" : ""}
              help={modelModificationError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-16-input hyd-osago-autocomplete"
                htmlFor={"step-1_" + modelModification}
                labelText={"Модификация"}
                divId={"float-label-small"}
                child={getFieldDecorator(modelModification, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{
                    required: modelModifications && !isEmpty(modelModifications.filter(modification => filterModifications(modification, undefined, undefined))),
                    message: " "
                  }],
                })(
                  <AutoComplete
                    className="hyd-input-autocomplete-small"
                    dataSource={modelModifications && modelModifications.filter(modification => filterModifications(modification, undefined, undefined))}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(inputValue, option) => {
                      const modelData = modelModifications.find(
                        (item) => +item.value === +inputValue
                      );

                      saveModelModificationsData(modelData);
                      toggleIsSelectedModel(true);
                    }}
                    onChange={() => {
                      this.setState({isSelectedModel: false});
                      toggleIsSelectedModel(false);
                    }}
                    disabled={modelCarError || !getFieldValue(modelCar)}
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              validateStatus={vehicleTypeError ? "error" : ""}
              help={vehicleTypeError || ""}
            >
              <div className="hyd-select-small hyd-osago-8-input hyd-osago-select hyd-osago-no-margin">
                {getFieldDecorator(vehicleType, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onSelect={(inputValue, option) => {
                      setFieldsValue({
                          resolution_max_weight: undefined,
                          unladen_mass: undefined,
                          number_of_seats: undefined,
                        }
                      )
                    }}
                  >
                    {vehicleCategories.map(({value, text}) =>
                      <Option value={value} key={value}>
                        <div className={"hyd-select-tooltip"}>Тип транспортного средства</div>
                        <div>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              validateStatus={documentsError || dataForm.documents.value === 5 ? "error" : ""}
              help={documentsError || ""}
            >
              <div className="hyd-select-small hyd-osago-8-input hyd-osago-select">
                {getFieldDecorator(documents, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [{required: true, message: " "}],
                })(
                  <Select
                    className="hyd-input-select-small"
                    style={{width: "100%"}}
                    onChange={(e) => {
                      if (
                        !([7, 8].includes(dataForm.documents.value) === [7, 8].includes(e)) ||
                        !([3, 6].includes(dataForm.documents.value) === [3, 6].includes(e)) ||
                        !((dataForm.documents.value === 1) === (e === 1)) ||
                        !((dataForm.documents.value === 5) === (e === 5))
                      ) {
                        this.setState({cleaveKey: ++this.state.cleaveKey});
                        setFieldsValue({
                          car_document_serial: '',
                          car_document_number: '',
                          car_document_series_number: ''
                        })
                      }
                    }}
                  >
                    {carDocumentsOptions.map(({value, text}) =>
                      <Option
                        value={value}
                        key={value}
                        disabled={
                          ((value === 2) && withoutCarPlate) ||
                          ([2, 3, 6, 8].includes(value) && getFieldValue(isForeignVehicle))
                        }
                      >
                        <div className={"hyd-select-tooltip"}>Документы на ТС</div>
                        <div>{text}</div>
                      </Option>
                    )}
                  </Select>
                )}
              </div>
            </Form.Item>

            {/* Серия */}
            <Form.Item
              style={getFieldValue(isForeignVehicle) ? undefined : {display: 'none'}}
              validateStatus={carDocumentSerialError ? "error" : ""}
              help={carDocumentSerialError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-4-input  hyd-osago-low-margin"
                htmlFor={"step-1_" + carDocumentSerial}
                labelText={"Серия"}
                divId={"float-label-small"}
                child={getFieldDecorator(carDocumentSerial, {})(
                  <Input
                    className="hyd-input-small hyd-osago-4-input"
                  />
                )}
              />
            </Form.Item>

            {/* Номер */}
            <Form.Item
              style={getFieldValue(isForeignVehicle) ? undefined : {display: 'none'}}
              validateStatus={carDocumentNumberError ? "error" : ""}
              help={carDocumentNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-4-input"
                htmlFor={"step-1_" + carDocumentNumber}
                labelText={"Номер"}
                divId={"float-label-small"}
                child={getFieldDecorator(carDocumentNumber, {})(
                  <Input
                    className="hyd-input-small hyd-osago-4-input"
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              style={!getFieldValue(isForeignVehicle) ? undefined : {display: 'none'}}
              validateStatus={carDocumentSeriesNumberError ? "error" : ""}
              help={carDocumentSeriesNumberError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-1_" + carDocumentSeriesNumber}
                labelText={"Серия и номер документа"}
                divId={"float-label-small"}
                child={getFieldDecorator(carDocumentSeriesNumber, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: !getFieldValue(isForeignVehicle),
                      message: " ",
                    },
                    {
                      pattern: (
                        [7, 8].includes(dataForm.documents.value) ? /^\d{15}$/g :
                          (dataForm.documents.value === 1 ? /(^[0-9]{2}[АВЕКМНОРСТУХавекмнорстух]{2}) [0-9]{6}$/g :
                            [3, 6].includes(dataForm.documents.value) ? /(^[АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя]{2}) [0-9]{6}$/g :
                                /(^[0-9]{4}) [0-9]{6}|(^[0-9]{2}[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]{2}) [0-9]{6}$/g)
                      ),
                      message: " ",
                    },
                  ],
                })(
                  <Cleave
                    key={this.state.cleaveKey}
                    className="ant-input hyd-input-small hyd-osago-8-input"
                    options={{
                      blocks: ([7, 8].includes(dataForm.documents.value) ? [15] : [3, 6].includes(dataForm.documents.value) ? [2, 6] : [4, 6]),
                      delimiter: ' ',
                      numericOnly: false
                    }}
                    onChange={onChangeCarDocumentSeriesNumber(setFieldsValue)}
                  />
                )}
              />
            </Form.Item>

            {/* Дата выдачи */}
            <Form.Item
              validateStatus={carDocumentDateError ? "error" : ""}
              help={carDocumentDateError || ""}
            >
              <FloatingLabelInput
                divClassName={"hyd-osago-8-input hyd-osago-no-margin"}
                htmlFor={"step-1_" + carDocumentDate}
                labelText={"Дата выдачи"}
                divId={"float-label-small"}
                child={getFieldDecorator(carDocumentDate, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: !getFieldValue(carDocumentDate),
                      message: " "
                    },
                    {
                      validator: checkCarDocumentsDate(getFieldValue(carYearIssue)),
                    },
                  ],
                })(
                  <Cleave
                    className={
                      "ant-input hyd-input-small hyd-osago-8-input "
                    }
                    key={JSON.stringify({
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    })}
                    options={{
                      date: true,
                      delimiter: ".",
                      datePattern: ["d", "m", "Y"],
                    }}
                  />
                )}
              />
            </Form.Item>
          </Row>

          {/* Технически допустимая max масса  ResolutionMaxWeight*/}
          {[9, 10, 11].includes(dataForm.vehicle_type.value) &&
          <>
            <Form.Item
              validateStatus={resolutionMaxWeightError ? "error" : ""}
              help={resolutionMaxWeightError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-1_" + resolutionMaxWeight}
                labelText={"Технически допустимая масса (кг)"}
                divId={"float-label-small"}
                child={getFieldDecorator(resolutionMaxWeight, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: [9, 10, 11].includes(dataForm.vehicle_type.value),
                      validator: this.checkMaxWeight,
                      message: " "
                    },
                    {
                      pattern: /^(\d)+$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-osago-8-input"
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              validateStatus={unladenMassError ? "error" : ""}
              help={unladenMassError || ""}
            >
              <FloatingLabelInput
                divClassName="hyd-osago-8-input"
                htmlFor={"step-1_" + unladenMass}
                labelText={"Масса в снаряженном состоянии (кг)"}
                divId={"float-label-small"}
                child={getFieldDecorator(unladenMass, {
                  validateTrigger: ["onBlur", "onChange"],
                  rules: [
                    {
                      required: [9, 10, 11].includes(dataForm.vehicle_type.value),
                      message: " "
                    },
                    {
                      pattern: /^(\d)+$/g,
                      message: " ",
                    },
                  ],
                })(
                  <Input
                    className="hyd-input-small hyd-osago-8-input"
                  />
                )}
              />
            </Form.Item>
          </>
          }

          {/* количество мест */}
          {[4, 5, 6].includes(dataForm.vehicle_type.value) &&
          <Form.Item
            validateStatus={numberOfSeatsError ? "error" : ""}
            help={numberOfSeatsError || ""}
          >
            <FloatingLabelInput
              divClassName="hyd-osago-8-input"
              htmlFor={"step-1_" + numberOfSeats}
              labelText={"Количество мест"}
              divId={"float-label-small"}
              child={getFieldDecorator(numberOfSeats, {
                validateTrigger: ["onBlur", "onChange"],
                rules: [
                  {
                    required: false,
                    message: " "
                  },
                  {
                    pattern: /^(\d)+$/g,
                    message: " ",
                  },
                ],
              })(
                <Input
                  className="hyd-input-small hyd-osago-8-input"
                />
              )}
            />
          </Form.Item>
          }

          {/* Далее */}
          <Row>
            <Form.Item>
              <Button
                className="hyd-btn-small hyd-osago-btn-proceed"
                type="primary"
                htmlType="submit"
                form="osago-step-1"
                key="submit"
                disabled={hasErrors(getFieldsError()) || dataForm.documents.value === 5}
              >
                Продолжить
              </Button>
            </Form.Item>
          </Row>

        </Form>
        {[
          {number: 2, text: "Страхователь"},
          {number: 3, text: "Список водителей"},
          {number: 4, text: "Условия использования"},
          {number: 5, text: "Оформление полиса ОСАГО"},
          {number: 6, text: "Предварительный просмотр документов"},
        ].map(({number, text}) =>
          <div className="step-title-disabled" key={number}>
            <div className="step-icon-block">
              <NumberInactiveIcon className="hyd-large-num-ico"/>
              <span className="hyd-large-num-ico-num">{number}</span>
            </div>
            <span className="step-title-disabled-header">{text}</span>
          </div>
        )}


        <ModalDiagnosticCard/>
        <ModalChoiceTO/>
        <ModalError type={1}/>
      </>
    );
  }

}

const WrappedFirstStepForm = Form.create({
    name: "step-1",
    mapPropsToFields(props) {
      const {dataForm} = props;
      const transformed = transform({dataForm});

      return transformed.dataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields, "car");
    },
  }
)(FirstStep);

const mapStateToProps = (state) => (
  {
    currentStep: currentStepSelector(state),
    dataForm: dataFormSelector(state),
    errors: errorsSelector(state),
    infoTO: infoTOSelector(state),
    carBrands: carBrandsSelector(state),
    carModels: carModelsSelector(state),
    modelModifications: modelModificationsSelector(state),
    isRequest: isRequestSelector(state),
    buttonTO: buttonTOSelector(state),
    withoutCarPlate: inputCarPlateSelector(state),
    withoutVIN: inputVinSelector(state),
    typeCarIdentity: typeCarIdentitySelector(state),
    visibleTOModal: visibleTOModalSelector(state),
    isSelectedBrand: isSelectedBrandSelector(state),
    isSelectedModel: isSelectedModelSelector(state),
    visibleChoiceTOModal: visibleChoiceTOModalSelector(state),
    buttonModalTO: buttonModalTOSelector(state),
  });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToNextStep,
      setDataForm,
      getInfoByVin,
      getTOInfo,
      getCarBrands,
      getCarModels,
      getModelModifications,
      showTOButton,
      toggleTOModal,
      saveCarBrandData,
      saveCarModelData,
      saveModelModificationsData,
      toggleIsSelectedBrand,
      toggleIsSelectedModel,
      toggleWithoutCarPlate,
      clearDataCarPlate,
      toggleWithoutVIN,
      clearDataVIN,
      clearMarkModel,
      clearDataDK,
      toggleTypeCarIdentity,
      clearDataBodyNumber,
      clearDataChassisNumber,
      toggleButtonModalTo,
      toggleVehicleCategory,
      changeDateInfoTO,
      populateBrandModelData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedFirstStepForm);
