import initialState from '../store/initialState';
import injectReducer from '../store/injectReducer';
import * as types from '../store/actionTypes';


function transformDataForm(dataForm, state) {
  let dataFormResult = {}
  let cascoKind = state.cascoKind
  let ptsFileList = state.ptsFileList
  let stsFileList = state.stsFileList
  let currentPolicyFileList = state.currentPolicyFileList
  let passportFileList = state.passportFileList
  let juridicalCardFileList = state.juridicalCardFileList
  let drivingLicenseFileList = state.drivingLicenseFileList

  dataFormResult["bank_or_leasing"] = dataForm.pledge_lease.value
  dataFormResult["sum_insured"] = dataForm.insured_sum.value?.replace(/\s+/g, "")
  dataFormResult["franchise"] = dataForm.franchise.value?.replace(/\s+/g, "")
  dataFormResult["car_mileage"] = dataForm.mileage.value
  dataFormResult["policyholder_full_name"] = dataForm.policyholder_full_name.value
  dataFormResult["note"] = dataForm.note.value

  dataFormResult["sts"] = stsFileList
  dataFormResult["ins_policy"] = currentPolicyFileList
  dataFormResult["pst_or_epts"] = ptsFileList

  dataFormResult["passport_or_jur_card"] = cascoKind === 1 ? passportFileList : juridicalCardFileList

  if (cascoKind === 1) {
    dataFormResult["driver_license"] = drivingLicenseFileList
  }

  return dataFormResult
}

export default injectReducer(initialState.cascoReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.cascoReducer
  }),

  [types.SET_DATA_FORM_CASCO]: (state, action) => ({
    ...state,
    cascoDataForm: {...state.cascoDataForm, ...action.payload.data}
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload,
  }),

  [types.TOGGLE_CASCO_KIND]: (state, {payload}) => ({
    ...state,
    cascoKind: payload,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.UPLOAD_PASSPORT_FILE]: (state, {payload}) => ({
    ...state,
    passportFileList: [...state.passportFileList, payload],
  }),

  [types.REMOVE_PASSPORT_FILE]: (state, {payload}) => ({
    ...state,
    passportFileList: state.passportFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_PTS_FILE]: (state, {payload}) => ({
    ...state,
    ptsFileList: [...state.ptsFileList, payload],
  }),

  [types.REMOVE_PTS_FILE]: (state, {payload}) => ({
    ...state,
    ptsFileList: state.ptsFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_STS_FILE]: (state, {payload}) => ({
    ...state,
    stsFileList: [...state.stsFileList, payload],
  }),

  [types.REMOVE_STS_FILE]: (state, {payload}) => ({
    ...state,
    stsFileList: state.stsFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_CURRENT_POLICY_FILE]: (state, {payload}) => ({
    ...state,
    currentPolicyFileList: [...state.currentPolicyFileList, payload],
  }),

  [types.REMOVE_CURRENT_POLICY_FILE]: (state, {payload}) => ({
    ...state,
    currentPolicyFileList: state.currentPolicyFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_JURIDICAL_CARD_FILE]: (state, {payload}) => ({
    ...state,
    juridicalCardFileList: [...state.juridicalCardFileList, payload],
  }),

  [types.REMOVE_JURIDICAL_CARD_FILE]: (state, {payload}) => ({
    ...state,
    juridicalCardFileList: state.juridicalCardFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_DRIVING_LICENSE_FILE]: (state, {payload}) => ({
    ...state,
    drivingLicenseFileList: [...state.drivingLicenseFileList, payload],
  }),

  [types.REMOVE_DRIVING_LICENSE_FILE]: (state, {payload}) => ({
    ...state,
    drivingLicenseFileList: state.drivingLicenseFileList.filter(file => file !== payload),
  }),

  [types.SEND_DATA_FORM_CASCO]: state => {
    let stateCascoDataForm = state.cascoDataForm

    let dataFormResult = transformDataForm(stateCascoDataForm, state)

    return {
      ...state,
      dataFormResult: dataFormResult,
    }
  },

  [types.SEND_DATA_FORM_CASCO_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isFormSuccessfullySent: false,
  }),

  [types.SEND_DATA_FORM_CASCO_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null,
    isFormSuccessfullySent: true,
  }),

  [types.SEND_DATA_FORM_CASCO_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload},
    visibleErrorModal: true,
    isFormSuccessfullySent: false,
  }),

  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 3) {
      return {
        ...state,
        ...initialState.cascoReducer
      };
    } else {
      return {
        ...state
      };
    }
  },

  [types.TOGGLE_CASCO_MODAL]: (state, {payload}) => ({
    ...state,
    visibleModalCASCOApplication: payload
  }),

})
