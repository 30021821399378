import {ReactComponent as DownloadIcon} from "../assets/Icons/Download.svg";
import {ReactComponent as DocSmallIcon} from "../assets/Icons/DocSmall.svg";
import React from "react";
import {Button, Form} from "antd";
import isArray from "lodash/isArray";
import moment from "moment";
import initialState from "../store/initialState";
import petrovich from "petrovich";

export const frontDateFormat = "DD.MM.YYYY"
export const backDateFormat = "YYYY-MM-DD"

export const isPDProcessingConsentCheckedKey = "is_pd_processing_consent_checked"

export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export function isObject(value) {
  return value && typeof value === "object" && value.constructor === Object
}

export function isEmpty(obj) {
  let hasOwnProperty = Object.prototype.hasOwnProperty

  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }

  return true
}

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 8
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 16
    }
  }
}

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

export const inlineFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 24,
      offset: 4
    }
  }
}

export const transform = obj => {

  return Object.keys(obj).reduce((acc, cv) => {
    return {
      ...acc,
      [cv]:
        typeof obj[cv] === "object" && !("value" in obj[cv])
          ? isArray(obj[cv])
            ? obj[cv].map(item => transform(item))
            : transform(obj[cv])
          : Form.createFormField({
            ...obj[cv],
            // value: obj[cv] && obj[cv].value ? obj[cv].value : undefined
            //пересмотреть:
            value: obj[cv] && obj[cv].value ? obj[cv].value :
              ((obj[cv] && (obj[cv].name === 'policy_owner_birth_date_driver' || 'car_owner_birth_date_driver' || 'car_dk_date')) ? '' : undefined)
          })
    }
  }, {})
}

export function isNumeric(str) {
  return /^\d*\.?\d+$/.test(str);
}

/* Шаг 3 */
export function getDigits(str) {
  return parseInt(str.replace(/[^\d]/g, ''))
}

export function getDecimal(str) {
  if (str) {
    return Number(str.replace(/\s+/g, '').replace(',', '.'))
  }
}

export function getDateFromString(dateValue) {
  let day = dateValue[0]
  let month = dateValue[1]
  let year = dateValue[2]
  return new Date(year, month - 1, day)  // в Date месяц с нуля [дата начала стажа]
}

/* Шаг 4 */
export function splitDate(result_date) {
  let arr_date = []
  arr_date[0] = result_date.getDate()
  arr_date[1] = result_date.getMonth() + 1
  arr_date[2] = String(result_date.getFullYear())
  if (arr_date[0] < 10) {
    arr_date[0] = '0' + arr_date[0]
  }
  if (arr_date[1] < 10) {
    arr_date[1] = '0' + arr_date[1]
  }
  return arr_date
}

/*Получить сегодняшюю дату */
export const getTodayDate = () => {
  let today = new Date()

  let dd = today.getDate()
  //today.setDate(dd + 1)
  let mm = today.getMonth() + 1
  let yyyy = today.getFullYear()
  if (dd < 10) {
    dd = "0" + dd
  }
  if (mm < 10) {
    mm = "0" + mm
  }
  //today = dd + "." + mm + "." + yyyy
  today = yyyy + "-" + mm + "-" + dd
  return today
}

/*Проверка необходимости отображения кнопки проверки ТО по году выпуска авто*/
export const checkTOBtnByYear = (e) => {
  const year = parseInt(e)
  const currentYear = new Date().getFullYear()
  return (year - currentYear < -3)
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

export const declOfNum = (number, titles) => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

/* Валидация объекта НС при переходе из журнала по кнопкам "Оформить", "Копировать", "Оплатить" */
function isNull(obj: any, key: any) {
  return (obj[key] == null || obj[key] === undefined || obj[key] === "null")
}

export const validateNulls = (obj: any) => {
  let objKeys = Object.keys(obj);
  objKeys.forEach((key) => {
    if (isNull(obj, key)) {
      obj[key] = ""
    }
    if (typeof (obj[key]) == "object") {
      validateNulls(obj[key]);
    }
  })
}

export const validateNullsDelete = (obj: any) => {
  let objKeys = Object.keys(obj);
  objKeys.forEach((key) => {
    if (isNull(obj, key)) {
      obj[key] = undefined
    }
    if (typeof (obj[key]) == "object") {
      validateNullsDelete(obj[key]);
    }
  })
}

export const baseTrigger = ["onBlur", "onChange"]

export const baseRules = [
  {
    required: true,
    message: " "
  },
]

export const nameRules = [
  {
    required: true,
    whitespace: true,
    message: " "
  },
  {
    // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
    pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
    message: " ",
  },
]

export const nameRulesCyrillic = [
  {
    required: true,
    whitespace: true,
    message: " "
  },
  {
    // только кириллица (допускается двойное имя с пробелом по центру или тире)
    pattern: /^[А-Яа-яЁё() -]+$/g,
    message: " ",
  },
]

export const constructField = (fieldName: string, value: any) => {
  return {
    value: value,
    name: fieldName,
    validating: true,
    dirty: true,
    touched: true,
  }
}

const letters = {
  "A": "А",
  "B": "В",
  "E": "Е",
  "K": "К",
  "M": "М",
  "H": "Н",
  "O": "О",
  "P": "Р",
  "C": "С",
  "T": "Т",
  "Y": "У",
  "X": "Х",
}

export const transliterateSeries = (word) => {
  return word.split("").map(function (char) {
    return letters[char] || char;
  }).join("")
}

export const downloadDocsButton = (getPaymentDocument, item, index) => {
  return (
    <Button
      className={"hyd-doc-download-small"}
      key={item + index}
      onClick={() => {
        getPaymentDocument(item.value, item.type)
      }}
    >
      <DownloadIcon/>
      <span className={"hyd-doc-download-small-txt"}>{item.name}</span>
    </Button>
  )
}

export const downloadDocsButtonTxt = (getPaymentDocument, item, index) => {
  return (
    <Button
      className={"hyd-doc-download-small-2"}
      key={item + index}
      onClick={() => {
        getPaymentDocument(item.value, item.type)
      }}
    >
      <span className={"hyd-doc-download-small-txt-2"}>{item.name}</span>
      <DocSmallIcon/>
    </Button>
  )
}

export const currencyOptions = {
  style: "currency",
  currency: "RUB"
}

export const numToRubbles = (num) => {
  return Number(num)?.toLocaleString("ru-RU", currencyOptions)
}

export const get_doc = (url, _) => {
  window.open(url, "_blank", "noreferrer")
}

export const vehicleCategories = [
  {
    value: 1,
    category: "A",
    text: "Мотоциклы (кат. А)",
  },
  {
    value: 2,
    category: "B",
    text: "Легковые (кат. В)",
  },
  {
    value: 9,
    category: "C",
    text: "Грузовые (кат. С)",
  },
  {
    value: 5,
    category: "D",
    text: "Автобусы (кат. D)",
  },
]

export const juristicDocumentsTypes = [
  {
    key: 1,
    text: "Свидетельство о регистрации",
  },
  {
    key: 2,
    text: "Выписка из ЕГРЮЛ",
  },
]

export const getCategoryId = (category) => {
  return vehicleCategories.find((item) => item["category"] === category).value
}

export const mortgageIdentityDocs = [
  {
    value: 1,
    text: "Паспорт гражданина РФ",
  },
]

export const mortgageMaritalStatuses = [
  {
    value: 1,
    text: "Никогда не состоял (а) в браке",
    value_back: "single"
  },
  {
    value: 2,
    text: "Состоит в браке",
    value_back: "married"
  },
  {
    value: 3,
    text: "Разведен (а)",
    value_back: "divorced"
  },
  {
    value: 4,
    text: "Незарегистрированный брак",
    value_back: "civil"
  },
  {
    value: 5,
    text: "Вдовец (вдова)",
    value_back: "widower"
  },
]

export const mortgageObjectTypes = [
  {
    value: 1,
    text: "Квартира",
    value_back: "flat",
    disabled: false
  },
  {
    value: 2,
    text: "Комната",
    value_back: "room",
    disabled: true
  },
  {
    value: 3,
    text: "Апартаменты",
    value_back: "apartments",
    disabled: false
  },
  {
    value: 4,
    text: "Таунхаус",
    value_back: "townhouse",
    disabled: true
  },
]

export const mortgageInsuranceObjects = [
  {
    value: 1,
    text: "Конструктивные элементы",
    value_back: "struct element",
    disabled: false
  },
  {
    value: 2,
    text: "Конструктивные элементы с отделкой и оборудованием",
    value_back: "struct element plus",
    disabled: true
  },
]

const STONE = "stone"
const PANEL = "panel"
const MIXED = "mixed"
const BLOCK = "block"
const OTHER = "other"
const WOOD = "wood"
const MONOLITH = "monolith"
const CONCRETE = "concrete"

export const mortgageBuildingMaterials = [
  {
    value: 1,
    text: "Каменные, кирпичные",
    value_back: STONE
  },
  {
    value: 2,
    text: "Панельные",
    value_back: PANEL
  },
  {
    value: 3,
    text: "Смешанные",
    value_back: MIXED
  },
  {
    value: 4,
    text: "Блочные",
    value_back: BLOCK
  },
  {
    value: 5,
    text: "Иные",
    value_back: OTHER
  },
  {
    value: 6,
    text: "Деревянные",
    value_back: WOOD
  },
  {
    value: 7,
    text: "Монолитные",
    value_back: MONOLITH
  },
]

export const mortgageFloorMaterials = [
  {
    value: 1,
    text: "Железобетонные",
    value_back: CONCRETE
  },
  {
    value: 2,
    text: "Деревянные",
    value_back: WOOD
  },
  {
    value: 3,
    text: "Смешанные",
    value_back: MIXED
  },
  {
    value: 4,
    text: "Иные",
    value_back: OTHER
  },
]

export const accidentsProductTypeOptions = [
  {
    value: 1,
    value_back: "ACH",
    text: "НС Здоровье"
  },
  {
    value: 2,
    value_back: "ACS",
    text: "НС Спорт"
  },
]

export const carDocumentsOptions = [
  {
    value: 1,
    text: "ПТС"
  },
  {
    value: 2,
    text: "СТС"
  },
  {
    value: 3,
    text: "Технический паспорт"
  },
  // {
  //   value: 4,
  //   text: "Технический талон"
  // },
  {
    value: 5,
    text: ""  // это какой-то костыль?
  },
  {
    value: 6,
    text: "ПСМ"
  },
  {
    value: 7,
    text: "ЭПТС"
  },
  {
    value: 8,
    text: "ЭПСM"
  },
]

export const checkPolicyValueDate = (rule, value, callback) => {
  if (value) {
    let resultCurrentDate = new Date()

    if (moment(value, frontDateFormat, true).isValid()) {
      let result = getDateFromString(value.split("."))
      result.setHours(0, 0, 0, 0)
      resultCurrentDate.setHours(0, 0, 0, 0)

      if (result && result < resultCurrentDate) {
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  }
}

export const addYear = (date, form, updateDateFieldName) => {
  const momentObj = moment(date, frontDateFormat)
  if (date.split('.').length === 3 && date.split('.')[2] && date.split('.')[2].length === 4) {
    let endDate = moment(momentObj)
      .add(1, "Y")
      .subtract(1, "days")
      .format(frontDateFormat)
    form.setFieldsValue({[updateDateFieldName]: endDate})
  }
}

export const checkCarYearIssue = (rule, value, callback) => {
  if (value) {
    let currentYear = new Date().getFullYear()

    if (currentYear < value) {
      callback(" ")
    } else if (!/^((19|20)[0-9]\d{1})$/g.test(value)) {
      callback(" ")
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const checkCarDocumentsDate = (carYearIssue) => (rule, value, callback) => {
  if (value) {
    let resultCurrentDate = new Date()
    let resultPastDate = new Date(1920, 0, 1)
    let resultValueDate = value.split(".")  // в Date месяц с нуля

    if (moment(value, frontDateFormat, true).isValid()) {
      let val_year = resultValueDate[2]
      let result = getDateFromString(resultValueDate)

      if ((result && resultCurrentDate < result) || result < resultPastDate) {
        callback(" ")
      } else if (val_year < carYearIssue) {
        console.log(val_year)
        console.log(carYearIssue)
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  } else {
    callback()
  }
}
export const onChangeCarDocumentSeriesNumber = (setFieldsValue) => (event) => {
  event.target.value = event.target.value.toUpperCase()
  let resultValueDate = event.target.value.split(" ")
  if (resultValueDate.length === 2) {
    let series = resultValueDate[0]
    let number = resultValueDate[1]
    setFieldsValue({car_document_number: number, car_document_serial: series});
  } else {
    setFieldsValue({car_document_number: event.target.rawValue, car_document_serial: ''});
  }
}

export const addressCharacteristicsList = [
  "policy_owner_cladr",
  "policy_owner_cladr_okato",
  "policy_owner_cladr_address",
  "policy_owner_cladr_country",
  "policy_owner_cladr_region",
  "policy_owner_cladr_zip",
  "policy_owner_cladr_city_name",
  "policy_owner_cladr_city_kladr",
  "policy_owner_cladr_street_kladr",
  "policy_owner_cladr_street_name",
  "policy_owner_cladr_house",
  "policy_owner_cladr_block",
  "policy_owner_cladr_flat",
  "policy_owner_fias_id",
  "policy_owner_house_fias_id",

  "policy_owner_juristic_cladr_zip",
  "policy_owner_juristic_address",
  "policy_owner_juristic_cladr_okato",
  "policy_owner_juristic_cladr_country",
  "policy_owner_juristic_cladr_region",
  "policy_owner_juristic_cladr_city_name",
  "policy_owner_juristic_cladr_city_kladr",
  "policy_owner_juristic_cladr_street_kladr",
  "policy_owner_juristic_cladr_street_name",
  "policy_owner_juristic_cladr_house",
  "policy_owner_juristic_cladr_block",
  "policy_owner_juristic_cladr_flat",
  "policy_owner_juristic_fias_id",
  "policy_owner_juristic_house_fias_id",

  "car_owner_cladr",
  "car_owner_cladr_address",
  "car_owner_cladr_okato",
  "car_owner_cladr_country",
  "car_owner_cladr_region",
  "car_owner_cladr_zip",
  "car_owner_cladr_city_name",
  "car_owner_cladr_city_kladr",
  "car_owner_cladr_street_kladr",
  "car_owner_cladr_street_name",
  "car_owner_cladr_house",
  "car_owner_cladr_block",
  "car_owner_cladr_flat",
  "car_owner_fias_id",
  "car_owner_house_fias_id",

  "car_owner_juristic_cladr_zip",
  "car_owner_juristic_address",
  "car_owner_juristic_cladr_okato",
  "car_owner_juristic_cladr_country",
  "car_owner_juristic_cladr_region",
  "car_owner_juristic_cladr_city_name",
  "car_owner_juristic_cladr_city_kladr",
  "car_owner_juristic_cladr_street_kladr",
  "car_owner_juristic_cladr_street_name",
  "car_owner_juristic_cladr_house",
  "car_owner_juristic_cladr_block",
  "car_owner_juristic_cladr_flat",
  "car_owner_juristic_fias_id",
  "car_owner_juristic_house_fias_id",
]

export const checkValueBirthDateChild = (rule, value, callback) => {
  // Валидатор без ограничения по возрасту
  if (value) {
    let resultCurrentDate = new Date()
    let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

    if (moment(value, frontDateFormat, true).isValid()) {
      let result = getDateFromString(value.split("."))
      if (result && ((resultCurrentDate < result) || (result < resultPastDate))) {
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  } else {
    callback()
  }
}

export const checkValueBirthDate = (rule, value, callback) => {
  if (value) {
    let resultCurrentDate = new Date()
    let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))

    if (moment(value, frontDateFormat, true).isValid()) {
      let result = getDateFromString(value.split("."))
      if (result && ((resultCurrentDate < result) || (result < resultPastDate))) {
        callback(" ")
      } else if (moment(resultCurrentDate).diff(moment(result), "years", true) < 18) { // 18 yo valid
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  } else {
    callback()
  }
}

export const checkValueExperienceDate = (birth_date) => (rule, value, callback) => {
  if (value) {
    let resultCurrentDate = new Date()
    let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
    let birthDate = getDateFromString(birth_date.split('.'))
    let resultValueDate = value.split(".")
    if (moment(value, frontDateFormat, true).isValid()) {
      let result = getDateFromString(resultValueDate)
      if (result && ((resultCurrentDate < result) || (result < resultPastDate))) {
        callback(" ")
      } else if (moment(result).diff(moment(birthDate), "years", true) < 14) { // 14 yo valid
        callback(" ")
      } else {
        callback()
      }
    } else {
      callback(" ")
    }
  } else {
    callback()
  }
}

export const validateGender = (setFieldsValue) => (rule, value, callback) => {
  if (petrovich.detect_gender(value) === 'male') {
    setFieldsValue({'gender': 'M'})
    callback()
  } else if (petrovich.detect_gender(value) === 'female') {
    setFieldsValue({'gender': 'F'})
    callback()
  } else {
    setFieldsValue({'gender': 'M'})
    callback()
  }
}

export const isValidDate = (rule, value, callback) => {
  if (value) {
    if (moment(value, frontDateFormat, true).isValid()) {
      callback()
    } else {
      callback(" ")
    }
  } else {
    callback()
  }
}

export const constructClearedForm = (dataForm, arrayOfData) => {  // TODO remove duplicate code lines in Osago
  return Object.keys(dataForm).reduce((object, key) => {
    const findData = arrayOfData.find(item => item === key);
    if (!findData) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    } else if (initialState.mortgageReducer.mortgageDataForm[key] !== undefined) {
      object[key] = initialState.mortgageReducer.mortgageDataForm[key];
    }
    return object;
  }, {})
}

export const findInsuranceID = (insurances, insKeys, insID) => {
  return insurances.find(i => ((insKeys.includes(i["ins_key"]) && i.id === insID)))
}

export const updateArray = (array, newObj) => {
  // Find if there are any objects in the array with the same 'key' as newObj
  const index = array.findIndex((obj) => obj.key === newObj.key)

  if (index >= 0) { // if such an object was found
    // Replace the existing object with newObj
    array[index] = newObj
  } else {
    // if no such object was found, add newObj to the array
    array.push(newObj)
  }

  return array
}

export const isListsNotEmpty = (...lists) => {
  // Check if any list is empty
  for (let list of lists) {
    if (!list.length) {
      return false
    }
  }
  // All lists are not empty
  return true
}

export const replaceNullWithEmptyString = (array) => {
  return array.map(obj => {
    let newObj = {}
    for (let key in obj) {
      newObj[key] = obj[key] === null ? "" : obj[key]
    }
    return newObj
  })
}

export const calculateFilters = (data, filterColumns) => {
  return Object.fromEntries(filterColumns.map(column => {
    const set =[ ...new Set(data.map(item => item[column] ? item[column] : ""))]
    return [column, set.map(
      value => ({
        text: value,
        value: value,
      })
    )]
  }))
}

export const identificationDocumentsOptions = [
  {
    value: 1,
    text: "Паспорт гражданина РФ"
  },
  {
    value: 3,
    text: "Паспорт иностранный"
  },
  {
    value: 9,
    text: "Свидетельство о рождении"
  },
]

export const validateIdentityDocs = (dataForm, identityDocKey, serialKey, numberKey) => {
  if (!identificationDocumentsOptions.find(e => dataForm[identityDocKey]?.value === e.value)) {
    dataForm[serialKey] = constructField(serialKey, undefined, true)
    dataForm[numberKey] = constructField(numberKey, undefined, true)
  }
}

export const paidPaymentStatus = "paid"
export const agentCommissionKey = "agent_commission"
export const warningKey = "warning"
