import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Preloader from "../../components/Preloader";
import {
  goToPrevStep,
  goToNextStep,
  clearForm,
  clearIsRequestState,
  setDataAccidentsSportForm,
} from "../../actions/accidentsSportActions";
import {
  isRequestSelector,
  currentStepSelector,
} from "../../selectors/accidentsSportSelectors";
import {tokenSelector} from "../../selectors/loginSelectors";
import "./styles/index.scss";
import FirstStepAccidentsSport from "./step-1";
import SecondStepAccidentsSport from "./step-2";
import ThirdStepAccidentsSport from "./step-3";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";


class AccidentsSport extends Component {
  componentDidMount() {
    document.title = "НС"

    const {token, clearIsRequestState, getNotifications, checkEarnedMoneyAmount} = this.props
    if (token) {
      getNotifications()
      checkEarnedMoneyAmount()
    }
    clearIsRequestState()
  }

  componentWillUnmount() {
    this.props.clearForm(4)
  }

  renderForms = () => {
    let {currentStep} = this.props;

    switch (currentStep) {
      case 0:
        return <FirstStepAccidentsSport/>;
      case 1:
        return <SecondStepAccidentsSport/>;
      case 2:
        return <ThirdStepAccidentsSport/>;
      default:
        return <FirstStepAccidentsSport/>;
    }
  };

  render() {
    const {
      isRequest,
    } = this.props;

    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="accidents-sport-container">
          <span className="hyd-very-big-bold-text-small accidents-sport-header">НС</span>
          {this.renderForms()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPrevStep,
      goToNextStep,
      clearForm,
      getNotifications,
      clearIsRequestState,
      setDataAccidentsSportForm,
      checkEarnedMoneyAmount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccidentsSport);
