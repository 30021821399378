import React, {Component} from "react";
import {Row, Col, Form, Input, Button, Select, Tooltip, Checkbox, notification} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  baseTrigger,
  formItemLayout,
  hasErrors,
  isListsNotEmpty,
  transform,
  transliterateSeries,
  isPDProcessingConsentCheckedKey,
} from "../../utils";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import {
  setDataForm, getInsurances, uploadAddendumFiles, removeAddendumFiles, sendAddendumApplication, toggleModalAddendum,
} from "../../actions/osagoActions";
import {clearForm} from "../../actions/accidentsSportActions";
import {
  dataFormSelector, insurancesSelector, addendumFileListSelector, isFormSuccessfullySentSelector,
} from "../../selectors/osagoSelectors";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import uploadButton from "../../components/UploadButton";
import {ReactComponent as QuestionIcon} from '../../assets/Icons/Question.svg';
import ModalApplication from "../../components/Form/ModalApplication";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import PersonalDataCheckbox from "../../components/Form/PersonalDataCheckbox";
import {tokenSelector} from "../../selectors/loginSelectors";

const {Option} = Select;
const {TextArea} = Input;


class Addendum extends Component {
  componentDidMount() {
    document.title = "Аддендум"

    const {token, getInsurances, getNotifications, checkEarnedMoneyAmount} = this.props
    if (token) {
      getInsurances()
      getNotifications()
      checkEarnedMoneyAmount()
    }
  }

  componentWillUnmount() {
    const {clearForm} = this.props
    clearForm(1)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isRequest, isFormSuccessfullySent, toggleModalAddendum} = this.props
    if (isFormSuccessfullySent !== prevProps.isFormSuccessfullySent && !isRequest && isFormSuccessfullySent) {
      toggleModalAddendum(true)
    }
  }


  isApplicationTypesConfigCorrect = () => {
    const {form} = this.props
    const {getFieldValue} = form

    let addDriverValue = getFieldValue("add_driver")
    let deleteDriverValue = getFieldValue("delete_driver")
    let changeNumberSTSValue = getFieldValue("change_number_sts")
    let changeDriverLicenseValue = getFieldValue("change_driver_license")
    let addOrChangeRegPlateValue = getFieldValue("add_or_change_reg_plate")
    let changeLastNameValue = getFieldValue("change_last_name")
    let prolongationInsurancePeriodValue = getFieldValue("prolongation_insurance_period")
    let changeAddressValue = getFieldValue("change_address")

    return !!(addDriverValue || deleteDriverValue || changeNumberSTSValue || changeDriverLicenseValue || addOrChangeRegPlateValue || changeLastNameValue || prolongationInsurancePeriodValue || changeAddressValue)
  }

  isNoteRequired = () => {
    const {form} = this.props
    const {getFieldValue} = form

    let addDriver = getFieldValue("add_driver")
    let changeNumberSTS = getFieldValue("change_number_sts")
    let changeDriverLicense = getFieldValue("change_driver_license")
    let addOrChangeRegPlate = getFieldValue("add_or_change_reg_plate")
    let changeLastName = getFieldValue("change_last_name")
    let changeAddress = getFieldValue("change_address")

    return !!(addDriver || changeNumberSTS || changeDriverLicense || addOrChangeRegPlate || changeLastName || changeAddress)
  }

  isNoteEmpty = () => {
    const {form} = this.props
    const {getFieldValue} = form

    let note = getFieldValue("note")

    return note === '' || note == null
  }

  handleSubmit = (e) => {
    const {form, addendumFileList, sendAddendumApplication} = this.props
    const {validateFields} = form

    e.preventDefault()

    validateFields((err,) => {
      if (!err) {
        if (!isListsNotEmpty(addendumFileList) && this.isNoteRequired()) {
          notification["error"]({
            message: "Приложите необходимые документы!"
          })
        } else if (!this.isApplicationTypesConfigCorrect()) {
          notification["error"]({
            message: "Недопустимая конфигурация типов аддендума!"
          })
        } else if (this.isNoteRequired() && this.isNoteEmpty()) {
          notification["error"]({
            message: "Заполните примечание!"
          })
        } else {
          sendAddendumApplication()
        }
      } else {
        notification["error"]({
          message: err
        })
      }
    })
  }

  render() {
    const {
      form,
      insurances,
      addendumFileList,
      uploadAddendumFiles,
      removeAddendumFiles,
    } = this.props

    const {
      getFieldDecorator,
      getFieldError,
      getFieldValue,
      getFieldsError,
    } = form

    const agentEmail = "agent_email";
    const agentEmailError = getFieldError(agentEmail);

    const policySeries = "policy_series";
    const policySeriesError = getFieldError(policySeries);

    const policyNumber = "policy_number";
    const policyNumberError = getFieldError(policyNumber);

    const note = "note";
    const noteError = getFieldError(note);

    const insuranceCompany = "insurance_company";
    const insuranceCompanyError = getFieldError(insuranceCompany);

    const addDriver = "add_driver";
    const deleteDriver = "delete_driver";
    const changeNumberSTS = "change_number_sts";
    const changeDriverLicense = "change_driver_license";
    const addOrChangeRegPlate = "add_or_change_reg_plate";
    const changeLastName = "change_last_name";
    const prolongationInsurancePeriod = "prolongation_insurance_period";
    const changeAddress = "change_address";

    const addendumDocuments = [
      {
        value: 1,
        value_back: "addendum_documents",
        text: "Документы",
        multiple: true,
        fileList: addendumFileList,
        actionUpload: uploadAddendumFiles,
        actionRemove: removeAddendumFiles,
      },
    ]

    const textAddendumType = () => {
      return (
        <div style={{padding: "4px 12px"}}>
          <div style={{marginTop: 8}}>Добавить водителя - загрузить в/у с двух сторон.</div>
          <div style={{marginTop: 8}}>Удалить водителя - указать ФИО водителя в примечании.</div>
          <div style={{marginTop: 8}}>Внести/изменить рег. знак - загрузить СТС обе стороны.</div>
          <div style={{marginTop: 8}}>Продлить период страхования - указать в примечании на сколько месяцев требуется продлить.</div>
          <div style={{marginTop: 8}}>Изменить номер СТС - загрузить СТС обе стороны.</div>
          <div style={{marginTop: 8}}>Замена в/у - загрузить новое в/у.</div>
          <div style={{marginTop: 8}}>Смена фамилии - загрузить новый паспорт/новое в/у.</div>
          <div style={{marginTop: 8}}>Изменить адрес страхователя/собственника - загрузить паспорт, страница 3-4 + прописка.</div>
        </div>
      )
    }

    return (
      <>
        <div className="addendum-container">
          <span className="hyd-very-big-bold-text-small">Заявка на аддендум</span>

          <Form {...formItemLayout} onSubmit={this.handleSubmit}>

            <div className={"hyd-row-prolongation"} style={{marginTop: 16}}>
              {/* Email */}
              <Form.Item
                validateStatus={agentEmailError ? "error" : ""}
                help={agentEmailError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-osago-8-input"
                  htmlFor={"addendum_" + agentEmail}
                  labelText={"E-mail агента"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(agentEmail, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {required: true, message: " "},
                      {
                        pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                        message: " "
                      }
                    ]
                  })(<Input className="ant-input hyd-input-small hyd-osago-8-input"/>)}
                />
              </Form.Item>

              {/* Страховая компания */}
              <Form.Item
                validateStatus={insuranceCompanyError ? "error" : ""}
                help={insuranceCompanyError || ""}
              >
                <div className="hyd-select-small hyd-select-prolongation">
                  {getFieldDecorator(insuranceCompany, {
                    rules: [{required: true, message: " "}]
                  })(
                    <Select
                      className="hyd-input-select-small"
                      style={{width: "100%"}}
                      placeholder="Страховая компания"
                      onChange={(e) => {
                        console.log(e)
                      }}
                    >
                      {insurances && insurances
                        .filter(insurance => insurance["addendum_visible"] === true)
                        .map(({id, short_name, addendum_active}) =>
                          <Option value={id} key={id} disabled={!addendum_active}>
                            <div className={"hyd-select-tooltip"}>Страховая компания</div>
                            <div className={"hyd-select-value"}>{short_name}</div>
                          </Option>
                        )}
                    </Select>
                  )}
                </div>
              </Form.Item>

              {/* Серия полиса */}
              <Form.Item
                validateStatus={policySeriesError ? "error" : ""}
                help={policySeriesError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-addendum-136"
                  htmlFor={"addendum_" + policySeries}
                  labelText={"Серия полиса"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(policySeries, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        message: " "
                      },
                      {
                        pattern: /((^[АВЕКМНОРСТУХавекмнорстух]{3})|(^[ABEKMHOPCTYXabekmhopctyx]{3}))$/g,
                        message: " "
                      },
                    ],
                  })(<Input className="hyd-input-small hyd-addendum-136"
                            maxLength={3}
                            onChange={(e) => {
                              e.target.value = transliterateSeries(e.target.value.toUpperCase())
                            }}/>)}
                />
              </Form.Item>

              {/* Номер полиса */}
              <Form.Item
                validateStatus={policyNumberError ? "error" : ""}
                help={policyNumberError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-mortgage-136"
                  htmlFor={"addendum_" + policyNumber}
                  labelText={"Номер полиса"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(policyNumber, {
                    rules: [
                      {
                        required: true,
                        message: " "
                      },
                      {
                        pattern: /^[0-9]{10}$/g,
                        message: " "
                      },
                    ],
                  })(<Input className="hyd-input-small hyd-addendum-136" maxLength={10}/>)}
                />
              </Form.Item>
            </div>

            <Col>
              <Row>
                <span className="hyd-addendum-subtitle">Тип аддендума</span>
                <Tooltip
                  trigger="hover"
                  title={textAddendumType}
                  overlayClassName={"hyd-tooltip hyd-tooltip-wide-2"}
                >
                  <QuestionIcon className="hyd-q-ico-3"/>
                </Tooltip>
              </Row>

              <Row style={{marginTop: 20}}>
                {getFieldDecorator(addDriver, {})(
                  <Checkbox
                    checked={getFieldValue(addDriver)}
                    className={"hyd-norm-text hyd-addendum-checkbox-left"}
                  >
                    Добавить водителя
                  </Checkbox>
                )}

                {getFieldDecorator(changeNumberSTS, {})(
                  <Checkbox
                    checked={getFieldValue(changeNumberSTS)}
                    className={"hyd-norm-text hyd-addendum-checkbox-right"}
                  >
                    Изменить номер СТС
                  </Checkbox>
                )}
              </Row>

              <Row style={{marginTop: 20}}>
                {getFieldDecorator(deleteDriver, {})(
                  <Checkbox
                    checked={getFieldValue(deleteDriver)}
                    className={"hyd-norm-text hyd-addendum-checkbox-left"}
                  >
                    Удалить водителя
                  </Checkbox>
                )}

                {getFieldDecorator(changeDriverLicense, {})(
                  <Checkbox
                    checked={getFieldValue(changeDriverLicense)}
                    className={"hyd-norm-text hyd-addendum-checkbox-right"}
                  >
                    Замена в/у
                  </Checkbox>
                )}
              </Row>

              <Row style={{marginTop: 20}}>
                {getFieldDecorator(addOrChangeRegPlate, {})(
                  <Checkbox
                    checked={getFieldValue(addOrChangeRegPlate)}
                    className={"hyd-norm-text hyd-addendum-checkbox-left"}
                  >
                    Внести/изменить рег. знак
                  </Checkbox>
                )}

                {getFieldDecorator(changeLastName, {})(
                  <Checkbox
                    checked={getFieldValue(changeLastName)}
                    className={"hyd-norm-text hyd-addendum-checkbox-right"}
                  >
                    Смена фамилии
                  </Checkbox>
                )}
              </Row>

              <Row style={{marginTop: 20}}>
                {getFieldDecorator(prolongationInsurancePeriod, {})(
                  <Checkbox
                    checked={getFieldValue(prolongationInsurancePeriod)}
                    className={"hyd-norm-text hyd-addendum-checkbox-left"}
                  >
                    Продлить период страхования
                  </Checkbox>
                )}

                {getFieldDecorator(changeAddress, {})(
                  <Checkbox
                    checked={getFieldValue(changeAddress)}
                    className={"hyd-norm-text hyd-addendum-checkbox-right"}
                  >
                    Изменить адрес страхователя/собственника
                  </Checkbox>
                )}
              </Row>
            </Col>

            <div style={{marginTop: 40, marginBottom: 20}}>
              <span className="hyd-addendum-subtitle">Примечание</span>
            </div>
            <Form.Item
              validateStatus={noteError ? "error" : ""}
              help={noteError || ""}
            >
              <FloatingLabelInput
                divClassName="casco-wide-tall-input"
                htmlFor={"casco_" + note}
                labelText={"Примечание"}
                divId={"float-label-small"}
                child={getFieldDecorator(note, {
                  validateTrigger: baseTrigger,
                  rules: [
                    {
                      required: false,
                      message: " "
                    },
                  ],
                })(<TextArea maxLength={300} className={"ant-input hyd-input-small"}/>)}
              />
            </Form.Item>

            <span className="hyd-addendum-subtitle">Загрузить документы</span>

            <Row style={{marginTop: 30, marginBottom: 30}}>
              {addendumDocuments.map(item => uploadButton(
                item.text, item.multiple, item.fileList, item.actionUpload, item.actionRemove,
              ))}
            </Row>

            <PersonalDataCheckbox
              getFieldDecorator={getFieldDecorator}
              getFieldValue={getFieldValue}
            />

            <Row>
              <Button
                className="hyd-btn-small"
                type="primary"
                htmlType="submit"
                style={{marginTop: 20}}
                disabled={hasErrors(getFieldsError()) || !getFieldValue(isPDProcessingConsentCheckedKey)}
              >
                Отправить заявку
              </Button>
            </Row>

          </Form>

        </div>
        <ModalError type={1}/>
        <ModalApplication/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  dataForm: dataFormSelector(state),
  insurances: insurancesSelector(state),
  addendumFileList: addendumFileListSelector(state),
  isFormSuccessfullySent: isFormSuccessfullySentSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearForm,
      setDataForm,
      getInsurances,
      uploadAddendumFiles,
      removeAddendumFiles,
      sendAddendumApplication,
      toggleModalAddendum,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  )


const WrappedAddendumForm = Form.create({
    name: "addendum",
    mapPropsToFields(props) {
      const {dataForm} = props;
      const transformed = transform({dataForm});

      return transformed.dataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(Addendum)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAddendumForm)
