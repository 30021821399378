import React, {Component} from "react";
import {
  Layout,
  Form,
  Input,
  Button,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hasErrors} from "../../utils";
import * as keys from '../../routers/keys';
import {signIn, clearIsRequestState, getUserInfo} from "../../actions/loginActions";
import {
  getTools,
  getFavoritesTools,
  getActiveBanners,
  getNotifications,
  checkEarnedMoneyAmount,
} from "../../actions/mainActions";
import {tokenSelector, isRequestSelector} from "../../selectors/loginSelectors";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import Preloader from "../../components/Preloader";
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as LogoIcon} from "../../assets/Icons/logos/LogoAuth.svg";
import Background from '../../images/pexelsFauxels.jpg';
import {getRenewalPoliciesCount} from "../../actions/renewalActions";
import {SEVAINS_URL} from "../../constants";

const {Content} = Layout;

class Auth extends Component {
  componentDidMount() {
    document.title = "SEVA"
    // To disabled submit button at the beginning.
    this.props.clearIsRequestState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      token,
      history,
      getUserInfo,
      getTools,
      getFavoritesTools,
      getActiveBanners,
      getNotifications,
      checkEarnedMoneyAmount,
      getRenewalPoliciesCount,
    } = this.props;
    if (token) {
      getUserInfo()
      getFavoritesTools()
      getTools()
      getActiveBanners()
      getNotifications()
      checkEarnedMoneyAmount()
      getRenewalPoliciesCount()
      history.replace(keys.HOME)
    }
  }

  handleSubmit = e => {
    const {signIn} = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        signIn(values);
      }
    });
  };

  render() {
    const {
      isRequest,
    } = this.props;

    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
    } = this.props.form;


    const username = "username";
    const usernameError = getFieldError(username);
    const password = "password";
    const passwordError = getFieldError(password);
    return (
      <>
        <Preloader loading={isRequest}/>
        <Content
          className="hyd-content-wrapper"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh'
          }}
        >
          <div className="hyd-login-container">

            <div className="hyd-logo-auth">
              <a href={SEVAINS_URL}>
                <LogoIcon/>
              </a>
            </div>

            <Form className="hyd-form hyd-form-auth" onSubmit={this.handleSubmit}>
              {/* Логин */}
              <Form.Item
                validateStatus={usernameError ? "error" : ""}
                help={usernameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"auth_" + username}
                  labelText={"Логин"}
                  always_active
                  divId={"float-label-small"}
                  child={getFieldDecorator(username, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}]
                  })(<Input className="hyd-input-small hyd-input-auth"/>)}
                />
              </Form.Item>

              {/* Пароль */}
              <Form.Item
                validateStatus={passwordError ? "error" : ""}
                help={passwordError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth hyd-auth-no-margin"
                  htmlFor={"auth_" + password}
                  labelText={"Пароль"}
                  always_active
                  divId={"float-label-small"}
                  child={getFieldDecorator(password, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}]
                  })(<Input className="hyd-input-small hyd-input-auth" type="password"/>)}
                />
              </Form.Item>

              <Button
                className="hyd-link-btn-2"
                type="link"
                htmlType="button"
                disabled
                style={{marginBottom: 8}}
              >
                Забыли пароль?
              </Button>

              <Button
                className="hyd-btn-small-2 hyd-btn-auth"
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Войти
              </Button>

            </Form>
          </div>

          <Button
            className="hyd-btn-small-2-white hyd-btn-auth-2"
            onClick={() => {
              const {history} = this.props
              history.replace(keys.REGISTRATION)
            }}
          >
            Зарегистрироваться
          </Button>

        </Content>
        <ModalError type={0}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isRequest: isRequestSelector(state),
  token: tokenSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signIn,
      getUserInfo,
      clearIsRequestState,
      getFavoritesTools,
      getTools,
      getActiveBanners,
      getNotifications,
      checkEarnedMoneyAmount,
      getRenewalPoliciesCount,
    },
    dispatch
  );


const WrappedAuthForm = Form.create({name: 'auth'})(Auth);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAuthForm);
