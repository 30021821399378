export const kkmIdentityDocs = [
  {
    value: 1,
    text: "Паспорт гражданина РФ",
  },
]

export const  currencyOptions = {
  style: "currency",
  currency: "RUB"
}