import React, {Component} from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  Checkbox,
  AutoComplete,
  notification,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hasErrors, nameRulesCyrillic} from "../../utils";
import * as keys from '../../routers/keys';
import {signUp, clearIsRequestState, saveRefId, setRegionId} from "../../actions/registrationActions";
import {
  isRequestSelector,
  refIdSelector,
  regionIdSelector,
  signUpCompleteSelector
} from "../../selectors/registrationSelectors";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import Preloader from "../../components/Preloader";
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as LogoIcon} from "../../assets/Icons/logos/LogoAuth.svg";
import Background from '../../images/pexelsFauxels.jpg';
import Cleave from "cleave.js/react";
import qs from 'qs';

import personalDataProcessing from "../../assets/files/registration/personalDataProcessing.pdf";
import privacyPolicy from "../../assets/files/registration/privacyPolicy.pdf";
import publicOffer from "../../assets/files/registration/publicOffer.pdf";
import {SEVAINS_URL} from "../../constants";
import {regionsSelector} from "../../selectors/profileSelectors";
import {getRegions} from "../../actions/profileActions";

const {Content} = Layout;

class Registration extends Component {
  state = {
    oferta: "Подробное описание оферты ...",
    confidentiality: "Политика в отношении обработки персональных данных ...",
  }

  componentDidMount() {
    const {
      location,
      saveRefId,
      getRegions,
      clearIsRequestState,
    } = this.props
    document.title = "SEVA"
    getRegions()
    clearIsRequestState()  // To disabled submit button at the beginning.
    let ref_id = qs.parse(location.search, { ignoreQueryPrefix: true }).ref_id
    ref_id && saveRefId(ref_id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isRequest, signUpComplete} = this.props;
    if (signUpComplete !== prevProps.signUpComplete && !isRequest && signUpComplete) {
      notification["success"]({
        message: "Вам отправлен email и SMS с параметрами учетной записи.",
        duration: 30,
      })
    }
  }

  handleSubmit = e => {
    const {form, signUp, refId, regionId} = this.props;
    e.preventDefault();

    form.validateFields((err, values) => {
      values.ref_id = refId
      values.region = regionId
      if (!err) {
        signUp(values)
      }
    })
  }

  render() {
    const {
      form,
      regions,
      isRequest,
      setRegionId,
    } = this.props

    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      getFieldError,
    } = form

    const email = "email";
    const emailError = getFieldError(email);
    const phone = "phone";
    const phoneError = getFieldError(phone);
    const firstName = "first_name";
    const firstNameError = getFieldError(firstName);
    const lastName = "last_name";
    const lastNameError = getFieldError(lastName);
    const middleName = "middle_name";
    const middleNameError = getFieldError(middleName);
    const region = "region";
    const regionError = getFieldError(region);
    const isOfertaChecked = "is_oferta_checked";
    const isConfidentialityChecked = "is_confidentiality_checked";
    const isAgreementChecked = "is_agreement_checked";

    return (
      <>
        <Preloader loading={isRequest}/>
        <Content
          className="hyd-content-wrapper"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh'
          }}
        >
          <div className="hyd-registration-container">

            <div className="hyd-logo-auth">
              <a href={SEVAINS_URL}>
                <LogoIcon/>
              </a>
            </div>

            <Form className="hyd-form hyd-form-auth" onSubmit={this.handleSubmit}>

              {/* Email */}
              <Form.Item
                validateStatus={emailError ? "error" : ""}
                help={emailError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + email}
                  labelText={"Email"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(email, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {required: true, message: " "},
                      {
                        pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                        message: " "
                      }
                    ]
                  })(<Input className="hyd-input-small hyd-input-auth"/>)}
                />
              </Form.Item>

              {/* Телефон */}
              <Form.Item
                validateStatus={phoneError ? "error" : ""}
                help={phoneError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + phone}
                  labelText={"Телефон"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(phone, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [
                      {
                        required: true,
                        message: " "
                      },
                      {
                        pattern: /^\+?([0-9]{1})\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                        message: " "
                      },
                    ]
                  })(
                    <Cleave
                      className="ant-input hyd-input-small hyd-input-auth"
                      key={phone}
                      options={{
                        phone: true,
                        prefix: "+7 ",
                        phoneRegionCode: 'RU',
                        noImmediatePrefix: true,
                      }}
                    />
                  )}
                />
              </Form.Item>

              {/* Фамилия */}
              <Form.Item
                validateStatus={lastNameError ? "error" : ""}
                help={lastNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + lastName}
                  labelText={"Фамилия"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(lastName, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: nameRulesCyrillic,
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              {/* Имя */}
              <Form.Item
                validateStatus={firstNameError ? "error" : ""}
                help={firstNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + firstName}
                  labelText={"Имя"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(firstName, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: nameRulesCyrillic,
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              {/* Отчество */}
              <Form.Item
                validateStatus={middleNameError ? "error" : ""}
                help={middleNameError || ""}
                style={{marginBottom: 8}}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + middleName}
                  labelText={"Отчество"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(middleName, {
                    rules: [
                      {
                        // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                        pattern: /^[А-Яа-яЁё() -]+$/g,
                        message: " ",
                      },
                    ],
                  })(
                    <Input
                      className={"ant-input hyd-input-small hyd-input-auth"}
                    />
                  )}
                />
              </Form.Item>

              {/* Регион */}
              <Form.Item
                validateStatus={regionError ? "error" : ""}
                help={regionError || ""}
              >
                <FloatingLabelInput
                  divClassName="hyd-input-auth"
                  htmlFor={"registration_" + region}
                  labelText={"Регион"}
                  divId={"float-label-small"}
                  child={getFieldDecorator(region, {
                    validateTrigger: ["onBlur", "onChange"],
                    rules: [{required: true, message: " "}],
                  })(
                    <AutoComplete
                      className="hyd-input-autocomplete-small"
                      dataSource={regions && regions.map(_ => _.text)}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onSelect={(inputValue) => {
                        let regionID = regions.filter(region => region.text === inputValue)[0].key
                        setRegionId(regionID)
                      }}
                    />
                  )}
                />
              </Form.Item>

              <Button
                className="hyd-btn-small-2 hyd-btn-auth"
                type="primary"
                htmlType="submit"
                disabled={
                  hasErrors(getFieldsError()) ||
                  !getFieldValue(isOfertaChecked) ||
                  !getFieldValue(isAgreementChecked) ||
                  !getFieldValue(isConfidentialityChecked)
                }
                style={{marginBottom: 12}}
              >
                Зарегистрироваться
              </Button>

              <div className="hyd-registration-checkbox">
                {getFieldDecorator(isOfertaChecked, {initialValue: false})(
                  <Checkbox>
                  <span className={"hyd-checkbox-registration-txt"}>
                    Принимаю условия <a
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      href={publicOffer}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{" публичной оферты"}</a>
                  </span>
                  </Checkbox>
                )}
              </div>

              <div className="hyd-registration-checkbox">
                {getFieldDecorator(isConfidentialityChecked, {initialValue: false})(
                  <Checkbox>
                  <span className={"hyd-checkbox-registration-txt"}>
                    Согласен <a
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      href={privacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{" с политикой конфиденциальности"}</a>
                  </span>
                  </Checkbox>
                )}
              </div>

              <div className="hyd-registration-checkbox">
                {getFieldDecorator(isAgreementChecked, {initialValue: false})(
                  <Checkbox>
                  <span className={"hyd-checkbox-registration-txt"}>
                    <a
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      href={personalDataProcessing}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{"Согласие "}</a> на обработку персональных данных
                  </span>
                  </Checkbox>
                )}
              </div>

            </Form>
          </div>

          <Button
            className="hyd-btn-small-2-white hyd-btn-auth-2"
            onClick={() => {
              const {history} = this.props
              history.replace(keys.AUTH)
            }}
          >
            Войти
          </Button>

        </Content>
        <ModalError type={6}/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  refId: refIdSelector(state),
  regions: regionsSelector(state),
  regionId: regionIdSelector(state),
  isRequest: isRequestSelector(state),
  signUpComplete: signUpCompleteSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUp,
      saveRefId,
      getRegions,
      setRegionId,
      clearIsRequestState,
    },
    dispatch
  );


const WrappedRegistrationForm = Form.create({name: 'registration'})(Registration);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
