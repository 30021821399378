import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {
  constructClearedForm,
  constructField,
  frontDateFormat,
  getDecimal,
  mortgageBuildingMaterials,
  mortgageFloorMaterials,
  mortgageInsuranceObjects,
  mortgageMaritalStatuses,
  mortgageObjectTypes,
  validateNulls,
  updateArray,
  validateNullsDelete,
  backDateFormat,
} from "../utils"
import {MORTGAGE} from "../constants"
import moment from "moment";
import {transformCoBorrowers} from "../pages/Mortgage/mortgageUtils";

const policy_holder = "policy_holder"
const property_address_fias_ = "property_address_fias"
const property_address_ = "property_address"
const policyholder_address_ = "policyholder"
const policyholder_address_key = "policyholder_address"
const policyholder_fias_ = "policyholder_fias"
const policyholder_kladr_ = "policyholder_kladr"
const policyholder_city_ = "city"
const policyholder_flat_ = "flat"
const policyholder_house_ = "house"
const policyholder_region_ = "region"
const policyholder_street_ = "street"
const policyholder_zip_code_ = "zip_code"
const policyholder_area_ = "policyholder_area"
const policyholder_block_ = "block"
const policyholder_block_type_ = "block_type"
const fact_address_key = "_fact"
const policyholder_address_fact_ = `${policyholder_address_key}${fact_address_key}`
const policyholder_fias_fact_ = `${policyholder_fias_}${fact_address_key}`
const policyholder_kladr_fact_ = `${policyholder_kladr_}${fact_address_key}`
const policyholder_city_fact_ = `${policyholder_city_}${fact_address_key}`
const policyholder_flat_fact_ = `${policyholder_flat_}${fact_address_key}`
const policyholder_house_fact_ = `${policyholder_house_}${fact_address_key}`
const policyholder_region_fact_ = `${policyholder_region_}${fact_address_key}`
const policyholder_street_fact_ = `${policyholder_street_}${fact_address_key}`
const policyholder_zip_code_fact_ = `${policyholder_zip_code_}${fact_address_key}`
const policyholder_area_fact_ = `${policyholder_area_}${fact_address_key}`
const policyholder_block_fact_ = `${policyholder_block_}${fact_address_key}`
const policyholder_block_type_fact_ = `${policyholder_block_type_}${fact_address_key}`
const property_address_str_ = "address_str"
const property_zip_ = "property_address_zip_code"
const property_region_ = "property_address_region"
const property_region_without_type_ = "property_address_region_without_type"
const property_area_ = "property_area_with_type"
const property_city_ = "property_address_city"
const property_settlement_ = "property_settlement_with_type"
const property_street_ = "property_address_street"
const property_house_type_ = "property_house_type"
const property_house_ = "property_address_house"
const property_block_type_ = "property_block_type"
const property_block_ = "property_block"
const property_flat_type_ = "property_flat_type"
const property_flat_ = "property_address_flat"
const property_room_ = "property_address_room"
const property_corpus_ = "property_address_corpus"
const property_building_ = "property_address_building"

const propertyDict = {
  "zip_code": property_zip_,
  "address_str": property_address_str_,
  "city": property_city_,
  "fias": property_address_fias_,
  "house": property_house_,
  "kladr": "property_address_kladr",
  "region": property_region_,
  "flat": property_flat_,
  "room": property_room_,
  "street": property_street_,
  "construction_year": "construction_year",
  "overhaul_year": "overhaul_year",
  "object_wear": "object_wear",
  "number_of_floors": "floors_num",
  "building_area": "object_area",
  "building_value": "object_cost",
  "cadastr_nr": "cadastr_nr",
}

const addressDataArray = [
  property_address_,
  property_zip_,
  property_region_,
  property_region_without_type_,
  property_city_,
  property_address_fias_,
  property_area_,
  property_settlement_,
  property_flat_type_,
  property_block_,
  property_block_type_,
  property_house_type_,
  property_street_,
  property_house_,
  property_corpus_,
  property_building_,
  property_flat_,
  property_room_,
  property_address_str_,
]

function clearFormStep3(dataForm) {
  const arrayOfData = [
    "start_policy_date",
    "finish_policy_date",
    "application_date",
    "object_cost",
    "object_wear",
    "floors_num",
    "object_area",
    "cadastr_nr",
    "construction_year",
    "overhaul_year",
  ]
  arrayOfData.push(addressDataArray)

  return constructClearedForm(dataForm, arrayOfData)
}

function clearPolicyholderAddress(dataForm) {
  let addressDataArrayPolicyholder = [
    policyholder_address_key,
    policyholder_city_,
    policyholder_fias_,
    policyholder_flat_,
    policyholder_house_,
    policyholder_kladr_,
    policyholder_region_,
    policyholder_street_,
    policyholder_zip_code_,
  ]
  return constructClearedForm(dataForm, addressDataArrayPolicyholder)
}

function clearPolicyholderFactAddress(dataForm) {
  let addressDataArrayPolicyholder = [
    policyholder_address_fact_,
    policyholder_city_fact_,
    policyholder_fias_fact_,
    policyholder_flat_fact_,
    policyholder_house_fact_,
    policyholder_kladr_fact_,
    policyholder_region_fact_,
    policyholder_street_fact_,
    policyholder_zip_code_fact_,
  ]
  return constructClearedForm(dataForm, addressDataArrayPolicyholder)
}

function clearObjectAddress(dataForm) {
  return constructClearedForm(dataForm, addressDataArray)
}

function constructAddressStr(
  property_region: string,
  property_area: string,
  property_city: string,
  property_settlement: string,
  property_street: string,
  property_house_type: string,
  property_house: string,
  property_flat_type: string,
  property_flat: string,
  property_block_type: string = undefined,
  property_block: string = undefined,
  property_corpus: string = undefined,
  property_building: string = undefined,
  property_room: string = undefined,
) {

  let region = property_region ? `${property_region}, ` : ``
  let area = property_area ? `${property_area}, ` : ``
  let settlement = property_settlement ? `${property_settlement}, ` : ``
  let city = property_city ? `${property_city}, ` : ``
  let street = property_street && property_street !== "-" ? `${property_street}, ` : ``
  let house_type = property_house_type ? `${property_house_type}. ` : ``
  let house = property_house ? `${house_type}${property_house}, ` : ``
  let block_type = property_block_type ? `${property_block_type}. ` : ``
  let block = property_block ? `${block_type}${property_block}, ` : ``
  let flat_type = property_flat_type ? `${property_flat_type}. ` : ``
  let flat = property_flat ? `${flat_type}${property_flat}` : ``
  let room = property_room ? `, ${property_room}` : ``

  if (city === area || city === region || city === settlement) {
    city = ``
  }

  if (settlement === street) {
    street = ``
  }

  let corpus = ``
  let building = ``
  if (!block) {
    corpus = property_corpus ? `корп. ${property_corpus}, ` : ``
    building = property_building ? `стр. ${property_building}, ` : ``
  }

  return `${region}${area}${city}${settlement}${street}${house}${corpus}${building}${block}${flat}${room}`
}

function constructCoBorrowerAddressData(data, addressDataMap) {
  validateNullsDelete(data)
  let addressData = {}
  for (let item in data) {
    if (data[item] && Object.keys(addressDataMap).includes(item)) {
      addressData[addressDataMap[item]] = constructField(addressDataMap[item], data[item])
    }
  }
  validateNullsDelete(addressData)
  return addressData
}

function constructAddressData(data, addressDataMap, type = policyholder_address_) {
  validateNullsDelete(data)
  let addressData = {}
  for (let item in data) {
    if (data[item] && Object.keys(addressDataMap).includes(item)) {
      addressData[addressDataMap[item]] = constructField(addressDataMap[item], data[item])
    }
  }

  if (type === property_address_) {
    let addressStr = constructAddressStr(
      addressData[property_region_] && addressData[property_region_].value,
      addressData[property_area_] && addressData[property_area_].value,
      addressData[property_city_] && addressData[property_city_].value,
      addressData[property_settlement_] && addressData[property_settlement_].value,
      addressData[property_street_] && addressData[property_street_].value,
      addressData[property_house_type_] && addressData[property_house_type_].value,
      addressData[property_house_] && addressData[property_house_].value,
      addressData[property_flat_type_] && addressData[property_flat_type_].value,
      addressData[property_flat_] && addressData[property_flat_].value,
      addressData[property_block_type_] && addressData[property_block_type_].value,
      addressData[property_block_] && addressData[property_block_].value,
    )
    addressData[property_address_str_] = constructField(property_address_str_, addressStr)
  }

  if (!data["street"]) {
    addressData[`${type}_street`] = constructField(`${type}_street`, "-")
  }

  if (!data["flat"]) {
    addressData[`${type}_flat`] = constructField(`${type}_flat`, 0)
  }

  if (data["settlement_with_type"] && (data["region_with_type"] === data["city_with_type"] || data["area_with_type"])) {
    addressData[`${type}_city`] = constructField(`${type}_city`, `${data["area_with_type"]}, ${data["settlement_with_type"]}`)
  } else if (data["city_with_type"] && data["settlement_with_type"]) {
    addressData[`${type}_city`] = constructField(`${type}_city`, `${data["city_with_type"]}, ${data["settlement_with_type"]}`)
  } else if (data["settlement_with_type"]) {
    addressData[`${type}_city`] = constructField(`${type}_city`, data["settlement_with_type"])
  }

  if (data["block"]) {
    if (data["block_type_full"] === "корпус") {
      addressData[property_corpus_] = constructField(property_corpus_, data["block"])
    }
    if (data["block_type_full"] === "строение") {
      addressData[property_building_] = constructField(property_building_, data["block"])
    }
    if (data["block"].includes("стр")) {
      let corp_build = data["block"].split(" стр ")
      addressData[property_corpus_] = constructField(addressDataMap[property_corpus_], corp_build[0])
      addressData[property_building_] = constructField(addressDataMap[property_building_], corp_build[1])
    }
  }

  addressData[`${type}_kladr`] = constructField(`${type}_kladr`, data["value_kladr"].slice(0, 13))
  addressData[`${type}_block`] = constructField(`${type}_block`, data["block"])
  addressData[`${type}_block_type`] = constructField(`${type}_block_type`, data["block_type"])

  validateNullsDelete(addressData)
  return addressData
}

function transformPrecalculateDataForm(dataForm, fiasId) {
  let isLifeRisk = dataForm.is_life_risk.value
  let isPropertyRisk = dataForm.is_property_risk.value

  let dataFormResult = {}

  let policyHolder = {
    "value": {
      "birth_date": isLifeRisk ? dataForm.birth_date.value : undefined,
      "gender": dataForm.gender.value,
      "fias": fiasId,
    }
  }

  if (isPropertyRisk) {
    let selectedPropertyType = mortgageObjectTypes.find(
      (element) => element.value === dataForm.property_type.value
    )
    dataFormResult["insured_property"] = {
      value: {
        property_type_code: selectedPropertyType.value_back,
      },
    }
  } else {
    dataFormResult["insured_property"] = null
  }

  dataFormResult[policy_holder] = policyHolder
  dataFormResult["insurance_city"] = dataForm.insurance_city.value
  dataFormResult["is_life_risk"] = isLifeRisk
  dataFormResult["is_property_risk"] = isPropertyRisk
  dataFormResult["rate"] = getDecimal(dataForm.rate.value)
  dataFormResult["loan_amount"] = getDecimal(dataForm.loan_amount.value)
  dataFormResult["mortgage_bank"] = dataForm.mortgage_bank.value
  dataFormResult["percentage_of_osz"] = dataForm.percentage_of_osz.value
  dataFormResult["start_policy_date"] = moment().endOf("date").add(1, "d").format(frontDateFormat)
  dataFormResult["finish_policy_date"] = moment().add(1, "Y").format(frontDateFormat)
  dataFormResult["mortgage_contract_start_date"] = dataForm.mortgage_contract_start_date.value
  dataFormResult["loan_term_in_month"] = parseInt(dataForm.loan_term_in_month.value)
  dataFormResult["number_of_floors"] = parseInt(dataForm.number_of_floors.value)
  dataFormResult["insurance_city_fias"] = fiasId

  return dataFormResult
}

function transformApplicationDataForm(dataForm, state) {
  let dataFormResult = {}
  let passportFileList = state.passportFileList
  let loanAgreementFileList = state.loanAgreementFileList
  let evaluationReportFileList = state.evaluationReportFileList
  let EGRNFileList = state.EGRNFileList
  let selectedPropertyType = mortgageObjectTypes.find(
    (element) => element.value === dataForm.property_type.value
  )

  dataFormResult["passport"] = passportFileList
  dataFormResult["loan_agreement"] = loanAgreementFileList
  dataFormResult["evaluation_report"] = evaluationReportFileList
  dataFormResult["EGRN"] = EGRNFileList
  dataFormResult["policyholder_full_name"] = dataForm.policyholder_full_name.value
  dataFormResult["note"] = dataForm.note.value
  dataFormResult["loan_amount"] = getDecimal(dataForm.loan_amount.value)
  dataFormResult["rate"] = getDecimal(dataForm.rate.value)
  dataFormResult["property_type"] = selectedPropertyType.value_back
  dataFormResult["ceiling"] = dataForm.application_is_wooden_floors.value
  dataFormResult["gas_pump"] = dataForm.application_is_gas.value
  dataFormResult["life_insurance"] = dataForm.application_is_life_and_health_insurance.value
  dataFormResult["property_insurance"] = dataForm.application_is_property_insurance.value
  dataFormResult["title_insurance"] = dataForm.application_is_title_plus_property.value
  dataFormResult["profession"] = dataForm.profession ? dataForm.profession.value : ""
  dataFormResult["bank"] = dataForm.mortgage_bank.value
  dataFormResult["loan_agreement_issue_date"] = dataForm.loan_agreement_issue_date &&
    dataForm.loan_agreement_issue_date.value ?
      moment(dataForm.loan_agreement_issue_date.value, frontDateFormat).format(backDateFormat) : null

  return dataFormResult
}

function transformDataForm(dataForm, fiasId, coBorrowersKeys = undefined) {
  let dataFormResult = {}

  let block_type = dataForm.block_type.value !== undefined ? dataForm.block_type.value : ""
  let block = dataForm.block.value !== undefined ? dataForm.block.value : ""
  let house_with_block = `${dataForm.house.value} ${block_type} ${block}`
  let policyHolder = {
    "value": {
      "first_name": dataForm.first_name.value,
      "last_name": dataForm.last_name.value,
      "middle_name": dataForm.middle_name.value,
      "birth_date": dataForm.birth_date.value,
      "birth_place": dataForm.birth_place.value,
      "gender": dataForm.gender.value,
      "family_state": mortgageMaritalStatuses.find(e => dataForm.marital_status.value === e.value).value_back,
      "phone": dataForm.phone.value.replace(/\s+/g, ""),
      "email": dataForm.email.value,
      "document_series": dataForm.document_series.value.replace(/\s+/g, ""),
      "document_number": dataForm.document_number.value,
      "document_issue_date": dataForm.document_issue_date.value,
      "document_issuer": dataForm.document_issuer.value,
      "division_code": dataForm.division_code.value,
      "citizenship": "RU",
      "address_type": "registration",
      "street": dataForm.street.value,
      "house": house_with_block,
      "flat": dataForm.flat.value,
      "zip_code": dataForm.zip_code.value,
      "kladr": dataForm.policyholder_kladr.value,
      "employment_status": dataForm.employment_status.value,
      "health_abnormalities": dataForm.health_abnormalities.value,
      "place_of_work_address": "городок",
      "is_public_official": !dataForm.not_public_official.value,
      "registration_address": dataForm.policyholder_address.value,
      "is_fact_address": dataForm.is_fact_address.value,
      "fias": dataForm.policyholder_fias.value,
      "fact_address": dataForm.policyholder_address_fact.value,
      "fias_fact": dataForm.policyholder_fias_fact.value,
    }
  }

  let isLifeRisk = dataForm.is_life_risk.value
  let isPropertyRisk = dataForm.is_property_risk.value

  if (isLifeRisk) {
    dataFormResult["insured_person"] = [
      {
        ...policyHolder.value,
        "risk_start_date": dataForm.start_policy_date.value,
        "risk_end_date": dataForm.finish_policy_date.value
      }
    ]
    dataFormResult["insured_property"] = null
  }
  if (isPropertyRisk) {
    let property_block_type = dataForm.property_block_type.value !== undefined ? dataForm.property_block_type.value : ""
    let property_block = dataForm.property_block.value !== undefined ? dataForm.property_block.value : ""
    let house_with_block = `${dataForm.property_address_house.value} ${property_block_type} ${property_block}`
    dataFormResult["insured_property"] = {
      "value": {
        "cover_code": mortgageInsuranceObjects.find(e => dataForm.insurance_object.value === e.value).value_back,
        "risk_start_date": dataForm.start_policy_date.value,
        "risk_end_date": dataForm.finish_policy_date.value,
        "zip_code": dataForm.property_address_zip_code.value,
        "address_str": dataForm.address_str.value,
        "address": dataForm.property_address.value,
        "city": dataForm.property_address_city.value,
        "fias": dataForm.property_address_fias.value,
        "house": house_with_block,
        "kladr": dataForm.property_address_kladr.value,
        "region": dataForm.property_address_region_without_type ? dataForm.property_address_region_without_type.value : "",
        "flat": dataForm.property_address_flat ? dataForm.property_address_flat.value : "",
        "room": dataForm.property_address_room ? dataForm.property_address_room.value : null,
        "street": dataForm.property_address_street.value,
        "construction_year": dataForm.construction_year.value,
        "overhaul_year": dataForm.overhaul_year ? dataForm.overhaul_year.value : "",
        "object_wear": dataForm.object_wear ? dataForm.object_wear.value : "",
        "building_area": getDecimal(dataForm.object_area.value),
        "building_value": getDecimal(dataForm.object_cost.value),
        "property_type_code": mortgageObjectTypes.find(e => dataForm.property_type.value === e.value).value_back,
        "ceiling_material": mortgageFloorMaterials.find(e => dataForm.floor_material.value === e.value).value_back,
        "walls_material": mortgageBuildingMaterials.find(e => dataForm.building_material.value === e.value).value_back,
        "number_of_floors": dataForm.floors_num ? dataForm.floors_num.value : null,
        "cadastr_nr": dataForm.cadastr_nr.value,
        "property_location": "in a building",
      }
    }
  }

  dataFormResult["borrower_person"] = [
    {
      ...policyHolder.value,
      "percent": dataForm.property_share.value
    }
  ]
  dataFormResult[policy_holder] = policyHolder
  dataFormResult[policyholder_city_] = dataForm.city.value || dataForm.policyholder_city.value
  dataFormResult[policyholder_region_] = dataForm.region.value
  dataFormResult["rate"] = getDecimal(dataForm.rate.value)
  dataFormResult["loan_amount"] = getDecimal(dataForm.loan_amount.value)
  dataFormResult["percentage_of_osz"] = dataForm.percentage_of_osz.value
  dataFormResult["contract_number"] = dataForm.contract_number.value
  dataFormResult["mortgage_contract_start_date"] = dataForm.mortgage_contract_start_date.value
  dataFormResult["loan_term_in_month"] = parseInt(dataForm.loan_term_in_month.value)
  dataFormResult["start_policy_date"] = dataForm.start_policy_date.value
  dataFormResult["finish_policy_date"] = dataForm.finish_policy_date.value
  dataFormResult["application_date"] = dataForm.application_date.value
  dataFormResult["profession"] = dataForm.profession && dataForm.profession.value
  dataFormResult["seller"] = dataForm.seller && dataForm.seller.value
  dataFormResult["mortgage_bank"] = dataForm.mortgage_bank.value
  dataFormResult["city"] = dataForm.insurance_city.value
  dataFormResult["insurance_city_fias"] = fiasId

  dataFormResult["is_life_risk"] = isLifeRisk
  dataFormResult["is_property_risk"] = isPropertyRisk

  if (coBorrowersKeys && coBorrowersKeys.length) {
    dataFormResult["co_insurers"] = transformCoBorrowers(dataForm, coBorrowersKeys)
  }

  return dataFormResult
}

function constructDataForm(data, is_delete) {
  if (is_delete) {
    validateNullsDelete(data)
  } else {
    validateNulls(data)
  }
  let dataForm = {...initialState.mortgageReducer.mortgageDataForm}
  for (let item in data) {
    if (data[item] === "" && dataForm[item] !== undefined) {

    } else if (["loan_amount", "rate"].includes(item)) {
      dataForm[item] = constructField(item, data[item].replace(".", ","))
    } else {
      dataForm[item] = constructField(item, data[item])
    }
  }

  for (let item in data[policy_holder]) {
    if (data[policy_holder][item] === "" && dataForm[item] !== undefined) {

    } else {
      if (item === "document_series") {
        dataForm[item] = constructField(item, data[policy_holder][item].replace(/(\d{2})(\d{2})/, "$1 $2"))
      } else if (item === "is_fact_address") {
        let regAddress = data[policy_holder]["registration_address"]
        let fias = data[policy_holder]["fias"]
        dataForm[item] = constructField(item, parseInt(data[policy_holder][item]))
        dataForm["policyholder_address_fact"] = constructField("policyholder_address_fact", regAddress)
        dataForm["policyholder_fias_fact"] = constructField("policyholder_fias_fact", fias)
        dataForm["policyholder_address"] = constructField("policyholder_address", regAddress)
        dataForm["policyholder_fias"] = constructField("policyholder_fias", fias)
        delete data[policy_holder]["fact_address"]
        delete data[policy_holder]["fias_fact"]
      } else {
        dataForm[item] = constructField(item, data[policy_holder][item])
      }
    }

    dataForm["marital_status"] = constructField(
      "marital_status",
      mortgageMaritalStatuses.find(e => data[policy_holder]["family_state"] === e.value_back).value
    )
  }

  dataForm["application_date"] = constructField("application_date", new Date().toLocaleDateString("ru-RU"))
  dataForm[policyholder_kladr_] = constructField(policyholder_kladr_, data[policy_holder]["kladr"])
  dataForm[policyholder_fias_] = constructField(policyholder_fias_, data[policy_holder]["fias"])

  if (data["is_property_risk"]) {
    for (let item in data["insured_property"]) {
      if (data["insured_property"][item] === "" && dataForm[item] !== undefined) {

      } else if (["building_area", "building_value"].includes(item)) {
        dataForm[propertyDict[item]] = constructField(propertyDict[item], data["insured_property"][item].toString().replace(".", ","))
      } else if (["zip_code"].includes(item)) {
        dataForm[propertyDict[item]] = constructField(propertyDict[item], data["insured_property"][item].toString())
      } else if (["region"].includes(item)) {
        dataForm["property_address_region_without_type"] = constructField("property_address_region_without_type", data["insured_property"][item].toString())
        dataForm[propertyDict[item]] = constructField(propertyDict[item], data["insured_property"][item])
      } else {
        dataForm[propertyDict[item]] = constructField(propertyDict[item], data["insured_property"][item])
      }
    }

    dataForm["insurance_object"] = constructField(
      "insurance_object",
      mortgageInsuranceObjects.find(e => data["insured_property"]["cover_code"] === e.value_back).value
    )
    dataForm["floor_material"] = constructField(
      "floor_material",
      mortgageFloorMaterials.find(e => data["insured_property"]["ceiling_material"] === e.value_back).value
    )
    dataForm["building_material"] = constructField(
      "building_material",
      mortgageBuildingMaterials.find(e => data["insured_property"]["walls_material"] === e.value_back).value
    )
    dataForm["property_type"] = constructField(
      "property_type",
      mortgageObjectTypes.find(e => data["insured_property"]["property_type_code"] === e.value_back).value
    )
    dataForm["property_address"] = constructField("property_address", data["insured_property"]["address"])
  }

  let coInsurers = data["co_insurers"]
  if (coInsurers) {
    dataForm["coInsurersKeys"] = Object.keys(coInsurers)
    dataForm["coInsurersQuantity"] = coInsurers.length
    coInsurers.forEach((obj, index) => {
      dataForm[`identity_doc_${index}`] = constructField(`identity_doc_${index}`, 1)
      for (let key in obj) {
        if (key === "is_fact_address") {
          dataForm[`${key}_${index}`] = constructField(`${key}_${index}`, parseInt(obj[key]))
          dataForm[`fact_address_${index}`] = constructField(`fact_address_${index}`, obj["registration_address"])
          dataForm[`fias_fact_${index}`] = constructField(`fias_fact_${index}`, obj["fias"])
          delete obj["fact_address"]
          delete obj["fias_fact"]
        } else if (key === "document_series") {
          dataForm[`${key}_${index}`] = constructField(`${key}_${index}`, obj[key].replace(/(\d{2})(\d{2})/, "$1 $2"))
        } else {
          dataForm[`${key}_${index}`] = constructField(`${key}_${index}`, obj[key])
        }
      }
    })
  }

  dataForm["insurance_city"] = constructField("insurance_city", data["city"])
  dataForm["property_share"] = constructField("property_share", data["borrower_person"][0]["percent"])

  return dataForm
}

export default injectReducer(initialState.mortgageReducer, {

  [types.SET_DATA_FORM_MORTGAGE]: (state, action) => ({
    ...state,
    mortgageDataForm: {...state.mortgageDataForm, ...action.payload.data}
  }),

  [types.GO_TO_NEXT_STEP_MORTGAGE]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.GO_TO_PREV_STEP_MORTGAGE]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.mortgageReducer
  }),

  [types.GET_ONE_TIME_TOKEN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModalMortgage: payload
  }),

  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 7) {
      return {
        ...state,
        ...initialState.mortgageReducer
      };
    } else {
      return {
        ...state
      };
    }
  },

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.SEND_DATA_FORM_MORTGAGE]: state => {
    let stateMortgageDataForm = state.mortgageDataForm
    let coBorrowersKeys = state.coBorrowersKeys

    let dataFormResult = transformDataForm(stateMortgageDataForm, state.fiasId, coBorrowersKeys)

    return {
      ...state,
      dataFormResult: {...dataFormResult, "draft_id": stateMortgageDataForm.draft_id},
      calcResult: [],
      calculationComplete: false,
    };
  },

  [types.SEND_DATA_FORM_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.SEND_DATA_FORM_MORTGAGE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      calcResult: payload,
      errors: null
    };
  },

  [types.SEND_DATA_FORM_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    dataFormResult: undefined,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),


  [types.CLEAR_PRECALCULATE_RESULT_MORTGAGE]: state => {

    return {
      ...state,
      precalculateResult: [],
    }
  },

  [types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE]: state => {
    let stateMortgageDataForm = state.mortgageDataForm
    let precalculateDataFormResult = transformPrecalculateDataForm(stateMortgageDataForm, state.fiasId)

    return {
      ...state,
      precalculateDataFormResult: {...precalculateDataFormResult},
      precalculateResult: [],
      calculationComplete: false,
    }
  },

  [types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      precalculateResult: payload,
    }
  },

  [types.SEND_PRECALCULATE_DATA_FORM_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true,
    precalculateDataFormResult: undefined,
  }),

  [types.ADD_PRECALCULATE_RESULT_MORTGAGE]: (state, {payload}) => {
    const statePrecalculateResult = [...state.precalculateResult]
    let precalculateResult = updateArray(statePrecalculateResult, payload.result)
    return {
      ...state,
      precalculateResult: precalculateResult,
      calculationInProgress: false,
    }
  },

  [types.START_PRECALCULATE_MORTGAGE]: (state) => ({
    ...state,
    calculationInProgress: true,
  }),

  [types.PRECALCULATE_COMPLETE_MORTGAGE]: (state) => ({
    ...state,
    calculationComplete: true,
  }),

  [types.ERROR_PRECALCULATE_MORTGAGE]: (state, {payload}) => ({
    ...state,
    errors: {errors: payload.errors},
  }),

  [types.GET_LASTNAME_ARRAY_MORTGAGE]: (state, action) => ({
    ...state,
    isRequest: false,
    fio: action.fio,
    errors: null
  }),
  [types.GET_LASTNAME_ARRAY_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_LASTNAME_ARRAY_MORTGAGE_SUCCESS]: (state, action) => {
    let lastName = []; //action.payload;
    let gender = undefined;

    let j = 0;
    action.payload.map((item) => {
      if (state.fio === "surname" && item.surname) {
        lastName[j] = {
          text: item.surname,
          value: item.surname,
          gender: item.gender
        }
        j++
      } else if (state.fio === "name" && item.name) {
        lastName[j] = {
          text: item.name,
          value: item.name,
          gender: item.gender
        }
        j++
      } else if (state.fio === "patronymic" && item.patronymic) {
        lastName[j] = {
          text: item.patronymic,
          value: item.patronymic,
          gender: item.gender
        }
        j++
      }
    })

    if (state.fio === "surname") {
      return {
        ...state,
        isRequest: false,
        lastNameArr: lastName,
        genGender: gender,
        errors: null
      }
    } else if (state.fio === "name") {
      return {
        ...state,
        isRequest: false,
        firstNameArr: lastName,
        genGender: gender,
        errors: null
      }
    } else if (state.fio === "patronymic") {
      return {
        ...state,
        isRequest: false,
        patronymicArr: lastName,
        genGender: gender,
        errors: null
      }
    } else {
      return {
        ...state,
        isRequest: false,
        errors: null
      }
    }
  },

  [types.GET_LASTNAME_ARRAY_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
  }),

  [types.DEFINE_GENDER_MORTGAGE]: (state, {payload}) => {
    let gen = undefined;
    let arr_name = undefined;
    if (payload.type === "lastName") {
      arr_name = state.lastNameArr
    } else if (payload.type === "firstName") {
      arr_name = state.firstNameArr
    }
    arr_name && arr_name.map((item,) => {
      if (payload.type === "lastName") {
        if (payload.value === item.text) {
          gen = item.gender
        }
      } else if (payload.type === "firstName") {
        if (payload.value === item.text) {
          gen = item.gender
        }
      } else if (payload.type === "patronymic") {
        if (payload.value === item.text) {
          gen = item.gender
        }
      } else {
        gen = undefined;
      }
    });

    return {
      ...state,
      isRequest: false,
      genGender: gen,
      errors: null
    };
  },

  [types.MORTGAGE_SELECTED_REG_ADDRESS]: (state, {payload}) => {
    return {
      ...state,
      selectedRegAddress: payload
    }
  },

  [types.CLEAR_POLICYHOLDER_ADDRESS_DATA_MORTGAGE]: state => {
    let dataFormResult = clearPolicyholderAddress(state.mortgageDataForm);
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.SAVE_POLICYHOLDER_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let dataMap = {
      "zip": policyholder_zip_code_,
      "region": policyholder_region_,
      "area_with_type": policyholder_area_,
      "city_with_type": policyholder_city_,
      "street": policyholder_street_,
      "house": policyholder_house_,
      "block": policyholder_block_,
      "block_type": policyholder_block_type_,
      "flat": policyholder_flat_,
      "fias_id": policyholder_fias_,
      "address": policyholder_address_key,
    }

    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {...state.mortgageDataForm, ...constructAddressData(payload, dataMap)},
      errors: null
    };
  },

  [types.CLEAR_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE]: state => {
    let dataFormResult = clearPolicyholderFactAddress(state.mortgageDataForm);
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.SAVE_POLICYHOLDER_FACT_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let dataMap = {
      "zip": policyholder_zip_code_fact_,
      "region_with_type": policyholder_region_fact_,
      "area_with_type": policyholder_area_fact_,
      "city_with_type": policyholder_city_fact_,
      "street": policyholder_street_fact_,
      "house": policyholder_house_fact_,
      "block": policyholder_block_fact_,
      "block_type": policyholder_block_type_fact_,
      "flat": policyholder_flat_fact_,
      "fias_id": policyholder_fias_fact_,
      "address": policyholder_address_fact_,
    }

    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {...state.mortgageDataForm, ...constructAddressData(payload, dataMap, policyholder_address_fact_)},
      errors: null
    };
  },

  [types.CLEAR_CO_BORROWER_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let addressData = [
      `fias_${payload.k}`,
      `registration_address_${payload.k}`,
    ]
    let dataFormResult = constructClearedForm(state.mortgageDataForm, addressData)
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.SAVE_CO_BORROWER_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let dataMap = {
      "fias_id": `fias_${payload.k}`,
      "address": `registration_address_${payload.k}`,
    }

    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {
        ...state.mortgageDataForm,
        ...constructCoBorrowerAddressData(payload.value, dataMap)
      },
      errors: null
    };
  },

  [types.CLEAR_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let addressData = [
      `fias_fact_${payload.k}`,
      `fact_address_${payload.k}`,
    ]
    let dataFormResult = constructClearedForm(state.mortgageDataForm, addressData)
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.SAVE_CO_BORROWER_FACT_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let dataMap = {
      "fias_id": `fias_fact_${payload.k}`,
      "address": `fact_address_${payload.k}`,
    }

    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {
        ...state.mortgageDataForm,
        ...constructCoBorrowerAddressData(payload.value, dataMap)
      },
      errors: null
    };
  },

  [types.GET_ADDRESS_DATA]: (state, {address_type}) => {
    return ({
      ...state,
      requestedAddressType: address_type,
    })
  },

  [types.GET_ADDRESS_DATA_REQUEST]: state => {
    return ({
      ...state,
      isRequest: true,
      errors: null,
    })
  },

  [types.GET_ADDRESS_DATA_SUCCESS]: (state, {payload}) => {
    let address = payload

    payload.map((item, i) => {
      address[i].city_kladr = item.city_kladr;
      address[i].value_kladr = item.settlement_kladr_id && item.settlement_kladr_id.length === 13 ? item.settlement_kladr_id :
        item.city_kladr_id ? item.city_kladr_id :
          item.area_kladr_id ? item.area_kladr_id :
            item.region_kladr_id;
      address[i].value = item.text
    })

    let addressArr
    if (state.requestedAddressType === "fact") {
      addressArr = {
        factAddressArr: address,
      }
    } else if (state.requestedAddressType === "reg") {
      addressArr = {
        regAddressArr: address,
      }
    } else if (state.requestedAddressType === "property") {
      addressArr = {
        objectAddressArr: address,
      }
    }

    return {
      ...state,
      isRequest: false,
      errors: null,
      ...addressArr
    }
  },

  [types.GET_ADDRESS_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors.error},
    visibleErrorModalMortgage: true,
    requestedAddressType: null,
  }),

  [types.CLEAR_OBJECT_ADDRESS_DATA_MORTGAGE]: state => {
    let dataFormResult = clearObjectAddress(state.mortgageDataForm);
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.SAVE_OBJECT_ADDRESS_DATA_MORTGAGE]: (state, {payload}) => {
    let dataMap = {
      "zip": property_zip_,
      "region_with_type": property_region_,
      "region": property_region_without_type_,
      "city_with_type": property_city_,
      "street": property_street_,
      "house": property_house_,
      "flat": property_flat_,
      "fias_id": property_address_fias_,
      "address": property_address_,
      "area_with_type": property_area_,
      "settlement_with_type": property_settlement_,
      "flat_type": property_flat_type_,
      "block": property_block_,
      "block_type": property_block_type_,
      "house_type": property_house_type_,
    }

    let dataForm = state.mortgageDataForm
    let constructedAddressData = constructAddressData(payload, dataMap, property_address_)

    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {...dataForm, ...constructedAddressData},
      errors: null
    };
  },

  [types.CLEAR_FORM_STEP_3]: state => {
    let dataFormResult = clearFormStep3(state.mortgageDataForm);
    return {
      ...state,
      mortgageDataForm: dataFormResult
    };
  },

  [types.RE_CONSTRUCT_ADDRESS_STR]: (state, {value, name}) => {
    let dataForm = state.mortgageDataForm

    dataForm["address_str"].value = constructAddressStr(
      name === property_region_ ? value : dataForm[property_region_] && dataForm[property_region_].value,
      name === property_area_ ? value : dataForm[property_area_] && dataForm[property_area_].value,
      name === property_city_ ? value : dataForm[property_city_] && dataForm[property_city_].value,
      name === property_settlement_ ? value : dataForm[property_settlement_] && dataForm[property_settlement_].value,
      name === property_street_ ? value : dataForm[property_street_] && dataForm[property_street_].value,
      name === property_house_type_ ? value : dataForm[property_house_type_] && dataForm[property_house_type_].value,
      name === property_house_ ? value : dataForm[property_house_] && dataForm[property_house_].value,
      name === property_flat_type_ ? value : dataForm[property_flat_type_] && dataForm[property_flat_type_].value,
      name === property_flat_ ? value : dataForm[property_flat_] && dataForm[property_flat_].value,
      "",
      "",
      name === property_corpus_ ? value : dataForm[property_corpus_] && dataForm[property_corpus_].value,
      name === property_building_ ? value : dataForm[property_building_] && dataForm[property_building_].value,
      name === property_room_ ? value : dataForm[property_room_] && dataForm[property_room_].value,
    )

    return {
      ...state,
      mortgageDataForm: dataForm
    };
  },

  [types.ADD_CALC_RESULT_MORTGAGE]: (state, {payload}) => {
    return {
      ...state,
      calcResult: [...state.calcResult, payload.result],
    };
  },

  [types.CALC_ERROR_MORTGAGE]: (state, {payload}) => ({
    ...state,
    errors: {errors: payload.errors},
    calculationInProgress: false,
    visibleErrorModalMortgage: true
  }),

  [types.GET_ONE_TIME_TOKEN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalMortgage: true
  }),

  [types.ASSIGN_DRAFT_ID_MORTGAGE]: (state, {payload}) => {
    const draft_id = payload.draft_id
    return {
      ...state,
      mortgageDataForm: {
        ...state.mortgageDataForm,
        draft_id,
      },
      dataFormResult: {
        ...state.dataFormResult,
        draft_id,
      },
    }
  },

  [types.START_CALCULATION_MORTGAGE]: (state) => ({
    ...state,
    currentStep: 3,
    calculationInProgress: true
  }),

  [types.CALC_COMPLETE_MORTGAGE]: (state) => ({
    ...state,
    calculationInProgress: false,
    calculationComplete: true,
  }),

  [types.CREATE_POLICY_MORTGAGE]: state => {
    return {
      ...state,
      dataFormResult: state.dataFormResult,
      urlDownloadPdf: undefined,
      policyCreated: false,
    };
  },
  [types.CREATE_POLICY_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    creatingPolicy: true,
    errors: null,
  }),

  [types.CREATE_POLICY_MORTGAGE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      policyCreated: true,
      isRequest: false,
      creatingPolicy: false,
      documentsPolicy: payload,
      calc_id: payload.calc_id,
      errors: null,
      documents: payload.documents,
      insuranceDocuments: undefined,
      policyObjId: payload.policyObjId,
      policy_id: payload.policy_id,
    }
  },

  [types.CREATE_POLICY_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    policyCreated: false,
    creatingPolicy: false,
    errors: payload,
    visibleErrorModalMortgage: true
  }),

  [types.GET_PAYMENT_STATUS_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    paymentStatus: undefined,
    errors: null,
  }),

  [types.GET_PAYMENT_STATUS_MORTGAGE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      paymentStatus: payload.payment_status,
      documents: payload.documents && payload.documents.length > 0 ? payload.documents : state.documents,
      errors: null,
    };
  },

  [types.GET_PAYMENT_STATUS_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentStatus: undefined,
    errors: payload,
    visibleErrorModalMortgage: true,
  }),

  [types.PAYMENT_LINK_TO_EMAIL_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.PAYMENT_LINK_TO_EMAIL_SUCCESS]: state => {
    return {
      ...state,
      isRequest: false,
      errors: null,
    };
  },

  [types.PAYMENT_LINK_TO_EMAIL_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalMortgage: true,
  }),

  [types.COPY_POLICY_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.COPY_POLICY_MORTGAGE_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload, true)
    let selectedRegAddress = dataForm["kladr"] && true
    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {
        ...dataForm,
        draft_id: undefined
      },
      selectedRegAddress: selectedRegAddress,
      fiasId: payload.insurance_city_fias,
      coBorrowersKeys: dataForm["coInsurersKeys"] ? dataForm["coInsurersKeys"] : [],
      coBorrowersQuantity: dataForm["coInsurersQuantity"] ? dataForm["coInsurersQuantity"] : 0,
      errors: null,
      currentStep: 2,
    }
  },

  [types.COPY_POLICY_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalMortgage: true,
  }),

  [types.RESUME_POLICY_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_MORTGAGE_SUCCESS]: (state, {payload}) => {
    const draft_id = payload.id
    const calc_id = payload.policy_id
    let dataForm = constructDataForm(payload, true)
    let documentsPolicy = {
      documents: payload.documents,
      key: payload.insurance.ins_key,
      props_id: payload.props_id,
      oper_id: payload.oper_id,
      payment_url: payload.payment_url,
      policyObjId: draft_id,
      product_type: MORTGAGE,
    }
    let calcResult = [
      {
        key: payload.insurance.ins_key,
        props_id: payload.props_id,
        name: payload.insurance.name,
        short_name: payload.insurance.short_name,
        price: payload.premium_sum,
        policy_number: payload.policy_number,
        calc_id: calc_id,
        type: MORTGAGE,
      }
    ]
    let dataFormResult = transformDataForm(dataForm, payload.insurance_city_fias)
    let selectedRegAddress = dataForm["kladr"] && true
    return {
      ...state,
      isRequest: false,
      policyCreated: true,
      creatingPolicy: false,
      calcResult: calcResult,
      documentsPolicy: documentsPolicy,
      documents: payload.documents,
      calc_id: calc_id,
      mortgageDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      dataFormResult: {
        ...dataFormResult,
        draft_id: draft_id,
      },
      selectedRegAddress: selectedRegAddress,
      fiasId: payload.insurance_city_fias,
      coBorrowersKeys: dataForm["coInsurersKeys"] ? dataForm["coInsurersKeys"] : [],
      coBorrowersQuantity: dataForm["coInsurersQuantity"] ? dataForm["coInsurersQuantity"] : 0,
      errors: null,
      currentStep: 4,
    }
  },

  [types.RESUME_POLICY_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalMortgage: true,
  }),


  [types.RESUME_DRAFT_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_DRAFT_MORTGAGE_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload, true)
    let selectedRegAddress = dataForm["kladr"] && true
    let dataFormResult = transformDataForm(dataForm, payload.insurance_city_fias)
    const draft_id = payload.id
    return {
      ...state,
      isRequest: false,
      mortgageDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      dataFormResult: {
        ...dataFormResult,
        draft_id: draft_id,
      },
      selectedRegAddress: selectedRegAddress,
      fiasId: payload.insurance_city_fias,
      coBorrowersKeys: dataForm["coInsurersKeys"] ? dataForm["coInsurersKeys"] : [],
      coBorrowersQuantity: dataForm["coInsurersQuantity"] ? dataForm["coInsurersQuantity"] : 0,
      errors: null,
      currentStep: 2,
    }
  },

  [types.RESUME_DRAFT_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalMortgage: true,
  }),

  [types.GET_PROFESSIONS_MORTGAGE_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_PROFESSIONS_MORTGAGE_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      errors: null,
      professions: payload
    }
  },

  [types.GET_PROFESSIONS_MORTGAGE_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),

  [types.GET_BANKS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_BANKS_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      errors: null,
      banks: payload
    }
  },

  [types.GET_BANKS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),

  [types.GET_EMPLOYMENT_STATUSES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_EMPLOYMENT_STATUSES_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      errors: null,
      employment_statuses_list: payload
    }
  },

  [types.GET_EMPLOYMENT_STATUSES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),

  [types.GET_HEALTH_ABNORMALITIES_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_HEALTH_ABNORMALITIES_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      errors: null,
      health_abnormalities_list: payload
    }
  },

  [types.GET_HEALTH_ABNORMALITIES_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true
  }),

  [types.GET_FIAS_ID_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_FIAS_ID_SUCCESS]: (state, {payload}) => {

    return {
      ...state,
      isRequest: false,
      errors: null,
      fiasId: payload,
    }
  },

  [types.GET_FIAS_ID_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalMortgage: true,
    fiasId: undefined,
  }),

  [types.ADD_CO_BORROWER]: (state) => {
    let coBorrowersQuantity = state.coBorrowersQuantity
    let coBorrowersQuantityMax = state.coBorrowersQuantityMax
    let coBorrowersKeys = state.coBorrowersKeys
    let newKey = coBorrowersKeys.length ? coBorrowersKeys[coBorrowersKeys.length - 1] + 1 : 0
    let nextKeys = coBorrowersKeys.concat(newKey);

    if (coBorrowersQuantity < coBorrowersQuantityMax) {
      return ({
        ...state,
        coBorrowersQuantity: coBorrowersQuantity + 1,
        coBorrowersKeys: nextKeys,
      })
    }
    return {
      ...state,
    }
  },

  [types.REMOVE_CO_BORROWER]: (state, action) => {
    let coBorrowersQuantity = state.coBorrowersQuantity

    if (coBorrowersQuantity > 0) {

      let k = action.payload
      let dataForm = state.mortgageDataForm
      let coBorrowersKeys = state.coBorrowersKeys

      let reducedDataForm = Object.keys(dataForm).reduce(
        (object, key) => {
          if (
            `first_name_${k}` !== key &&
            `last_name_${k}` !== key &&
            `middle_name_${k}` !== key &&
            `birth_date_${k}` !== key &&
            `birth_place_${k}` !== key &&
            `email_${k}` !== key &&
            `phone_${k}` !== key &&
            `gender_${k}` !== key &&

            `fact_address_${k}` !== key &&
            `fias_fact_${k}` !== key &&

            `registration_address_${k}` !== key &&
            `fias_${k}` !== key &&

            `division_code_${k}` !== key &&
            `document_issue_date_${k}` !== key &&
            `document_issuer_${k}` !== key &&
            `document_series_${k}` !== key &&
            `document_number_${k}` !== key &&

            `profession_${k}` !== key &&
            `property_share_${k}` !== key
          ) {
            object[key] = dataForm[key]
          }
          return object
        },
        {}
      )

      return ({
        ...state,
        coBorrowersQuantity: coBorrowersQuantity - 1,
        coBorrowersKeys: coBorrowersKeys.filter((key) => key !== k),
        mortgageDataForm: reducedDataForm,
      })
    }
    return {
      ...state,
    }
  },

  [types.SET_DEFAULT_PROPERTY_SHARE]: (state) => {
    let dataForm = state.mortgageDataForm
    dataForm["property_share"] = constructField("property_share", 100)
    return ({
      ...state,
      mortgageDataForm: dataForm,
    })
  },

  [types.CHANGE_QUANTITY_CO_BORROWERS]: (state, {payload}) => ({
    ...state,
    coBorrowersQuantity: payload
  }),

  [types.REMOVE_RISK]: (state, {payload}) => {
    let dataForm = state.mortgageDataForm
    dataForm[payload] = constructField(payload, false)

    return ({
      ...state,
      mortgageDataForm: dataForm,
    });
  },

  [types.CALC_PROPERTY_SHARE_SUM]: state => {
    let dataForm = state.mortgageDataForm
    let coBorrowersKeys = state.coBorrowersKeys

    let coBorrowersPropertyShareSum = coBorrowersKeys
      .reduce((sum, key) => {
        return sum + dataForm[`property_share_${key}`] ?
          parseInt(dataForm[`property_share_${key}`].value || 0, 10) : 0
      }, 0)

    let propertyShareSum = coBorrowersPropertyShareSum + parseInt(dataForm.property_share.value || 0, 10)

    return ({
      ...state,
      propertyShareSum: propertyShareSum
    })
  },

  [types.UPLOAD_PASSPORT_FILES]: (state, {payload}) => ({
    ...state,
    passportFileList: [...state.passportFileList, payload],
  }),

  [types.REMOVE_PASSPORT_FILES]: (state, {payload}) => ({
    ...state,
    passportFileList: state.passportFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_LOAN_AGREEMENT_FILE]: (state, {payload}) => ({
    ...state,
    loanAgreementFileList: [...state.loanAgreementFileList, payload],
  }),

  [types.REMOVE_LOAN_AGREEMENT_FILE]: (state, {payload}) => ({
    ...state,
    loanAgreementFileList: state.loanAgreementFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_EVALUATION_REPORT_FILE]: (state, {payload}) => ({
    ...state,
    evaluationReportFileList: [...state.evaluationReportFileList, payload],
  }),

  [types.REMOVE_EVALUATION_REPORT_FILE]: (state, {payload}) => ({
    ...state,
    evaluationReportFileList: state.evaluationReportFileList.filter(file => file !== payload),
  }),

  [types.UPLOAD_EGRN_FILE]: (state, {payload}) => ({
    ...state,
    EGRNFileList: [...state.EGRNFileList, payload],
  }),

  [types.REMOVE_EGRN_FILE]: (state, {payload}) => ({
    ...state,
    EGRNFileList: state.EGRNFileList.filter(file => file !== payload),
  }),

  [types.SEND_MORTGAGE_APPLICATION]: state => {
    let stateDataForm = state.mortgageDataForm

    let dataFormResult = transformApplicationDataForm(stateDataForm, state)

    return {
      ...state,
      applicationDataFormResult: dataFormResult,
    }
  },

  [types.SEND_MORTGAGE_APPLICATION_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    isFormSuccessfullySent: false,
  }),

  [types.SEND_MORTGAGE_APPLICATION_SUCCESS]: (state) => ({
    ...state,
    isRequest: false,
    errors: null,
    isFormSuccessfullySent: true,
  }),

  [types.SEND_MORTGAGE_APPLICATION_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload},
    visibleErrorModalMortgage: true,
    isFormSuccessfullySent: false,
  }),

  [types.TOGGLE_MORTGAGE_APPLICATION_MODAL]: (state, {payload}) => ({
    ...state,
    visibleModalMortgageApplication: payload
  }),

});
