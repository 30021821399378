import React, {Component} from "react";
import {Button, Dropdown, Menu} from "antd";
import * as keys from "../../routers/keys";
import {ReactComponent as DropdownIcon} from '../../assets/Icons/Dropdown.svg';
import {ReactComponent as BellIcon} from '../../assets/Icons/Bell.svg';
import {ReactComponent as SmallRedCircleIcon} from '../../assets/Icons/SmallRedCircle.svg';
import "./styles/index.scss";
import {isNumeric} from "../../utils";
import {availableProductsSelector} from "../../selectors/mainSelectors";
import {bindActionCreators} from "redux";
import {getAvailableProducts} from "../../actions/mainActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getUserInfo} from "../../actions/loginActions";
import {userInfoSelector} from "../../selectors/loginSelectors";
import ModalNotifications from "../Form/ModalNotifications";

const {SubMenu} = Menu;


export const insuranceDropdownMenu = (history, availableProducts) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      onClick={() => {
        history.replace(keys.OSAGO)
      }}
    >
      ОСАГО
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        history.replace(keys.CASCO)
      }}>
      КАСКО
    </Menu.Item>
    <Menu.Item disabled>
      ИФЛ
    </Menu.Item>
    <Menu.Item disabled>
      НС Авто
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        history.replace(keys.ACCIDENTS_SPORT)
      }}
    >
      НС
    </Menu.Item>
    <SubMenu
      title={
        <span>КРОСС</span>
      }
    >
      <Menu.Item
        disabled={availableProducts["kkm"] === undefined || availableProducts["kkm"] === false}
        onClick={() => {
          history.replace(keys.KKM)
        }}
      >
        Каско Компакт Минимум
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.replace(keys.CROSS)
        }}
      >
        Демократичное КАСКО
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.replace(keys.CROSS)
        }}
      >
        Практичное КАСКО
      </Menu.Item>
    </SubMenu>
    <Menu.Item
      onClick={() => {
        history.replace(keys.MORTGAGE)
      }}
    >
      Ипотека
    </Menu.Item>
    <Menu.Item disabled>
      Телемедицина
    </Menu.Item>
  </Menu>
)

export const servicesDropdownMenu = (history) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      onClick={() => {
        history.replace(keys.KBM)
      }}
    >
      Проверить КБМ
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        history.replace(keys.PROLONGATION)
      }}
    >
      Пролонгация ОСАГО
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        history.replace(keys.ADDENDUM)
      }}
    >
      Аддендум
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        history.replace(keys.RENEWAL)
      }}
    >
      Продление
    </Menu.Item>
    <Menu.Item disabled>
      Комиссия
    </Menu.Item>
  </Menu>
)

export const reportsDropdownMenu = (history, userInfo) => (
  <Menu className="hyd-dropdown-menu">
    <Menu.Item
      onClick={() => {
        history.replace(keys.CONTRACTS)
      }}
    >
      Журнал договоров
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        if (userInfo.can_access_payment_acts) {
          history.replace(keys.PAYMENT_ACTS)
        }
      }}
      disabled={!userInfo.can_access_payment_acts}
    >
      Выплаты
    </Menu.Item>
  </Menu>
)


class DropdownMenuComponent extends Component {
  componentDidMount() {
    const {getAvailableProducts, getUserInfo} = this.props
    getAvailableProducts()
    getUserInfo()
  }

  render() {
    const {history, availableProducts, userInfo} = this.props
    return <>
      <Dropdown overlay={insuranceDropdownMenu(history, availableProducts)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Страхование <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
      </Dropdown>
      <Dropdown overlay={servicesDropdownMenu(history)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Сервисы <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
      </Dropdown>
      <Dropdown overlay={reportsDropdownMenu(history, userInfo)} trigger={['click']}>
      <span className="ant-dropdown-link hyd-dropdown" onClick={e => e.preventDefault()}>
        Отчёты <DropdownIcon className="hyd-dropdown-ico"/>
      </span>
      </Dropdown>
    </>
  }
}
const mapStateToProps = state => ({
  availableProducts: availableProductsSelector(state),
  userInfo: userInfoSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAvailableProducts,
      getUserInfo,
    },
    dispatch
  )

const DropdownMenu = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DropdownMenuComponent)
)
export const mainDropdownMenu = (history) => (
  <DropdownMenu history={history}/>
)

export const earnedHeader = (earnedMoney, earnedBonuses, userInfo) => (
  <>

    {userInfo.can_access_payment_acts && <Button className="hyd-btn-earned" style={{backgroundColor: "#FFFFFF", color: "#212226", borderColor: "#B3B3B3"}} disabled>
      <span style={{opacity: "50%", marginRight: "5px"}}>Доход</span> {earnedMoney && isNumeric(earnedMoney) ? Number(earnedMoney).toLocaleString("ru-RU") : "0.0"} ₽
    </Button>}

    <Button className="hyd-btn-bonus">
      Бонусы {earnedBonuses && isNumeric(earnedBonuses) ? Number(earnedBonuses).toLocaleString("ru-RU") : "0.0"} ₽
    </Button>
  </>
)


const showCountIcon = (countSentNotifications, iconNumCls, iconNumCls2) => {
  if (countSentNotifications) {
    return (
      <>
        <SmallRedCircleIcon className="hyd-small-num-ico"/>
        <span className={countSentNotifications < 10 ? iconNumCls : iconNumCls2}>
          {countSentNotifications}
        </span>
      </>
    )
  }
}

export const showNotifications = (
  notifications, toggleNotificationsModal,
  iconNumCls = "hyd-small-num-ico-num", iconNumCls2 = "hyd-small-num-ico-num-2",
) => {
  let countSentNotifications = notifications.filter(item => item.status === 'sent').length

  return (
    <>
      <span onClick={() => {
        toggleNotificationsModal(true)
      }}>
        <BellIcon className="hyd-bell-ico"/>
        {showCountIcon(countSentNotifications, iconNumCls, iconNumCls2)}
      </span>
      <ModalNotifications/>
    </>
  )
}
