import React, {Component} from "react";
import {Row, Form, Button, Popover, Popconfirm, Skeleton, Col} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {eOSAGO, eBSO, BSO, ALFA, RENINS, INGOS, VSK, RGS, ALFA_REGIONS} from "../../constants";

import {
  goToPrevStep,
  getConfig,
  toggleBsoModal,
  doPaymentNSS,
  getPaymentStatus,
  patchAlfaPayment,
  patchIngosPayment,
  clearPaymentStatus,
  getSberQRCode,
  getSberQRPaymentStatus,
  getPaymentMethods,
  getCrossProducts,
} from "../../actions/osagoActions";
import {
  currentStepSelector,
  documentsPolicySelector,
  isRequestSelector,
  isRequestCrossProductsSelector,
  configAdminSelector,
  documentsBSOSelector,
  documentsNSSSelector,
  paymentDocsSelector,
  paymentStatusSelector,
  alfaDocsSelector,
  ingosDocsSelector,
  insuranceDocumentsSelector,
  renessansDocsSelector,
  dataFormSelector,
  calcResultSelector,
  paymentDocTypeSelector,
  paymentMethodsSelector,
  sberPaymentUrlSelector,
  crossProductsSelector,
  draftIdSelector,
} from "../../selectors/osagoSelectors";
import {tokenSelector, userInfoSelector,} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import ModalOfflinePaymenuBSO from "../../components/Form/ModalOfflinePaymenuBSO";
import ModalSberQRPayment from "../../components/Form/ModalSberQRPayment";
import FirstStepDisabled from "../../components/OsagoDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/OsagoDisabledSteps/SecondStedDisabled";
import ThirdStepDisabled from "../../components/OsagoDisabledSteps/ThirdStepDisabled";
import FourthStepDisabled from "../../components/OsagoDisabledSteps/FourthStepDisabled";
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import {ReactComponent as ErrorMarkIcon} from "../../assets/Icons/ErrorMark.svg";
import FifthStepDisabled from "../../components/OsagoDisabledSteps/FifthStepDisabled";
import {
  agentCommissionKey,
  downloadDocsButton,
  get_doc,
  isEmpty,
  isObject,
  numToRubbles,
  paidPaymentStatus,
} from "../../utils";
import {crossProductsForm} from "./crossProducts";


class SixthStep extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    const {token, getConfig, getCrossProducts, getPaymentMethods, documentsPolicy, draftId, calcResult,} = this.props
    if (token) {
      getConfig()
      getPaymentMethods(documentsPolicy.product_type)

      let filteredCalcResult = calcResult.find(
        i => ((i.key === documentsPolicy.key) && (i.props_id === documentsPolicy.props_id) && (i.type === documentsPolicy.product_type))
      )
      let agentCommission = filteredCalcResult[agentCommissionKey]
      if ([VSK, ].includes(documentsPolicy.key) && agentCommission['cross']) {
        getCrossProducts(draftId)
      }
    }
  }

  render() {
    const {
      documentsPolicy,
      configAdmin,
      doPaymentNSS,
      documentsNSS,
      getPaymentStatus,
      dataForm,
      calcResult,
      payment_status,
      userInfo,
      getSberQRCode,
      getSberQRPaymentStatus,
      paymentMethods,
      sberPaymentUrl,
      crossProducts,
      getCrossProducts,
      isRequestCrossProducts,
      draftId,
    } = this.props;


    const is_original_documents =
      (this.props.insuranceDocuments && payment_status === 'paid') ||
      (documentsNSS && documentsNSS.documents && Array.isArray(documentsNSS.documents))

    const blockPolicyIssuance = (crossProducts, calcResult, documentsPolicy) => {
      if (crossProducts && calcResult && documentsPolicy) {
        if (![VSK, ].includes(documentsPolicy.key)) {
          return false
        }

        let filteredCalcResult = calcResult.find(
          i => ((i.key === documentsPolicy.key) && (i.props_id === documentsPolicy.props_id) && (i.type === documentsPolicy.product_type))
        )
        let agentCommission = filteredCalcResult[agentCommissionKey]
        agentCommission = agentCommission && isObject(agentCommission.value) ? agentCommission.value : agentCommission

        let isCross = agentCommission && agentCommission['cross']
        let isNoOneCrossPaid = !crossProducts.some(product => product.paymentStatus === paidPaymentStatus)

        return isCross && (isEmpty(crossProducts) || isNoOneCrossPaid)
      }
    }

    return (
      <>
        <FirstStepDisabled/>
        <SecondStepDisabled
          dataForm={dataForm}
        />
        <ThirdStepDisabled
          dataForm={dataForm}
        />
        <FourthStepDisabled
          dataForm={dataForm}
        />
        <FifthStepDisabled
          value={calcResult.find(i => ((i.key === documentsPolicy.key) && (i.props_id === documentsPolicy.props_id) && (i.type === documentsPolicy.product_type)))}
          userInfo={userInfo}
        />
        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">6</span>
          </div>
          <span className="step-title-active-header">Предварительный просмотр документов</span>
        </div>
        {documentsNSS && documentsNSS.documents &&
          Array.isArray(documentsNSS.documents) &&
          <Row>
            {documentsNSS.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}
        {this.props.insuranceDocuments && payment_status === 'paid' &&
          <Row>
            {this.props.insuranceDocuments.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}
        <Row>
          {/* Документы заявление, копия полиса, Представители */}
          {!is_original_documents && documentsPolicy &&
            Array.isArray(documentsPolicy.documents) &&
            documentsPolicy.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
        </Row>
        {documentsPolicy && documentsPolicy.add_products && documentsPolicy.add_products.map(add_product =>
          <>
            <div>
              <span>Дополнительный продукт: {add_product.product_name}</span>
              <span> {Number(add_product.premium_sum).toLocaleString("ru-RU")} ₽</span>
            </div>
            <Row>
              {!is_original_documents && add_product.documents &&
                Array.isArray(add_product.documents) &&
                add_product.documents.map((item, index) => {
                  return (item.name && (downloadDocsButton(get_doc, item, index)))
                })}
            </Row>
          </>
        )}
        <div className={"hyd-row-osago-step6"}>
          <div className={"hyd-osago-step6-payment"}>
            <div className={"hyd-osago-ins-result-head"}>
              Стоимость полиса
            </div>
            <div>
              {documentsPolicy && (
                <span className={"hyd-osago-ins-result-value hyd-osago-step6-value"}>
                {numToRubbles(documentsPolicy.price)}
              </span>
              )}
            </div>
          </div>
          {configAdmin.offline_payment && paymentMethods && paymentMethods.receipt && documentsPolicy &&
            <>
              {[INGOS, ALFA, ALFA_REGIONS, VSK, RGS].includes(documentsPolicy.key) &&
                [BSO, eBSO].includes(documentsPolicy.product_type) && (
                  <>
                    {!sberPaymentUrl && (!is_original_documents ?
                      <div className={"hyd-osago-step6-payment"}>
                        <Popconfirm
                          placement="top"
                          title={"Подтвердить оформление и выпуск полиса?"}
                          onConfirm={() => doPaymentNSS({
                              insKey: documentsPolicy.key,
                              policyObjId: documentsPolicy.policyObjId,
                            })}
                          okText="Да"
                          cancelText="Нет"
                          disabled={isRequestCrossProducts || blockPolicyIssuance(crossProducts, calcResult, documentsPolicy)}
                        >
                          <Button
                            className={`hyd-btn-small ${isRequestCrossProducts || blockPolicyIssuance(crossProducts, calcResult, documentsPolicy) && "hyd-btn-disabled"}`}
                            style={{marginRight: 30}}
                            type="primary"
                          >
                            {paymentMethods && paymentMethods.qr_code && documentsPolicy.product_type === eBSO ? "Квитанция" : "Сформировать квитанцию"}
                          </Button>
                        </Popconfirm>
                      </div> :
                      <Button
                        className={"hyd-osago-step6-payment hyd-btn-small hyd-policy-paid"}>
                        Полис оплачен
                      </Button>)
                    }
                  </>
                )}
            </>
          }

          {
            documentsPolicy && paymentMethods && paymentMethods.qr_code && ![RENINS,].includes(documentsPolicy.key) &&
            documentsPolicy.policy_seria && documentsPolicy.policy_number &&
            (
              <>
                {
                  !is_original_documents &&
                  ((payment_status && payment_status === "paid") ?
                    <Button className={"hyd-btn-small hyd-policy-paid hyd-policy-paid-m"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            getSberQRCode(documentsPolicy.policyObjId)
                          }}
                          type="primary"
                          disabled={isRequestCrossProducts || blockPolicyIssuance(crossProducts, calcResult, documentsPolicy)}
                        >
                          Оплата по QR-коду
                        </Button>
                      </div>


                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            getSberQRPaymentStatus(documentsPolicy.policyObjId)
                          }}
                          type="primary"
                        >
                          Проверить статус оплаты
                        </Button>
                        {payment_status && !(payment_status === "paid") ?
                          <div className={"hyd-not-paid"}>
                            <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                            <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                          </div> : ""
                        }
                      </div>
                    </>)
                }
              </>
            )
          }

          {configAdmin.online_payment && documentsPolicy && paymentMethods && paymentMethods.online && (
            <>

              {(documentsPolicy.product_type === eOSAGO || [RENINS,].includes(documentsPolicy.key)) && (
                <>
                  {payment_status === "paid" ?
                    <Button className={"hyd-btn-small hyd-policy-paid hyd-policy-paid-m"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            window.open(documentsPolicy["payment_url"])
                          }}
                          type="primary"
                          disabled={isRequestCrossProducts || blockPolicyIssuance(crossProducts, calcResult, documentsPolicy)}
                        >
                          Оплата картой
                        </Button>
                      </div>
                      <div className={"hyd-osago-step6-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          type="primary"
                          onClick={() => {
                            getPaymentStatus({
                              insKey: documentsPolicy.key,
                              policyObjId: documentsPolicy.policyObjId,
                            })

                          }}
                        >
                          Проверить статус оплаты
                        </Button>

                        {payment_status &&
                        !(payment_status === "paid") ?
                          <div className={"hyd-not-paid"}>
                            <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                            <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                          </div> : ""
                        }
                      </div>
                    </>
                  }
                </>
              )}

              {documentsPolicy &&
                documentsPolicy.payment_url &&
                documentsPolicy.payment_url.errors &&
                documentsPolicy.payment_url.errors.error && (
                  <div className={"hyd-osago-step6-payment"}>
                    <Popover
                      content={documentsPolicy.payment_url.errors.error}
                      title="Ошибка"
                      trigger="hover"
                    >
                      <Button
                        className={"hyd-btn-small"}
                        type="primary"
                        disabled={true}
                      >
                        Оплата картой
                      </Button>
                    </Popover>
                  </div>
                )}
            </>
          )}

        </div>

        {
          isRequestCrossProducts
            ? <Row gutter={8} className={"one-click-policy-container"}>
              {[...Array(3)].map((_, index) => (
                <Col key={index} span={4} className={"one-click-policy-entity"}>
                  <div><Skeleton active={true}/></div>
                </Col>
              ))}
            </Row>
            : <>{crossProductsForm(getCrossProducts, crossProducts, draftId, userInfo)}</>
        }

        <ModalError type={1}/>
        <ModalOfflinePaymenuBSO/>
        <ModalSberQRPayment/>
      </>
    );
  }

}

const WrappedSixthStepForm = Form.create({name: "step-6"})(SixthStep);

const mapStateToProps = (state) => ({
  currentStep: currentStepSelector(state),
  documentsPolicy: documentsPolicySelector(state),
  isRequest: isRequestSelector(state),
  isRequestCrossProducts: isRequestCrossProductsSelector(state),
  configAdmin: configAdminSelector(state),
  documentsBSO: documentsBSOSelector(state),
  documentsNSS: documentsNSSSelector(state),
  token: tokenSelector(state),
  paymentDocs: paymentDocsSelector(state),
  payment_status: paymentStatusSelector(state),
  alfa_docs: alfaDocsSelector(state),
  ingostrah_docs: ingosDocsSelector(state),
  insuranceDocuments: insuranceDocumentsSelector(state),
  renessans_docs: renessansDocsSelector(state),
  dataForm: dataFormSelector(state),
  calcResult: calcResultSelector(state),
  paymentDocType: paymentDocTypeSelector(state),
  userInfo: userInfoSelector(state),
  paymentMethods: paymentMethodsSelector(state),
  sberPaymentUrl: sberPaymentUrlSelector(state),
  crossProducts: crossProductsSelector(state),
  draftId: draftIdSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      getConfig,
      toggleBsoModal,
      doPaymentNSS,
      getPaymentStatus,
      patchAlfaPayment,
      patchIngosPayment,
      clearPaymentStatus,
      getSberQRCode,
      getSberQRPaymentStatus,
      getPaymentMethods,
      getCrossProducts,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedSixthStepForm);
