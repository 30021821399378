import React, {Component} from "react";
import {
  Form,
  Menu,
  Radio,
  Switch,
  Button,
  Tooltip,
  Checkbox,
  Dropdown,
  Popconfirm,
  Pagination,
  Input,
} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import {
  getAgents,
  setPageSize,
  updateAgent,
  getAllAgents,
  addAgentToList,
  addSupervisorToList,
  getAgentsTypes,
  setCurrentPage,
  getKVCategories,
  setIsShowKVFilter,
  setIsActiveFilter,
  setIsOnlineFilter,
  clearAgentsFilters,
  clearIsRequestState,
  clearSelectedAgentsList,
  updateAgentsTypesFilter,
  updateKVCategoriesFilter,
  isAllAgentsReceivedToggle,
} from "../../actions/agentsActions";
import {
  agentsSelector,
  pageSizeSelector,
  isRequestSelector,
  agentsTypesSelector,
  currentPageSelector,
  kvCategoriesSelector,
  filterShowKVSelector,
  filterIsActiveSelector,
  filterIsOnlineSelector,
  selectedAgentsListSelector,
  selectedSupervisorsListSelector,
  isAllAgentsReceivedSelector,
  agentsTypesSelectedFiltersSelector,
  kvCategoriesSelectedFiltersSelector,
} from "../../selectors/agentsSelectors";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";
import {ReactComponent as EyeIcon} from "../../assets/Icons/Agents/Eye.svg";
import {ReactComponent as DropdownIcon} from '../../assets/Icons/Dropdown.svg';
import {ReactComponent as CountIcon} from '../../assets/Icons/Agents/Count.svg';
import {ReactComponent as PhoneIcon} from "../../assets/Icons/Agents/Phone.svg";
import {ReactComponent as PersonIcon} from "../../assets/Icons/Agents/Person.svg";
import {ReactComponent as OnlineIcon} from "../../assets/Icons/Agents/Online.svg";
import {ReactComponent as OfflineIcon} from "../../assets/Icons/Agents/Offline.svg";
import {ReactComponent as LineGrey} from "../../assets/Icons/Agents/LineGrey.svg";
import {ReactComponent as LineWhite} from "../../assets/Icons/Agents/LineWhite.svg";
import {ReactComponent as DisabledIcon} from "../../assets/Icons/Agents/Disabled.svg";
import {ReactComponent as EyeCrossedOutIcon} from "../../assets/Icons/Agents/EyeCrossedOut.svg";
import Preloader from "../../components/Preloader";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {baseRules, baseTrigger} from "../../utils";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";

const DateSorterKey = "date_joined"
const ABCSorterKey = "last_name"
const IsActiveFilterKey = "is_active"
const IsBlockedFilterKey = "is_blocked"
const IsOnlineFilterKey = "is_online"
const IsOfflineFilterKey = "is_offline"
const IsDisplayedKVFilterKey = "is_displayed_kv"
const IsNotDisplayedKVFilterKey = "is_not_displayed_kv"
const DateSorterShortLabel = "дате регистрации"
const ABCSorterShortLabel = "алфавиту"
const SearchUserValue = "search_user_value"
const ManagerSearchKey = "parent_last_name"
const AgentSearchKey = "last_name"
const ManagerSearchLabel = "брокеру"
const AgentSearchLabel = "агенту"
const sorterRadioOptions = [
  {label: "дате регистрации по убыванию", value: `-${DateSorterKey}`, shortLabel: DateSorterShortLabel},
  {label: "дате регистрации по возрастанию", value: DateSorterKey, shortLabel: DateSorterShortLabel},
  {label: "алфавиту А-Я", value: ABCSorterKey, shortLabel: ABCSorterShortLabel},
  {label: "алфавиту Я-А", value: `-${ABCSorterKey}`, shortLabel: ABCSorterShortLabel},
]
const searchRadioOptions = [
  {label: ManagerSearchLabel, value: ManagerSearchKey},
  {label: AgentSearchLabel, value: AgentSearchKey},
]

class Agents extends Component {
  constructor(props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.state = {
      KVVisibilitySelectedValue: null,
      AgentTypeSelectedValue: null,
      KVSelectedValue: null,
      SorterSelectedValue: `-${DateSorterKey}`,
      SorterShortLabel: DateSorterShortLabel,
      SearchSelectedKey: ManagerSearchKey,
      SearchLabel: ManagerSearchLabel,
      dropdownVisibleChangeAgentType: false,
      dropdownVisibleChangeKV: false,
      dropdownVisibleChangeVisibilityKV: false,
      dropdownVisibleSorters: false,
      dropdownVisibleFilters: false,
      dropdownVisibleSearch: false,
    }
  }

  componentDidMount() {
    document.title = "Агенты"
    const {
      token,
      pageSize,
      getAgents,
      setPageSize,
      getAgentsTypes,
      getKVCategories,
      getNotifications,
      checkEarnedMoneyAmount,
      clearIsRequestState,
      clearSelectedAgentsList,
      isAllAgentsReceivedToggle,
    } = this.props
    setPageSize(12)
    isAllAgentsReceivedToggle(false)
    clearIsRequestState()
    if (token) {
      getAgents({"limit": pageSize, "offset": 0, "o": this.state.SorterSelectedValue})
      getKVCategories()
      getAgentsTypes()
      getNotifications()
      checkEarnedMoneyAmount()
    }
    clearSelectedAgentsList()
    document.addEventListener("click", this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
    const {clearAgentsFilters} = this.props
    clearAgentsFilters()
    document.removeEventListener("click", this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  isElementOrChildOfElement = (element) => {
    let excludedElements = [
      "ant-select",
      "hyd-dropdown-menu",
      "ant-popover-content",
      "hyd-tools-agents-menu",
      "ant-dropdown-menu hyd-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
    ]
    let tagName = element && element.tagName && element.tagName.toLowerCase()
    let className = element && element.className
    if (!element || tagName === "body") {
      return false  // Return false if we reached the body or the root of the DOM
    }
    if (
      tagName === "button" || className === "ant-modal" ||
      (typeof className === "string" && excludedElements.includes(className))
    ) {
      return true  // Return true if the element is a search element
    }
    return this.isElementOrChildOfElement(element.parentElement)  // Recursively check the parent element
  }

  handleClickOutside(event) {
    if (!this.isElementOrChildOfElement(event.target)) {
      const {clearSelectedAgentsList} = this.props
      clearSelectedAgentsList()
    }
  }

  onShowSizeChange = (currentPage, pageSize) => {
    const {
      form, getAgents, getAllAgents, setPageSize, setCurrentPage, isAllAgentsReceived,
      filterIsActive, filterIsOnline, filterShowKV, agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
    } = this.props
    const {getFieldValue} = form
    setPageSize(pageSize)
    setCurrentPage(1)
    let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
    getAgentsFunc({
      "limit": pageSize,
      "offset": 0,
      "o": this.state.SorterSelectedValue,
      "is_active": filterIsActive,
      "is_online": filterIsOnline,
      "show_commission": filterShowKV,
      "user_type": agentsTypesSelectedFilters,
      "kv_category": kvCategoriesSelectedFilters,
      [this.state.SearchSelectedKey]: getFieldValue(SearchUserValue),
    })
  }

  onPageChange = (currentPage, pageSize) => {
    const {
      form, getAgents, getAllAgents, setCurrentPage, isAllAgentsReceived,
      filterIsActive, filterIsOnline, filterShowKV, agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
    } = this.props
    const {getFieldValue} = form
    setCurrentPage(currentPage)
    let offset = (currentPage - 1) * pageSize
    let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
    getAgentsFunc({
      "limit": pageSize,
      "offset": offset,
      "o": this.state.SorterSelectedValue,
      "is_active": filterIsActive,
      "is_online": filterIsOnline,
      "show_commission": filterShowKV,
      "user_type": agentsTypesSelectedFilters,
      "kv_category": kvCategoriesSelectedFilters,
      [this.state.SearchSelectedKey]: getFieldValue(SearchUserValue),
    })
  }

  switchAllAgents = () => {
    const {
      getAgents, getAllAgents, pageSize, isAllAgentsReceivedToggle,
      filterIsActive, filterIsOnline, filterShowKV, agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
    } = this.props
    return (
      <span style={{marginRight: "55px"}}>
        Все
        <Switch
          className={"hyd-switch"}
          onChange={(checked) => {
            isAllAgentsReceivedToggle(checked)
            checked ? getAllAgents({
                "limit": pageSize,
                "offset": 0,
                "o": this.state.SorterSelectedValue,
                "is_active": filterIsActive,
                "is_online": filterIsOnline,
                "show_commission": filterShowKV,
                "user_type": agentsTypesSelectedFilters,
                "kv_category": kvCategoriesSelectedFilters,
              }) :
              getAgents({
                "limit": pageSize,
                "offset": 0,
                "o": this.state.SorterSelectedValue,
                "is_active": filterIsActive,
                "is_online": filterIsOnline,
                "show_commission": filterShowKV,
                "user_type": agentsTypesSelectedFilters,
                "kv_category": kvCategoriesSelectedFilters,
              })
          }}
        />
      </span>
    )
  }

  handleVisibleChangeAgentType = (flag) => {
    this.setState({dropdownVisibleChangeAgentType: flag})
  }

  handleVisibleChangeKV = (flag) => {
    this.setState({dropdownVisibleChangeKV: flag})
  }

  handleVisibleChangeVisibilityKV = (flag) => {
    this.setState({dropdownVisibleChangeVisibilityKV: flag})
  }

  handleVisibleChangeSorters = (flag) => {
    this.setState({dropdownVisibleSorters: flag})
  }

  handleVisibleChangeSearch = (flag) => {
    this.setState({dropdownVisibleSearch: flag})
  }

  handleVisibleChangeFilters = (flag) => {
    this.setState({dropdownVisibleFilters: flag})
  }

  ChangeAgentTypeDropdownMenu = () => {
    const {agentsTypes, selectedAgentsList, updateAgent} = this.props;
    return (
      <Menu className="hyd-dropdown-menu">
        <Radio.Group
          onChange={(e) => this.setState({AgentTypeSelectedValue: e.target.value})}
        >
          {agentsTypes && agentsTypes.map(({id, key, value, is_disabled}) => (
            <Menu.Item key={key}>
              <Radio
                style={{marginTop: 16}}
                value={id}
                disabled={is_disabled}
              >
                {value}
              </Radio>
            </Menu.Item>
          ))}
          <div style={{textAlign: "left", marginTop: 16}}>
            <Button
              disabled={!selectedAgentsList.length}
              onClick={() => {
                this.handleVisibleChangeAgentType(false)
                let agentType = agentsTypes.filter(({id}) => this.state.AgentTypeSelectedValue === id)[0]["key"]
                updateAgent({
                  agents: selectedAgentsList,
                  opType: "change_agent_type",
                  agentType: agentType,
                  o: this.state.SorterSelectedValue
                })
              }}
              type="link"
              size="small"
            >
              OK
            </Button>
          </div>
        </Radio.Group>
      </Menu>
    )
  }

  ChangeKVDropdownMenu = () => {
    const {kvCategories, selectedAgentsList, updateAgent} = this.props;
    return (
      <Menu className="hyd-dropdown-menu">
        <Radio.Group
          onChange={(e) => this.setState({KVSelectedValue: e.target.value})}
        >
          {kvCategories && kvCategories.map(({id, key, value}) => (
            <Menu.Item key={key}>
              <Radio style={{marginTop: 16}} value={id}>{value}</Radio>
            </Menu.Item>
          ))}
          <div style={{textAlign: "left", marginTop: 16}}>
            <Button
              disabled={!selectedAgentsList.length}
              onClick={() => {
                this.handleVisibleChangeKV(false)
                let kv = kvCategories.filter(({id}) => this.state.KVSelectedValue === id)[0]["key"]
                updateAgent({
                  agents: selectedAgentsList,
                  opType: "change_kv",
                  kv: kv,
                  o: this.state.SorterSelectedValue,
                })
              }}
              type="link"
              size="small"
            >
              OK
            </Button>
          </div>
        </Radio.Group>
      </Menu>
    )
  }

  VisibilityKVDropdownMenu = () => {
    const {selectedAgentsList, updateAgent} = this.props;
    let options = [
      {label: "Отображать КВ", value: 1},
      {label: "Не отображать КВ", value: 0},
    ]
    return (
      <Menu className="hyd-dropdown-menu">
        <Radio.Group
          onChange={(e) => this.setState({KVVisibilitySelectedValue: e.target.value})}
        >
          {options.map((option) => (
            <Menu.Item key={option.value}>
              <Radio style={{marginTop: 16}} value={option.value}>{option.label}</Radio>
            </Menu.Item>
          ))}

          <div style={{textAlign: "left", marginTop: 16}}>
            <Button
              disabled={!selectedAgentsList.length}
              onClick={() => {
                this.handleVisibleChangeVisibilityKV(false)
                updateAgent({
                  agents: selectedAgentsList,
                  opType: "update_kv_visibility",
                  showCommission: this.state.KVVisibilitySelectedValue,
                  o: this.state.SorterSelectedValue,
                })
              }}
              type="link"
              size="small"
            >
              OK
            </Button>
          </div>
        </Radio.Group>
      </Menu>
    )
  }

  sorterRadioOnChange = (e) => {
    this.setState({
      SorterSelectedValue: e.target.value,
      SorterShortLabel: sorterRadioOptions.find(option => option.value === e.target.value).shortLabel,
    })
  }

  SortersDropdownMenu = () => {
    const {
      form, pageSize, currentPage, getAgents, getAllAgents, isAllAgentsReceived,
      filterIsActive, filterIsOnline, filterShowKV, agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
    } = this.props
    const {getFieldValue} = form
    let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
    let offset = (currentPage - 1) * pageSize
    return (
      <Menu>
        <Radio.Group
          style={{margin: "0 16px 16px 16px"}}
          defaultValue={this.state.SorterSelectedValue}
          onChange={this.sorterRadioOnChange}
        >
          {sorterRadioOptions.map((option) => (
            <Menu.Item key={option.value}>
              <Radio style={{marginTop: 16}} value={option.value}>{option.label}</Radio>
            </Menu.Item>
          ))}
          <div style={{textAlign: "left", marginTop: 16}}>
            <Button
              onClick={() => {
                this.handleVisibleChangeSorters(false)
                getAgentsFunc({
                  "limit": pageSize,
                  "offset": offset,
                  "o": this.state.SorterSelectedValue,
                  "is_active": filterIsActive,
                  "is_online": filterIsOnline,
                  "show_commission": filterShowKV,
                  "user_type": agentsTypesSelectedFilters,
                  "kv_category": kvCategoriesSelectedFilters,
                  [this.state.SearchSelectedKey]: getFieldValue(SearchUserValue),
                })
              }}
              type="link"
              size="small"
            >
              OK
            </Button>
          </div>
        </Radio.Group>
      </Menu>
    )
  }

  onChangeIsActiveFilterCheckBox = (e) => {
    const {setFieldsValue, getFieldValue} = this.props.form;
    const {setIsActiveFilter} = this.props;
    let isActive = getFieldValue(IsActiveFilterKey)
    let isBlocked = getFieldValue(IsBlockedFilterKey)

    if (e.target.checked) {
      if (isActive) {
        setFieldsValue({is_active: false})
        setIsActiveFilter(false)
      } else if (isBlocked) {
        setFieldsValue({is_blocked: false})
        setIsActiveFilter(true)
      } else {
        if (e.target.id.includes(IsActiveFilterKey)) {
          setIsActiveFilter(true)
        } else if (e.target.id.includes(IsBlockedFilterKey)) {
          setIsActiveFilter(false)
        }
      }
    } else {
      setIsActiveFilter(undefined)
    }
  }

  onChangeIsOnlineFilterCheckBox = (e) => {
    const {setFieldsValue, getFieldValue} = this.props.form;
    const {setIsOnlineFilter} = this.props;
    let isOnline = getFieldValue(IsOnlineFilterKey)
    let isOffline = getFieldValue(IsOfflineFilterKey)

    if (e.target.checked) {
      if (isOnline) {
        setFieldsValue({is_online: false})
        setIsOnlineFilter(false)
      } else if (isOffline) {
        setFieldsValue({is_offline: false})
        setIsOnlineFilter(true)
      } else {
        if (e.target.id.includes(IsOnlineFilterKey)) {
          setIsOnlineFilter(true)
        } else if (e.target.id.includes(IsOfflineFilterKey)) {
          setIsOnlineFilter(false)
        }
      }
    } else {
      setIsOnlineFilter(undefined)
    }
  }

  onChangeIsDisplayedKVFilterCheckBox = (e) => {
    const {setFieldsValue, getFieldValue} = this.props.form;
    const {setIsShowKVFilter} = this.props;
    let IsDisplayedKV = getFieldValue(IsDisplayedKVFilterKey)
    let IsNotDisplayedKV = getFieldValue(IsNotDisplayedKVFilterKey)

    if (e.target.checked) {
      if (IsDisplayedKV) {
        setFieldsValue({is_displayed_kv: false})
        setIsShowKVFilter(false)
      } else if (IsNotDisplayedKV) {
        setFieldsValue({is_not_displayed_kv: false})
        setIsShowKVFilter(true)
      } else {
        if (e.target.id.includes(IsDisplayedKVFilterKey)) {
          setIsShowKVFilter(true)
        } else if (e.target.id.includes(IsNotDisplayedKVFilterKey)) {
          setIsShowKVFilter(false)
        } else {
          setIsShowKVFilter(null)
        }
      }
    }
  }

  clearFilters = () => {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      is_active: undefined,
      is_blocked: undefined,
      is_online: undefined,
      is_offline: undefined,
      is_displayed_kv: undefined,
      is_not_displayed_kv: undefined,
    })
  }

  clearSearchFilter = () => {
    const {setFieldsValue} = this.props.form
    setFieldsValue({
      search_user_value: undefined,
    })
  }

  FiltersDropdownMenu = () => {
    const {
      form, pageSize, currentPage,
      kvCategories, agentsTypes,
      getAgents, getAllAgents, isAllAgentsReceived,
      clearAgentsFilters, filterShowKV, filterIsActive, filterIsOnline,
      agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
      updateAgentsTypesFilter, updateKVCategoriesFilter,
    } = this.props;
    const {getFieldDecorator, getFieldValue} = form
    let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
    let offset = (currentPage - 1) * pageSize
    return (
      <Menu>
        {agentsTypes.map(({id, key, value}) => (
          <Menu.Item key={id} style={{marginLeft: 3}}>
            <Checkbox
              style={{marginTop: 12}}
              value={key}
              onChange={(e) => {
                updateAgentsTypesFilter(e.target.value)
              }}
              checked={agentsTypesSelectedFilters.includes(key)}
            >
              {value}
            </Checkbox>
          </Menu.Item>
        ))}

        <LineGrey style={{marginLeft: 8}}/>

        {kvCategories.map(({id, key, value}) => (
          <Menu.Item key={id} style={{marginLeft: 3}}>
            <Checkbox
              style={{marginTop: 8}}
              value={key}
              onChange={(e) => {
                updateKVCategoriesFilter(e.target.value)
              }}
              checked={kvCategoriesSelectedFilters.includes(key)}
            >
              {value}
            </Checkbox>
          </Menu.Item>
        ))}

        <LineGrey style={{marginLeft: 8}}/>

        <Menu.Item key={IsDisplayedKVFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0, height: 36}}>
            {getFieldDecorator(IsDisplayedKVFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox
                onChange={this.onChangeIsDisplayedKVFilterCheckBox}
              >
                Отображено КВ
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <Menu.Item key={IsNotDisplayedKVFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0}}>
            {getFieldDecorator(IsNotDisplayedKVFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox
                onChange={this.onChangeIsDisplayedKVFilterCheckBox}
              >
                Не отображено КВ
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <LineGrey style={{marginLeft: 8}}/>

        <Menu.Item key={IsActiveFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0}}>
            {getFieldDecorator(IsActiveFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox onChange={this.onChangeIsActiveFilterCheckBox}>
                Активен
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <Menu.Item key={IsBlockedFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0}}>
            {getFieldDecorator(IsBlockedFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox onChange={this.onChangeIsActiveFilterCheckBox}>
                Заблокирован
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <LineGrey style={{marginLeft: 8}}/>

        <Menu.Item key={IsOnlineFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0}}>
            {getFieldDecorator(IsOnlineFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox onChange={this.onChangeIsOnlineFilterCheckBox}>
                Онлайн
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <Menu.Item key={IsOfflineFilterKey} style={{height: 36}}>
          <Form.Item style={{marginLeft: 3, marginBottom: 0}}>
            {getFieldDecorator(IsOfflineFilterKey, {
              valuePropName: "checked",
              initialValue: false,
            })(
              <Checkbox onChange={this.onChangeIsOnlineFilterCheckBox}>
                Оффлайн
              </Checkbox>
            )}
          </Form.Item>
        </Menu.Item>

        <div style={{textAlign: "left", margin: 12}}>
          <Button
            onClick={() => {
              this.handleVisibleChangeFilters(false)
              getAgentsFunc({
                "limit": pageSize,
                "offset": offset,
                "o": this.state.SorterSelectedValue,
                "is_active": filterIsActive,
                "is_online": filterIsOnline,
                "show_commission": filterShowKV,
                "user_type": agentsTypesSelectedFilters,
                "kv_category": kvCategoriesSelectedFilters,
                [this.state.SearchSelectedKey]: getFieldValue(SearchUserValue),
              })
            }}
            type="link"
            size="small"
          >
            OK
          </Button>
          <Button
            onClick={() => {
              this.clearFilters()
              clearAgentsFilters()
            }}
            type="link"
            size="small"
            style={{float: "right"}}
          >
            Сбросить
          </Button>
        </div>
      </Menu>
    )
  }

  searchRadioOnChange = (e) => {
    this.setState({
      SearchSelectedKey: e.target.value,
      SearchLabel: searchRadioOptions.find(option => option.value === e.target.value).label,
    })
  }

  SearchDropdownMenu = () => {
    const {
      pageSize, currentPage, getAgents, getAllAgents, isAllAgentsReceived, form,
      filterIsActive, filterIsOnline, filterShowKV, agentsTypesSelectedFilters, kvCategoriesSelectedFilters,
    } = this.props

    const {getFieldValue, getFieldError, getFieldDecorator} = form

    let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
    let offset = (currentPage - 1) * pageSize
    return (
      <Menu>
        <Radio.Group
          style={{margin: "0 16px 16px 16px"}}
          defaultValue={this.state.SearchSelectedKey}
          onChange={this.searchRadioOnChange}
        >
          {searchRadioOptions.map((option) => (
            <Menu.Item key={option.value}>
              <Radio style={{marginTop: 16}} value={option.value}>{option.label}</Radio>
            </Menu.Item>
          ))}

          <Form.Item
            validateStatus={getFieldError(SearchUserValue) ? "error" : ""}
            help={getFieldError(SearchUserValue) || ""}
          >
            <FloatingLabelInput
              divClassName="agents-search-input"
              htmlFor={"agents_" + SearchUserValue}
              labelText={"Фамилия"}
              divId={"float-label-small"}
              child={getFieldDecorator(SearchUserValue, {
                validateTrigger: baseTrigger,
                rules: baseRules,
              })(<Input className={"ant-input hyd-input-small"}/>)}
            />
          </Form.Item>

          <div style={{textAlign: "left", marginTop: 16}}>
            <Button
              onClick={() => {
                this.handleVisibleChangeSearch(false)
                getAgentsFunc({
                  "limit": pageSize,
                  "offset": offset,
                  "o": this.state.SorterSelectedValue,
                  "is_active": filterIsActive,
                  "is_online": filterIsOnline,
                  "show_commission": filterShowKV,
                  "user_type": agentsTypesSelectedFilters,
                  "kv_category": kvCategoriesSelectedFilters,
                  [this.state.SearchSelectedKey]: getFieldValue(SearchUserValue),
                })
              }}
              type="link"
              size="small"
            >
              OK
            </Button>
            <Button
              onClick={() => {
                this.handleVisibleChangeSearch(false)
                getAgentsFunc({
                  "limit": pageSize,
                  "offset": offset,
                  "o": this.state.SorterSelectedValue,
                  "is_active": filterIsActive,
                  "is_online": filterIsOnline,
                  "show_commission": filterShowKV,
                  "user_type": agentsTypesSelectedFilters,
                  "kv_category": kvCategoriesSelectedFilters,
                })
                this.clearSearchFilter()
              }}
              type="link"
              size="small"
              style={{float: "right"}}
            >
              Сбросить
            </Button>
          </div>
        </Radio.Group>
      </Menu>
    )
  }

  render() {
    const {
      kvCategoriesSelectedFilters,
      agentsTypesSelectedFilters,
      isAllAgentsReceived,
      selectedAgentsList,
      selectedSupervisorsList,
      clearAgentsFilters,
      addAgentToList,
      getAllAgents,
      updateAgent,
      currentPage,
      getAgents,
      isRequest,
      pageSize,
      userInfo,
      agents,
      offset,
    } = this.props
    const {getFieldValue} = this.props.form;

    let filtersCount = kvCategoriesSelectedFilters.concat(agentsTypesSelectedFilters).length

    const filterKeys = [
      IsActiveFilterKey,
      IsBlockedFilterKey,
      IsDisplayedKVFilterKey,
      IsNotDisplayedKVFilterKey,
    ]

    filterKeys.forEach(key => {
      if (getFieldValue(key)) {
        filtersCount++
      }
    })

    return (
      <>
        <Preloader loading={isRequest}/>
        {agents &&

          <div className="agents-container">
            <div className="agents-header">
              <span style={{float: "left"}}>
                <span className="hyd-very-big-bold-text-small">
                  Агенты
                </span>
                <span className="big-bold-text-grey" style={{marginLeft: 24}}>
                  {agents["count"]}
                </span>
              </span>
              <span style={{float: "right", marginTop: 16}}>
                <span className="bold-text-grey">
                  Выбрано
                </span>
                <span className="bold-text-grey" style={{marginLeft: 16}}>
                  {selectedAgentsList.length}
                </span>
              </span>
            </div>

            <div className="hyd-tools-agents-menu">
              <Popconfirm
                placement="top"
                title={"Будут заблокированы выбранные агенты. Продолжить?"}
                onConfirm={() => updateAgent(
                  {agents: selectedAgentsList, opType: "block", o: this.state.SorterSelectedValue}
                )}
                okText="Да"
                cancelText="Нет"
                disabled={!selectedAgentsList.length}
              >
                <Button
                  className={`hyd-btn-small-grey-2 ${!selectedAgentsList.length && "hyd-btn-disabled"}`}
                  style={{margin: "30px 10px 30px 0"}}
                >
                  Блокировать
                </Button>
              </Popconfirm>

              <Dropdown overlay={this.ChangeAgentTypeDropdownMenu}
                        trigger={['click']}
                        onVisibleChange={this.handleVisibleChangeAgentType}
              >
                <span
                  className={"hyd-dropdown hyd-dropdown-agents"}
                  onClick={e => e.preventDefault()}>
                  Изменить роль <DropdownIcon className="hyd-dropdown-ico"/>
                </span>
              </Dropdown>

              <Dropdown overlay={this.ChangeKVDropdownMenu}
                        trigger={['click']}
                        onVisibleChange={this.handleVisibleChangeKV}
              >
                <span
                  className={"hyd-dropdown hyd-dropdown-agents"}
                  onClick={e => e.preventDefault()}>
                  Сменить КВ <DropdownIcon className="hyd-dropdown-ico"/>
                </span>
              </Dropdown>

              <Dropdown overlay={this.VisibilityKVDropdownMenu}
                        trigger={['click']}
                        onVisibleChange={this.handleVisibleChangeVisibilityKV}
              >
                <span
                  className={"hyd-dropdown hyd-dropdown-agents"}
                  onClick={e => e.preventDefault()}>
                  Видимость КВ <DropdownIcon className="hyd-dropdown-ico"/>
                </span>
              </Dropdown>
            </div>

            <div className={"agents-sorters-filters"}>
              <span className={"hyd-agents-grey-txt agents-sorters-txt"}>Сортировать по </span>
              <Dropdown overlay={this.SortersDropdownMenu}
                        trigger={['click']}
                        visible={this.state.dropdownVisibleSorters}
                        onVisibleChange={this.handleVisibleChangeSorters}
              >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  {this.state.SorterShortLabel} <DropdownIcon className="hyd-dropdown-ico"/>
                </a>
              </Dropdown>

              <Dropdown overlay={this.FiltersDropdownMenu}
                        trigger={['click']}
                        visible={this.state.dropdownVisibleFilters}
                        onVisibleChange={this.handleVisibleChangeFilters}
              >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <span className="count-filters-block">
                    Фильтр
                    {filtersCount > 0 ? <>
                      <CountIcon className="agents-count-ico"/>
                      <span className={
                        "filters-count" + (filtersCount > 9 ? " filters-count-more-9" : " filters-count-les-10")
                      }>
                        {filtersCount}
                      </span>
                    </> : ""}
                  </span>
                  <DropdownIcon className="hyd-dropdown-ico"/>
                </a>
              </Dropdown>

              <Dropdown overlay={this.SearchDropdownMenu}
                        trigger={['click']}
                        visible={this.state.dropdownVisibleSearch}
                        onVisibleChange={this.handleVisibleChangeSearch}
              >
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <span className="count-filters-block">
                    <span className={"hyd-agents-grey-txt agents-sorters-txt"}>Поиск по </span>
                    {this.state.SearchLabel} <DropdownIcon className="hyd-dropdown-ico"/>
                  </span>
                </a>
              </Dropdown>

              <a className={"agents-reset-filters"}
                 onClick={() => {
                   let getAgentsFunc = isAllAgentsReceived ? getAllAgents : getAgents
                   this.clearFilters()
                   this.clearSearchFilter()
                   clearAgentsFilters()
                   getAgentsFunc({"limit": pageSize, "offset": offset, "o": this.state.SorterSelectedValue})
                 }}>
                Сбросить фильтры
              </a>
            </div>

            <div className="hyd-tools-buttons-container">
              {Array.isArray(agents["results"]) &&
                agents["results"]
                  .map(agent => {
                      return (
                        <div className={"hyd-tool-btn-m"}>
                          <Button
                            className={
                              "hyd-agent-btn" + (selectedAgentsList.includes(agent.id) ? " hyd-agent-btn-selected" : "")
                            }
                            disabled={!agent["is_active"]}
                            onClick={() => {
                              addAgentToList(agent["id"])
                            }}
                          >
                            <div className={"hyd-agents-btn-data"}>
                              <span>
                                {!agent["is_active"] ?
                                  <span className="agent-status">
                                    Заблокирован <DisabledIcon style={{marginLeft: 5}}/>
                                  </span> : agent["is_online"] ?
                                    <span className="agent-status" style={{color: '#3BD041'}}>
                                    Онлайн <OnlineIcon style={{marginLeft: 5}}/>
                                  </span> :
                                    <span className="agent-status" style={{color: '#FF2943'}}>
                                    Оффлайн <OfflineIcon style={{marginLeft: 5}}/>
                                  </span>}
                              </span>
                              <span style={{fontSize: 14, fontWeight: 600, marginTop: 5}}>
                                {`${agent["last_name"]} ${agent["initials"]}`}
                              </span>
                              <span style={{whiteSpace: 'pre-wrap'}} className={"hyd-agents-grey-txt"}>
                                {agent["region"]}
                              </span>
                              <span className={"hyd-agents-grey-txt"}>
                                {agent["date_joined"]}
                              </span>
                              <span className={"agents-btn-footer"}>
                                <div>
                                  <span className={"hyd-agents-white-elem"}>
                                    {agent["user_type"]}
                                  </span>
                                  <span className={"hyd-agents-white-elem"}>
                                    {agent["kv_category"]}
                                  </span>
                                  <span className={"hyd-agent-icon"}>
                                    {
                                      agent["show_commission"] ?
                                        <EyeIcon/> :
                                        <EyeCrossedOutIcon className={"hyd-cross-eye-ico"}/>
                                    }
                                  </span>
                                </div>
                                <span>
                                  {
                                    selectedAgentsList.includes(agent.id) ?
                                      <LineWhite/> :
                                      <LineGrey/>
                                  }
                                </span>
                                <span
                                  className={
                                    "hyd-agents-grey-txt hyd-span-with-icon hyd-agent-icon" +
                                    (selectedSupervisorsList.includes(agent["parent_id"]) ?
                                      " supervisor-btn-selected" : "")
                                  }

                                >
                                  <PersonIcon style={{marginRight: 5}}/>
                                  {`${agent["parent_last_name"]} ${agent["parent_initials"]}`}
                                </span>
                              </span>
                            </div>
                          </Button>
                          <div className="agent-phone-icon-container">
                            <Tooltip title={agent["phone"]} overlayClassName={"hyd-tooltip"}>
                              <PhoneIcon className="agent-phone-icon"/>
                            </Tooltip>
                          </div>
                        </div>
                      )
                    }
                  )
              }
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              {userInfo && userInfo["is_superuser"] && this.switchAllAgents()}

              <Pagination
                total={agents["count"]}
                showSizeChanger
                pageSize={pageSize}
                onShowSizeChange={this.onShowSizeChange}
                onChange={this.onPageChange}
                pageSizeOptions={["12", "24"]}
                hideOnSinglePage={true}
                current={currentPage}
              />
            </div>

          </div>}
        <ModalError type={2}/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  token: tokenSelector(state),
  agents: agentsSelector(state),
  userInfo: userInfoSelector(state),
  pageSize: pageSizeSelector(state),
  isRequest: isRequestSelector(state),
  agentsTypes: agentsTypesSelector(state),
  currentPage: currentPageSelector(state),
  kvCategories: kvCategoriesSelector(state),
  filterShowKV: filterShowKVSelector(state),
  filterIsActive: filterIsActiveSelector(state),
  filterIsOnline: filterIsOnlineSelector(state),
  selectedAgentsList: selectedAgentsListSelector(state),
  selectedSupervisorsList: selectedSupervisorsListSelector(state),
  isAllAgentsReceived: isAllAgentsReceivedSelector(state),
  agentsTypesSelectedFilters: agentsTypesSelectedFiltersSelector(state),
  kvCategoriesSelectedFilters: kvCategoriesSelectedFiltersSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAgents,
      setPageSize,
      updateAgent,
      getAllAgents,
      setCurrentPage,
      addAgentToList,
      addSupervisorToList,
      getAgentsTypes,
      getKVCategories,
      setIsActiveFilter,
      setIsOnlineFilter,
      setIsShowKVFilter,
      clearAgentsFilters,
      clearIsRequestState,
      clearSelectedAgentsList,
      updateAgentsTypesFilter,
      updateKVCategoriesFilter,
      isAllAgentsReceivedToggle,
      getNotifications,
      checkEarnedMoneyAmount,
    },
    dispatch
  )


const WrappedAgentsForm = Form.create({name: 'agents'})(Agents)

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAgentsForm)
