import * as types from "../store/actionTypes";

export const goToNextStep = step => ({
  type: types.GO_TO_NEXT_STEP_KKM,
  payload: step
})

export const clearForm = (type) => ({
  type: types.CLEAR_FORM,
  payload: type,
})

export const goToPrevStep = step => ({
  type: types.GO_TO_PREV_STEP_KKM,
  payload: step
})

export const setDataForm = (data, object) => ({
  type: types.SET_DATA_FORM_KKM,
  payload: {data, object}
})

export const sendDataForm = () => ({
  type: types.SEND_DATA_FORM_KKM
})

export const getPrograms = () => ({
  type: types.GET_PROGRAMS_KKM
})

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
})

export const getAddressData = (address_data, address_type) => {
  return ({
    type: types.GET_KKM_ADDRESS_DATA,
    address_data: address_data,
    address_type: address_type,
  })
}

export const getPolicy = (payload) => ({
  type: types.GET_POLICY,
  payload: payload,
})

export const setCoverages = (payload) => ({
  type: types.SET_COVERAGES,
  payload: payload,
})

export const getInfoByVin = ({car_plate, vin}) => ({
  type: types.GET_INFO_BY_VIN_KKM,
  payload: {car_plate, vin}
})

export const getCarModels = (brand) => ({
  type: types.GET_CAR_MODELS,
  payload: brand
})

export const createPolicy = (draft_id) => ({
  type: types.CREATE_POLICY_KKM,
  payload: {draft_id}
})

export const getCarBrands = () => ({
  type: types.GET_CAR_BRANDS
})

export const clearCalcData = () => ({
  type: types.CLEAR_CALC_DATA_KKM
})

export const savePolicyOwnerCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.KKM_SAVE_POLICY_OWNER_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const saveCarOwnerCladr = (
  value,
  address,
  okato,
  country,
  region,
  zip,
  city_name,
  city_kladr,
  street_kladr,
  street_name,
  house,
  block,
  flat,
  fias_id,
  house_fias_id,
) => ({
  type: types.KKM_SAVE_CAR_OWNER_CLADR,
  payload: {
    value,
    address,
    okato,
    country,
    region,
    zip,
    city_name,
    city_kladr,
    street_kladr,
    street_name,
    house,
    block,
    flat,
    fias_id,
    house_fias_id,
  }
});

export const getPaymentStatus = (policyObjId) => ({
  type: types.GET_PAYMENT_STATUS_KKM,
  payload: {policyObjId}
})
