import React from "react";
import {ReactComponent as VoronkaIcon} from "../assets/Icons/Voronka.svg"
import {Button, Icon, Input, Tooltip} from "antd";
import Highlighter from "react-highlight-words";
import * as moment from "moment";

export const DRAFT = "Черновик"
export const ORDERED = "Оформлен"
export const BID = "Котировка"
export const DENIAL = "Отказ"

export const rowSelection = (selectPolicy) => ({
  type: "radio",
  onChange: (selectedRowKeys, selectedRows) => {
    selectPolicy(selectedRows[0])
  },
  getCheckboxProps: record => ({
    name: record.name,
    className: "hyd-radio-group",
  }),
})

export const getLastNameInitials = (fio) => {
  if (!fio) return ""

  // Кейс для ЮЛ
  const legalEntities = ["ООО", "ОАО", "АО", "ПАО", "НПАО", "ЗАО", "ГП", "МУП", "АНО", "НКО", "КФХ", "ТСЖ", "СНТ"];
  const words = fio.trim().split(/\s+/)
  if (legalEntities.includes(words[0])) {
    return fio.trim()  // Если первая часть — юридическая форма, возвращаем строку без изменений
  }

  // Удаляем лишние пробелы вокруг дефиса в фамилии
  const cleanedWords = fio.replace(/\s*-\s*/g, "-").split(/\s+/)
  const lastName = cleanedWords[0] // фамилия
  const initials = cleanedWords
    .slice(1)
    .map(word => word.slice(0, 1).toUpperCase() + ".")
    .join(" ")

  return `${lastName} ${initials}`.trim()
}

export const filterData = data => formatter => data.map(
  item => ({
    text: formatter(item),
    value: formatter(item)
  })
).filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i)

export const getFilterIcon = filtered => {
  return <VoronkaIcon className={filtered ? "hyd-voronka-ico-active" : "hyd-voronka-ico"}/>
}

export const getColumnSearchProps = (dataIndex, state, searchInput, handleSearch, handleReset) => ({
  filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
    <div style={{padding: 8}}>
      <Input
        ref={node => {
          searchInput = node
        }}
        placeholder={"Введите для поиска ..."}
        value={selectedKeys && selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{width: 188, marginBottom: 8, display: "block"}}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon="search"
        size="small"
        style={{width: 90, marginRight: 8}}
      >
        Поиск
      </Button>
      <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
        Сбросить
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon type="search" style={{color: filtered ? "#1890ff" : undefined}}/>
  ),
  onFilter: (value, record) => {
    if (record[dataIndex]) {
      if (["insurer_name", "seller_full_name", "manager"].includes(dataIndex)) {
        return record[dataIndex].split(" ")[0]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      } else {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      }
    } else return false
  },
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select())
    }
  },
  render: text =>
    state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
})

export const generateFilteredColumn = (Filters, filteredInfo, column, name, highlightText = undefined) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    dataIndex: column,
    key: column,
    filters: Filters? Filters[column] : [],
    filteredValue: filteredInfo[column] || null,
    onFilter: (value, record) => String(value) === record[column],
    filterIcon: filtered => getFilterIcon(filtered),
    width: "auto",
    render: text => text === highlightText ? <span style={{fontWeight: 700}}>{text}</span> : text,
  }
}

export const generateDateSortedColumn = (sortedInfo, column, name, with_time = true) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    dataIndex: column,
    key: column,
    render: text => moment(text).format(with_time ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"),
    sorter: (a, b) => new Date(a[column]) - new Date(b[column]),
    sortOrder: sortedInfo.columnKey === column && sortedInfo.order,
    width: "auto",
  }
}

export const generateSortedColumn = (sortedInfo, column, name) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    dataIndex: column,
    key: column,
    sorter: (a, b) => a[column] - b[column],
    sortOrder: sortedInfo.columnKey === column && sortedInfo.order,
    width: "auto",
  }
}

export const generateSearchedColumn = (state, searchInput, handleSearch, handleReset, column, name, isNeedInitialsRender = false) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    dataIndex: column,
    key: column,
    ...getColumnSearchProps(column, state, searchInput, handleSearch, handleReset),
    render: text => isNeedInitialsRender ? getLastNameInitials(text) : text,
    width: "auto"
  }
}

export const generateEllipsisColumn = (column, name) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    dataIndex: column,
    key: column,
    onCell: () => {
      return {
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 110,
        }
      }
    },
    render: text => (
      <Tooltip title={text}>
        <div style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</div>
      </Tooltip>
    ),
    width: "auto",
  }
}

export const generateIndexColumn = (column, name, pageNum, pageSize) => {
  return {
    title: <div className="hyd-table-header">{name}</div>,
    key: column,
    render: (value, item, index) => (pageNum - 1) * pageSize + index + 1,
    width: "auto",
  }
}
