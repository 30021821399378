import React, {Component} from "react";
import {Form, Input, Button, Select, AutoComplete, notification, Slider, Popconfirm, Tooltip} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  checkValueBirthDate,
  frontDateFormat,
  getDateFromString,
  hasErrors,
  nameRules,
  transform,
} from "../../utils";

import "./styles/index.scss";
import "../../fonts/open-sans.css"
import ModalError from "../../components/ModalError";
import FloatingLabelInput from "../../components/Form/FloatingLabeledInputs/FloatingLabelInput";
import {ReactComponent as ArrowRightSmallIcon} from "../../assets/Icons/ArrowRightSmall.svg";
import {ReactComponent as DocumentBlueIcon} from "../../assets/Icons/DocumentBlue.svg";
import {ReactComponent as ExclamationIcon} from "../../assets/Icons/ExclamationYellowSmallest.svg";
import {
  isRequestSelector,
  userAddressArrSelector,
  dataFormSelector,
  isEditModeOnSelector,
  errorsProfileSelector,
  updatingCompleteSelector,
  resetPasswordCompleteSelector,
  cardPaymentSystemSelector,
  creditLimitSelector,
  regionsSelector,
} from "../../selectors/profileSelectors";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";
import {
  clearIsRequestState,
  getProfile,
  setDataForm,
  getUserKladr,
  saveUserAddress,
  toggleUserSelectedAddress,
  saveCardPaymentSystem,
  toggleEditMode,
  updateProfile,
  resetPassword,
  getRegions,
  getBankByBIC,
} from "../../actions/profileActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions"
import Cleave from "cleave.js/react";
import Preloader from "../../components/Preloader";
import * as moment from "moment";
import * as keys from "../../routers/keys";
import personalDataProcessingConsent from "../../assets/files/common/personalDataProcessingConsent.pdf";

const {Option} = Select;
const agentsTxt = "Вы можете создать свою агентскую сеть и получать дополнительный доход. Подробнее у Вашего менеджера."

class Profile extends Component {
  state = {
    bicTimeoutId: undefined,
  }
  componentDidMount() {
    document.title = "Профиль"

    const {
      token,
      getProfile,
      getRegions,
      toggleEditMode,
      clearIsRequestState,
      getNotifications,
      checkEarnedMoneyAmount,
    } = this.props
    if (token) {
      getNotifications()
      getRegions()
      getProfile()
      checkEarnedMoneyAmount()
    }
    clearIsRequestState()
    toggleEditMode(false)
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {isRequest, updatingComplete, resetPasswordComplete,} = this.props
    if (updatingComplete !== prevProps.updatingComplete && !isRequest && updatingComplete) {
      notification['success']({
        message: `Профиль успешно обновлён`
      })
    }
    if (resetPasswordComplete !== prevProps.resetPasswordComplete && !isRequest && resetPasswordComplete) {
      notification['info']({
        message: `На ваш телефонный номер отправлено SMS с паролем.`
      })
    }
  }

  handleSubmit = (e) => {
    const {updateProfile} = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values, force = true) => {
      if (!err) {
        updateProfile(e.target.id)
      } else {
        console.log(err)
      }
    })
  }

  checkValueExperienceDate = (rule, value, callback) => {
    if (value) {
      let resultCurrentDate = new Date()
      let resultPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 55))
      let resultValueDate = value.split('.')
      if (moment(value, frontDateFormat, true).isValid()) {
        let result = getDateFromString(resultValueDate)
        if (result && (resultCurrentDate < result) || (result < resultPastDate)) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }

  checkValueCardDate = (rule, value, callback) => {
    if (value) {
      let currMonth = moment().startOf('month')
      let cardMonth = moment(value, "MM/YY")
      if (value.length === 5) {
        if (cardMonth < currMonth) {
          callback(" ")
        } else {
          callback()
        }
      } else {
        callback(" ")
      }
    } else {
      callback()
    }
  }
  handleBICInputChange = (event) => {
    const {getBankByBIC, form} = this.props
    const {validateFields} = form
    let value = event.target.value

    // Clear any existing timer
    clearTimeout(this.state.bicTimeoutId)

    // Set a new timer to trigger the function after a delay (e.g., 1000ms)
    this.setState({
      bicTimeoutId: setTimeout(() => {
        validateFields(["bank_bik"], (err,) => {
        if (!err) {
            getBankByBIC(value)
        }
      })
      }, 1000)
    })
  }
  clearJuristicFields = () => {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      juristic_name: null,
      juristic_boss_full_name: null,
      juristic_ogrn: null,
    })
  }

  clearFizFields = () => {
    const {setFieldsValue} = this.props.form;
    setFieldsValue({
      birth_date: null,
      gender: "M",
      snils: null,
      passport: null,
      passport_unite_code: null,
      passport_date: null,
      passport_issued_by: null,
      address: null,
    })
  }

  render() {
    const {
      isRequest,
      getUserKladr,
      saveUserAddress,
      addressArr,
      toggleUserSelectedAddress,
      toggleEditMode,
      resetPassword,
      isEditModeOn,
      dataForm,
      creditLimit,
      regions,
      userInfo,
      history,
    } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      getFieldValue,
      setFieldsValue,
    } = this.props.form;

    const marks = {
      0: {
        style: {
          fontSize: 16,
          color: "var(--color-two)",
          marginLeft: 11,
        },
        label: "0 ₽"
      },
      [creditLimit && Number(creditLimit["value"])]: {
        style: {
          fontSize: 16,
          color: "var(--color-two)",
          width: 180,
          paddingRight: 68,
        },
        label: creditLimit && Intl.NumberFormat('ru-RU').format(creditLimit["value"]) + " ₽"
      },
    }

    const receivables = undefined

    const username = "username";
    const usernameError = getFieldError(username);

    const accountType = "account_type";

    const lastName = "last_name";
    const lastNameError = getFieldError(lastName);

    const firstName = "first_name";
    const firstNameError = getFieldError(firstName);

    const middleName = "middle_name";
    const middleNameError = getFieldError(middleName);

    const phone = "phone";
    const phoneError = getFieldError(phone);

    const email = "email";
    const emailError = getFieldError(email);

    const region = "region";
    const regionError = getFieldError(region);


    const passport = "passport";
    const passportError = getFieldError(passport);

    const passportIssuedBy = "passport_issued_by";
    const passportIssuedByError = getFieldError(passportIssuedBy);

    const passportDate = "passport_date";
    const passportDateError = getFieldError(passportDate);

    const passportUniteCode = "passport_unite_code";
    const passportUniteCodeError = getFieldError(passportUniteCode);

    const address = "address";
    const addressError = getFieldError(address);

    const gender = "gender";

    const birthDate = "birth_date";
    const birthDateError = getFieldError(birthDate);

    const JurName = "juristic_name";
    const JurNameError = getFieldError(JurName);

    const JurBossName = "juristic_boss_full_name";
    const JurBossNameError = getFieldError(JurBossName);

    const JurOGRN = "juristic_ogrn";
    const JurOGRNError = getFieldError(JurOGRN);


    const inn = "inn";
    const innError = getFieldError(inn);

    const snils = "snils";
    const snilsError = getFieldError(snils);

    const bankAccount = "bank_account";
    const bankAccountError = getFieldError(bankAccount);

    const bankName = "bank_name";
    const bankNameError = getFieldError(bankName);

    const bankBik = "bank_bik";
    const bankBikError = getFieldError(bankBik);

    const bankKs = "bank_ks";
    const bankKsError = getFieldError(bankKs);

    const npd = "NPD"
    const ind = "IND"
    const fiz = "FIZ"
    const jur = "JUR"


    return (
      <>
        <Preloader loading={isRequest}/>
        <div className="profile-container">
          <div className="float-child-profile" style={{width: 627}}>
            <div className="hyd-profile-subheader">
              <span>Аккаунт</span>
            </div>
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              id="profile-account"
            >
              <div
                className="hyd-row-profile"
                style={{height: getFieldValue(accountType) === npd ? "85px" : "65px"}}
              >
                {/* Логин */}
                <Form.Item
                  validateStatus={usernameError ? "error" : ""}
                  help={usernameError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + username}
                    labelText={"Логин"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(username, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                      ],
                    })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                  />
                </Form.Item>

                {/* Тип аккаунта */}
                <Form.Item
                  required={false}
                >
                  <div className="hyd-select-small hyd-select-profile">
                    {getFieldDecorator(accountType, {
                      initialValue: fiz,
                      rules: [{required: true, message: " "}]
                    })(
                      <Select
                        className="hyd-input-select-small"
                        placeholder="Тип аккаунта"
                        disabled={!isEditModeOn}
                        onChange={(e,) => {
                          if ([fiz, npd].includes(e)) {
                            this.clearJuristicFields()
                          } else {
                            this.clearFizFields()
                          }
                        }}
                      >
                        <Option value={fiz}>
                          <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                          <div className={"hyd-select-value"}>Физическое лицо</div>
                        </Option>
                        <Option value={ind}>
                          <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                          <div className={"hyd-select-value"}>ИП</div>
                        </Option>
                        <Option value={npd}>
                          <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                          <div className={"hyd-select-value"}>Самозанятый</div>
                        </Option>
                        <Option value={jur}>
                          <div className={"hyd-select-tooltip"}>Тип аккаунта</div>
                          <div className={"hyd-select-value"}>Юридическое лицо</div>
                        </Option>
                      </Select>
                    )}
                    {getFieldValue(accountType) === npd &&
                      <div className="hyd-profile-hint">
                        <div>Нужно указать ИНН ниже</div>
                        <Tooltip
                          title={"Для типа аккаунта Самозанятый должен быть указан ИНН"}
                          placement={"bottom"}
                          overlayClassName={"hyd-tooltip"}
                        >
                          <span style={{marginLeft: "5px"}}><ExclamationIcon/></span>
                        </Tooltip>
                      </div>
                    }
                  </div>
                </Form.Item>
              </div>

              <div className="hyd-row-profile">
                {/* Фамилия */}
                <Form.Item
                  validateStatus={lastNameError ? "error" : ""}
                  help={lastNameError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + lastName}
                    labelText={"Фамилия"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(lastName, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: nameRules,
                    })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                  />
                </Form.Item>

                {/* Email */}
                <Form.Item
                  validateStatus={emailError ? "error" : ""}
                  help={emailError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + email}
                    labelText={"Email"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(email, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\s*)+$/g,
                          message: " "
                        },
                      ],
                    })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                  />
                </Form.Item>
              </div>

              <div className="hyd-row-profile">
                {/* Имя */}
                <Form.Item
                  validateStatus={firstNameError ? "error" : ""}
                  help={firstNameError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + firstName}
                    labelText={"Имя"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(firstName, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: nameRules,
                    })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                  />
                </Form.Item>

                {/* Телефон */}
                <Form.Item
                  validateStatus={phoneError ? "error" : ""}
                  help={phoneError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + phone}
                    labelText={"Телефон"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(phone, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^\+?([0-9])\)?[ ]?([0-9]{3})[- ]?([0-9]{3})[- ]?([0-9]{2})[- ]?([0-9]{2})$/g,
                          message: " "
                        },
                      ],
                    })(
                      <Cleave
                        className="ant-input hyd-input-small hyd-input-profile"
                        key={"phone-1"}
                        options={{
                          phone: true,
                          phoneRegionCode: 'RU',
                          prefix: "+7",
                          noImmediatePrefix: true,
                        }}
                        disabled
                      />
                    )}
                  />
                </Form.Item>
              </div>

              <div className="hyd-row-profile">
                {/* Отчество */}
                <Form.Item
                  validateStatus={middleNameError ? "error" : ""}
                  help={middleNameError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile"
                    htmlFor={"profile_" + middleName}
                    labelText={"Отчество"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(middleName, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          // только лат или только кириллица (допускается двойное имя с пробелом по центру или тире)
                          pattern: /^[А-Яа-яЁёa-zA-Z() -]+$/g,
                          message: " ",
                        },
                      ],
                    })(<Input className="hyd-input-small hyd-input-profile" disabled/>)}
                  />
                </Form.Item>

                {/* Регион */}
                <Form.Item
                  validateStatus={regionError ? "error" : ""}
                  help={regionError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-autocomplete-profile-input hyd-autocomplete-profile"
                    htmlFor={"profile_" + region}
                    labelText={"Регион"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(region, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [{required: true, message: " "}],
                    })(
                      <AutoComplete
                        className="hyd-input-autocomplete-small"
                        dataSource={regions && regions.map(_ => _.text)}
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        disabled={!isEditModeOn}
                      />
                    )}
                  />
                </Form.Item>
              </div>

              {[fiz, npd].includes(getFieldValue(accountType)) &&
                <>
                  <div className="hyd-profile-subheader">
                    <span>Личные данные</span>
                  </div>
                  <div className="hyd-row-profile">
                    {/* Дата рождения */}
                    <Form.Item
                      validateStatus={birthDateError ? "error" : ""}
                      help={birthDateError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + birthDate}
                        labelText={"Дата рождения"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(birthDate, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              validator: checkValueBirthDate,
                            },
                          ],
                        })(<Cleave
                          className="ant-input hyd-input-small hyd-input-profile"
                          key={birthDate}
                          options={{
                            date: true,
                            delimiter: ".",
                            datePattern: ["d", "m", "Y"]
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                    {/* Пол */}
                    <Form.Item>
                      <div className="hyd-select-small hyd-select-profile">
                        {getFieldDecorator(gender, {
                          initialValue: "M",
                          rules: [{required: true, message: " "}]
                        })(
                          <Select
                            className="hyd-input-select-small"
                            placeholder="Пол"
                            disabled={!isEditModeOn}
                          >
                            <Option value={"M"}>
                              <div className={"hyd-select-tooltip"}>Пол</div>
                              <div className={"hyd-select-value"}>Мужской</div>
                            </Option>
                            <Option value={"F"}>
                              <div className={"hyd-select-tooltip"}>Пол</div>
                              <div className={"hyd-select-value"}>Женский</div>
                            </Option>
                          </Select>
                        )}
                      </div>
                    </Form.Item>
                  </div>

                  <div className="hyd-row-profile">
                    {/* ИНН */}
                    <Form.Item
                      validateStatus={innError ? "error" : ""}
                      help={innError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + inn}
                        labelText={"ИНН"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(inn, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: getFieldValue(accountType) === npd,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{12}$/g,
                              message: " "
                            }
                          ],
                        })(<Input maxLength={12} className="hyd-input-small hyd-input-profile"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>

                    {/* СНИЛС */}
                    <Form.Item
                      validateStatus={snilsError ? "error" : ""}
                      help={snilsError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + snils}
                        labelText={"СНИЛС"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(snils, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{2}$/g,
                              message: " "
                            }
                          ],
                        })(<Cleave
                          key={"snils"}
                          className="ant-input hyd-input-small hyd-input-profile"
                          options={{
                            blocks: [3, 3, 3, 2],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                  </div>
                  <div className="hyd-profile-subheader-2">
                    <span>Паспорт</span>
                  </div>
                  <div className="hyd-row-profile">
                    {/* Серия и номер паспорта */}
                    <Form.Item
                      validateStatus={passportError ? "error" : ""}
                      help={passportError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-small"
                        htmlFor={"profile_" + passport}
                        labelText={"Серия и номер"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passport, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{4} [0-9]{6}$/g,
                              message: " "
                            },
                          ],
                        })(<Cleave
                          key={"passport"}
                          className="ant-input hyd-input-small hyd-profile-8-input"
                          options={{
                            blocks: [4, 6],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                    {/* Код подразделения */}
                    <Form.Item
                      validateStatus={passportUniteCodeError ? "error" : ""}
                      help={passportUniteCodeError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-small"
                        htmlFor={"profile_" + passportUniteCode}
                        labelText={"Код подразделения"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportUniteCode, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[0-9]{3} [0-9]{3}$/g,
                              message: " "
                            },
                          ],
                        })(<Cleave
                          key={"passport"}
                          className="ant-input hyd-input-small hyd-profile-8-input"
                          options={{
                            blocks: [3, 3],
                            delimiter: ' ',
                            numericOnly: false
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>
                    {/* Дата выдачи */}
                    <Form.Item
                      validateStatus={passportDateError ? "error" : ""}
                      help={passportDateError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-small"
                        htmlFor={"profile_" + passportDate}
                        labelText={"Дата выдачи"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportDate, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              validator: this.checkValueExperienceDate,
                            },
                          ],
                        })(<Cleave
                          className="ant-input hyd-input-small hyd-profile-8-input"
                          key={passportDate}
                          options={{
                            date: true,
                            delimiter: ".",
                            datePattern: ["d", "m", "Y"]
                          }}
                          disabled={!isEditModeOn}
                        />)}
                      />
                    </Form.Item>


                  </div>
                  <div className="hyd-row-profile">
                    {/* Кем выдан */}
                    <Form.Item
                      validateStatus={passportIssuedByError ? "error" : ""}
                      help={passportIssuedByError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-large"
                        htmlFor={"profile_" + passportIssuedBy}
                        labelText={"Кем выдан"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(passportIssuedBy, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                            {
                              pattern: /^[А-Яа-яЁё0-9\s-]{5,}$/g,
                              message: " "
                            },
                            {
                              pattern: /^.{5,}$/g,
                              message: " "
                            },
                          ],
                        })(<Input className="hyd-input-small hyd-input-profile-large" disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>
                  </div>
                  <div className="hyd-row-profile">

                    {/* Адрес регистрации */}
                    <Form.Item
                      validateStatus={addressError ? "error" : ""}
                      help={addressError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-autocomplete-profile-input-large hyd-autocomplete-profile-large"
                        htmlFor={"profile_" + address}
                        labelText={"Адрес регистрации"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(address, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              required: true,
                              message: " "
                            },
                          ],
                        })(
                          <AutoComplete
                            className="hyd-input-autocomplete-small"
                            dataSource={addressArr}
                            onSelect={(inputValue) => {
                              toggleUserSelectedAddress(true);
                              addressArr.map((value) => {
                                // достаем адрес из массива полученного из DaData
                                if (inputValue === value.value) {
                                  let valueOfInput = getFieldValue(address);

                                  if (valueOfInput.indexOf("_") !== -1) {
                                    valueOfInput = valueOfInput.split("_")
                                    setFieldsValue({
                                      address: valueOfInput[1],
                                    })
                                  }
                                  saveUserAddress(
                                    value.value_cladr,
                                    value.text,
                                    value.okato,
                                    value.country,
                                    value.region,
                                    value.zip,
                                    value.city_name,
                                    value.city_kladr,
                                    value.street_kladr,
                                    value.street_name,
                                    value.house,
                                    value.block,
                                    value.flat,
                                    value.fias_id,
                                    value.house_fias_id,
                                  );
                                }
                              });
                            }}

                            onChange={(value) => {
                              if (value && value.length > 1) {
                                getUserKladr({address: value});
                              }
                              toggleUserSelectedAddress(false);
                            }}
                            disabled={!isEditModeOn}
                          />
                        )}
                      />
                    </Form.Item>
                  </div>


                  <div className="hyd-profile-subheader">
                    <span>Реквизиты банковской карты</span>
                  </div>

                </>}

              {[ind, jur].includes(getFieldValue(accountType)) &&
                <>
                  <div className="hyd-row-profile" style={{marginTop: "20px"}}>
                    <Form.Item
                      validateStatus={JurNameError ? "error" : ""}
                      help={JurNameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-large"
                        htmlFor={"profile_" + JurName}
                        labelText={"Наименование"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(JurName, {
                          validateTrigger: ["onBlur", "onChange"],
                        })(<Input className="hyd-input-small hyd-input-profile-large"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>
                  </div>
                  <div className="hyd-row-profile">
                    <Form.Item
                      validateStatus={JurBossNameError ? "error" : ""}
                      help={JurBossNameError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile-large"
                        htmlFor={"profile_" + JurBossName}
                        labelText={"ФИО руководителя"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(JurBossName, {
                          validateTrigger: ["onBlur", "onChange"],
                        })(<Input className="hyd-input-small hyd-input-profile-large"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>
                  </div>
                  <div className="hyd-row-profile">
                    <Form.Item
                      validateStatus={innError ? "error" : ""}
                      help={innError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + inn}
                        labelText={"ИНН"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(inn, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [

                            {
                              pattern: getFieldValue(accountType) === jur ? /^[0-9]{10}$/g : /^[0-9]{12}$/g,
                              message: " "
                            }
                          ],
                        })(<Input maxLength={getFieldValue(accountType) === jur ? 10 : 12}
                                  className="hyd-input-small hyd-input-profile"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>
                    <Form.Item
                      validateStatus={JurOGRNError ? "error" : ""}
                      help={JurOGRNError || ""}
                    >
                      <FloatingLabelInput
                        divClassName="hyd-input-profile"
                        htmlFor={"profile_" + JurOGRN}
                        labelText={"ОГРН"}
                        divId={"float-label-small"}
                        disabled={!isEditModeOn}
                        child={getFieldDecorator(JurOGRN, {
                          validateTrigger: ["onBlur", "onChange"],
                          rules: [
                            {
                              pattern: getFieldValue(accountType) === jur ? /^[0-9]{13}$/g : /^[0-9]{15}$/g,
                              message: " "
                            }
                          ],
                        })(<Input maxLength={getFieldValue(accountType) === jur ? 13 : 15}
                                  className="hyd-input-small hyd-input-profile"
                                  disabled={!isEditModeOn}/>)}
                      />
                    </Form.Item>
                  </div>
                  <div className="hyd-profile-subheader">
                    <span>Банковский счет</span>
                  </div>
                </>}


              <div className="hyd-row-profile">
                {/* БИК */}
                <Form.Item
                  validateStatus={bankBikError ? "error" : ""}
                  help={bankBikError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile-small"
                    htmlFor={"profile_" + bankBik}
                    labelText={"БИК"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(bankBik, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^[0-9]{9}$/g,
                          message: " "
                        }
                      ],
                    })(<Input
                      maxLength={9}
                      className="ant-input hyd-input-small hyd-profile-8-input"
                      disabled={!isEditModeOn}
                      onChange={this.handleBICInputChange}
                    />)}
                  />
                </Form.Item>
                {/* К/с */}
                <Form.Item
                  validateStatus={bankKsError ? "error" : ""}
                  help={bankKsError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile-small"
                    htmlFor={"profile_" + bankKs}
                    labelText={"Кор. счет"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(bankKs, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^[0-9]{20}$/g,
                          message: " "
                        }
                      ],
                    })(<Input maxLength={20} className="ant-input hyd-input-small hyd-profile-8-input"
                              disabled={!isEditModeOn}/>)}
                  />
                </Form.Item>
                {/* Счёт получателя */}
                <Form.Item
                  validateStatus={bankAccountError ? "error" : ""}
                  help={bankAccountError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile-small"
                    htmlFor={"profile_" + bankAccount}
                    labelText={"Расч. счет"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(bankAccount, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          pattern: /^[0-9]{3} [0-9]{2} [0-9]{3} [0-9]{1} [0-9]{4} [0-9]{7}$/g,
                          message: " "
                        },
                      ],
                    })(<Cleave
                      key={"bankAccount"}
                      className="ant-input hyd-input-small hyd-profile-8-input"
                      options={{
                        blocks: [3, 2, 3, 1, 4, 7],
                        delimiter: ' ',
                        numericOnly: false
                      }}
                      disabled={!isEditModeOn}
                    />)}
                  />
                </Form.Item>
              </div>
              <div className="hyd-row-profile">
                {/* Банк */}
                <Form.Item
                  validateStatus={bankNameError ? "error" : ""}
                  help={bankNameError || ""}
                >
                  <FloatingLabelInput
                    divClassName="hyd-input-profile-large"
                    htmlFor={"profile_" + bankName}
                    labelText={"Наименование банка"}
                    divId={"float-label-small"}
                    disabled={!isEditModeOn}
                    child={getFieldDecorator(bankName, {
                      validateTrigger: ["onBlur", "onChange"],
                      rules: [
                        {
                          required: true,
                          message: " "
                        },
                        {
                          // только лат или только кириллица
                          pattern: /^(([А-Яа-яЁё])+(-|\s)?([А-Яа-яЁё]+))$|^(([A-za-z])+(-|\s)?([A-za-z]+))$/g,
                          message: " "
                        },
                      ],
                    })(<Input
                      className="hyd-input-small hyd-input-profile-large"
                      disabled={!isEditModeOn}
                    />)}
                  />
                </Form.Item>

              </div>
              <div className="hyd-row-profile">
                {!isEditModeOn &&
                  <Button
                    style={{marginTop: 12}}
                    className="hyd-btn-small"
                    onClick={() => {
                      toggleEditMode(true)
                    }}
                  >
                    Редактировать
                  </Button>}

                {isEditModeOn &&
                  <Button
                    style={{marginTop: 12}}
                    className="hyd-btn-small"
                    type="primary"
                    htmlType="submit"
                    form="profile-account"
                    key="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    Сохранить
                  </Button>}

                <div className="hyd-row-profile hyd-sms-pwd">
                  <Popconfirm
                    placement="top"
                    title={"Будет сформирован новый пароль и отправлен по SMS. Продолжить?"}
                    onConfirm={resetPassword}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button className="hyd-btn-small">Изменить пароль</Button>
                  </Popconfirm>
                </div>
              </div>

            </Form>

          </div>

          <div className="float-child-profile" style={{width: 283}}>

            <div className="hyd-profile-menu">
              <Tooltip title={!userInfo["has_agents"] ? agentsTxt : ""} placement={"bottom"}>
                <Button
                  className={`hyd-arrow-btn ${!userInfo["has_agents"] && "hyd-btn-disabled"}`}
                  onClick={() => {
                    userInfo["has_agents"] && history.replace(keys.AGENTS)
                  }}>
                  Агенты
                  <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
                </Button>
              </Tooltip>
            </div>

            <div className="hyd-profile-menu">
              <Button
                className="hyd-arrow-btn"
                onClick={() => {
                  history.replace(keys.REFERRAL_LINK)
                }}>
                Реферальная ссылка
                <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
              </Button>
            </div>

            <div className="hyd-profile-menu">
              <Button
                className="hyd-arrow-btn"
                style={{height: 152}}
                onClick={() => {
                  notification['warning']({
                    message: `Недоступно.`
                  })
                }}>
                <span className="hyd-profile-btn-main-txt">Агент “Онлайн”</span>
                <span className="hyd-profile-btn-txt">Ваш статус. Зарабатывайте больше, став агентом “Классик”</span>
                <div className="hyd-arrow-btn-ico"><ArrowRightSmallIcon/></div>
              </Button>
            </div>

            <div className="hyd-profile-menu">
              <div className="hyd-profile-menu-info">
                <span className="hyd-profile-btn-main-txt">Ваш менеджер</span>
                <div className="hyd-profile-menu-manager">
                  <span>{dataForm.supervisor_data && (dataForm.supervisor_data["last_name"] + " " + dataForm.supervisor_data["first_name"])}</span><br/>
                  <span className="hyd-profile-menu-txt">Телефон </span>
                  <span>{
                    dataForm.supervisor_data && dataForm.supervisor_data["sup_phone"] &&
                    dataForm.supervisor_data["sup_phone"].replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')
                  }</span><br/>
                  <span className="hyd-profile-menu-txt">Почта </span>
                  <span>{dataForm.supervisor_data && dataForm.supervisor_data["sup_email"]}</span>
                  {/*<a href={"mailto:" + dataForm.supervisor_data["sup_email"]}>{dataForm.supervisor_data["sup_email"]}</a>*/}
                </div>
              </div>
            </div>

            <div className="hyd-profile-menu-info">
              <span className="hyd-profile-btn-main-txt">Дебиторская задолженность</span>
              {creditLimit && Number(creditLimit["value"]) ?
                <div className="hyd-profile-slider-container">
                  <Slider
                    className={"hyd-profile-slider"}
                    trackStyle={{backgroundColor: "var(--color-four)"}}
                    marks={marks}
                    step={1}
                    min={0}
                    max={creditLimit && creditLimit["value"]}
                    value={receivables}  // TODO set the value of receivables after we decide where to get such value
                  />
                  <span className="hyd-receivables">
                    {Number.isInteger(receivables) ?
                      Intl.NumberFormat('ru-RU').format(receivables) + " ₽" : "Нет информации"}
                  </span>
                </div>
                : <div>Нет информации</div>}
            </div>

            <div className="hyd-profile-menu hyd-profile-menu-docs">
              <Button
                className="hyd-link-btn"
                onClick={event => {
                  event.stopPropagation()
                }}
                href={personalDataProcessingConsent}
                target="_blank"
                rel="noopener noreferrer"
                style={{margin: 0}}
              >
                <div className="hyd-profile-download-doc-ico">
                  <span className="hyd-profile-download-doc">
                    Согласие на обработку персональных данных
                  </span>
                  <DocumentBlueIcon/>
                </div>
              </Button>
            </div>

          </div>

        </div>
        <ModalError type={5}/>
      </>
    );
  }
}

const WrappedProfileForm = Form.create({
    name: 'profile',
    mapPropsToFields(props) {
      const {dataForm} = props;
      const transformed = transform({dataForm});

      return transformed.dataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(Profile);

const mapStateToProps = state => ({
  token: tokenSelector(state),
  isRequest: isRequestSelector(state),
  isEditModeOn: isEditModeOnSelector(state),
  updatingComplete: updatingCompleteSelector(state),
  resetPasswordComplete: resetPasswordCompleteSelector(state),
  cardPaymentSystem: cardPaymentSystemSelector(state),
  creditLimit: creditLimitSelector(state),
  addressArr: userAddressArrSelector(state),
  dataForm: dataFormSelector(state),
  errors: errorsProfileSelector(state),
  regions: regionsSelector(state),
  userInfo: userInfoSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearIsRequestState,
      setDataForm,
      getProfile,
      getUserKladr,
      saveUserAddress,
      toggleUserSelectedAddress,
      saveCardPaymentSystem,
      toggleEditMode,
      resetPassword,
      updateProfile,
      getRegions,
      getNotifications,
      checkEarnedMoneyAmount,
      getBankByBIC,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedProfileForm);
