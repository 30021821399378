import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {
  accidentsProductTypeOptions,
  constructField,
  frontDateFormat,
  backDateFormat,
  getDigits,
  validateNulls
} from "../utils";

import * as moment from "moment";


function clearListInsurers(dataForm) {
  return Object.keys(dataForm).reduce((object, key) => {
    //удаляем дополнительных водителей
    if (
      key.indexOf("additional_insurer_lastname_") === -1 &&
      key.indexOf("additional_insurer_name_") === -1 &&
      key.indexOf("additional_insurer_middlename_") === -1 &&
      key.indexOf("additional_insurer_birth_") === -1
    ) {
      //если совпадения не найдены
      object[key] = dataForm[key];
    }
    return object;
  }, {});
}

function transformDataFromBackend(data, dataForm) {
  validateNulls(data)
  for (let item in data) {
    if (item === "sport_kind") {
      dataForm[item] = constructField(item, data[item].map(String))
    } else if (item === "keys") {
      dataForm[item] = constructField(item, [...Array(data[item]).keys()])
    } else if (item === "insured_person") {
      if (data["policy_owner_is_insurer"]) {
        dataForm["main_insurer_last_name"] = constructField("main_insurer_last_name", data["insurer_last_name"])
        dataForm["main_insurer_name"] = constructField("main_insurer_name", data["insurer_first_name"])
        dataForm["main_insurer_middle_name"] = constructField("main_insurer_middle_name", data["insurer_middle_name"])
        dataForm["main_insurer_birth_date"] = constructField(
          "main_insurer_birth_date", moment(data["insurer_birth_date"], backDateFormat).format(frontDateFormat))
      } else {
        dataForm["main_insurer_last_name"] = constructField("main_insurer_last_name", data[item][0]["lastname"])
        dataForm["main_insurer_name"] = constructField("main_insurer_name", data[item][0]["firstname"])
        dataForm["main_insurer_middle_name"] = constructField("main_insurer_middle_name", data[item][0]["parentname"])
        dataForm["main_insurer_birth_date"] = constructField(
          "main_insurer_birth_date", moment(data[item][0]["birthday"], backDateFormat).format(frontDateFormat))
        data[item].shift()
      }
      let i = 0
      data[item].map(person => {
        if (
          data["insurer_last_name"] !== person["lastname"] ||
          data["insurer_first_name"] !== person["firstname"] ||
          data["insurer_middle_name"] !== person["parentname"] ||
          data["insurer_birth_date"] !== person["birthday"]
        ) {
          dataForm[`additional_insurer_lastname_${i}`] = constructField(`additional_insurer_lastname_${i}`, person["lastname"])
          dataForm[`additional_insurer_name_${i}`] = constructField(`additional_insurer_name_${i}`, person["firstname"])
          dataForm[`additional_insurer_middlename_${i}`] = constructField(`additional_insurer_middlename_${i}`, person["parentname"])
          dataForm[`additional_insurer_birth_${i}`] = constructField(
            `additional_insurer_birth_${i}`, moment(person["birthday"], backDateFormat).format(frontDateFormat))
          i++
        }
      })
    } else if (item === "insurer_address_fias") {
      dataForm[item] = data[item]
    } else if (item === "product_name") {
      dataForm[item] = constructField(item, accidentsProductTypeOptions.find(e => data[item] === e.value_back).value)
    } else if (item === "insurer_address") {
      dataForm[item] = data[item]
      dataForm["insurer_address_text"] = constructField("insurer_address_text", data[item])
    } else if (item === "territory") {
      dataForm[item] = constructField(item, data[item] === "РФ" ? 1 : 2)
    } else if (["start_policy_date", "finish_policy_date",
      "insurer_birth_date", "insurer_document_issue_date"].includes(item)) {
      dataForm[item] = constructField(item, moment(data[item], backDateFormat).format(frontDateFormat))
    } else {
      dataForm[item] = constructField(item, data[item])
    }
  }

  dataForm["series_number_document"] = constructField(
    "series_number_document", data["insurer_series_document"] + " " + data["insurer_number_document"])

  delete dataForm["object_id"]
  return dataForm;
}

function transformDataFormWithListAdditionalInsurers(dataForm) {
  let length = 0;
  let i = 0, j;
  let insured_person = [];
  let digits = [];

  Object.keys(dataForm).reduce((object, key) => {
    if (key.indexOf("additional_insurer_name_") !== -1) {
      //если совпадения найдены
      length++;
    }
  }, {});

  Object.keys(dataForm).reduce((object, key) => {
    //находим номера в названиях доп.застрахованных
    if (key.indexOf("additional_insurer_name_") !== -1) {
      let num = getDigits(key);
      if (!isNaN(getDigits(key)))
        digits.push(num);
    }
  }, {});

  if (dataForm.policy_owner_is_insurer.value) {
    insured_person.push({
      //первый в списке - страхователь
      lastname: dataForm.insurer_last_name.value,
      firstname: dataForm.insurer_first_name.value,
      parentname: dataForm.insurer_middle_name.value,
      birthday: moment(dataForm.insurer_birth_date.value, frontDateFormat).format(backDateFormat),
    });
  }
  if (!dataForm.policy_owner_is_insurer.value) {
    insured_person.push({
      lastname: dataForm.main_insurer_last_name.value,
      firstname: dataForm.main_insurer_name.value,
      parentname: dataForm.main_insurer_middle_name.value,
      birthday: moment(dataForm.main_insurer_birth_date.value, frontDateFormat).format(backDateFormat),
    });
  }

  for (i = 0, j = 1; i < length; i++, j++) {
    insured_person[j] = Object.keys(dataForm).reduce((object, key) => {
      //получаем массив с дополнительными водителями

      if (key.indexOf(`additional_insurer_lastname_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["lastname"] = dataForm[key].value;
      }
      if (key.indexOf(`additional_insurer_name_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["firstname"] = dataForm[key].value;
      }
      if (key.indexOf(`additional_insurer_middlename_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["parentname"] = dataForm[key].value;
      }
      if (key.indexOf(`additional_insurer_birth_${digits[i]}`) !== -1) {
        //если совпадения найдены
        object["birthday"] = moment(dataForm[key].value, frontDateFormat).format(backDateFormat);
      }

      return object;
    }, {});
  }

  let dataFormResult = clearListInsurers(dataForm); //удаляем доп страхователей как отдельные объекты
  if (insured_person)
    dataFormResult["insured_person"] = insured_person;
  //заносим доп.водителей в форме массива
  else {
    dataFormResult["insured_person"] = [];
  }

  // work with alien api
  dataFormResult["start_policy_date"] = moment(
    dataForm.start_policy_date.value, frontDateFormat).format(backDateFormat)
  dataFormResult["finish_policy_date"] = moment(
    dataForm.finish_policy_date.value, frontDateFormat).format(backDateFormat)
  dataFormResult["insurer_birth_date"] = moment(
    dataForm.insurer_birth_date.value, frontDateFormat).format(backDateFormat)
  dataFormResult["insurer_document_issue_date"] = moment(
    dataForm.insurer_document_issue_date.value, frontDateFormat).format(backDateFormat)
  dataFormResult["insurer_phone"] = "+" + getDigits(dataForm.insurer_phone.value).toString()
  dataFormResult["territory"] = dataForm.territory.value === 1 ? "РФ" : "Весь мир"
  dataFormResult["leisure"] = dataForm.product_name.value === 1
  dataFormResult["sport"] = dataForm.product_name.value === 2
  dataFormResult["sport_kind"] = dataForm.sport_kind.value ? dataForm.sport_kind.value.map(Number) : []
  dataFormResult["keys"] = dataForm.keys.value.length
  dataFormResult["is_professional"] = dataForm.is_professional.value
  dataFormResult["product_name"] = accidentsProductTypeOptions.find(e => dataForm.product_name.value === e.value).value_back

  return dataFormResult;
}

export default injectReducer(initialState.accidentsSportReducer, {

  [types.SET_DATA_ACCIDENTS_SPORT_FORM]: (state, action) => ({
    ...state,
    accidentsSportDataForm: {...state.accidentsSportDataForm, ...action.payload.data}
  }),

  [types.GO_TO_NEXT_STEP_ACCIDENTS_SPORT]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.GO_TO_PREV_STEP_ACCIDENTS_SPORT]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.GET_SUM]: (state, action) => {
    let sum = {sum_range: action.payload}
    return {
      ...state,
      accidentsSportDataForm: {...state.accidentsSportDataForm, ...sum}
    }
  },

  [types.GET_KINDS_OF_SPORTS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_KINDS_OF_SPORTS_SUCCESS]: (state, {payload}) => {
    let kindsOfSports = payload

    payload.map((item, i) => {
      kindsOfSports[i].value = `${item.id}`;
      kindsOfSports[i].text = `${item.name.toUpperCase()}`;
    })

    return {
      ...state,
      isRequest: false,
      kindsOfSports: kindsOfSports,
      errors: null
    }
  },

  [types.GET_KINDS_OF_SPORTS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalAccidentsSport: true
  }),

  [types.SAVE_SPORT_KIND_DATA]: (state, action) => {
    let obj = {sport_kind_data: action.payload};

    return {
      ...state,
      isRequest: false,
      accidentsSportDataForm: {...state.accidentsSportDataForm, ...obj},
      errors: null
    }
  },

  [types.SELECTED_INSURER_ADDRESS]: (state, {payload}) => {
    return {
      ...state,
      selectedInsurerAddress: payload
    }
  },

  [types.GET_INSURER_KLADR_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_INSURER_KLADR_SUCCESS]: (state, action) => {
    let address = action.payload;

    action.payload.map((item, i) => {
      address[i].value = item.text
    })

    return {
      ...state,
      isRequest: false,
      insurerAddressArr: address,
      errors: null
    };
  },

  [types.GET_INSURER_KLADR_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors.error},
    visibleErrorModalAccidentsSport: true
  }),

  [types.SAVE_KLADR]: (state, action) => {
    let obj = {};
    let key1 = `insurer_address_fias`;
    let key2 = `insurer_address`;

    obj[key1] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    return {
      ...state,
      isRequest: false,
      accidentsSportDataForm: {...state.accidentsSportDataForm, ...obj},
      errors: null
    };
  },

  [types.CHANGE_QUANTITY_ADDITIONAL_INSURERS]: (state, {payload}) => ({
    ...state,
    quantityAdditionalInsurers: payload
  }),

  [types.REMOVE_INSURER_FROM_LIST_INSURERS]: (state, action) => {
    // удаляем информацию про конкретного застрахованного
    let k = action.payload;
    let dataForm = state.accidentsSportDataForm;

    const newDataForm = Object.keys(dataForm).reduce(
      (object, key) => {
        if (
          `additional_insurer_lastname_${k}` !== key &&
          `additional_insurer_name_${k}` !== key &&
          `additional_insurer_middlename_${k}` !== key &&
          `additional_insurer_birth_${k}` !== key
        ) {
          object[key] = dataForm[key];
        }
        return object;
      },
      {}
    );

    return {
      ...state,
      accidentsSportDataForm: newDataForm
    };
  },

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.accidentsSportReducer
  }),

  [types.SEND_DATA_ACCIDENT_SPORT_FORM]: state => {
    let dataFormResult
    let stateAccidentsSportDataForm = state.accidentsSportDataForm

    dataFormResult = transformDataFormWithListAdditionalInsurers(stateAccidentsSportDataForm)

    return {
      ...state,
      dataFormResult: dataFormResult,
      calcResult: [],
      calculationComplete: false,
    };
  },

  [types.SEND_DATA_ACCIDENT_SPORT_FORM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.SEND_DATA_ACCIDENT_SPORT_FORM_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      calcResult: payload,
      errors: null
    };
  },

  [types.SEND_DATA_ACCIDENT_SPORT_FORM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalAccidentsSport: true
  }),

  [types.ADD_CALC_RESULT_ACCIDENT_SPORT]: (state, {payload}) => {
    return {
      ...state,
      calcResult: [...state.calcResult, payload.result],
    };
  },

  [types.ASSIGN_DRAFT_ID_ACCIDENT_SPORT]: (state, {payload}) => {
    const draft_id = payload.draft_id
    return {
      ...state,
      accidentsSportDataForm: {
        ...state.accidentsSportDataForm,
        draft_id,
      },
      dataFormResult: {
        ...state.dataFormResult,
        draft_id,
      },
    };
  },

  [types.CALC_ERROR_ACCIDENT_SPORT]: (state, {payload}) => ({
    ...state,
    errors: {errors: payload.errors},
    calculationInProgress: false,
    visibleErrorModalAccidentsSport: true
  }),

  [types.GET_ONE_TIME_TOKEN_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors},
    visibleErrorModalAccidentsSport: true
  }),

  [types.START_CALCULATION_ACCIDENT_SPORT]: (state) => ({
    ...state,
    currentStep: 1,
    calculationInProgress: true
  }),

  [types.CALC_COMPLETE_ACCIDENT_SPORT]: (state) => ({
    ...state,
    calculationInProgress: false,
    calculationComplete: true,
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModalAccidentsSport: payload
  }),

  [types.CREATE_POLICY_ACCIDENT_SPORT]: state => {
    return {
      ...state,
      dataFormResult: state.dataFormResult,
      urlDownloadPdf: undefined,
      policyCreated: false,
    };
  },

  [types.CREATE_POLICY_ACCIDENT_SPORT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    creatingPolicy: true,
    paymentStatus: undefined,
    documents: undefined,
    errors: null
  }),

  [types.CREATE_POLICY_ACCIDENT_SPORT_SUCCESS]: (state, {payload}) => ({
    ...state,
    errors: null,
    isRequest: false,
    policyCreated: true,
    creatingPolicy: false,
    documentsPolicy: payload,
    documents: payload.documents,
    policyObjId: payload.policyObjId,
  }),

  [types.CREATE_POLICY_ACCIDENT_SPORT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    policyCreated: false,
    creatingPolicy: false,
    errors: {errors: payload.errors},
    visibleErrorModalAccidentsSport: true
  }),

  [types.GET_PAYMENT_STATUS_ACCIDENT_SPORT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    paymentStatus: undefined,
    documents: undefined,
    errors: null,
  }),

  [types.GET_PAYMENT_STATUS_ACCIDENT_SPORT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      paymentStatus: payload.payment_status,
      documents: payload.documents,
      errors: null,
    };
  },

  [types.GET_PAYMENT_STATUS_ACCIDENT_SPORT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentStatus: undefined,
    errors: payload,
    visibleErrorModalAccidentsSport: true,
  }),


  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 4) {
      return {
        ...state,
        ...initialState.accidentsSportReducer
      };
    } else {
      return {
        ...state
      };
    }
  },

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.TOOGLE_IS_SELECTED_SPORT]: (state, {payload}) => {
    return {
      ...state,
      isSelectedSport: payload
    };
  },

  [types.RESUME_POLICY_ACCIDENTS_SPORT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_ACCIDENTS_SPORT_SUCCESS]: (state, {payload}) => {
    let stateAccidentsSportDataForm = {...initialState.accidentsSportReducer.accidentsSportDataForm}
    let dataFormResult = transformDataFromBackend(payload, stateAccidentsSportDataForm)
    return {
      ...state,
      accidentsSportDataForm: {
        ...dataFormResult
      },
      isRequest: false,
      errors: null,
    };
  },

  [types.RESUME_POLICY_ACCIDENTS_SPORT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalAccidentsSport: true,
  }),

  [types.COPY_POLICY_ACCIDENTS_SPORT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.COPY_POLICY_ACCIDENTS_SPORT_SUCCESS]: (state, {payload}) => {
    let stateAccidentsSportDataForm = {...initialState.accidentsSportReducer.accidentsSportDataForm}
    let dataFormResult = transformDataFromBackend(payload, stateAccidentsSportDataForm)
    return {
      ...state,
      accidentsSportDataForm: {
        ...dataFormResult,
        draft_id: undefined
      },
      isRequest: false,
      errors: null,
    };
  },

  [types.COPY_POLICY_ACCIDENTS_SPORT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalAccidentsSport: true,
  }),

  [types.PAY_POLICY_ACCIDENTS_SPORT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.PAY_POLICY_ACCIDENTS_SPORT_SUCCESS]: (state, {payload}) => {
    let documentsPolicy = {
      "key": payload["insurance"]["ins_key"],
      "props_id": payload["props_id"],
      "policyObjId": payload["id"],
      "type": payload["type"],
      "price": payload["premium_sum"],
      "documents": payload["documents"],
      "product_type": payload["product_type"],
      "insurance_name": payload["insurance_name"],
      "agent_commission": payload["agent_commission"],
      "payment_url": payload["payment_url"],
    }
    let stateAccidentsSportDataForm = {...initialState.accidentsSportReducer.accidentsSportDataForm}
    let dataFormResult = transformDataFromBackend(payload, stateAccidentsSportDataForm)
    return {
      ...state,
      accidentsSportDataForm: {
        ...dataFormResult
      },
      documentsPolicy: documentsPolicy,
      documents: payload["documents"],
      currentStep: 2,
      isRequest: false,
      errors: null,
    };
  },

  [types.PAY_POLICY_ACCIDENTS_SPORT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalAccidentsSport: true,
  }),

});
