import {Button, Col, Divider, Row, Tooltip} from "antd";
import {downloadDocsButtonTxt, get_doc, numToRubbles, paidPaymentStatus} from "../../utils";
import {ReactComponent as QuestionIcon} from "../../assets/Icons/Question.svg";
import {ReactComponent as RefreshWhiteIcon} from "../../assets/Icons/OneClickPolicy/RefreshW.svg";
import {ReactComponent as RefreshBlackIcon} from "../../assets/Icons/OneClickPolicy/RefreshB.svg";
import React from "react";


export const crossProductsForm = (getCrossProducts, crossProducts, draftId, userInfo) => {

  return (
    <>
      {crossProducts && Array.isArray(crossProducts) && crossProducts.length > 0 &&
        <Row gutter={8} className={"one-click-policy-container"}>
          <div className={"one-click-policy-header"}>
            Оформи полис в 1 клик. Данные заполнены
          </div>
          {crossProducts.map((
            {
              header,
              insName,
              tooltipMsg,
              paymentStatus,
              documents,
              price,
              agent_commission,
              payment_url,
              additional_info,
              insurance_amount,
              risks,
            }) =>
            <Col span={4} className={"one-click-policy-entity"}>
              <div className={"one-click-policy-entity-header"}>
                {header}
                <Tooltip
                  trigger="hover"
                  title={tooltipMsg}
                  overlayClassName={"hyd-tooltip"}
                >
                  <QuestionIcon className="hyd-q-ico-5"/>
                </Tooltip>
              </div>
              <Row style={{fontSize: 14, marginBottom: 20}}>
                {insName}
              </Row>

              {paymentStatus && paymentStatus === paidPaymentStatus ?
                <Button
                  className={"hyd-btn-small hyd-policy-paid one-click-policy-btn"}
                  style={{width: 253}}
                >
                  Оплачено
                </Button> :
                <>
                  <Button
                    className={"hyd-btn-small one-click-policy-btn"}
                    style={{marginRight: 3}}
                    type="primary"
                    onClick={() => {
                      window.open(payment_url)
                    }}
                  >
                    Оплатить
                  </Button>

                  <Tooltip
                    trigger="hover"
                    title="Проверить статус оплаты"
                    overlayClassName={"hyd-tooltip"}
                  >
                    <Button
                      className={"hyd-btn-small one-click-policy-refresh-btn"}
                      type="primary"
                      onClick={() => {
                        getCrossProducts(draftId)
                      }}
                    >
                      <div>
                        <RefreshWhiteIcon className="hyd-refresh-ico icon-default"/>
                        <RefreshBlackIcon className="hyd-refresh-ico icon-hover"/>
                      </div>
                    </Button>
                  </Tooltip>
                </>
              }

              {documents && documents.map((item, index) => {
                return (item.name && (downloadDocsButtonTxt(get_doc, item, index)))
              })}

              {price && <>
                <Row className={"one-click-policy-result-head"}>Стоимость</Row>
                <Row className={"one-click-policy-result-value"}>
                  {numToRubbles(price)}
                </Row>
              </>}

              {userInfo.show_commission && agent_commission && <>
                <Row className={"one-click-policy-result-head"}>Комиссия агента</Row>
                <Row className={"one-click-policy-result-value one-click-policy-result-commission"}>
                  {numToRubbles(Number(agent_commission) * Number(price) / 100.0)}
                  <span className={"one-click-policy-result-value one-click-policy-result-commission-percent"}>
                      {" / "}{agent_commission}%
                    </span>
                </Row>
              </>}

              <Divider style={{margin: "16px 0", background: "var(--color-two)"}}/>

              {additional_info && <Row className={"one-click-policy-add-info"}>{additional_info}</Row>}

              {insurance_amount && <Row className={"one-click-policy-add-info"}>
                Страховая сумма {numToRubbles(insurance_amount)}
              </Row>}

              {risks && <>
                <Row className={"one-click-policy-add-info"}>Риски:</Row>
                {risks.map((risk) => <Row className={"one-click-policy-add-info"}>— {risk}</Row>)}
              </>}

            </Col>
          )}
        </Row>
      }
    </>
  )
}
