import React, {Component} from "react";
import {Row, Form, Button, Popover} from "antd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  goToPrevStep,
  getPaymentStatus,
  paymentLinkToEmail,
} from "../../actions/mortgageActions";
import {
  isRequestSelector,
  currentStepSelector,
  mortgageDataFormSelector,
  calcResultSelector,
  creatingPolicySelector,
  documentsPolicySelector,
  paymentStatusSelector,
  documentsSelector,
  paymentDocsSelector,
  paymentDocTypeSelector,
} from "../../selectors/mortgageSelectors";
import {tokenSelector, userInfoSelector} from "../../selectors/loginSelectors";
import ModalError from "../../components/ModalError";
import {downloadDocsButton, get_doc, transform} from "../../utils";
import {ALFA, MORTGAGE, RENINS, VSK} from "../../constants";
import "./styles/index.scss";
import "../../fonts/open-sans.css"
import {ReactComponent as NumberIcon} from "../../assets/Icons/Number.svg";
import FirstStepDisabled from "../../components/MortgageDisabledSteps/FirstStepDisabled";
import SecondStepDisabled from "../../components/MortgageDisabledSteps/SecondStepDisabled";
import ThirdStepDisabled from "../../components/MortgageDisabledSteps/ThirdStepDisabled";
import FourthStepDisabled from "../../components/MortgageDisabledSteps/FourthStepDisabled";
import {ReactComponent as ErrorMarkIcon} from "../../assets/Icons/ErrorMark.svg";

class FifthStepMortgage extends Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    this.headerRef.current.scrollIntoView()
    // this.props.getConfig()
  }

  componentDidUpdate(prevProps, prevState, snapshot?) {
    const {isRequest, paymentDocs, paymentDocType} = this.props;

    if (paymentDocs !== prevProps.paymentDocs && !isRequest && paymentDocs) { //открытие Документов заявление, копия полиса
      let docType
      if (paymentDocType) {
        docType = paymentDocType
      } else {
        docType = 'application/pdf'
      }
      let file = new Blob([paymentDocs], {type: docType});
      let fileURL = URL.createObjectURL(file);
      fileURL && window.open(fileURL);
    }
  }

  render() {
    const {
      documentsPolicy,
      paymentLinkToEmail,
      getPaymentStatus,
      calcResult,
      paymentStatus,
      documents,
      userInfo,
    } = this.props

    return (
      <>
        <FirstStepDisabled/>
        <SecondStepDisabled/>
        <ThirdStepDisabled/>
        <FourthStepDisabled
          value={calcResult.find(i => ((i.key === documentsPolicy.key) && (i.props_id === documentsPolicy.props_id) && (i.type === documentsPolicy.product_type)))}
          userInfo={userInfo}
        />

        <div className="step-title-active" ref={this.headerRef}>
          <div className="step-icon-block">
            <NumberIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">5</span>
          </div>

          <span className="step-title-active-header">Оплата</span>
        </div>

        {documents &&
          <Row>
            {documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}

        {documentsPolicy && (
          <>
            {[ALFA, VSK, RENINS].includes(documentsPolicy.key) &&
              documentsPolicy.product_type === MORTGAGE && (
                <>
                  {["paid"].includes(paymentStatus) ?
                    <Button className={"hyd-btn-small hyd-policy-paid"}>Полис оплачен</Button> :
                    <>
                      <div className={"hyd-accident-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          onClick={() => {
                            if ([ALFA,].includes(documentsPolicy.key)) {
                              paymentLinkToEmail(documentsPolicy)
                            } else {
                              window.open(documentsPolicy["payment_url"])
                            }
                          }}
                          type="primary"
                        >
                          {[ALFA,].includes(documentsPolicy.key) ? "Отправить ссылку на Email" : "Оплата картой"}
                        </Button>
                      </div>
                      <div className={"hyd-accident-payment"}>
                        <Button
                          className={"hyd-btn-small"}
                          type="primary"
                          onClick={() => {
                            getPaymentStatus(documentsPolicy)
                          }}
                        >
                          Проверить статус оплаты
                        </Button>
                      </div>
                    </>
                  }
                  {paymentStatus && <div>
                    {!["paid"].includes(paymentStatus) ?
                      <div className={"hyd-not-paid hyd-not-paid-mortgage"}>
                        <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
                        <span className={"hyd-not-paid-txt"}>Не оплачен</span>
                      </div> : ""
                    }
                  </div>}
                </>
              )}

            {documentsPolicy &&
              documentsPolicy.payment_url &&
              documentsPolicy.payment_url.errors &&
              documentsPolicy.payment_url.errors.error && (
                <div className={"hyd-accident-payment"}>
                  <Popover
                    content={documentsPolicy.payment_url.errors.error}
                    title="Ошибка"
                    trigger="hover"
                  >
                    <Button
                      className={"hyd-btn-small"}
                      type="primary"
                      disabled={true}
                    >
                      Оплата картой
                    </Button>
                  </Popover>
                </div>
              )}
          </>
        )}

        <ModalError type={7}/>
      </>
    );
  }
}

const WrappedFifthStepMortgageForm = Form.create({
    name: "mortgage-step-5",
    mapPropsToFields(props) {
      const {mortgageDataForm} = props;
      const transformed = transform({mortgageDataForm});

      return transformed.mortgageDataForm;
    },
    onFieldsChange(props, changedFields) {
      props.setDataForm(changedFields);
    },
  }
)(FifthStepMortgage);

const mapStateToProps = (state) => ({
  isRequest: isRequestSelector(state),
  currentStep: currentStepSelector(state),
  calcResult: calcResultSelector(state),
  creatingPolicy: creatingPolicySelector(state),
  documentsPolicy: documentsPolicySelector(state),
  token: tokenSelector(state),
  paymentDocs: paymentDocsSelector(state),
  mortgageDataForm: mortgageDataFormSelector(state),
  paymentStatus: paymentStatusSelector(state),
  paymentDocType: paymentDocTypeSelector(state),
  documents: documentsSelector(state),
  userInfo: userInfoSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPrevStep,
      getPaymentStatus,
      paymentLinkToEmail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WrappedFifthStepMortgageForm);
