import * as types from "../store/actionTypes";

export const clearIsRequestState = () => ({
    type: types.CLEAR_IS_REQUEST_STATE
});

export const signUp = (value) => ({
    type: types.SIGN_UP,
    payload: value,
});

export const saveRefId = (value) => ({
    type: types.SAVE_REF_ID,
    payload: value,
});

export const setRegionId = (value) => ({
    type: types.SET_REGION_ID,
    payload: value,
});
