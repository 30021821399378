import React from "react";
import {Button, Row} from "antd";
import {connect} from "react-redux";
import InsurersDisabled from "../InsurersDisabled";
import {ReactComponent as NumberInactiveIcon} from "../../../assets/Icons/NumberInactive.svg";
import {accidentsSportDataFormSelector, kindsOfSportsSelector} from "../../../selectors/accidentsSportSelectors";
import {accidentsProductTypeOptions} from "../../../utils";

const territory_options = [
  {
    value: 1,
    text: "РФ"
  },
  {
    value: 2,
    text: "Весь мир"
  },
]

const risks_options = [
  {
    value: "physical_injuries",
    text: "Травма в результате НС"
  },
  {
    value: "disability",
    text: "Инвалидность 1, 2 группы в результате НС"
  },
  {
    value: "death",
    text: "Смерть в результате НС"
  },
]


class FirstStepDisabled extends React.Component {
  render() {
    const {
      dataForm,
      goToPrevStep,
      kindsOfSports,
    } = this.props

    const showPolicyOwnerInsurer = () =>
      <InsurersDisabled
        insurerNumber={1}
        lastName={dataForm.insurer_last_name.value}
        firstName={dataForm.insurer_first_name.value}
        middleName={dataForm.insurer_middle_name ? dataForm.insurer_middle_name.value : ""}
        birthDate={dataForm.insurer_birth_date.value}
      />

    const showMainInsurer = () =>
      <InsurersDisabled
        insurerNumber={1}
        lastName={dataForm.main_insurer_last_name.value}
        firstName={dataForm.main_insurer_name.value}
        middleName={dataForm.main_insurer_middle_name ? dataForm.main_insurer_middle_name.value : ""}
        birthDate={dataForm.main_insurer_birth_date.value}
      />

    const formItems = () => dataForm.keys.value.map((k, index) => {
      return (
        <InsurersDisabled
          insurerNumber={index + 2}
          lastName={dataForm[`additional_insurer_lastname_${k}`].value}
          firstName={dataForm[`additional_insurer_name_${k}`].value}
          middleName={dataForm[`additional_insurer_middlename_${k}`].value}
          birthDate={dataForm[`additional_insurer_birth_${k}`].value}
        />
      )
    })

    const filteredKindsOfSports = () => dataForm.sport_kind.value.map((sport) => {
      return (
        <div>{kindsOfSports.find(e => sport === e.value).text}</div>
      )
    })

    return (
      <>
        <div className="step-title-disabled-small">
          <div className="step-icon-block">
            <NumberInactiveIcon className="hyd-large-num-ico"/>
            <span className="hyd-large-num-ico-num">1</span>
          </div>
          <span className="step-title-disabled-header">Оформление</span>
          {goToPrevStep &&
          <Button
            className="hyd-change-button-small"
            onClick={goToPrevStep}
          >
            Изменить
          </Button>}

        </div>
        <Row
          type="flex"
          style={{alignItems: "top"}}
        >
          {dataForm.duration_insurance &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Срок страхования</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.duration_insurance.value === 1 ? "1 год" : "Краткосрочный договор"}
            </div>
          </div>}

          {dataForm.start_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата начала действия полиса</div>
              <div className={"hyd-prev-step-value"}>{dataForm.start_policy_date.value}</div>
            </div>}
          {dataForm.finish_policy_date &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Дата окончания действия полиса</div>
              <div className={"hyd-prev-step-value"}>{dataForm.finish_policy_date.value}</div>
            </div>}

          {dataForm.product_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Тип страхования</div>
            <div className={"hyd-prev-step-value"}>
              {accidentsProductTypeOptions.find(e => dataForm.product_name.value === e.value).text}
            </div>
          </div>}

          {dataForm.product_name.value === 2 && dataForm.sport_kind.value && kindsOfSports &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Виды спорта</div>
            <div className={"hyd-prev-step-value"}>
              {filteredKindsOfSports()}
            </div>
          </div>}

          {dataForm.is_professional &&
            <div className={"hyd-prev-step"}>
              <div className={"hyd-prev-step-header"}>Спортсмены-профессионалы</div>
              <div className={"hyd-prev-step-value"}>
                {dataForm.is_professional.value ? "ДА" : "НЕТ"}
              </div>
            </div>}

          {dataForm.territory &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Территория страхования</div>
            <div className={"hyd-prev-step-value"}>
              {territory_options.find(e => dataForm.territory.value === e.value).text}
            </div>
          </div>}

          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Риски</div>
            <div className={"hyd-prev-step-value"}>
              {
                dataForm.physical_injuries.value &&
                <div>{risks_options.find(e => dataForm.physical_injuries.name === e.value).text}</div>
              }
              {
                dataForm.disability.value &&
                <div>{risks_options.find(e => dataForm.disability.name === e.value).text}</div>
              }
              {dataForm.death.value && <div>{risks_options.find(e => dataForm.death.name === e.value).text}</div>}
            </div>
          </div>
        </Row>

        <Row>
          {dataForm.sum_range &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Покрытие</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.sum_range.value}
            </div>
          </div>}

          {dataForm.insurer_last_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Фамилия</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_last_name.value}
            </div>
          </div>}

          {dataForm.insurer_first_name &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Имя</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_first_name.value}
            </div>
          </div>}

          {dataForm.insurer_middle_name.value &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Отчество</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_middle_name.value}
            </div>
          </div>}

          {dataForm.insurer_birth_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата рождения</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_birth_date.value}
            </div>
          </div>}
        </Row>

        <Row>
          {dataForm.series_number_document &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Серия и номер паспорта</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.series_number_document.value}
            </div>
          </div>}

          {dataForm.insurer_document_issue_date &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Дата выдачи</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_document_issue_date.value}
            </div>
          </div>}

          {dataForm.insurer_document_issue_by &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Кем выдан</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_document_issue_by.value}
            </div>
          </div>}

          {dataForm.insurer_address &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Адрес регистрации</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_address}
            </div>
          </div>}
        </Row>

        <Row>
          {dataForm.insurer_email &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>E-mail</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_email.value}
            </div>
          </div>}

          {dataForm.insurer_phone &&
          <div className={"hyd-prev-step"}>
            <div className={"hyd-prev-step-header"}>Телефон</div>
            <div className={"hyd-prev-step-value"}>
              {dataForm.insurer_phone.value}
            </div>
          </div>}
        </Row>

        <Row>
          {dataForm.policy_owner_is_insurer.value && showPolicyOwnerInsurer()}

          {!dataForm.policy_owner_is_insurer.value && showMainInsurer()}

          {dataForm.keys && formItems()}
        </Row>

      </>
    )
  }
}

const mapStateToProps = (state) => (
  {
    kindsOfSports: kindsOfSportsSelector(state),
    dataForm: accidentsSportDataFormSelector(state),
  });

export default connect(mapStateToProps)(FirstStepDisabled);