import React from "react";
import {Button, Popconfirm, Popover, Row} from "antd";
import {ReactComponent as ErrorMarkIcon} from "../../../assets/Icons/ErrorMark.svg";
import {downloadDocsButton, get_doc} from "../../../utils";
import {eOSAGO, eBSO, BSO, ALFA, RENINS, INGOS, VSK, RGS} from "../../../constants";

export default class IssuingProlongationPolicy extends React.Component {
  render() {
    const {
      documentsNSS,
      configAdmin,
      documentsPolicy,
      doPaymentNSS,
      payment_status,
      alfa_docs,
      ingostrah_docs,
      getPaymentStatus,
      renessans_docs,
      insuranceDocuments,
      userInfo
    } = this.props

    const is_original_documents = !!alfa_docs || !!renessans_docs || !!insuranceDocuments ||
      (ingostrah_docs && payment_status === 'paid') ||
      (documentsNSS && documentsNSS.documents && Array.isArray(documentsNSS.documents))


    return (
      <>

        <div className="step-title-active" ref={this.headerRef}>
          <span>Предварительный просмотр документов</span>
        </div>

        {documentsNSS && documentsNSS.documents &&
          Array.isArray(documentsNSS.documents) &&
          <Row>
            {documentsNSS.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>}

        {this.props.alfa_docs &&
          <Row>
            {alfa_docs.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
          </Row>
        }

        {this.props.ingostrah_docs && payment_status === 'paid' &&
          <Row>
            {
              this.props.ingostrah_docs.map((item, index) => {
                return (item.name && (downloadDocsButton(get_doc, item, index)))
              })
            }
          </Row>
        }
        {this.props.renessans_docs && payment_status === "paid" &&
          <Row>
            {
              this.props.renessans_docs.map((item, index) => {
                return (item.name && (downloadDocsButton(get_doc, item, index)))
              })
            }
          </Row>
        }
        {this.props.insuranceDocuments && payment_status === "paid" &&
          <Row>
            {
              this.props.insuranceDocuments.map((item, index) => {
                return (item.name && (downloadDocsButton(get_doc, item, index)))
              })
            }
          </Row>
        }

        <Row>
          {/* Документы заявление, копия полиса, Представители */}
          {!is_original_documents && documentsPolicy &&
            Array.isArray(documentsPolicy.documents) &&
            documentsPolicy.documents.map((item, index) => {
              return (item.name && (downloadDocsButton(get_doc, item, index)))
            })}
        </Row>

        {configAdmin.offline_payment && userInfo.user_type !== "AGENT_ONLINE" && documentsPolicy &&
          <>
            { [BSO, eBSO].includes(documentsPolicy.product_type) &&
              ([INGOS, ALFA, VSK].includes(documentsPolicy.key) )
              && (
                <>
                  {!is_original_documents ?
                    <div className={"hyd-osago-step6-payment"}>
                      <Popconfirm
                        placement="top"
                        title={"Подтвердить оформление и выпуск полиса?"}
                        onConfirm={() => doPaymentNSS({
                              insKey: documentsPolicy.key,
                              policyObjId: documentsPolicy.policyObjId,
                            })}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button
                          className={"hyd-btn-small"}
                          style={{marginRight: 30}}
                          type="primary"
                        >
                          Сформировать квитанцию
                        </Button>
                      </Popconfirm>
                    </div> :
                    <Button
                      className={"hyd-osago-step6-payment hyd-btn-small hyd-policy-paid"}>
                      Полис оплачен
                    </Button>
                  }
                </>
              )}
          </>
        }

        <Row>
          {configAdmin.online_payment && documentsPolicy && (
            <>
              {(([ALFA, INGOS, RENINS, RGS].includes(documentsPolicy.key) &&
                documentsPolicy.product_type === eOSAGO) || [RENINS,].includes(documentsPolicy.key)) &&  (
                  <>
                    {payment_status === "paid" ?
                      <Button className={"hyd-btn-small hyd-policy-paid hyd-policy-paid-m"}>Полис оплачен</Button> :
                      <>
                        <div className={"hyd-osago-step6-payment"}>
                          <Button
                            className={"hyd-btn-small"}
                            onClick={() => {
                              window.open(documentsPolicy["payment_url"])
                            }}
                            type="primary"
                          >
                            Оплата картой
                          </Button>
                        </div>
                        <div className={"hyd-osago-step6-payment"}>
                          <Button
                            className={"hyd-btn-small"}
                            type="primary"
                            onClick={() => {
                              getPaymentStatus({
                                insKey: documentsPolicy.key,
                                policyObjId: documentsPolicy.policyObjId,
                              })
                            }}
                          >
                            Проверить статус оплаты
                          </Button>

                        </div>
                      </>
                    }
                  </>
                )}
            </>
          )}

          {documentsPolicy &&
            documentsPolicy.payment_url &&
            documentsPolicy.payment_url.errors &&
            documentsPolicy.payment_url.errors.error && (
              <div className={"hyd-osago-step6-payment"}>
                <Popover
                  content={documentsPolicy.payment_url.errors.error}
                  title="Ошибка"
                  trigger="hover"
                >
                  <Button
                    className={"hyd-btn-small"}
                    type="primary"
                    disabled={true}
                  >
                    Оплата картой
                  </Button>
                </Popover>
              </div>
            )}
        </Row>

        {payment_status &&
        !(payment_status === "paid") ?
          <div className={"hyd-not-paid hyd-not-paid-prolong"}>
            <span className={"hyd-not-paid-ico"}><ErrorMarkIcon/></span>
            <span className={"hyd-not-paid-txt"}>Не оплачен</span>
          </div> : ""
        }

      </>
    )
  }
}