export const visibleToolsModalSelector = state => state.mainReducer.visibleToolsModal;
export const toolsListSelector = state => state.mainReducer.toolsList;
export const tempToolsListSelector = state => state.mainReducer.tempToolsList;
export const favoritesToolsSelector = state => state.mainReducer.favoritesTools;
export const activeBannersSelector = state => state.mainReducer.activeBanners;
export const notificationsSelector = state => state.mainReducer.notifications;
export const isRequestSelector = state => state.mainReducer.isRequest;
export const visibleNotificationsModalSelector = state => state.mainReducer.visibleNotificationsModal;
export const earnedBonusesSelector = state => state.mainReducer.earnedBonuses;
export const earnedMoneySelector = state => state.mainReducer.earnedMoney;
export const availableProductsSelector = state => state.mainReducer.availableProducts;
