import * as types from "../store/actionTypes";

export const getPaymentActsList = () => {
  return ({
    type: types.GET_PAYMENT_ACTS_LIST,
  });
};

export const clearIsRequestState = () => ({
  type: types.CLEAR_IS_REQUEST_STATE
});

export const createPaymentAct = () => {
  return ({
    type: types.CREATE_PAYMENT_ACT,
  });
};

export const signPaymentAct = (id) => {
  return ({
    type: types.SIGN_PAYMENT_ACT,
    payload: {payment_act_id: id}
  });
};

export const selectPaymentAct = (id) => ({
  type: types.SELECT_PAYMENT_ACT,
  payload: id,
});


export const getPaymentActDetails = (id) => {
  return ({
    type: types.GET_PAYMENT_ACT_DETAILS,
    payload: {payment_act_id: id}
  });
};

export const toggleVisiblePaymentActDetailsModal = (payload) => {
  return ({
    type: types.TOGGLE_PAYMENT_ACT_DETAILS_MODAL,
    payload: payload
  });
};
