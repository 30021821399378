import * as api from "./api";
import * as types from "../store/actionTypes";
import {constructField, isObject} from "../utils";
import {put as putEffect, select, takeLatest} from "redux-saga/effects";


export function* getPolicySaga({payload}) {
  yield api.get("find_policy/", payload, types.GET_POLICY)
}

export function* calculateKKMSaga() {
  const stateForm = yield select(state => state.kkmReducer.dataFormResult);
  let formFields = {};

  for (const item in stateForm) {
    formFields[item] = (isObject(stateForm[item])
    )
      ? stateForm[item].value
      : stateForm[item];
    if (formFields[item] === "") {
      formFields[item] = undefined
    }
  }

  yield api.post(`cross/calculate/vsk/`, null, formFields, types.SEND_DATA_FORM_KKM);
}

export function* createPolicyKKMSaga({payload}) {
  yield api.post(`cross/create_policy/vsk/`, null, payload, types.CREATE_POLICY_KKM)
}

export function* getPaymentStatusSaga({payload}) {
  yield api.post(`cross/payment/vsk/status/`, null, payload, types.GET_PAYMENT_STATUS_KKM)
}

export function* getInfoByVinSaga({payload}) {
  yield api.post("osago/vehicle/make_report/", null, payload, types.GET_INFO_BY_VIN_KKM)

  const brand_avtocod_id = yield select(state => state.kkmReducer.kkmDataForm.brand_avtocod_id)
  if (brand_avtocod_id && brand_avtocod_id.value) {
    const carBrands = yield select(state => state.kkmReducer.carBrands)
    let brandData = carBrands.find((item) => item["avtocod_id"] === brand_avtocod_id.value)
    if (brandData) {
      let brandCar = constructField("car_brand", brandData.value.toString())
      yield putEffect({type: types.SAVE_CAR_BRAND_KKM, payload: brandCar})
      const model_avtocod_id = yield select(state => state.kkmReducer.kkmDataForm.model_avtocod_id)
      if (model_avtocod_id) {
        yield* api.get("osago/get_car_models/", {brand: brandData.value}, types.GET_CAR_MODELS)
        const carModels = yield select(state => state.kkmReducer.carModels);
        let modelData = carModels.find((item) => item["avtocod_id"] === model_avtocod_id.value)
        if (modelData) {
          let modelCar = constructField("car_model", modelData.value.toString())
          yield putEffect({type: types.SAVE_CAR_MODEL_KKM, payload: modelCar})
        }
      }
    }
  }
}

export function* getKKMAddressDataSaga({address_data}) {
  yield api.post(
    "osago/get_kladr/",
    null,
    {address: address_data},
    types.GET_KKM_ADDRESS_DATA
  )
}


export function* populateBrandModelDataSaga() {
  yield* api.get("osago/get_car_brands/", null, types.GET_CAR_BRANDS);
  const car_brand = yield select(state => state.kkmReducer.kkmDataForm.car_brand);
  if (car_brand && car_brand.value) {
    yield* api.get("osago/get_car_models/", {brand: car_brand.value}, types.GET_CAR_MODELS);

  }
}


export function* getProgramsSaga() {
  yield* api.get("kkm_programs/", null, types.GET_PROGRAMS_KKM);

}


export default function* () {
  yield takeLatest(types.GET_INFO_BY_VIN_KKM, getInfoByVinSaga);
  yield takeLatest(types.GET_POLICY, getPolicySaga);
  yield takeLatest(types.SEND_DATA_FORM_KKM, calculateKKMSaga);
  yield takeLatest(types.CREATE_POLICY_KKM, createPolicyKKMSaga);
  yield takeLatest(types.GET_KKM_ADDRESS_DATA, getKKMAddressDataSaga);
  yield takeLatest(types.GET_PAYMENT_STATUS_KKM, getPaymentStatusSaga);
  yield takeLatest(types.RESUME_DRAFT_KKM_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.COPY_POLICY_KKM_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.RESUME_POLICY_KKM_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_POLICY_SUCCESS, populateBrandModelDataSaga);
  yield takeLatest(types.GET_POLICY_SUCCESS, getProgramsSaga);
  yield takeLatest(types.GET_PROGRAMS_KKM, getProgramsSaga);
}
