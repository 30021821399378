export const isRequestSelector = state => state.kkmReducer.isRequest;
export const currentStepSelector = state => state.kkmReducer.currentStep;
export const errorsKKMSelector = state => state.kkmReducer.errors;
export const visibleErrorModalKKMSelector = state => state.kkmReducer.visibleErrorModalKkm;
export const kkmDataFormSelector = state => state.kkmReducer.kkmDataForm;
export const carBrandsSelector = state => state.kkmReducer.carBrands;
export const carModelsSelector = state => state.kkmReducer.carModels;
export const regAddressArrSelector = state => state.kkmReducer.regAddressArr;
export const priceSelector = state => state.kkmReducer.price;
export const agentCommissionSelector = state => state.kkmReducer.agentCommission;
export const draftIdSelector = state => state.kkmReducer.draft_id;
export const paymentUrlSelector = state => state.kkmReducer.payment_url;
export const withoutVinSelector = state => state.kkmReducer.kkmDataForm.without_vin.value;
export const withoutCarPlateSelector = state => state.kkmReducer.kkmDataForm.without_car_plate.value;
export const paymentStatusSelector = state => state.kkmReducer.paymentStatus;
export const documentsSelector = state => state.kkmReducer.documents;
export const paymentDocsSelector = state => state.kkmReducer.paymentDocs;
export const programsSelector = state => state.kkmReducer.programs;
export const dtpCoveragesSelector = state => state.kkmReducer.dtpCoverages;
export const accidentCoveragesSelector = state => state.kkmReducer.accidentCoverages;
export const evacuationCoveragesSelector = state => state.kkmReducer.evacuationCoverages;
export const juristicConsultCoveragesSelector = state => state.kkmReducer.juristicConsultCoverages;
export const commissarCoveragesSelector = state => state.kkmReducer.commissarCoverages;
export const emergencyHelpCoveragesSelector = state => state.kkmReducer.emergencyHelpCoverages;
export const evacuationPriceSelector = state => state.kkmReducer.evacuationPrice;
export const juristicConsultPriceSelector = state => state.kkmReducer.juristicConsultPrice;
export const commissarPriceSelector = state => state.kkmReducer.commissarPrice;
export const emergencyHelpPriceSelector = state => state.kkmReducer.emergencyHelpPrice;
