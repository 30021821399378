import React from "react";
import {Checkbox, Row} from "antd";
import personalDataProcessingConsent from "../../../assets/files/common/personalDataProcessingConsent.pdf";
import {isPDProcessingConsentCheckedKey} from "../../../utils";


export default class PersonalDataCheckbox extends React.Component {

  render() {
    const {
      getFieldDecorator,
      getFieldValue,
      checkboxText="Согласие клиента на обработку персональных данных"
    } = this.props

    return (

      <Row>
        {getFieldDecorator(isPDProcessingConsentCheckedKey, {initialValue: false})(
          <Checkbox
            checked={getFieldValue(isPDProcessingConsentCheckedKey)}
            className={"hyd-norm-text"}
          >
            <a
              onClick={event => {
                event.stopPropagation()
              }}
              href={personalDataProcessingConsent}
              target="_blank"
              rel="noopener noreferrer"
            >
              {checkboxText}
            </a>
          </Checkbox>
        )}
      </Row>

    )
  }
}
