import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ModalError from "../../components/ModalError";
import {Button, Empty, Layout, Skeleton, Spin, Table} from "antd";
import {
  paymentActsCountSelector,
  paymentActsListSelector,
  paymentActsFiltersSelector,
  isRequestSelector,
  selectedActSelector,
} from "../../selectors/paymentActsSelectors";
import {
  createPaymentAct,
  getPaymentActDetails,
  getPaymentActsList,
  signPaymentAct,
  selectPaymentAct,
  clearIsRequestState
} from "../../actions/paymentActsActions";
import {checkEarnedMoneyAmount, getNotifications} from "../../actions/mainActions";
import {
  generateDateSortedColumn,
  generateFilteredColumn,
  generateSearchedColumn,
  generateSortedColumn,
  rowSelection
} from "../../utilsTable";
import ModalPaymentActDetails from "../../components/Form/ModalPaymentActDetails";
import {earnedMoneySelector} from "../../selectors/mainSelectors";
import * as keys from "../../routers/keys";
import {userInfoSelector} from "../../selectors/loginSelectors";

const {Content} = Layout;


class PaymentActs extends Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    searchText: '',
    searchedColumn: '',
    pageNum: 1,
    pageSize: 10,
  }

  componentDidMount() {
    document.title = "Акты выплат"
    const {
      getPaymentActsList,
      clearIsRequestState,
      getNotifications,
      checkEarnedMoneyAmount,
      userInfo,
      history,
      selectPaymentAct
    } = this.props;
    if (!userInfo.can_access_payment_acts) {
      history.replace(keys.HOME)
    }
    clearIsRequestState()
    getNotifications()
    selectPaymentAct(null)
    checkEarnedMoneyAmount()
    getPaymentActsList()
  }

  handleChange = (pagination, filters, sorter: { currentDataSource: [] }) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    })
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({searchText: ""})
  }

  render() {
    const {
      paymentActsCount,
      paymentActsList,
      isRequest,
      createPaymentAct,
      signPaymentAct,
      getPaymentActDetails,
      selectPaymentAct,
      selectedAct,
      earnedMoney,
      paymentActsFilters,
    } = this.props;
    let {sortedInfo, filteredInfo } = this.state
    sortedInfo = sortedInfo || {}
    filteredInfo = filteredInfo || {}
    const paymentActsColumns = [
      generateSearchedColumn(this.state, this.searchInput, this.handleSearch, this.handleReset, "act_number", "Акт, №"),
      generateDateSortedColumn(sortedInfo, "created", "Дата создания"),
      {
        title: <div className="hyd-table-header">{"Подтвержденных"}</div>,
        children: [
          generateSortedColumn(sortedInfo, "payments_count", "шт"),

          {
            ...generateSortedColumn(sortedInfo, "payments_policies_sum", "Сумма"),
            render: (text) => `${text} ₽`
          },
          {
            ...generateSortedColumn(sortedInfo, "payments_sum", "К выплате"),
            render: (text) => `${text} ₽`
          },
        ]
      },
      {
        title: <div className="hyd-table-header">{"Расхождений"}</div>,
        children: [
          generateSortedColumn(sortedInfo, "discrepancies_count", "шт"),
          {
            ...generateSortedColumn(sortedInfo, "discrepancies_policies_sum", "Сумма"),
            render: (text) => `${text} ₽`
          },
          {
            ...generateSortedColumn(sortedInfo, "discrepancies_sum", "К выплате"),
            render: (text) => `${text} ₽`
          },
        ]
      },
      generateFilteredColumn(paymentActsFilters, filteredInfo, "status", "Статус"),
    ]
    return (
      <>
        <Content className="content-wrapper">
          <div className="hyd-contracts-container">

            <div className="hyd-contracts-title">
              <span className="hyd-very-big-bold-text-small">
                Выплаты
                <span className="hyd-contracts-count">
                  {paymentActsCount}
                </span>
              </span>
            </div>
            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              style={{width: 160}}
              onClick={() => {
                createPaymentAct()
              }}
              disabled={!(earnedMoney>0)}
            >
              <span>Сформировать</span>
            </Button>
            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              style={{width: 128}}
              onClick={() => {
                selectedAct && signPaymentAct(selectedAct.id)
              }}
              disabled={!(selectedAct && selectedAct.status === "Согласован")}
            >
              <span>Подписать</span>
            </Button>
            <Button
              className="hyd-medium-btn-grey hyd-contracts-btn"
              target="_blank"
              style={{width: 128}}
              onClick={() => {
                selectedAct && getPaymentActDetails(selectedAct.id)
              }}
              disabled={!selectedAct}
            >
              <span>Просмотр</span>
            </Button>
            <div className="hyd-contracts-table">
              <Table
                bordered
                size={"small"}
                columns={paymentActsColumns}
                onRow={(record) => {
                  return {
                    onDoubleClick: () => {
                      getPaymentActDetails(record.id)
                    }
                  }
                }}
                dataSource={isRequest ? [] : paymentActsList}
                locale={{
                  emptyText: isRequest ?
                    <div><Spin/><Skeleton active={true}/></div> :
                    <Empty/>
                }}
                onChange={this.handleChange}
                rowSelection={rowSelection(selectPaymentAct)}
                rowClassName={"hyd-table-content"}
                pagination={{
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                  showSizeChanger: true,
                  hideOnSinglePage: true,
                  size: "large",
                }}
                scroll={{x: 'max-content'}}
              />
            </div>
          </div>
        </Content>
        <ModalError type={12}/>
        <ModalPaymentActDetails/>
      </>
    )
  }
}

const mapStateToProps = state => ({
  paymentActsCount: paymentActsCountSelector(state),
  paymentActsList: paymentActsListSelector(state),
  isRequest: isRequestSelector(state),
  selectedAct: selectedActSelector(state),
  earnedMoney: earnedMoneySelector(state),
  userInfo: userInfoSelector(state),
  paymentActsFilters: paymentActsFiltersSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPaymentActsList,
      createPaymentAct,
      signPaymentAct,
      getPaymentActDetails,
      selectPaymentAct,
      clearIsRequestState,
          getNotifications,
    checkEarnedMoneyAmount,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PaymentActs);
