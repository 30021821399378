import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {calculateFilters, replaceNullWithEmptyString} from "../utils";

const filterColumns = [
  "policy_type",
  "insurance_name",
  "product_name",
  "car_category",
  "car_brand_name",
  "car_model_name",
  "car_year_issue",
  "common_kbm",
  "policy_status",
  "payment_type",
  "payment_status",
]

export default injectReducer(initialState.contractsReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.contractsReducer
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModal: payload
  }),

  [types.GET_EXPORT_DOCUMENT_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_EXPORT_DOCUMENT_SUCCESS]: (state, {payload}) => {
    let fileURL = URL.createObjectURL(payload);
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute(
      'download',
      `FileName.xlsx`,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    return {
      ...state,
      isRequest: false,
      errors: null,
    };
  },

  [types.GET_EXPORT_DOCUMENT_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true,
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.GET_CONTRACTS_LIST_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    contractsList: [],
  }),

  [types.GET_CONTRACTS_LIST_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    contractsList: replaceNullWithEmptyString(payload),
    contractsCount: payload.length,
    contractsFilters: calculateFilters(payload, filterColumns),
    errors: null
  }),

  [types.GET_CONTRACTS_LIST_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModal: true
  }),


  [types.TOGGLE_VIEW_DOCS_MODAL]: (state, {payload}) => ({
    ...state,
    visibleViewDocsModal: payload
  }),

  [types.GET_POLICY_DOCS]: state => ({
    ...state
  }),

  [types.GET_POLICY_DOCS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    policyDocs: undefined
  }),

  [types.GET_POLICY_DOCS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      policyDocs: payload
    };
  },

  [types.GET_POLICY_DOCS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    policyDocs: undefined,
    visibleErrorModal: true
  }),

  [types.GET_PAYMENT_STATUS_COMMON_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
    policyDocs: undefined
  }),

  [types.GET_PAYMENT_STATUS_COMMON_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      errors: null,
      policyDocs: payload
    };
  },

  [types.GET_PAYMENT_STATUS_COMMON_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    policyDocs: undefined,
    visibleErrorModal: true
  }),

});
