// insurances
export const ALFA = "alfastrah"
export const ALFA_REGIONS = "alfa_regions"
export const RENINS = "renesans"
export const INGOS = "ingostrah"
export const VSK = "vsk"
export const RGS = "rosgosstrah"
export const HELIOS = "gelios"
export const ABSOLUT = "absolut"
export const UGSK = "ugsk"
export const SEVA = "seva"
export const MAX = "makc"

// product types
export const BSO = "bso"
export const eBSO = "ebso"
export const eOSAGO = "eosago"
export const ACCIDENT = "accident"
export const MORTGAGE = "mortgage"
export const CROSS = "cross"

// urls
export const SEVAINS_URL = "https://sevains.ru/"
