import initialState from "../store/initialState";
import injectReducer from "../store/injectReducer";
import * as types from "../store/actionTypes";
import {
  transform,
  constructField,
  validateNullsDelete,
  validateNulls,
  addressCharacteristicsList,
  validateIdentityDocs,
} from "../utils"
import {ACCIDENT} from "../constants"


function constructDataForm(data, is_delete) {
  if (is_delete) {
    validateNullsDelete(data)
  } else {
    validateNulls(data)
  }
  let data_form = {...initialState.kkmReducer.kkmDataForm}
  for (let item in data) {
    if (data[item] === "" && data_form[item] !== undefined) {

    } else if (item === "email" && data[item]) {
      data_form["policy_owner_email"] = constructField(item, data[item])
    } else if (item === "car_brand" && data[item]) {
      data_form[item] = constructField(item, data[item].toString())
    } else if (item === "car_model" && data[item]) {
      data_form[item] = constructField(item, data[item].toString())
    } else if (addressCharacteristicsList.includes(item)) {
      data_form[item] = data[item]
    } else {
      data_form[item] = constructField(item, data[item])
    }
  }
  data_form["policy_owner_address"] = constructField("policy_owner_address", data_form["policy_owner_cladr_address"])
  data_form["car_owner_address"] = constructField("car_owner_address", data_form["car_owner_cladr_address"])
  validateIdentityDocs(data_form, "policy_owner_identity_document", "policy_owner_serial", "policy_owner_number")
  validateIdentityDocs(data_form, "car_owner_identity_document", "car_owner_serial", "car_owner_number")
  if (data_form["car_document_serial"] && data_form["car_document_number"] && data_form["car_document_serial"].value) {
    data_form["car_document_series_number"] = {
      value: `${data_form["car_document_serial"].value} ${data_form["car_document_number"].value}`,
      name: "car_document_series_number",
      errors: false,
      validating: false,
      dirty: false
    }
  } else if (data_form["car_document_number"]) {
    data_form["car_document_series_number"] = data_form["car_document_number"]
  }

  return data_form
}

function getCoverageForm(state, coverages) {
  const dtpCoverages = coverages.filter(value => value.risk_type === "dtp")
  const accidentCoverages = coverages.filter(value => value.risk_type === ACCIDENT)
  const evacuationCoverages = coverages.filter(value => value.risk_type === "evacuation")
  const juristicConsultCoverages = coverages.filter(value => value.risk_type === "juristic_consult")
  const commissarCoverages = coverages.filter(value => value.risk_type === "commissar")
  const emergencyHelpCoverages = coverages.filter(value => value.risk_type === "emergency_help")
  return {
    dtpCoverages: dtpCoverages,
    accidentCoverages: accidentCoverages,
    evacuationCoverages: evacuationCoverages,
    juristicConsultCoverages: juristicConsultCoverages,
    commissarCoverages: commissarCoverages,
    emergencyHelpCoverages: emergencyHelpCoverages,
    evacuationPrice: evacuationCoverages[0] && evacuationCoverages[0].cost,
    juristicConsultPrice: juristicConsultCoverages[0] && juristicConsultCoverages[0].cost,
    commissarPrice: commissarCoverages[0] && commissarCoverages[0].cost,
    emergencyHelpPrice: emergencyHelpCoverages[0] && emergencyHelpCoverages[0].cost,
    kkmDataForm: {
      ...state.kkmDataForm,
      dtp_cover: constructField("dtp_cover", dtpCoverages[0] ? dtpCoverages[0].id : undefined),
      dtp_price: constructField("dtp_price", dtpCoverages[0] ? dtpCoverages[0].cost : undefined),
      accident_cover: constructField("accident_cover", accidentCoverages[0] ? accidentCoverages[0].id : undefined),
      accident_price: constructField("accident_price", accidentCoverages[0] ? accidentCoverages[0].cost : undefined),
    },
  }
}


export default injectReducer(initialState.kkmReducer, {

  [types.SIGN_OUT]: state => ({
    ...state,
    ...initialState.kkmReducer
  }),

  [types.CLEAR_IS_REQUEST_STATE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [types.TOGGLE_ERROR_MODAL]: (state, {payload}) => ({
    ...state,
    visibleErrorModalKkm: payload,
  }),

  [types.CLEAR_FORM]: (state, {payload}) => {
    if (payload === 9) {
      return {
        ...state,
        ...initialState.kkmReducer
      }
    } else {
      return {
        ...state
      }
    }
  },

  [types.GO_TO_NEXT_STEP_KKM]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.GO_TO_PREV_STEP_KKM]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),

  [types.SET_DATA_FORM_KKM]: (state, action) => ({
    ...state,
    kkmDataForm: {...state.kkmDataForm, ...action.payload.data}
  }),

  [types.GET_CAR_BRANDS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CAR_BRANDS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    carBrands: payload,
    errors: null
  }),

  [types.GET_CAR_BRANDS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalKkm: true
  }),

  [types.GET_CAR_MODELS_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null
  }),

  [types.GET_CAR_MODELS_SUCCESS]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    carModels: payload,
    errors: null
  }),

  [types.GET_CAR_MODELS_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalKkm: true
  }),

  [types.GET_INFO_BY_VIN_KKM]: state => ({
    ...state
  }),

  [types.GET_INFO_BY_VIN_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    reportuid: null,
    errors: undefined
  }),

  [types.GET_INFO_BY_VIN_KKM_SUCCESS]: (state, {payload}) => {
    let obj = null;

    let report = {
      car_plate: {
        value: state.kkmDataForm.without_car_plate.value ? undefined : payload.result.car_plate
      },
      vin: {
        value: state.kkmDataForm.without_vin.value ? undefined : payload.result.vin
      },
      car_year_issue: {
        value: payload.result.year
      },
      engine_power: {
        value: parseInt(payload.result.engine_power)
      },
      brand_avtocod_id: {
        value: payload.result.brand
      },
      model_avtocod_id: {
        value: payload.result.model
      },
    }

    return {
      ...state,
      isRequest: false,
      kkmDataForm: {
        ...state.kkmDataForm,
        ...transform(report),
        ...obj
      },
      errors: null
    }
  },

  [types.GET_INFO_BY_VIN_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    reportuid: null,
    errors: payload,
    visibleErrorModalKkm: true
  }),

  [types.SAVE_CAR_BRAND_KKM]: (state, action) => {
    let obj = {car_brand: action.payload};

    return {
      ...state,
      kkmDataForm: {...state.kkmDataForm, ...obj},
    }
  },

  [types.SAVE_CAR_MODEL_KKM]: (state, action) => {
    let obj = {car_model: action.payload};

    return {
      ...state,
      kkmDataForm: {...state.kkmDataForm, ...obj},
    }
  },

  [types.GET_POLICY_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_POLICY_SUCCESS]: (state, {payload}) => {
    const draft_data = {
      ...payload['draft'],
      draft_id: undefined,
      seller: undefined,
    }
    draft_data["car_owner_identity_document"] = draft_data["identity_document"]
    let kkmDataForm = constructDataForm(draft_data, true)

    let policyOwnerPhone = (kkmDataForm["phone"]?.value !== undefined && kkmDataForm["phone"]?.value.includes("7")) ? (kkmDataForm["phone"]?.value.includes("+") ? kkmDataForm["phone"]?.value : "+" + kkmDataForm["phone"]?.value) : undefined
    let carOwnerPhone = (kkmDataForm["car_owner_phone"]?.value !== undefined && kkmDataForm["car_owner_phone"]?.value.includes("7")) ? (kkmDataForm["car_owner_phone"]?.value.includes("+") ? kkmDataForm["car_owner_phone"]?.value : "+" + kkmDataForm["car_owner_phone"]?.value) : undefined
    kkmDataForm["policy_owner_phone"] = constructField("policy_owner_phone", policyOwnerPhone)
    kkmDataForm["car_owner_phone"] = constructField("car_owner_phone", carOwnerPhone)

    kkmDataForm["without_vin"] = constructField("without_vin", kkmDataForm["vin"] === undefined)
    kkmDataForm["without_car_plate"] = constructField("without_car_plate", kkmDataForm["car_plate"] === undefined)
    kkmDataForm["osago_policy_series"] = constructField("osago_policy_series", payload["policy_series"])
    kkmDataForm["osago_policy_number"] = constructField("osago_policy_number", payload["policy_num"])

    return {
      ...state,
      isRequest: false,
      kkmDataForm: {
        ...kkmDataForm,
      },
      errors: null,
    }
  },

  [types.GET_POLICY_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalKkm: true,
  }),

  [types.GET_KKM_ADDRESS_DATA]: (state, {address_type}) => {
    return ({
      ...state,
      requestedAddressType: address_type,
    })
  },

  [types.GET_KKM_ADDRESS_DATA_REQUEST]: state => {
    return ({
      ...state,
      isRequest: true,
      errors: null,
    })
  },

  [types.GET_KKM_ADDRESS_DATA_SUCCESS]: (state, {payload}) => {
    let address = payload

    payload.map((item, i) => {
      address[i].city_kladr = item.city_kladr;
      address[i].value_kladr = item.settlement_kladr_id && item.settlement_kladr_id.length === 13 ? item.settlement_kladr_id :
        item.city_kladr_id ? item.city_kladr_id :
          item.area_kladr_id ? item.area_kladr_id :
            item.region_kladr_id;
      address[i].value = item.text
    })


    return {
      ...state,
      isRequest: false,
      errors: null,
      regAddressArr: address,
    }
  },

  [types.GET_KKM_ADDRESS_DATA_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: {errors: payload.errors.error},
    visibleErrorModalMortgage: true,
  }),


  [types.KKM_SAVE_CAR_OWNER_CLADR]: (state, action) => {
    let obj = {};
    let key1 = `car_owner_cladr`;
    let key2 = `car_owner_cladr_address`;
    let key3 = `car_owner_cladr_okato`;
    let key4 = `car_owner_cladr_country`;
    let key5 = `car_owner_cladr_region`;
    let key6 = `car_owner_cladr_zip`;
    let key7 = `car_owner_cladr_city_name`;
    let key8 = `car_owner_cladr_city_kladr`;
    let key9 = `car_owner_cladr_street_kladr`;
    let key10 = `car_owner_cladr_street_name`;
    let key11 = `car_owner_cladr_house`;
    let key12 = `car_owner_cladr_block`;
    let key13 = `car_owner_cladr_flat`;
    let key14 = `car_owner_fias_id`;
    let key15 = `car_owner_house_fias_id`;

    //obj = { car_owner_cladr: action.payload };
    obj[key1] = action.payload.value === null ? undefined : action.payload.value;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    obj[key3] = action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] = action.payload.country === null ? undefined : action.payload.country;
    obj[key5] = action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] = action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] = action.payload.city_kladr === null ? undefined : action.payload.city_kladr;
    obj[key9] = action.payload.street_kladr === null ? undefined : action.payload.street_kladr;
    obj[key10] = action.payload.street_name === null ? undefined : action.payload.street_name;
    obj[key11] = action.payload.house === null ? undefined : action.payload.house;
    obj[key12] = action.payload.block === null ? undefined : action.payload.block;
    obj[key13] = action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] = action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      kkmDataForm: {...state.kkmDataForm, ...obj},
      errors: null
    };
  },

  [types.KKM_SAVE_POLICY_OWNER_CLADR]: (state, action) => {
    let obj = {};
    let key1 = `policy_owner_cladr`;
    let key2 = `policy_owner_cladr_address`;
    let key3 = `policy_owner_cladr_okato`;
    let key4 = `policy_owner_cladr_country`;
    let key5 = `policy_owner_cladr_region`;
    let key6 = `policy_owner_cladr_zip`;
    let key7 = `policy_owner_cladr_city_name`;
    let key8 = `policy_owner_cladr_city_kladr`;
    let key9 = `policy_owner_cladr_street_kladr`;
    let key10 = `policy_owner_cladr_street_name`;
    let key11 = `policy_owner_cladr_house`;
    let key12 = `policy_owner_cladr_block`;
    let key13 = `policy_owner_cladr_flat`;
    let key14 = `policy_owner_fias_id`;
    let key15 = `policy_owner_house_fias_id`;
    //	zip, city_kladr, street_kladr, street_name, house

    obj[key1] = action.payload.value === null ? undefined : action.payload.value;
    obj[key2] = action.payload.address === null ? undefined : action.payload.address;
    obj[key3] = action.payload.okato === null ? undefined : action.payload.okato;
    obj[key4] = action.payload.country === null ? undefined : action.payload.country;
    obj[key5] = action.payload.region === null ? undefined : action.payload.region;
    obj[key6] = action.payload.zip === null ? undefined : action.payload.zip;
    obj[key7] = action.payload.city_name === null ? undefined : action.payload.city_name;
    obj[key8] = action.payload.city_kladr === null ? undefined : action.payload.city_kladr;
    obj[key9] = action.payload.street_kladr === null ? undefined : action.payload.street_kladr;
    obj[key10] = action.payload.street_name === null ? undefined : action.payload.street_name;
    obj[key11] = action.payload.house === null ? undefined : action.payload.house;
    obj[key12] = action.payload.block === null ? undefined : action.payload.block;
    obj[key13] = action.payload.flat === null ? undefined : action.payload.flat;
    obj[key14] = action.payload.fias_id === null ? undefined : action.payload.fias_id;
    obj[key15] = action.payload.house_fias_id === null ? undefined : action.payload.house_fias_id;
    return {
      ...state,
      isRequest: false,
      kkmDataForm: {...state.kkmDataForm, ...obj},
      errors: null
    };
  },

  [types.SEND_DATA_FORM_KKM]: state => {
    let dataFormResult = state.kkmDataForm
    if (state.kkmDataForm.policy_owner_address && state.kkmDataForm.policy_owner_address.value) {
      let valueAddr = state.kkmDataForm.policy_owner_address.value;

      if (valueAddr.indexOf("_") !== -1) {
        valueAddr = valueAddr.split("_")[1];
      }

      dataFormResult.policy_owner_address.value = valueAddr;
    }

    if (state.kkmDataForm.car_owner_address && state.kkmDataForm.car_owner_address.value) {
      let valueAddr = state.kkmDataForm.car_owner_address.value;

      if (valueAddr.indexOf("_") !== -1) {
        valueAddr = valueAddr.split("_")[1];
      }
      dataFormResult.car_owner_address.value = valueAddr;
    }

    return {
      ...state,
      dataFormResult: dataFormResult,
      price: undefined,
      agentCommission: {},
    };
  },


  [types.SEND_DATA_FORM_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.SEND_DATA_FORM_KKM_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      draft_id: payload.draft_id,
      price: payload.price,
      agentCommission: payload.agent_commission,
      isRequest: false,
      errors: null,
    }
  },

  [types.SEND_DATA_FORM_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalKkm: true,
  }),


  [types.CLEAR_CALC_DATA_KKM]: (state) => ({
    ...state,
    draft_id: initialState.kkmReducer.draft_id,
    price: initialState.kkmReducer.price,
    agentCommission: initialState.kkmReducer.agentCommission,
  }),


  [types.GET_PAYMENT_STATUS_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    paymentStatus: undefined,
    errors: null,
  }),

  [types.GET_PAYMENT_STATUS_KKM_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      paymentStatus: payload.payment_status,
      documents: payload.documents && payload.documents.length > 0 ? payload.documents : state.documents,
      errors: null,
    }
  },

  [types.GET_PAYMENT_STATUS_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentStatus: undefined,
    errors: payload,
    visibleErrorModalKkm: true,
  }),


  [types.CREATE_POLICY_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    documents: undefined,
    errors: null,
  }),

  [types.CREATE_POLICY_KKM_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isRequest: false,
      documents: payload.documents,
      payment_url: payload.payment_url,
      currentStep: state.currentStep + 1,
      errors: null,
    }
  },

  [types.CREATE_POLICY_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    paymentStatus: undefined,
    errors: payload,
    visibleErrorModalKkm: true,
  }),


  [types.RESUME_DRAFT_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_DRAFT_KKM_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload)

    const draft_id = payload.id
    return {
      ...state,
      isRequest: false,
      kkmDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      draft_id: payload.id,
      price: payload.price,
      agentCommission: payload.agent_commission,
      errors: null,
      currentStep: 0,
    }
  },

  [types.RESUME_DRAFT_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),


  [types.COPY_POLICY_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.COPY_POLICY_KKM_SUCCESS]: (state, {payload}) => {
    let dataForm = constructDataForm(payload)
    return {
      ...state,
      isRequest: false,
      kkmDataForm: {
        ...dataForm,
        draft_id: undefined
      },
      draft_id: undefined,
      errors: null,
      currentStep: 0,
    }
  },

  [types.COPY_POLICY_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),


  [types.RESUME_POLICY_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.RESUME_POLICY_KKM_SUCCESS]: (state, {payload}) => {
    const draft_id = payload.id
    let dataForm = constructDataForm(payload)
    let agent_commission = {
      identifier: payload.agent_commission_rule,
      value: payload.agent_commission
    }
    return {
      ...state,
      isRequest: false,
      kkmDataForm: {
        ...dataForm,
        draft_id: draft_id,
      },
      documents: payload.document_files,
      payment_url: payload.payment_url,
      draft_id: draft_id,
      price: payload.premium_sum,
      agentCommission: agent_commission,
      errors: null,
      currentStep: 1,
    }
  },

  [types.RESUME_POLICY_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),

  [types.GET_PROGRAMS_KKM_REQUEST]: state => ({
    ...state,
    isRequest: true,
    errors: null,
  }),

  [types.GET_PROGRAMS_KKM_SUCCESS]: (state, {payload}) => {
    let program
    if (state.kkmDataForm.coverage_program.value) {
      program = payload.find((value) => value.id === state.kkmDataForm.coverage_program.value)
    }
    if (program === undefined) {
      program = payload[0]
    }
    let new_state = {
      ...state,
      isRequest: false,
      programs: payload,
      ...getCoverageForm(state, program.coverages)
    }
    return {
      ...new_state,
      kkmDataForm: {
        ...new_state.kkmDataForm,
        coverage_program: constructField("coverage_program", program.id)
      }
    }
  },

  [types.GET_PROGRAMS_KKM_FAILURE]: (state, {payload}) => ({
    ...state,
    isRequest: false,
    errors: payload,
    visibleErrorModalCross: true,
  }),

  [types.SET_COVERAGES]: (state, {payload}) => {

    return {
      ...state,
      ...getCoverageForm(state, payload.coverages)
    }
  },

})
